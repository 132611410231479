import React from 'react';
import './TapBadges.less';
import { Switch, Route } from 'react-router-dom';
import GeneratorProductTable from 'components/GeneratorProductTable/GeneratorProductTable';
import { generateTapBadges } from 'util/generate';
import TapBadgeGeneratorFormContent from './TapBadgeGeneratorFormContent/TapBadgeGeneratorFormContent';

const TapBadges = () => (
  <Switch>
    <Route
      exact
      path="/dashboard/tap-badges"
      render={() => (
        <GeneratorProductTable
          type="tap-badge"
          selectionType="multiple"
          pluralLabel="tap badges"
          GeneratorFormContent={TapBadgeGeneratorFormContent}
          onGenerate={generateTapBadges}
        />
      )}
    />
  </Switch>
);

export default TapBadges;
