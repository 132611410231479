import React, { useState } from 'react';
import {
  Form, Input, Button, Checkbox, Modal,
  notification,
} from 'antd';
import './EmailLoginForm.less';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import firebase from 'firebase';

const EmailLoginForm = () => {
  const [loggingIn, setLoggingIn] = useState(false);

  const onNeedAccountClicked = () => {
    Modal.confirm({
      centered: true,
      title: "Don't have an account yet? No problem!",
      content: "Just send us an email and and we'll set one up for you as soon as possible.",
      okText: 'Send email',
      onOk: () => {
        window.location.href = 'mailto:matrix@garageproject.co.nz?subject=Please create a Matrix account for me&body=Hi,%0D%0A%0D%0AI would like access to the Matrix but don\'t have an account, can you please create one for me?%0D%0A%0D%0A[PLEASE PROVIDE ANY ADDITIONAL DETAILS HERE]%0D%0A%0D%0AThanks!';
      },
      cancelText: 'Close',
    });
  };

  const onSubmit = ({ email, password, rememberMe }) => {
    setLoggingIn(true);
    firebase.auth()
      .setPersistence(rememberMe ? (
        firebase.auth.Auth.Persistence.LOCAL
      ) : (
        firebase.auth.Auth.Persistence.NONE
      ))
      .then(() => firebase.auth()
        .signInWithEmailAndPassword(email, password))
      .catch((error) => {
        setLoggingIn(false);
        notification.error({
          message: 'Error logging in',
          description: error.message,
        });
      });
  };

  return (
    <Form
      id="email-login-form"
      name="email-login-form"
      initialValues={{ rememberMe: true }}
      onFinish={onSubmit}
      validateMessages={{
        required: 'Email is required',
        types: {
          email: 'Please enter a valid email address',
        },
      }}
    >
      <div className="email-login-text-fields">
        <Form.Item
          name="email"
          rules={[{
            required: true,
            type: 'email',
          }]}
        >
          <Input
            prefix={<MailOutlined className="field-icon-prefix" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input
            prefix={<LockOutlined className="field-icon-prefix" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
      </div>
      <div className="horizontal-fields">
        <Form.Item name="rememberMe" valuePropName="checked" id="remember-me-checkbox">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            onClick={onNeedAccountClicked}
            className="inline-link-button"
          >
            Need an account?
          </Button>
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          id="login-button"
          loading={loggingIn}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailLoginForm;
