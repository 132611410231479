import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAYDJfaf4gJzHWDCxFQ-4orGpjriA2Xj5I',
  authDomain: 'gpmatrix.co.nz',
  projectId: 'garage-project-matrix',
  storageBucket: 'garage-project-matrix.appspot.com',
  messagingSenderId: '838677944332',
  appId: '1:838677944332:web:bc1b954df2f7a91b9cf629',
  measurementId: 'G-5T24P427Y2',
};

firebase.initializeApp(config);
firebase.analytics();

// export const storageRef = firebase.storage().ref();
