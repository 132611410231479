import React, { useContext, useState, useEffect } from 'react';
import './ShippingRates.less';
import * as api from 'util/api';
import { Button, Tooltip, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PageTabsContext } from 'util/PageTabs';
import { FullPageLoader } from 'components';
import ShippingRateCard from './ShippingRateCard/ShippingRateCard';

const ShippingRates = () => {
  const [shippingRates, setShippingRates] = useState(null);

  const shipFromCountry = useContext(PageTabsContext);

  useEffect(() => {
    if (shippingRates) {
      setShippingRates(null);
    }
    if (shipFromCountry) {
      api.getShippingRates(shipFromCountry).then(setShippingRates);
    }
  }, [shipFromCountry]);

  const currency = shipFromCountry === 'NZ' ? 'NZD' : 'AUD';

  const loadingShippingRates = shippingRates === null;

  return loadingShippingRates ? <FullPageLoader /> : (
    <div id="webstore-shipping-rates-page">
      {/* TODO */}
      {/* <div className="shipping-rate-test-address">
        <Button type="primary" disabled>
          Test address
        </Button>
      </div> */}
      <div className="shipping-rates-header">
        <Alert message={`Prices are in ${currency}`} type="info" showIcon />
        <Tooltip title="Coming soon">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled
          >
            Add new shipping rate
          </Button>
        </Tooltip>
      </div>
      <div className="shipping-rates-card-grid">
        {shippingRates?.map((shippingRate) => (
          <ShippingRateCard
            key={shippingRate.serviceCode}
            {...shippingRate}
          />
        ))}
        {/* <Tooltip title="Coming soon">
          <Card className="add-new-shipping-rate-card" bordered>
            <Button
              type="link"
              icon={<PlusOutlined />}
              disabled
            >
              Add new shipping rate
            </Button>
          </Card>
        </Tooltip> */}
      </div>
    </div>
  );
};

export default ShippingRates;
