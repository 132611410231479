import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8015'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/get-channels';

export default (country) => useQuery({
  queryKey: ['get_channels', country],
  queryFn: async () => (await axios({
    method: 'GET',
    url: `${URL}?country=${country}`,
  })).data.channels,
});
