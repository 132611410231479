import {
  Form, Select,
} from 'antd';
import React, { useMemo } from 'react';
import useArtistsQuery from './use-formats-query';

const FormatSelectField = ({ fieldName, type }) => {
  /**
   * Fetch the existing format options for the specified type (pack or keg).
   */
  const formatsQuery = useArtistsQuery(type);

  /**
   * When the array of formats has loaded, map the format values to a
   * set of options for the autocomplete suggestions.
   */
  const options = useMemo(() => (
    formatsQuery.isSuccess ? formatsQuery.data.map((format) => ({
      key: format,
      value: format,
      label: format,
    })) : []
  ), [formatsQuery]);

  return (
    <Form.Item
      name={fieldName}
      label={(
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          Format
          <p style={{
            opacity: 0.7,
            fontSize: 12,
            marginBottom: 0,
          }}
          >
            Select from an existing format, or enter a new one.
          </p>
        </div>
      )}
      labelCol={{ span: 24 }}
      className="product-editor-form-format"
    >
      <Select
        showSearch
        showArrow
        options={options}
        loading={formatsQuery.isLoading}
        notFoundContent={(() => {
          if (formatsQuery.isSuccess) {
            return `No existing ${type} formats found`;
          } if (formatsQuery.isError) {
            return `Error loading ${type} formats`;
          }
          return `Loading ${type} formats...`;
        })()}
        onBlur={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />

    </Form.Item>
  );
};

export default FormatSelectField;
