import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './StockTable.less';
import * as api from 'util/api.js';
import {
  Button, Table as AntTable, Switch, Typography,
} from 'antd';
import { CustomTable } from 'components';
import dayjs from 'dayjs';

const { Text } = Typography;
const { Column } = AntTable;

const StockTable = ({ location }) => {
  const [tableId, setTableId] = useState(`stock-availability-${location}`);
  const [stockData, setStockData] = useState(null);
  const [groupProducts, setGroupProducts] = useState(true);

  useEffect(() => {
    setStockData(null);
    setTableId(`stock-availability-${location}`);
    api.getStock(location).then(setStockData);
  }, [location]);

  return (
    <CustomTable
      key={location}
      id={tableId}
      pluralLabel="products"
      rowClassName={groupProducts ? 'expandable-row' : false}
      searchableColumns={groupProducts ? (
        ['name', 'sku', 'type', 'instances.location', 'instances.batch', 'instances.expiry']
      ) : (
        ['name', 'sku', 'type', 'location', 'batch', 'expiry']
      )}
      dataSource={groupProducts ? (stockData?.map((productData) => (
        {
          ...productData,
          key: productData.id, // Each row must have a unique key
        }
      ))) : (
        // Split product instances into separate rows
        stockData?.reduce((rows, { instances, ...product }) => {
          const newRows = [...rows];
          instances.forEach((instance) => {
            const key = (product.id || '') + (instance.location || '') + (instance.available || '') + (instance.batch || '');
            newRows.push({
              key, // Each row must have a unique key
              ...product,
              ...instance,
            });
          });
          return newRows;
        }, [])
      )}
      // =====================================================
      // TODO: User test toggle to beta test product groupings
      controls={(
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 12,
        }}
        >
          <Text type="secondary" style={{ marginRight: 12 }}>
            Group products
          </Text>
          <Switch
            checked={groupProducts}
            onChange={() => setGroupProducts((current) => !current)}
          />
        </div>
      )}
      // =====================================================
      expandable={groupProducts ? ({
        indentSize: 100,
        expandRowByClick: true,
        expandedRowRender: ({ id: rowId, instances }) => (instances ? (
          <CustomTable
            nested
            id={`${tableId}-${rowId}`}
            dataSource={instances.map((instanceData) => (
              {
                ...instanceData,
                key: `${instanceData.location || ''}${instanceData.available || ''}${instanceData.batch || ''}`, // Each row must have a unique key
              }
            ))}
            pagination={false}
            disableControls
          >
            <Column
              title="Location"
              dataIndex="location"
              key="location"
            />
            <Column
              title="Gyle"
              dataIndex="batch"
              key="batch"
            />
            <Column
              title="Available"
              dataIndex="available"
              key="available"
            />
            <Column
              title="Expiry"
              dataIndex="expiryDate"
              key="expiryDate"
              render={(value) => (dayjs.isDayjs(value) ? value.format('DD/MM/YYYY') : value)}
            />
          </CustomTable>
        ) : <div>No locations found</div>),
      }) : false}
    >
      <Column
        title="Product"
        key="name"
        dataIndex="name"
        sortType="string"
      />
      <Column
        title="SKU"
        key="sku"
        dataIndex="sku"
        sortType="string"
      />
      {/* TODO: Filter by all types by searching through products */}
      <Column
        title="Type"
        dataIndex="type"
        key="type"
      />

      {!groupProducts ? (
        <Column
          title="Location"
          dataIndex="location"
          key="location"
        />
      ) : <></>}
      {!groupProducts ? (
        <Column
          title="Gyle"
          dataIndex="batch"
          key="batch"
        />
      ) : <></>}

      {!groupProducts ? (
        <Column
          title="Available"
          dataIndex="available"
          key="available"
        />
      ) : <></>}

      {!groupProducts ? (
        <Column
          title="Expiry"
          dataIndex="expiryDate"
          key="expiryDate"
          render={(value) => (dayjs.isDayjs(value) ? value.format('DD/MM/YYYY') : value)}
        />
      ) : <></>}

    </CustomTable>
  );
};

StockTable.propTypes = {
  location: PropTypes.oneOf(Object.values(api.STOCK_LOCATIONS)).isRequired,
};

export default StockTable;
