import React from 'react';
import { Form, Input } from 'antd';
import { productProperties } from 'config/language';
import ChannelsSelectField from './ChannelsSelect';

const { TextArea } = Input;

const Extras = () => (
  <div className="columns-container">
    <div className="column">
      <Form.Item
        name="style"
        label={productProperties.style.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="malts"
        label={productProperties.malts.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
        />
      </Form.Item>
      <Form.Item
        name="hops"
        label={productProperties.hops.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
        />
      </Form.Item>
      <Form.Item
        name="tastingNotes"
        label={productProperties.tastingNotes.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
        />
      </Form.Item>
      <Form.Item
        name="story"
        label={productProperties.story.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
    </div>
    <div className="column">
      <Form.Item
        name="accolades"
        label={productProperties.accolades.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="brewSize"
        label={productProperties.brewSize.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <ChannelsSelectField country="nz" fieldName="channelsNz" label="Channels - New Zealand" />
      <ChannelsSelectField country="au" fieldName="channelsAu" label="Channels - Australia" />
      <Form.Item
        name="eventsAndMarketing"
        label={productProperties.eventsAndMarketing.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
      <Form.Item
        name="other"
        label={productProperties.other.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
    </div>
  </div>
);

export default Extras;
