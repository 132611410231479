import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './FullPageLoader.less';

const { Text } = Typography;

const FullPageLoader = ({ message }) => (
  <div className="full-page-spinner-container">
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
      tip={message}
    />
  </div>
);

FullPageLoader.propTypes = {
  message: PropTypes.string,
};

FullPageLoader.defaultProps = {
  message: null,
};

export default FullPageLoader;
