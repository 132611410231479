import React, { useCallback, useMemo } from 'react';
import {
  Table as AntTable, Button, Tag, Typography, notification,
} from 'antd';
import { sentenceCase } from 'change-case';
import { isEqual } from 'lodash';

import { CustomTable } from 'components';

import './index.less';
import {
  CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, MinusCircleOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import useEstablishMondayWebhooksMutation from './use-establish-monday-webhooks-mutation';
import useMondayWebhooksQuery from './use-monday-webhooks-query';
import {
  MondayWebhookEventType, WebhookStatus,
} from '../constants';

const { Paragraph } = Typography;
const { Column } = AntTable;

const WEBHOOK_STATUS_TAG_CONFIG = {
  [WebhookStatus.Checking]: {
    color: 'blue',
    icon: <LoadingOutlined spin />,
  },
  [WebhookStatus.ErrorChecking]: {
    color: 'red',
    icon: <ExclamationCircleOutlined />,
  },
  [WebhookStatus.Established]: {
    color: 'green',
    icon: <CheckCircleOutlined />,
  },
  [WebhookStatus.NotEstablished]: {
    color: 'red',
    icon: <MinusCircleOutlined />,
  },
};

const REQUIRED_WEBHOOKS = [
  { event: MondayWebhookEventType.CreateItem },
  { event: MondayWebhookEventType.ChangeName },
  { event: MondayWebhookEventType.ChangeColumnValue },
  { event: MondayWebhookEventType.ItemArchived },
  { event: MondayWebhookEventType.ItemDeleted },
  { event: MondayWebhookEventType.ItemRestored },
];

const WebhookStatusTable = () => {
  /**
   * Fetch the details for the webhooks.
   */
  const webhooksQuery = useMondayWebhooksQuery();

  /**
   * Define the data for the table.
   */
  const statuses = useMemo(() => REQUIRED_WEBHOOKS.map((webhook) => ({
    key: `${webhook.event}${webhook.config ? JSON.stringify(webhook.config) : ''}`,
    event: webhook.event,
    config: JSON.stringify(webhook.config, null, 2),
    status: (() => {
      if (webhooksQuery.isLoading) {
        return WebhookStatus.Checking;
      } if (webhooksQuery.isError) {
        return WebhookStatus.ErrorChecking;
      } if (webhooksQuery.data.find((establishedWebhook) => (
        establishedWebhook.event === webhook.event && (
          !webhook.config || (isEqual(webhook.config, establishedWebhook.config)))))
      ) {
        return WebhookStatus.Established;
      }
      return WebhookStatus.NotEstablished;
    })(),
  })), [webhooksQuery]);

  /**
   * Access the query client.
   */
  const queryClient = useQueryClient();

  /**
   * Remove the 'establish_monday_webhooks' query when the webhook establishment mutation
   * has completed (either by success or failure) to allow the query to be re-performed
   * to show the most up to state statuses.
   */
  const onEstablishWebhooksSettled = useCallback(() => {
    queryClient.removeQueries('establish_monday_webhooks');
  }, []);

  /**
   * Show a success notification when the webhooks have been established.
   */
  const onEstablishWebhooksSuccess = useCallback(() => {
    notification.success({
      message: 'Successfully established webhooks!',
    });
  }, []);

  /**
   * Show an error notification when the webhooks failed to establish.
   */
  const onEstablishWebhooksError = useCallback(() => {
    notification.error({
      message: 'There was an error establishing the webhooks. Please try again.',
    });
  }, []);

  /**
   * Mutation for invoking the establishment of the required Monday webhooks.
   */
  const {
    isLoading: isEstablishingWebhooks,
    mutate: establishWebhooks,
  } = useEstablishMondayWebhooksMutation({
    onSettled: onEstablishWebhooksSettled,
    onSuccess: onEstablishWebhooksSuccess,
    onError: onEstablishWebhooksError,
  });

  return (
    <div id="monday-webhook-status-container">
      <div style={{ marginBottom: 10 }}>
        <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
          The Matrix uses webhooks to listen for changes in the Monday product
          database board.
        </Paragraph>
        <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
          The webhooks listed below must be established on this board for the
          product syncing to operate correctly.
        </Paragraph>
      </div>
      <CustomTable
        id="monday-webhooks"
        pluralLabel="webhooks"
        dataSource={statuses}
        searchableColumns={['event']}
        controls={(
          <Button
            type="primary"
            onClick={establishWebhooks}
            loading={isEstablishingWebhooks}
          >
            {isEstablishingWebhooks ? 'Establishing webhooks...' : 'Establish webhooks'}
          </Button>
        )}
      >
        <Column
          title="Board event"
          key="event"
          dataIndex="event"
        />
        <Column
          title="Event config"
          key="config"
          dataIndex="config"
        />
        <Column
          title="Webhook status"
          key="status"
          dataIndex="status"
          render={(status) => {
            const { color, icon } = WEBHOOK_STATUS_TAG_CONFIG[status];
            return (
              <Tag
                icon={icon}
                color={color}
              >
                {sentenceCase(status)}
              </Tag>
            ); }}
        />
      </CustomTable>
    </div>
  );
};

export default WebhookStatusTable;
