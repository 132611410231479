import React, { useContext } from 'react';
import { Button } from 'antd';
import './PageNotFound.less';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'util/Auth';
import FullPageMessage from 'pages/FullPageMessage/FullPageMessage';

const PageNotFound = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  return (
    <FullPageMessage
      title="Page not found!"
      content={(
        <>
          {'If this seems incorrect, '}
          <Button
            className="inline-link-button"
            type="link"
            href={`mailto:matrix@garageproject.co.nz?subject=Missing page in the Matrix&body=Hi,%0D%0A%0D%0AI tried to access the page at "${location.pathname}" in the Matrix, but it could not be found.%0D%0A%0D%0AI expected to see:%0D%0A%0D%0A[PLEASE PROVIDE ADDITIONAL DETAILS HERE]%0D%0A%0D%0AThanks,%0D%0A${currentUser.displayName}.`}
          >
            click here
          </Button>
          {' to let us know.'}
        </>
      )}
      image="/assets/hazy-daze-eye-guy.png"
      buttonLabel="Back to Home"
      linkTo="/dashboard/home"
    />
  );
};

export default PageNotFound;
