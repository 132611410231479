import React, { useState, useEffect, useContext } from 'react';
import './BriefTable.less';
import * as api from 'util/api-morpheus.js';
import { AuthContext } from 'util/Auth';
import { Link } from 'react-router-dom';
import {
  Space, Button, Table as AntTable, Modal, Tooltip,
} from 'antd';
import { CustomTable } from 'components';
import {
  PlusOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { briefProperties } from 'config/language';
import dayjs from 'dayjs';

const { Column } = AntTable;

const BriefTable = () => {
  const [briefs, setBriefs] = useState(null);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getBriefs = async () => {
      try {
        setBriefs((await api.getBriefs())
          // Assign a unique key to each brief using the ID
          .map((brief) => ({ key: brief.id, ...brief })));
      } catch (error) {
        console.log('Error fetching briefs');
      }
    };
    getBriefs();
  }, []);

  const onDelete = (briefToDelete) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this brief?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteBrief(briefToDelete.id)
        .then(() => {
          setBriefs(briefs.filter((brief) => (
            Number(brief.id) !== Number(briefToDelete.id)
          )));
        })
        .catch(),
    });
  };

  return (
    <CustomTable
      id="briefs"
      pluralLabel="briefs"
      searchableColumns={['name']}
      dataSource={briefs}
      controls={currentUser.admin ? (
        <Link to="/dashboard/product-database/briefs/create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
          >
            Create product brief
          </Button>
        </Link>
      ) : null}
    >

      <Column
        title={briefProperties.name.labels.field}
        key="name"
        sortType="name"
        render={({ id, name }) => (
          <Link
            to={`/dashboard/product-database/briefs/${id}${currentUser.admin ? '/edit' : ''}`}
            className="brief-name-link"
          >
            <Button type="link" className="inline-link-button">
              { name || <em>Untitled Brief</em>}
            </Button>
          </Link>
        )}
      />

      {/* <Column
        title={briefProperties.oneLiner.labels.field}
        dataIndex="oneLiner"
        key="oneLiner"
      />

      <Column
        title={briefProperties.dateReleased.labels.field}
        key="dateReleased"
        dataIndex="dateReleased"
        render={(dateReleased, { releaseMonthUnknown }) => {
          let date;
          if (!dayjs.isDayjs(dateReleased)) {
            date = dateReleased;
          } else {
            date = dateReleased.format(releaseMonthUnknown ? 'YYYY' : 'MMMM YYYY');
          }
          return (
            <span className="no-line-break">
              {date}
            </span>
          );
        }}
      />
       */}

      <Column
        title={briefProperties.dateCreated.labels.field}
        key="dateCreated"
        sortType="date"
        defaultSortOrder="descend"
        render={(allValues) => {
          const { dateCreated } = allValues;
          console.log('dateCreated', dateCreated);
          return dayjs(dateCreated).format('DD/MM/YYYY[ at ]h:mm:ss a');
        }}
      />

      <Column
        style={{ whiteSpace: 'nowrap' }}
        title={briefProperties.dateEdited.labels.field}
        key="dateEdited"
        sortType="date"
        render={(allValues) => {
          const { dateEdited } = allValues;
          console.log('dateEdited', dateEdited);
          return dayjs(dateEdited).format('DD/MM/YYYY[ at ]h:mm:ss a');
        }}
      />

      {currentUser.admin ? (
        <Column
          title=""
          key="actions"
          render={(brief) => (
            // TODO: Change to a dropdown on narrow screens to avoid column max width
            <Space size="middle">
              <Link to={`/dashboard/product-database/briefs/${brief.id}/edit`}>
                <Button type="link">
                  Edit
                </Button>
              </Link>
              <Tooltip title="Coming soon...">
                <Button type="link" disabled>
                  Download PDF
                </Button>
              </Tooltip>
              <Tooltip title="Coming soon...">
                <Button type="link" disabled>
                  Convert to product
                </Button>
              </Tooltip>
              <Button
                type="link"
                danger
                onClick={() => onDelete(brief)}
              >
                Delete
              </Button>
            </Space>
          )}
        />
      ) : <></>}
    </CustomTable>
  );
};

export default BriefTable;
