import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Select, InputNumber, Tooltip,
} from 'antd';
import countryRegionData from 'country-region-data';
import './AddressInput.less';
import AddressSearch from './AddressSearch/AddressSearch';

const AddressInput = ({
  form, disabled, permittedCountryCodes,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  useEffect(() => {
    // Set the initial country code on mounts
    const initialCountry = form.getFieldValue(['shippingAddress', 'country']);
    setSelectedCountryCode(initialCountry ? initialCountry.value : null);
  }, []);

  useEffect(() => {
    // Filter by country codes if provided, otherwise include all countries
    const permittedCountries = permittedCountryCodes ? countryRegionData.filter(
      ({ countryShortCode }) => permittedCountryCodes.includes(countryShortCode),
    ) : countryRegionData;

    // Change the keys to label/value so it can be used with the select field
    setCountryOptions(permittedCountries.map(
      ({ countryName, countryShortCode }) => ({ label: countryName, value: countryShortCode }),
    ));
  }, [permittedCountryCodes]);

  useEffect(() => {
    if (selectedCountryCode) {
      const country = countryRegionData.find(
        ({ countryShortCode }) => countryShortCode === selectedCountryCode,
      );
      if (country) {
        // Change the keys to label/value so it can be used with the select field
        setRegionOptions(country.regions.map(
          ({ name, shortCode }) => ({ label: name, value: shortCode }),
        ));
      } else {
        throw new Error('Unknown country code:', selectedCountryCode);
      }
    }
  }, [selectedCountryCode]);

  const onCountryChange = ({ value }) => {
    setSelectedCountryCode(value);
    // Clear province whenever the country changes
    // (to avoid retaining province from previous country)
    form.resetFields([['shippingAddress', 'province']]);
  };

  const [zipCode, setZipCode] = useState('');
  const validateZipCode = (rule, value) => {
    if (disabled || /^\d{4}$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 4-digit code.'));
  };

  return (
    <>
      <Form.Item
        label="First name"
        name={['shippingAddress', 'firstName']}
        required={!disabled}
        className="hide-form-item-error"
        rules={[({ getFieldValue }) => ({
          validator: (_, value) => {
            if (!disabled && (!value || !getFieldValue(['shippingAddress', 'lastName']))) {
              return Promise.reject(new Error(' '));
            }
            return Promise.resolve();
          },
        })]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Last name"
        name={['shippingAddress', 'lastName']}
        required={!disabled}
        rules={[({ getFieldValue }) => ({
          validator: (_, value) => {
            if (!disabled && (!value || !getFieldValue(['shippingAddress', 'firstName']))) {
              return Promise.reject(new Error('First and last names are required'));
            }
            return Promise.resolve();
          },
        })]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Company"
        name={['shippingAddress', 'company']}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Address line 1"
        name={['shippingAddress', 'address1']}
        rules={[{ required: !disabled, message: 'Address is required' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Address line 2"
        name={['shippingAddress', 'address2']}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="City"
        name={['shippingAddress', 'city']}
        rules={[{ required: !disabled, message: 'City is required' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Tooltip title={!selectedCountryCode ? 'Please select a country first' : null}>
        <Form.Item
          label="Region/state"
          name={['shippingAddress', 'province']}
          rules={[{ required: !disabled, message: 'Region/state is required' }]}
        >
          <Select
            labelInValue
            disabled={disabled || !selectedCountryCode}
            options={regionOptions}
            showSearch
            filterOption={(input, option) => (
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )}
          />
        </Form.Item>
      </Tooltip>
      <Form.Item
        label="Postcode"
        name={['shippingAddress', 'zip']}
        rules={[
          {
            required: !disabled,
            message: 'Postcode is required',
          },
          {
            validator: validateZipCode,
          },
        ]}
      >
        <Input
          type="text"
          disabled={disabled}
          style={{ width: '100%' }}
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item
        label="Country"
        name={['shippingAddress', 'country']}
        rules={[{ required: !disabled, message: 'Country is required' }]}
      >
        <Select
          labelInValue
          disabled={disabled}
          options={countryOptions}
          onChange={onCountryChange}
          showSearch
          filterOption={(input, option) => (
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          )}
        />
      </Form.Item>
      <Form.Item
        label="Phone number"
        name={['shippingAddress', 'phone']}
        rules={[{ required: !disabled, message: 'A phone number is required' }]}
      >
        <Input type="number" disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Email"
        name={['shippingAddress', 'email']}
        rules={[{
          required: !disabled,
          message: 'An email address is required',
        }, {
          type: 'email',
          message: 'Please enter a valid email address',
        }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
};

AddressInput.propTypes = {
  // Disabled since ant form shape may change
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  permittedCountryCodes: PropTypes.arrayOf(PropTypes.string),
};

AddressInput.defaultProps = {
  disabled: false,
  permittedCountryCodes: ['NZ', 'AU'],
};

export default AddressInput;
