import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Image, Checkbox, Switch, Skeleton, InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import { productProperties } from 'config/language';

import { DatePicker, LoadingImage } from 'components';

const { TextArea } = Input;

const KeyDates = ({ form }) => {
  const [releaseMonthUnknown, setReleaseMonthUnknown] = useState(false);
  useEffect(() => {
    setReleaseMonthUnknown(form.getFieldValue('releaseMonthUnknown'));
  });
  return (
    <div className="columns-container">
      <div className="column">
        <Form.Item
          name="dateAdded"
          label={productProperties.dateAdded.labels.field}
          labelCol={{ span: 24 }}
        >
          <DatePicker
            placeholder={null}
            style={{ width: '100%' }}
            format="D MMMM YYYY"
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 0 }}
          name="dateReleased"
          label={productProperties.dateReleased.labels.field}
          labelCol={{ span: 24 }}
        >
          <DatePicker
            defaultPickerValue={dayjs().set('day', 1)}
            picker={releaseMonthUnknown ? 'year' : 'month'}
            placeholder={null}
            style={{ width: '100%' }}
            format={releaseMonthUnknown ? 'YYYY' : 'MMMM YYYY'}
          />
        </Form.Item>
        <Form.Item
          name="releaseMonthUnknown"
          valuePropName="checked"
          labelCol={{ span: 24 }}
        >
          <Checkbox onChange={setReleaseMonthUnknown}>
            {productProperties.releaseMonthUnknown.labels.field}
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="dateBrewed"
          label={productProperties.dateBrewed.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input placeholder="Unconfirmed" />
        </Form.Item>
        <Form.Item
          name="datePacked"
          label={productProperties.datePacked.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input placeholder="Unconfirmed" />
        </Form.Item>
      </div>
    </div>
  );
};

export default KeyDates;
