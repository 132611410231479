import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import './StickyHeader.less';

const StickyHeader = ({ children }) => {
  const stickyHeaderRef = useRef();

  const getScrollArea = () => document.getElementById('scrollable-page-area-container');

  const compact = useMediaQuery({
    query: '(min-width: 576px)', // TODO: Change to sm breakpoint
  });

  const onScroll = useCallback(() => {
    if (stickyHeaderRef.current) {
      // TODO Change below to @margin-md & @margin-lg
      if (getScrollArea().scrollTop < (compact ? 16 : 24)) {
        if (stickyHeaderRef.current.classList.contains('hovering')) {
          stickyHeaderRef.current.classList.remove('hovering');
        }
      } else if (!stickyHeaderRef.current.classList.contains('hovering')) {
        stickyHeaderRef.current.classList.add('hovering');
      }
    }
  }, [stickyHeaderRef]);

  useEffect(() => {
    const scrollArea = getScrollArea();
    scrollArea.addEventListener('scroll', onScroll);
    return () => scrollArea.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <div className="sticky-header" ref={stickyHeaderRef}>
      <div className="sticky-header-content">
        {children}
      </div>
    </div>
  );
};

StickyHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StickyHeader;
