import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8006'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/check-monday-token';

export default () => useQuery({
  queryKey: ['check_monday_token'],
  queryFn: async () => (await axios({
    method: 'GET',
    url: URL,
  })).data,
});
