const { ClipboardItem } = window;

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {
    console.log('Async: Copying to clipboard was successful!');
  }, (err) => {
    console.error('Async: Could not copy text: ', err);
  });
};

// export const copyElementToClipboard = (elementId) => {
//   const doc = document;
//   const text = doc.getElementById(elementId);
//   let range;
//   let selection;

//   if (doc.body.createTextRange) {
//     range = doc.body.createTextRange();
//     range.moveToElement(text);
//     range.select();
//   } else if (window.getSelection) {
//     selection = window.getSelection();

//     range = doc.createRange();
//     range.selectNodeContents(text);

//     selection.removeAllRanges();
//     selection.addRange(range);
//   }

//   document.execCommand('copy');
//   window.getSelection().removeAllRanges();
// };

export const copyElementToClipboard = ({ elementId, inner = false }) => {
  try {
    let element = document.getElementById(elementId);
    if (inner) {
      element = element.firstChild;
    }
    console.log('element', element);
    const content = element.innerHTML;
    const blobInput = new Blob([content], { type: 'text/html' });
    const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput });
    navigator.clipboard.write([clipboardItemInput]);
  } catch (error) {
    console.log('Error copying element to clipboard', error);
  }
};
