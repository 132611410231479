import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './GeneratorProductTable.less';
import * as api from 'util/api.js';
import {
  Table as AntTable, Tooltip, Checkbox, Avatar, Button,
} from 'antd';
import { UserOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { CustomTable, GeneratorModal } from 'components';
import { TableConfigContext } from 'util/TableConfig';
import dayjs from 'dayjs';

const { Column } = AntTable;

const GeneratorProductTable = ({
  type,
  selectionType,
  pluralLabel,
  GeneratorFormContent,
  initialFormValues,
  onGenerate,
  generatorStatus,
  generatorOutput,
  onGeneratorReset,
}) => {
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [generatorModalOpen, setGeneratorModalOpen] = useState(false);

  useEffect(() => {
    api.getProducts().then(setProducts);
  }, []);

  // useEffect(() => {
  //   // Close the modal after a successful generation
  //   if (!generating && !errorGenerating && generatorModalOpen) {
  //     setGeneratorModalOpen(false);
  //   }
  // }, [generating]);

  const tableId = `generator-product-table-${type}-${pluralLabel}`;

  const onGenerateClicked = () => {
    setGeneratorModalOpen(true);
  };

  const handleClose = () => {
    setGeneratorModalOpen(false);

    // Avoid flickering back to form view
    setTimeout(() => onGeneratorReset(), 300);
  };

  return (
    <>
      <CustomTable
        id={tableId}
        pluralLabel="products"
        searchableColumns={['name', 'oneLiner']}
        enableSelection
        selectionType={selectionType}
        onSelectionChange={setSelectedProducts}
        dataSource={products?.map((product) => (
          {
            ...product,
            key: product.id, // Each row must have a unique key
            disabled: !(product.tapBadgeUrl) && type !== 'mailchimp',
          }
        ))}
        disabledMessage="A tap badge hasn't been added for this product"
        controls={(
          <Tooltip title={!selectedProducts.length ? `Select products to generate ${pluralLabel} for first` : null}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              // Disable when no products are selected or loading
              disabled={!selectedProducts.length || !products}
              onClick={onGenerateClicked}
            >
              {`Generate ${pluralLabel}`}
            </Button>
          </Tooltip>
      )}
      >
        <Column
          title={null}
          dataIndex="tapBadgeUrl"
          key="tapBadge"
          width={64}
          render={(tapBadgeUrl) => (
            <Avatar src={tapBadgeUrl} />
          )}
        />
        <Column
          title="Name"
          dataIndex="name"
        />
        <Column
          title="ABV"
          key="abv"
          render={({ abv, abvMin, abvMax }) => {
            const abvIsRange = abvMin !== null || abvMax !== null;

            if (abv === null && !abvIsRange) {
              return <div className="empty-cell">N/A</div>;
            }

            return (abv !== null ? (
              `${abv}%`
            ) : (
              `${abvMin === null ? '?' : `${abvMin}%`} - ${abvMax === null ? '?' : `${abvMax}%`}`
            ));
          }}
        />
        <Column title="One Liner" dataIndex="oneLiner" key="oneLiner" />
      </CustomTable>
      <GeneratorModal
        type={type}
        selectionType={selectionType}
        pluralLabel={pluralLabel}
        products={selectedProducts}
        open={generatorModalOpen}
        onClose={handleClose}
        onGenerate={onGenerate}
        generatorStatus={generatorStatus}
        generatorOutput={generatorOutput}
        FormContent={GeneratorFormContent}
        initialFormValues={initialFormValues}
      />
    </>
  );
};

GeneratorProductTable.propTypes = {
  type: PropTypes.oneOf(['mailchimp', 'tap-badge', 'keg', 'slab', 'case', 'pallet']).isRequired,
  selectionType: PropTypes.oneOf(['single', 'multiple']).isRequired,
  pluralLabel: PropTypes.string.isRequired,
  GeneratorFormContent: PropTypes.elementType.isRequired,
  initialFormValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onGenerate: PropTypes.func.isRequired,
  generatorStatus: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }),
  generatorOutput: PropTypes.shape({
    type: PropTypes.oneOf(['pdf']),
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any,
  }),
  onGeneratorReset: PropTypes.func,
};

GeneratorProductTable.defaultProps = {
  initialFormValues: {},
  generatorStatus: {
    active: false,
    error: null,
    success: false,
  },
  generatorOutput: null,
  onGeneratorReset: () => {},
};

export default GeneratorProductTable;
