import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Layout, Menu, Drawer, Typography, Tag,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import './SideMenu.less';
import { AuthContext } from 'util/Auth';
import routeConfig from 'config/routes';
import { MatrixLogo } from 'components';
import logo from 'assets/logo.svg';

const { Title } = Typography;

const { SubMenu } = Menu;

const { Sider } = Layout;

const MobileSideMenu = ({ children, open, onClose }) => (
  <Drawer
    id="side-menu"
    placement="left"
    closable={false}
    onClose={onClose}
    visible={open}
    bodyStyle={{ padding: 0 }}
  >
    {children}
  </Drawer>
);

const DesktopSideMenu = ({ children, open }) => (
  <Sider
    id="side-menu"
    trigger={null}
    collapsible
    collapsed={!open}
    theme="light"
  >
    {children}
  </Sider>
);

// Recursively renders menu items based on config (config/routes.js)
const renderItem = ({
  item, parentPath, handleChange, userEmail, userIsAdmin, userIsExternal,
}) => {
  // Don't add item to menu if:
  // - it requires admin access and the current user is not an admin
  // - the route has not been set as allowed for external users and the user is external
  // - the route config expclitly specifies it as hidden
  if (
    (item.requiresAdmin && !userIsAdmin)
    || (!item.allowExternal && userIsExternal)
    || item.hidden
    // Whitelist exists but user is not listed
    || (item.emailWhitelist && !item.emailWhitelist.includes(userEmail))
  ) {
    return null;
  }
  const IconComponent = item.icon;
  const icon = IconComponent ? <IconComponent /> : null;
  const currentPath = `${parentPath}/${item.id}`;

  // Render as a submenu if there are subitems that aren't hidden
  return item.subItems && (item.subItems.some((subItem) => !subItem.hidden)) ? (
    <SubMenu
      key={item.id}
      icon={icon}
      title={item.new ? (
        <span>
          {item.menuLabel}
          <Tag color="blue" className="side-menu-tag">NEW</Tag>
        </span>
      ) : (item.menuLabel)}
      onSelect={(subItem) => handleChange(`${currentPath}/${subItem.key}`)}
    >
      {item.subItems.map((subItem) => (
        renderItem({
          item: subItem,
          parentPath: currentPath,
          handleChange,
          userEmail,
          userIsAdmin,
          userIsExternal,
        })
      ))}
    </SubMenu>
  ) : (
    <Menu.Item
      key={currentPath}
      icon={icon}
      onClick={item.url ? () => {
        window.location = item.url;
      } : null}
    >
      {item.new ? (
        <span className="side-menu-tag-label">
          <span>{item.menuLabel}</span>
          <Tag color="blue" className="side-menu-tag">NEW</Tag>
        </span>
      ) : (item.menuLabel)}
    </Menu.Item>
  );
};

// TODO: Keep tab highlighted if at a submenu route (e.g. product database -> product editor)
const SideMenu = ({ open, isMobile, onClose }) => {
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(location.pathname.replace('/dashboard', ''));

  const { currentUser } = useContext(AuthContext);

  // Update the selected menu item when the pathname changes
  useEffect(() => {
    setSelected(location.pathname.replace('/dashboard', ''));
  }, [location.pathname]);

  const Wrapper = isMobile ? MobileSideMenu : DesktopSideMenu;

  const handleNavigation = (path) => {
    history.push(`/dashboard${path}`);
    if (isMobile) {
      onClose();
    }
  };

  return (
    <div id="side-menu-container">
      <Wrapper
        open={open}
        onClose={onClose}
      >
        <div className="side-menu-header">
          <MatrixLogo />
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selected]}
          onSelect={(item) => handleNavigation(item.key)}
        >
          {routeConfig.map((item) => (
            renderItem({
              item,
              parentPath: '',
              handleNavigation,
              userEmail: currentUser.email,
              userIsAdmin: currentUser.admin,
              userIsExternal: currentUser.external,
            })
          ))}
        </Menu>
      </Wrapper>
    </div>
  );
};

SideMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SideMenu;
