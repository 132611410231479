import React from 'react';
import PropTypes from 'prop-types';

export const PageTabsContext = React.createContext();

export const PageTabsProvider = ({ activeTab, children }) => (
  <PageTabsContext.Provider value={activeTab}>
    {children}
  </PageTabsContext.Provider>
);

PageTabsProvider.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageTabsProvider.defaultProps = {
  activeTab: null,
};
