import React from 'react';
import './ComingSoon.less';
import FullPageMessage from 'pages/FullPageMessage/FullPageMessage';

const ComingSoon = () => (
  <FullPageMessage
    title="Coming soon..."
    content="This page is still being worked on and will be released soon!"
    image="/assets/weird-flex-grapefruit.png"
    imageHeight={200}
    buttonLabel="Back to Home"
    linkTo="/dashboard/home"
  />
);

export default ComingSoon;
