import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import './BeerBible.less';
import * as api from 'util/api.js';
import { FullPageLoader, StickyHeader } from 'components';
import { Input, Pagination, Empty } from 'antd';
import { omit } from 'lodash';
import ProductCard from './ProductCard/ProductCard';

const { Search } = Input;

const filterBySearch = (products, searchQuery) => (
  products.filter(({ name }) => (
    name?.toUpperCase().includes(searchQuery.toUpperCase())
  ))
);

const evaluateIndexRange = (currentPage, productsPerPage) => {
  const start = (currentPage - 1) * productsPerPage;
  const end = currentPage * productsPerPage;
  return [start, end];
};

const BeerBible = () => {
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  useEffect(() => {
    api.getProducts().then(setProducts);
  }, []);

  const getScrollArea = () => document.getElementById('scrollable-page-area-container');

  const onSearch = (event) => {
    setFilteredProducts(filterBySearch(products, event.target.value));
    // Reset current page to default.
    setCurrentPage(1);
    // Scroll back to top of page.
    getScrollArea().scrollTop = 0;
  };

  const onSearchChange = (event) => {
    // Clear search
    if (!event.target.value) {
      setFilteredProducts(null);
      // Reset current page to default.
      setCurrentPage(1);
      // Scroll back to top of page.
      getScrollArea().scrollTop = 0;
    }
  };

  const onPaginationChange = (page, pageSize) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      // Scroll back to top of page.
      getScrollArea().scrollTop = 0;
    }
    if (pageSize !== productsPerPage) {
      setProductsPerPage(pageSize);
    }
  };

  const productData = filteredProducts || products;

  return products ? (
    <div id="beer-bible">
      <StickyHeader>
        <Search
          disabled={!products}
          placeholder="Search..."
          onChange={onSearchChange}
          onPressEnter={onSearch}
          allowClear
        />
      </StickyHeader>

      {productData.length > 0 ? (
        <>
          {(productData.slice(
            ...evaluateIndexRange(currentPage, productsPerPage),
          )).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}

          <div id="pagination-container">
            <Pagination
              responsive
              hideOnSinglePage
              defaultCurrent={currentPage}
              defaultPageSize={productsPerPage}
              total={productData.length}
              onChange={onPaginationChange}
              pageSizeOptions={[10, 20, 50]} // Limit to prevent slow rendering
            />
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

    </div>
  ) : (<FullPageLoader />);
};

export default BeerBible;
