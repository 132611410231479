import axios from 'axios';
import firebase from 'firebase';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { camelCase } from 'change-case';
import dayjs from 'dayjs';
import io from 'socket.io-client';
import { Modal, notification } from 'antd';

const showNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

const baseURL = process.env.REACT_APP_NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.gpmatrix.co.nz';

const client = axios.create({
  baseURL,
  json: true,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

// Request interceptor to:
// - attach auth token to request.
// - convert the keys of the outgoing data to snakecase.
client.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (firebase.auth().currentUser) {
      newConfig.headers = {
        ...newConfig.headers,
        AuthToken: await firebase.auth().currentUser.getIdToken(true),
      };
    }
    newConfig.data = decamelizeKeys(newConfig.data);
    return newConfig;
  },
  (error) => Promise.reject(error),
);

// Convert the keys of the incoming data to camelcase.
client.interceptors.response.use(
  (response) => ({ ...response, data: camelizeKeys(response.data) }),
  (error) => Promise.reject(error),
);

// TODO: Response interceptor to refresh if an auth token has expired.
// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

// Either return the socket client if it exists, otherwise open the connection
let socketClient;
const getSocket = async () => {
  if (!socketClient) {
    if (firebase.auth().currentUser) {
      socketClient = io(baseURL, {
        withCredentials: true,
        extraHeaders: {
          AuthToken: await firebase.auth().currentUser.getIdToken(true),
        },
      });
      socketClient.on('connect_failed', () => {
        this.reportError('Error establishing socket connection');
      });
    } else {
      // TODO: Handle no current user
    }
  } else if (!socketClient.connected) {
    // TODO: Handle existing client that's disconnected
  }
  return socketClient;
};

// ========== FRESH CLUB ========

export const getAllOnetimesData = (countryId) => client({
  method: 'POST',
  url: `/recharge/onetimes/${countryId}`,
}).then((response) => response.data)
  .catch((error) => {
    console.log(error);
    showNotification('error', 'Error loading onetimes');
    return Promise.reject(error);
  });

export const getAllCustomerData = (countryId) => client({
  method: 'POST',
  url: `/recharge/customers/${countryId}`,
}).then((response) => response.data)
  .catch((error) => {
    console.log(error);
    showNotification('error', 'Error loading customers');
    return Promise.reject(error);
  });

export const getAllSubscriptionData = (countryId) => client({
  method: 'POST',
  url: `/recharge/subscriptions/${countryId}`,
}).then((response) => response.data)
  .catch((error) => {
    console.log(error);
    showNotification('error', 'Error loading subscriptions');
    return Promise.reject(error);
  });

// new
export const submitOnetimes = (countryId, payload) => client({
  method: 'POST',
  url: `/recharge/onetimes/create/${countryId}`,
  data: payload,
}).then((response) => {
  showNotification('success', 'Onetime submitted!');
  return response.data;
}).catch((error) => {
  console.log(error);
  showNotification('error', 'Error submitting onetimes');
  return Promise.reject(error);
});

// ========== PRODUCTS ==========

export const getProducts = () => client({
  method: 'GET',
  url: '/products',
}).then(({ data }) => data.map(({ // Convert dates to Day.js objects
  dateReleased, dateAdded, dateLastModified, ...product
}) => ({
  ...product,
  dateReleased: dateReleased ? dayjs(dateReleased, 'DD/MM/YYYY') : dateReleased,
  dateAdded: dateAdded ? dayjs(dateAdded) : dateAdded,
  dateLastModified: dateLastModified ? dayjs(dateLastModified) : dateLastModified,
}))).catch(() => {
  showNotification('error', 'Error loading products');
});

export const getProduct = (productId) => client({
  method: 'GET',
  url: `/products/${productId}`,
}).then((res) => {
  // Convert dates to Day.js objects
  const data = { ...res.data };
  const { dateReleased, dateAdded, dateLastModified } = data;
  data.dateReleased = dateReleased ? dayjs(dateReleased, 'DD/MM/YYYY') : dateReleased;
  data.dateAdded = dateAdded ? dayjs(dateAdded) : dateAdded;
  data.dateLastModified = dateLastModified ? dayjs(dateLastModified) : dateLastModified;
  return data;
}).catch(() => {
  showNotification('error', 'Error loading product');
});

export const createProduct = (data) => client({
  method: 'POST',
  url: '/products',
  data: {
    ...data,
    dateReleased: dayjs.isDayjs(data.dateReleased) ? (
      // Only keep the month and/or year.
      data.dateReleased
        .date(1)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format('DD/MM/YYYY') // TODO: Change to ISO.
    ) : data.dateReleased,
    // Convert dates to ISO format
    dateAdded: data.dateAdded ? dayjs(data.dateAdded).format() : data.dateAdded,
    dateLastModified: dayjs().format(), // Set time last edited as current time
  },
}).then(({ data: productId }) => productId);
// .catch((error) => {
//   showNotification('error', 'Error saving product');
//   // Allow error to be caught where method was called
//   return Promise.reject(error);
// });

export const updateProduct = (productId, data) => client({
  method: 'PUT',
  url: `/products/${productId}`,
  data: {
    ...data,
    dateReleased: dayjs.isDayjs(data.dateReleased) ? (
      // Only keep the month and/or year.
      data.dateReleased
        .date(1)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format('DD/MM/YYYY')// TODO: Change to ISO.
    ) : data.dateReleased,
    // Convert dates to ISO format
    dateAdded: data.dateAdded ? dayjs(data.dateAdded).format() : data.dateAdded,
    dateLastModified: dayjs().format(), // Set time last edited as current time
  },
});
// .catch((error) => {
//   showNotification('error', 'Error saving product');
//   // Allow error to be caught where method was called
//   return Promise.reject(error);
// });

export const deleteProduct = (productId) => client({
  method: 'DELETE',
  url: `/products/${productId}`,
}).then(() => {
  showNotification('success', 'Product deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting product');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== FOODSTUFFS ==========

export const FOODSTUFFS_REGIONS = {
  NORTH: 'north-island',
  SOUTH: 'south-island',
};

export const getFoodstuffsData = (region) => client({
  method: 'GET',
  url: `/foodstuffs/${region}`,
}).then(({ data }) => data)
  .catch(() => {
    showNotification('error', 'Error loading Foodstuffs data');
  });

export const createFoodstuffsItem = (region, data) => client({
  method: 'POST',
  url: `/foodstuffs/${region}`,
  data,
}).then(({ data: itemId }) => {
  showNotification('success', 'Item created!');
  return itemId;
}).catch(() => {
  showNotification('error', 'Error saving item');
});

export const updateFoodstuffsItem = (region, itemId, data) => client({
  method: 'PUT',
  url: `/foodstuffs/${region}/${itemId}`,
  data,
}).then(() => {
  showNotification('success', 'Item updated!');
}).catch(() => {
  showNotification('error', 'Error saving item');
});

export const deleteFoodstuffsItem = (region, itemId) => client({
  method: 'DELETE',
  url: `/foodstuffs/${region}/${itemId}`,
}).then(() => {
  showNotification('success', 'Item deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting item');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== QR CODES ==========

export const getQRCodes = () => client({
  method: 'GET',
  url: '/qr-codes',
}).then(({ data }) => data.map(({
  dateLastModified, ...qrCodeData
}) => ({
  ...qrCodeData,
  // Convert dates to Day.js objects
  dateLastModified: dateLastModified ? dayjs(dateLastModified) : dateLastModified,
})))
  .catch(() => {
    showNotification('error', 'Error loading QR codes');
  });

export const createQRCode = (data) => client({
  method: 'POST',
  url: '/qr-codes',
  data,
}).then(({ data: qrCodeData }) => {
  showNotification('success', 'QR code created!');
  const { qrCodeImageUrl, dateLastModified } = qrCodeData;
  return {
    qrCodeImageUrl,
    dateLastModified: dayjs(dateLastModified),
  };
}).catch((error) => {
  showNotification('error', 'Error saving QR code');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const updateQRCode = (id, data) => client({
  method: 'PUT',
  url: `/qr-codes/${id}`,
  data,
}).then(({ data: qrCodeData }) => {
  showNotification('success', 'QR code updated!');
  const { dateLastModified } = qrCodeData;
  return {
    dateLastModified: dayjs(dateLastModified),
  };
}).catch((error) => {
  showNotification('error', 'Error saving QR code');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const deleteQRCode = (id) => client({
  method: 'DELETE',
  url: `/qr-codes/${id}`,
}).then(() => {
  showNotification('success', 'QR code deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting QR code');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== USERS ==========

export const getUsers = () => client({
  method: 'GET',
  url: '/users',
}).then((res) => {
  const data = res.data.map(({ lastSignInTime, ...user }) => ({
    ...user,
    lastSignInTime: lastSignInTime ? dayjs(lastSignInTime) : lastSignInTime,
  }));
  return data;
}).catch(() => {
  showNotification('error', 'Error loading users');
});

export const createUser = (data) => client({
  method: 'POST',
  url: '/users',
  data,
}).then(({ data: userId }) => {
  showNotification('success', 'User created!', 'An email with their login details has been sent to them.');
  return userId;
}).catch((error) => {
  showNotification('error', 'Error creating user');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const updateUser = (userId, data) => client({
  method: 'PUT',
  url: `/users/${userId}`,
  data,
}).then(() => {
  showNotification('success', 'User updated!');
}).catch((error) => {
  showNotification('error', 'Error updating user');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const deleteUser = (userId) => client({
  method: 'DELETE',
  url: `/users/${userId}`,
}).then(() => {
  showNotification('success', 'User deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting user');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const setAdminStatus = (userId, admin) => client({
  method: 'POST',
  url: `/users/${userId}/admin-status`,
  data: {
    admin,
  },
}).then(() => {
  showNotification('success', `User is ${admin ? 'now an admin!' : 'no longer an admin'}`);
}).catch(() => {
  showNotification('error', 'Error updating admin status');
});

export const getMiscellaneousData = () => client({
  method: 'GET',
  url: '/misc',
}).then(({ data }) => (
  { // Convert birthday dates to Day.js objects
    ...data,
    birthdays: data.birthdays.map(
      ({ date, ...birthday }) => (
        {
          ...birthday,
          date: dayjs(date),
        }
      ),
    ),
  }
))
  .catch(() => {
  // TODO: Handle error
  });

// ========== DEAR ==========

export const STOCK_LOCATIONS = {
  TOLL: 'toll',
  SCOTT_BASE: 'scott-base',
  CELLARING_STOCK: 'cellaring-stock',
  MOTUS: 'motus',
  PETER_SADLER: 'peter-sadler',
  GROWLER_DEPOT: 'growler-depot',
};

// TODO: Use query params for location instead
export const getStock = (location) => client({
  method: 'GET',
  url: `/dear/stock/${location}`,
}).then((res) => {
  // Convert dates to Day.js objects
  // TODO: convert expiry in each instance
  const data = res.data.map(({ instances, ...product }) => ({
    ...product,
    instances: instances.map(({ expiryDate, ...instance }) => (
      {
        ...instance,
        expiryDate: expiryDate ? dayjs(expiryDate) : expiryDate,
      }
    )),
  }));
  return data;
}).catch(() => {
  showNotification('error', 'Error loading stock');
});

export const getDearProducts = (skuSearch = null, cancelToken = null) => client({
  method: 'GET',
  url: '/dear/products',
  params: {
    skuSearch,
  },
  cancelToken,
}).then((res) => {
  const { data } = res;
  return data;
  // Allow error to be caught where method was called
}).catch((error) => Promise.reject(error));

export const getDearProduct = (sku) => client({
  method: 'GET',
  url: `/dear/products/${sku}`,
}).then((res) => {
  const { data } = res;
  return data;
  // Allow error to be caught where method was called
}).catch((error) => Promise.reject(error));

// ========== REPORT USER AUTH ==========

export const getReportUsers = () => client({
  method: 'GET',
  url: '/reportusers',
}).then(({ data: reportUsers }) => reportUsers)
  .catch(() => {
    showNotification('error', 'Error loading Users');
  });

export const createReportUser = (data) => client({
  method: 'POST',
  url: '/reportusers',
  data,
}).then(({ data: reportUsersId }) => {
  showNotification('success', 'User added!');
  return reportUsersId;
}).catch((error) => {
  showNotification('error', 'Error adding User');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const updateReportUser = (reportUsersId, data) => client({
  method: 'PUT',
  url: `/reportusers/${reportUsersId}`,
  data,
}).then(() => {
  showNotification('success', 'User updated!');
}).catch((error) => {
  showNotification('error', 'Error updating User');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const deleteReportUser = (reportUsersId) => client({
  method: 'DELETE',
  url: `/reportusers/${reportUsersId}`,
}).then(() => {
  showNotification('success', 'User deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting User');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== DTC PRICING MANAGEMENT ========

export const getDtcPricing = () => client({
  method: 'GET',
  url: '/dtc-prices',
}).then(({ data: prices }) => prices)
  .catch(() => {
    showNotification('error', 'Error loading DTC Pricing');
  });

// ========== CERTIFIED FRESH MANAGEMENT ========

export const getCertifiedFresh = () => client({
  method: 'GET',
  url: '/certified-fresh/retailers',
}).then(({ data: retailers }) => retailers)
  .catch(() => {
    showNotification('error', 'Error loading Certified Fresh retailers');
  });

export const updateCertifiedFresh = (retailerId, updateData) => client({
  method: 'PUT',
  url: `/certified-fresh/retailers/${retailerId}`,
  data: updateData,
}).then(({ data }) => {
  showNotification('success', 'Retailer updated!');
  return data;
})
  .catch((error) => {
    showNotification('error', 'Error updating Certified Fresh retailer');
    return Promise.reject(error);
  });

export const createRetailer = (data) => client({
  method: 'POST',
  url: '/certified-fresh/retailers',
  data,
}).then(({ data: retailerId }) => {
  showNotification('success', 'Retailer added!');
  return retailerId;
}).catch((error) => {
  showNotification('error', 'Error adding retailer');
  return Promise.reject(error);
});

export const deleteRetailer = (retailerId) => client({
  method: 'DELETE',
  url: `/certified-fresh/retailers/${retailerId}`,
}).then(() => {
  showNotification('success', 'Retailer deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting retailer');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const getCoordsFromAddress = (address) => client({
  method: 'POST',
  url: '/certified-fresh/add-coords',
  data: { address },
}).then((res) => res.data)
  .catch((error) => {
    console.error('Error retrieving coordinates:', error.response?.data || error.message);
    showNotification('error', 'Error retrieving coordinates');
    return null;
  });

// ========== DASHBOARDS (NEW REPORTS) ==========

export const getDashboards = () => client({
  method: 'GET',
  url: '/reports',
}).then(({ data: dashboards }) => dashboards)
  .catch(() => {
    showNotification('error', 'Error loading dashboards');
  });

export const createDashboard = (data) => client({
  method: 'POST',
  url: '/reports',
  data,
}).then(({ data: dashboardsId }) => {
  showNotification('success', 'Dashboard added!');
  return dashboardsId;
}).catch((error) => {
  showNotification('error', 'Error adding dashboard');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const updateDashboard = (dashboardsId, data) => client({
  method: 'PUT',
  url: `/reports/${dashboardsId}`,
  data,
}).then(() => {
  showNotification('success', 'Dashboard updated!');
  console.log('dashboard updated');
}).catch((error) => {
  showNotification('error', 'Error updating dashboard');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const deleteDashboard = (dashboardsId) => client({
  method: 'DELETE',
  url: `/reports/${dashboardsId}`,
}).then(() => {
  showNotification('success', 'Dashboard deleted!');
}).catch((error) => {
  showNotification('error', 'Error deleting dashboard');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== LEGACY REPORTS ==========

// export const getReports = () => client({
//   method: 'GET',
//   url: '/reports',
// }).then(({ data: categories }) => (
//   Object.entries(categories).reduce((output, [category, sets]) => ({
//     ...output,
//     [category]: sets.map(({ date, ...reports }) => ({
//       ...reports,
//       date: dayjs(date), // Convert dates to Day.js objects
//     })),
//   }), {})))
//   .catch(() => {
//     showNotification('error', 'Error loading reports');
//   });

// export const createReportSet = ({ date, ...data }) => client({
//   method: 'POST',
//   url: '/reports',
//   data: {
//     ...data,
//     date: date.format(),
//   },
// }).then(({ data: reportSetId }) => {
//   showNotification('success', 'Report created!');
//   return reportSetId;
// }).catch((error) => {
//   showNotification('error', 'Error creating report');
//   // Allow error to be caught where method was called
//   return Promise.reject(error);
// });

// export const updateReportSet = (reportSetId, { date, ...data }) => client({
//   method: 'PUT',
//   url: `/reports/${reportSetId}`,
//   data: {
//     ...data,
//     date: date.format(),
//   },
// }).then(() => {
//   showNotification('success', 'Report updated!');
// }).catch((error) => {
//   showNotification('error', 'Error updating report');
//   // Allow error to be caught where method was called
//   return Promise.reject(error);
// });

// export const deleteReportSet = (reportSetId) => client({
//   method: 'DELETE',
//   url: `/reports/${reportSetId}`,
// }).then(() => {
//   showNotification('success', 'Report deleted!');
// }).catch((error) => {
//   showNotification('error', 'Error deleting report');
//   // Allow error to be caught where method was called
//   return Promise.reject(error);
// });

// ========== ACTIVITY ==========

export const getRecentActivity = () => client({
  method: 'GET',
  url: '/activity/recent',
}).then(({ data }) => {
  // Convert dates to Day.js objects and changed fields to camelcase
  const recentActivity = data.map(({ timestamp, changes, ...event }) => ({
    ...event,
    timestamp: dayjs(timestamp),
    changes: changes.map((change) => ({
      ...change,
      field: camelCase(change.field),
    })),
  }));
  return recentActivity;
});

export const acknowledgeActivity = async () => {
  try {
    const socket = await getSocket();
    socket.emit('acknowledge_activity');
  } catch (error) {
    this.reportError(error);
  }
};

let newActivityListener;
export const listenForNewActivity = async (onNewActivity) => {
  try {
    const socket = await getSocket();
    // Clear activity listener if it already exists
    if (newActivityListener) {
      socket.off('new_activity', newActivityListener);
    }
    // Set up new activity listener
    newActivityListener = ({ timestamp, ...event }) => (
      // Convert keys to camelcase + tiemstamp to dayjs object
      onNewActivity({
        ...camelizeKeys(event),
        timestamp: dayjs(timestamp),
      })
    );
    socket.on('new_activity', newActivityListener);
  } catch (error) {
    this.reportError(error);
  }
};

// ========== SHOPIFY ==========

export const createNewShopifyProduct = (store, data) => client({
  method: 'POST',
  url: '/shopify/products/create',
  params: {
    store,
  },
  data,
}).then(() => {
  showNotification('success', 'Product created!');
})
  .catch((error) => {
    // TODO: Switch back to notification error eventually
    // showNotification('error', 'Error submitting request');
    Modal.error({
      title: 'Error creating product',
      centered: true,
      content: (
        'Please check your Shopify store in case of partially created product.'
      ),
    });
    // Allow error to be caught where method was called
    return Promise.reject(error);
  });

export const getAvailableShopifyProducts = (store) => client({
  method: 'GET',
  url: '/shopify/products/available',
  params: {
    store,
  },
}).then(({ data }) => data)
  .catch(() => {
    showNotification('error', 'Error loading products');
  });

export const createShopifyProductRequest = (store, data) => client({
  method: 'POST',
  url: '/shopify/products/requests',
  params: {
    store,
  },
  data,
}).then(() => {})
  .catch((error) => {
    // TODO: Switch back to notification error eventually
    // showNotification('error', 'Error submitting request');
    Modal.error({
      title: 'Error submitting request',
      centered: true,
      content: (
        `However, your request may still be partially submitted.
        So before trying again please contact dev@garageproject.co.nz so
        that this can be checked to avoid duplicate requests.`
      ),
    });
    // Allow error to be caught where method was called
    return Promise.reject(error);
  });

export const getShopifyProductRequests = (store, status) => client({
  method: 'GET',
  url: '/shopify/products/requests',
  params: {
    store,
    status,
  },
}).then(({ data }) => data.map(({
  // Convert dates to Day.js objects
  dateRequested,
  ...request
}) => ({
  ...request,
  dateRequested: dayjs(dateRequested),
}))).catch(() => {
  showNotification('error', 'Error loading requests');
});

export const updateShopifyProductSetting = (store, productId, settingKey, value) => client({
  method: 'PUT',
  url: `/shopify/products/${productId}/settings/${settingKey}`,
  params: {
    store,
  },
  data: { value },
}).catch((error) => {
  showNotification('error', 'Error updating product setting');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const approveShopifyProductRequest = (requestId) => client({
  method: 'PUT',
  url: `/shopify/products/requests/${requestId}/status`,
  data: {
    status: 'approved',
  },
}).then(() => {
  showNotification('success', 'Request approved!');
}).catch((error) => {
  showNotification('error', 'Error approving request');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

export const rejectShopifyProductRequest = (requestId) => client({
  method: 'PUT',
  url: `/shopify/products/requests/${requestId}/status`,
  data: {
    status: 'rejected',
  },
}).then(() => {
  showNotification('success', 'Request rejected!');
}).catch((error) => {
  showNotification('error', 'Error rejecting request');
  // Allow error to be caught where method was called
  return Promise.reject(error);
});

// ========== SHIPPING ==========

// Gets all shipping rates
export const getShippingRates = (shipFromCountry) => client({
  method: 'GET',
  url: '/shopify/shipping-rates',
  params: {
    shipFromCountry,
  },
}).then(({ data }) => data)
  .catch(() => {
    showNotification('error', 'Error loading shipping rates');
  });

// Evalutes available shipping rates for an address
export const evaluateShippingRates = ({
  store, destination, variants, internalWebstore,
}) => client({
  method: 'POST',
  url: '/shopify/shipping-rates',
  params: {
    store,
    internalWebstore,
  },
  data: {
    rate: {
      destination,
      items: variants,
    },
  },
}).then(({ data }) => data)
  .catch(() => {
    showNotification('error', 'Error loading shipping rates');
  });

// ========== XERO ==========

export const getCustomerContacts = (countryCode, searchTerm = null, cancelToken = null) => client({
  method: 'GET',
  url: '/xero/customer-contacts',
  params: {
    countryCode,
    searchTerm,
  },
  cancelToken, // TODO: Ignore cancelled request error when debouncing
}).then(({ data }) => data);

// ========== ERROR REPORTING ==========

export const reportError = (error) => client({
  method: 'POST',
  url: '/report-client-error',
  data: {
    error: {
      message: error.message,
      stack: error.stack,
    },
  },
}).catch(() => {
  console.log('Error reporting error');
});
