import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './FixedButton.less';

const FixedButton = ({ className, ...props }) => (
  <Button
    type="primary"
    size="large"
    className={`fixed-button${className ? ` ${className}` : ''}`}
    {...props}
  />
);

FixedButton.propTypes = {
  className: PropTypes.string,
};

FixedButton.defaultProps = {
  className: null,
};

export default FixedButton;
