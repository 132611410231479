import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8014'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/get-formats';

export default (type) => useQuery({
  queryKey: ['get_formats', type],
  queryFn: async () => (await axios({
    method: 'GET',
    url: `${URL}?type=${type}`,
  })).data.formats,
});
