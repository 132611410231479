import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8007'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/get-monday-webhooks';

export default () => useQuery({
  queryKey: ['get_monday_webhooks'],
  queryFn: async () => (await axios({
    method: 'GET',
    url: URL,
  })).data,
});
