import React from 'react';
import PropTypes from 'prop-types';
import './TapBadgeGeneratorFormContent.less';
import {
  Form, Input, Select, Avatar, Typography, InputNumber,
} from 'antd';

const { Text } = Typography;

const { Option } = Select;

const TapBadgeGeneratorFormContent = ({ products, form }) => (
  <>
    <div className="modal-description-area">
      {products.map((product) => (
        <div key={product.id}>
          <Avatar src={product.tapBadgeUrl} className="generator-modal-product-avatar" />
          <Text>{product.name}</Text>
        </div>
      ))}
    </div>
    <Form.Item
      name="format"
      label="Format"
      rules={[{ required: true, message: 'Format is required' }]}
    >
      <Select>
        <Option value="Large">Large</Option>
        <Option value="Small">Small</Option>
        <Option value="A4">A4</Option>
        <Option value="Kingsland">Kingsland</Option>
      </Select>
    </Form.Item>
    <Form.Item
      name="quantity"
      label={products.length > 1 ? 'Quantity of each' : 'Quantity'}
    >
      <InputNumber placeholder={1} />
    </Form.Item>
  </>
);

TapBadgeGeneratorFormContent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Disabled since ant form shape may change
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
};

export default TapBadgeGeneratorFormContent;
