import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const TableConfigContext = React.createContext();

const DEFAULT_CONFIG = {
  data: null,
  selected: [],
  searchQuery: null,
  filters: null,
  sorter: null,
};

export const TableConfigProvider = ({ children }) => {
  const [tableConfig, setTableConfig] = useState({});

  const setTableConfigCallback = useCallback((tableId, config) => (
    setTableConfig((current) => ({ ...current, [tableId]: config }))
  ), []);

  return (
    <TableConfigContext.Provider value={{
      getTableConfig: (tableId) => (tableId in tableConfig ? tableConfig[tableId] : DEFAULT_CONFIG),
      setTableConfig: setTableConfigCallback,
    }}
    >
      {children}
    </TableConfigContext.Provider>
  );
};

TableConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
