/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './RecentActivityDetail.less';
import { sentenceCase } from 'change-case';

const CHARACTER_LIMIT = 80;

const RecentActivityDetail = ({
  languageData, field, previousValue, newValue, userName, contextLabel, identifierLink,
}) => {
  const inlineLabel = languageData?.labels?.inline || field;

  let content = null;

  if (!!previousValue && !newValue) {
    const verb = languageData?.verbOverrides?.cleared || 'cleared';
    content = (
      <>
        {`${userName ? `${userName} ` : ''}${userName ? verb : sentenceCase(verb)} ${languageData?.determiner || ''} ${inlineLabel}${identifierLink ? ` for the ${contextLabel}` : ''}`}
        <span className="final-word">
          {identifierLink}
          .
        </span>
      </>
    );
  } else if (languageData?.valuesUnreadable
    || (!!previousValue && previousValue.length > CHARACTER_LIMIT)
    || (!!newValue && newValue.length > CHARACTER_LIMIT)) {
    const verb = languageData?.verbOverrides?.set || 'set';
    content = (
      <>
        {`${userName ? `${userName} ` : ''}${userName ? verb : sentenceCase(verb)} ${languageData?.determiner || ''} ${inlineLabel}${identifierLink ? ` for the ${contextLabel} ` : ''}`}
        <span className="final-word">
          {identifierLink}
          .
        </span>
      </>
    );
  } else if (!previousValue && !!newValue) {
    const verb = languageData?.verbOverrides?.set || 'set';
    content = (
      <>
        {`${userName ? `${userName} ` : ''}${userName ? verb : sentenceCase(verb)} ${languageData?.determiner || ''} ${inlineLabel} to ${newValue}${identifierLink ? ` for the ${contextLabel} ` : ''}`}
        <span className="final-word">
          {identifierLink}
          .
        </span>
      </>
    );
  } else if (!!previousValue && !!newValue) {
    const verb = languageData?.verbOverrides?.changed || 'changed';
    content = (
      <>
        {`${userName ? `${userName} ` : ''}${userName ? verb : sentenceCase(verb)} ${languageData?.determiner || ''} ${inlineLabel} ${languageData?.identifier ? `of the ${contextLabel}` : 'from'} ${previousValue} to ${languageData?.identifier ? '' : newValue}${(!languageData?.identifier && identifierLink) ? ' for the ' : ''}`}
        {languageData?.identifier && identifierLink}
        <span className="final-word">
          {!languageData?.identifier ? (
            <>
              {contextLabel ? `${contextLabel} ` : null}
              {identifierLink}
            </>
          ) : null}
          .
        </span>
      </>
    );
  }

  return (
    <div className="recent-activity-detail">{content}</div>
  );
};

RecentActivityDetail.propTypes = {
  languageData: PropTypes.object,
  field: PropTypes.string.isRequired,
  previousValue: PropTypes.any,
  newValue: PropTypes.any,
  userName: PropTypes.string,
  contextLabel: PropTypes.string,
  identifierLink: PropTypes.node,
};

RecentActivityDetail.defaultProps = {
  languageData: null,
  previousValue: null,
  newValue: null,
  userName: null,
  contextLabel: null,
  identifierLink: null,
};

export default RecentActivityDetail;
