import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Popconfirm } from 'antd';

const CreateStaffUserConfirm = ({ children, onConfirm }) => {
  const [hasStaffEmail, setHasStaffEmail] = useState(null);

  const reset = () => {
    setTimeout(() => setHasStaffEmail(false), 400);
  };

  const props = hasStaffEmail ? ({
    title: 'No need to create an account! Staff members can use their Google Workspace account to log into the Matrix.',
    okText: 'Close',
    cancelButtonProps: {
      style: {
        display: 'none',
      },
    },
    onVisibleChange: (visible) => {
      if (!visible) {
        reset();
      }
    },
  }) : ({
    title: 'Does the staff member have a Garage Project email address?',
    okText: 'Yes',
    cancelText: 'No',
    onCancel: onConfirm,
    okButtonProps: {
      onClick: (e) => {
        e.preventDefault();
        setHasStaffEmail(true);
      },
    },
  });

  return (
    <Popconfirm
      placement="bottom"
      overlayInnerStyle={{
        maxWidth: 350,
      }}
      {...props}
    >
      {children}
    </Popconfirm>
  );
};

CreateStaffUserConfirm.propTypes = {
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CreateStaffUserConfirm;
