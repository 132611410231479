import React, { useState, useEffect } from 'react';
import './MerchProductCard.less';
import PropTypes from 'prop-types';
import {
  Select, Button, Card, Form, InputNumber, Typography, Badge, message, Dropdown, Menu, Popover, Switch, Tag,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { LoadingImage } from 'components';
import ProductAdminSettings from './ProductAdminSettings/ProductAdminSettings';

const { Text } = Typography;

const { Meta } = Card;

const { Option } = Select;

const MerchProductCard = ({
  store, product, onAdd, cart, webstoreAdmin, onSettingsChange, storeIsNZ,
}) => {
  const {
    id: productId, title: productTitle, image, variants, status, settings,
  } = product;

  // Use the first variant that has inventory to set as the initially selected variant
  const initialVariantId = variants.find(({ inventoryQuantity }) => inventoryQuantity > 0).id;

  const [variantPrice, setVariantPrice] = useState(
    variants.find(({ id }) => id === initialVariantId).price,
  );

  const { requestableInDraftMode, salesSamplesOnly } = settings;

  const hasOnlyDefault = variants[0].title === 'Default Title';
  const isDraft = status === 'draft';
  const requestable = !isDraft || (isDraft && requestableInDraftMode);

  const onSubmit = ({ variantId, quantity }) => {
    onAdd({
      quantity,
      variant: variants.find(({ id }) => id === variantId),
      product,
    });
  };

  let settingsRibbon;

  if (salesSamplesOnly) {
    settingsRibbon = {
      color: 'volcano',
      text: 'Sales Samples Only',
    };
  }

  const statusRibbon = isDraft ? {
    color: 'gold',
    text: requestableInDraftMode ? 'Requestable Draft' : 'Draft',
  } : {
    color: 'green',
    text: 'Active',
  };

  const rrp = (
    <Text
      strong
      type="secondary"
      style={{
        marginTop: -8,
        marginLeft: hasOnlyDefault ? 0 : 52,
      }}
    >
      {`RRP $${variantPrice} ${storeIsNZ ? 'NZD' : 'AUD'}`}
    </Text>
  );

  return (
    <Badge.Ribbon
      style={!statusRibbon || !webstoreAdmin ? { display: 'none' } : null}
      placement="start"
      {...statusRibbon}
    >
      <Badge.Ribbon
        style={!settingsRibbon ? { display: 'none' } : null}
        placement="start"
        className={webstoreAdmin ? 'offset-ribbon' : null}
        {...settingsRibbon}
      >
        <Card
          className={`webstore-product-card ${isDraft && !requestableInDraftMode ? 'disabled' : ''}`}
          bordered={false}
          cover={image ? (
            <LoadingImage
              alt={productTitle}
              src={image.src}
              height={136}
              whiteOverlay
              disabled
            />
          ) : null}
        >
          <Meta title={productTitle} />
          <Form
            onFinish={onSubmit}
            onFinishFailed={() => message.error('Items were not added to your cart, please review form errors')}
            name={`webstore-product-card-form-${productId}`}
            initialValues={{
              variantId: initialVariantId,
              quantity: 1,
            }}
          >
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[({ getFieldValue }) => ({
                validator: (_, quantity) => {
                  const variantId = getFieldValue('variantId');
                  const { inventoryQuantity } = variants.find(({ id }) => id === variantId);
                  const quantityInCart = ((cart.find(
                    (cartProduct) => cartProduct.id === productId,
                  )?.variants.find((cartVariant) => cartVariant.id === variantId)?.requestQuantity)
                  ) || 0;
                  const totalAvailable = inventoryQuantity - quantityInCart;
                  if (quantity !== null && quantity !== '') {
                    if (quantity <= 0) {
                      return Promise.reject(new Error('Invalid quantity'));
                    } if (totalAvailable <= 0) {
                      return Promise.reject(new Error('All inventory is already in your cart'));
                    } if (quantity > totalAvailable) {
                      return Promise.reject(new Error(`There ${inventoryQuantity > 1 ? 'are' : 'is'} only ${totalAvailable} available`));
                    }
                  } else {
                    return Promise.reject(new Error('Quantity is required'));
                  }
                  return Promise.resolve();
                },
              })]}
            >
              <InputNumber
                className="product-quantity-field"
                disabled={!requestable}
              />
            </Form.Item>
            {hasOnlyDefault && rrp}
            <Form.Item
              name="variantId"
              label="Size"
              style={{ visibility: hasOnlyDefault ? 'hidden' : 'visible' }}
            >
              <Select
                className="product-size-field"
                disabled={!requestable}
                onChange={(variantId) => setVariantPrice(
                  variants.find(({ id }) => id === variantId).price,
                )}
              >
                {variants.map(({ id: variantId, title: variantTitle, inventoryQuantity }) => (
                  <Option
                    key={variantId}
                    value={variantId}
                    disabled={inventoryQuantity <= 0}
                  >
                    {`${variantTitle}${inventoryQuantity <= 0 ? ' (sold out)' : `  ( ${inventoryQuantity} avail.)`}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!hasOnlyDefault && rrp}
            <Form.Item>
              <div className="product-buttons-container">
                <Button
                  className="add-to-cart-button"
                  htmlType="submit"
                  type="primary"
                  disabled={!requestable}
                >
                  Add to cart
                </Button>
                {webstoreAdmin && (
                  <ProductAdminSettings
                    store={store}
                    productId={productId}
                    settings={settings}
                    onChange={(...args) => onSettingsChange(productId, ...args)}
                  />
                )}
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Badge.Ribbon>
    </Badge.Ribbon>
  );
};

MerchProductCard.propTypes = {
  storeIsNZ: PropTypes.bool.isRequired,
};

export default MerchProductCard;
