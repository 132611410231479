import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Radio, Input, Upload, Button,
} from 'antd';
import FileUpload from '../FileUpload/FileUpload';

const QRCodeURLSelector = ({
  form, urlType, onImageUploaded, onUploadingStateChange, otherIds,
}) => (
  <>
    <Form.Item
      name="urlType"
      label="Link to"
      rules={[{ required: true, message: 'Please select what this QR code will link to' }]}
    >
      <Radio.Group>
        <Radio value="file">An uploaded file</Radio>
        <Radio value="url">A website</Radio>
      </Radio.Group>
    </Form.Item>

    { urlType === 'file' && (
      <Form.Item
        name="destinationUrl"
        label="Upload file"
        rules={[({ getFieldValue }) => ({
          validator: (_, value) => {
            const id = getFieldValue('id');
            if (id) {
              if (otherIds.includes(id)) {
                return Promise.reject(new Error('Please change the name so that the ID is unique before uploading'));
              }
              if (!value || !value.includes('firebasestorage')) {
                return Promise.reject(new Error('Please upload a file'));
              }
            } else {
              return Promise.reject(new Error('Please enter a name for the QR code before uploading'));
            }
            return Promise.resolve();
          },
        })]}
      >
        <FileUpload
          form={form}
          onImageUploaded={onImageUploaded}
          onUploadingStateChange={onUploadingStateChange}
          otherIds={otherIds}
        />
      </Form.Item>
    )}

    { urlType === 'url' && (
      <Form.Item
        name="destinationUrl"
        label="URL"
        rules={[{ required: true, message: 'A website URL is required' }]}
      >
        <Input />
      </Form.Item>
    )}
  </>
);

QRCodeURLSelector.propTypes = {
  urlType: PropTypes.oneOf(['file', 'url']),
};

QRCodeURLSelector.defaultProps = {
  urlType: null,
};

export default QRCodeURLSelector;
