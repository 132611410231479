import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCountryFromIP } from 'util/geolocation';

export const CountryDetectionContext = React.createContext();

export const CountryDetectionProvider = ({ children }) => {
  const [state, setState] = useState({
    initialising: true,
    country: null,
  });

  useEffect(async () => {
    const country = await getCountryFromIP();
    setState({
      initialising: false,
      country,
    });
  }, []);

  return (
    <CountryDetectionContext.Provider value={state}>
      {children}
    </CountryDetectionContext.Provider>
  );
};

CountryDetectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
