import {
  Form, Select,
} from 'antd';
import React, { useMemo } from 'react';
import { productProperties } from 'config/language';
import useArtistsQuery from './use-artists-query';

const ArtistSelectField = ({ fieldName }) => {
  /**
   * Fetch the existing artist options.
   */
  const artistsQuery = useArtistsQuery();

  /**
   * When the array of artists has loaded, map the artist names to a
   * set of options for the autocomplete suggestions.
   */
  const options = useMemo(() => (
    artistsQuery.isSuccess ? artistsQuery.data.map((artist) => ({
      key: artist,
      value: artist,
      label: artist,
    })) : []
  ), [artistsQuery]);

  return (
    <Form.Item
      name={fieldName}
      label={(
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {productProperties.artist.labels.field}
          <p style={{
            opacity: 0.7,
            fontSize: 12,
            marginBottom: 0,
          }}
          >
            Select from an existing artist, or enter a new one.
            If there are multiple artists, enter each artist separately.
          </p>
        </div>
      )}
      labelCol={{ span: 24 }}
      className="product-editor-form-artist"
    >

      <Select
        mode="tags"
        showSearch
        showArrow
        options={options}
        loading={artistsQuery.isLoading}
        notFoundContent={(() => {
          if (artistsQuery.isSuccess) {
            return 'No existing artists found';
          } if (artistsQuery.isError) {
            return 'Error loading existing artists';
          }
          return 'Loading existing artists...';
        })()}
        onBlur={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}

      />

    </Form.Item>
  );
};

export default ArtistSelectField;
