import React, { useState } from 'react';
import './LabelBuilder.less';
import { Switch, Route, Redirect } from 'react-router-dom';
import GeneratorProductTable from 'components/GeneratorProductTable/GeneratorProductTable';
import {
  generateKegLabels,
  generateCaseLabels,
  generateSlabLabels,
  generatePalletLabels,
} from 'util/generate';
import * as api from 'util/api-morpheus.js';
import LabelGeneratorFormContent from './LabelGeneratorFormContent/LabelGeneratorFormContent';

const INITIAL_FORM_VALUES = {
  keg: {
    dateType: 'kegged',
    printAsPdf: false,
  },
  case: {
    dateType: 'bestBefore',
    printAsPdf: false,
  },
  slab: {
    dateType: 'bestBefore',
    printAsPdf: false,
  },
  pallet: {
    dateType: 'bestBefore',
    printAsPdf: false,
  },
};

const LabelBuilder = () => {
  const [generatorStatus, setGeneratorStatus] = useState({
    active: false,
    error: null,
    success: false,
  });

  // Set once a PDF has been successfully generated
  const [labelPDF, setLabelPDF] = useState(null);

  const handlePrint = async ({ type, data, onPrintCSR }) => {
    const { formData, product } = data;
    const { printAsPdf } = formData;
    if (printAsPdf) {
      setGeneratorStatus({
        active: true,
        error: null,
        success: false,
      });
      try {
        const pdf = await api.generatePackingLabels({
          type,
          productId: product.id,
          product,
          formData: {
            ...formData,
            quantity: formData.quantity || 1, // 1 label as default if unspecified
          },
        });

        setLabelPDF(pdf);

        setGeneratorStatus({
          active: false,
          error: null,
          success: true,
        });
      } catch (error) {
        setGeneratorStatus({
          active: false,
          error: error.response?.data.error.userError ?? 'There was an error generating the labels',
          success: false,
        });
      }
    } else {
      onPrintCSR(data);
    }
  };

  return (
    <Switch>
      {[
        {
          type: 'keg',
          onPrint: generateKegLabels,
        },
        {
          type: 'case',
          onPrint: generateCaseLabels,
        },
        {
          type: 'slab',
          onPrint: generateSlabLabels,
        },
        {
          type: 'pallet',
          onPrint: generatePalletLabels,
        },
      ].map(({ type, onPrint: onPrintCSR }) => (
        <Route
          key={type}
          exact
          path={`/dashboard/label-builder/${type}`}
          render={() => (
            <GeneratorProductTable
              type={type}
              selectionType="single"
              pluralLabel={`${type} labels`}
              GeneratorFormContent={LabelGeneratorFormContent}
              initialFormValues={(products) => {
                const initialValues = {
                  certifiedFresh: false,
                  printAsPdf: true,
                };
                products.forEach(({ id }) => {
                  if (Object.keys(INITIAL_FORM_VALUES).includes(type)) {
                    Object.entries(INITIAL_FORM_VALUES[type]).forEach(([field, value]) => {
                      initialValues[`${id}.${field}`] = value;
                    });
                  }
                });
                return initialValues;
              }}
              onGenerate={(data) => handlePrint({ type, data, onPrintCSR })}
              generatorStatus={generatorStatus}
              generatorOutput={{
                type: 'pdf',
                data: labelPDF,
              }}
              onGeneratorReset={() => setGeneratorStatus({
                active: false,
                error: null,
                success: false,
              })}
            />
          )}
        />
      ))}
      <Redirect to="/dashboard/label-builder/slab" />
    </Switch>
  );
};

export default LabelBuilder;
