import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './FoodstuffsTable.less';
import * as api from 'util/api.js';
import {
  Table as AntTable, Button, Space, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomTable } from 'components';
import { AuthContext } from 'util/Auth';
import FoodstuffsItemEditor from '../FoodstuffsItemEditor/FoodstuffsItemEditor';
import FoodstuffsProductLinker from '../FoodstuffsProductLinker';

const { Column } = AntTable;

const FoodstuffsTable = ({ region }) => {
  const [tableId, setTableId] = useState(`foodstuffs-${region}`);
  const [foodstuffsData, setFoodstuffsData] = useState(null);

  const [products, setProducts] = useState(null);
  useEffect(() => {
    api.getProducts().then(setProducts);
  }, []);

  // Used to store the object of the item currently being edited.
  const [editItem, setEditItem] = useState(null);

  // Used to store the object of the item currently being migrated to a product field.
  const [migrateItem, setMigrateItem] = useState(null);

  const articleNumberColumnId = useMemo(() => ({
    [api.FOODSTUFFS_REGIONS.NORTH]: 'foodstuffsArticleNumberNorthIsland',
    [api.FOODSTUFFS_REGIONS.SOUTH]: 'foodstuffsArticleNumberSouthIsland',
  }[region]), [region]);

  const [tableData, setTableData] = useState(undefined);

  useEffect(() => {
    if (Array.isArray(foodstuffsData) && Array.isArray(products)) {
      setTableData([
        ...foodstuffsData.map(({ id, name, articleNumber }) => ({
          key: `item-${id}`, // Each row must have a unique key
          id,
          name,
          articleNumber,
          isMigrated: false,
        })),
        ...products.filter(
          (product) => product[articleNumberColumnId] !== null,
        ).map(({ id, name, ...product }) => ({
          key: `product-${id}`, // Each row must have a unique key
          id,
          name,
          articleNumber: product[articleNumberColumnId],
          isMigrated: true,
        })),
      ]);
    }
  }, [foodstuffsData, products]);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    setFoodstuffsData(null);
    setTableId(`foodstuffs-${region}`);
    api.getFoodstuffsData(region).then(setFoodstuffsData);
  }, [region]);

  const onItemEditorClosed = (updatedItem) => {
    if (editItem) {
      setEditItem(null);
    }
    if (updatedItem) {
      if (tableData) {
        const newData = tableData.filter((item) => item.id !== updatedItem.id);
        setTableData([
          ...newData,
          updatedItem,
        ]);
      }
    }
  };

  const onProductLinkerClosed = (args) => {
    if (args) {
      const {
        migratedItemId, productId, productName, articleNumber,
      } = args;
      setMigrateItem(null);
      if (tableData) {
        setTableData([
          ...tableData.filter((item) => item.id !== migratedItemId),
          {
            key: `product-${productId}`, // Each row must have a unique key
            id: productId,
            name: productName,
            articleNumber,
            isMigrated: true,
          },
        ]);
      }
    }
  };

  const onDelete = (itemToDelete) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${itemToDelete.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteFoodstuffsItem(region, itemToDelete.id)
        .then(() => {
          setFoodstuffsData(foodstuffsData.filter((item) => (
            Number(item.id) !== Number(itemToDelete.id)
          )));
        })
        .catch(),
    });
  };

  return (
    <CustomTable
      key={region}
      id={tableId}
      pluralLabel="items"
      searchableColumns={['name', 'articleNumber']}
      dataSource={tableData}
      controls={currentUser.admin ? (
        <>
          <FoodstuffsItemEditor
            region={region}
            existingItem={editItem}
            onClose={onItemEditorClosed}
            disableButton={!foodstuffsData}
          />
          <FoodstuffsProductLinker
            products={products}
            region={region}
            existingItem={migrateItem}
            onClose={onProductLinkerClosed}
            disableButton={!foodstuffsData}
            articleNumberColumnId={articleNumberColumnId}
          />
        </>
      ) : null}
    >
      <Column
        title="Name"
        key="name"
        dataIndex="name"
        sortType="string"
      />
      <Column
        title="Article Number"
        key="articleNumber"
        dataIndex="articleNumber"
        sortType="number"
      />
      {currentUser.admin ? (
        <Column
          title=""
          key="actions"
          render={(item) => (
            <Space size="middle">
              {item.isMigrated ? (
                <Button
                  type="link"
                  href={`/dashboard/product-database/products/${item.id}/edit`}
                >
                  Edit product
                </Button>
              ) : (
                <>
                  <Button
                    type="link"
                    onClick={() => setMigrateItem(item)}
                  >
                    Migrate to product field
                  </Button>
                  <Button
                    type="link"
                    onClick={() => setEditItem(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={() => onDelete(item)}
                  >
                    Delete
                  </Button>
                </>
              )}

            </Space>
          )}
        />
      ) : <></>}
    </CustomTable>
  );
};

FoodstuffsTable.propTypes = {
  region: PropTypes.oneOf(Object.values(api.FOODSTUFFS_REGIONS)).isRequired,
};

export default FoodstuffsTable;
