import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './ReportUserAuth.less';
import {
  Button, Form, Input, Modal, Select,
} from 'antd';
import { CustomModal } from 'components';
import * as api from 'util/api.js';
import {
  PlusOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { omit } from 'lodash';

const { useForm } = Form;

const ReportUserAuth = ({ onClose, disableButton }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [existingReportUser, setExistingReportUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const [reportUsers, setReportUsers] = useState([]);

  useEffect(() => {
    api.getReportUsers().then(setReportUsers);
    console.log(reportUsers);
  }, [existingReportUser]);

  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
    } else {
      setFormInitialised(true);
      form.resetFields();
    }
  }, [form, formInitialised]);

  const handleUserChange = (userId) => {
    const user = reportUsers.find((u) => u.id === userId);
    form.setFieldsValue(user);
    setExistingReportUser(user);
    setSelectedUser(userId);
    console.log('user changed: ', user);
  };

  const onCreateClicked = () => {
    setModalOpen(true);
    setExistingReportUser(null);
    setFormInitialised(false);
  };

  const handleClose = (updated) => {
    setModalOpen(false);
    setTimeout(() => onClose(updated), 500);
    console.log('handle close ', existingReportUser);
    setExistingReportUser(null);
    setFormInitialised(false);
    setSelectedUser(null);
  };

  const handleDelete = () => {
    if (existingReportUser) {
      Modal.confirm({
        title: `Are you sure you want to remove user "${existingReportUser.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be reversed.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        centered: true,
        onOk: () => {
          api.deleteReportUser(existingReportUser.id).then(() => {
            setModalOpen(false);
            setExistingReportUser(null);
            setSelectedUser(null);
          }).catch(() => {
            setSaving(false);
          });
        },
      });
    }
  };

  const onSubmit = (values) => {
    setSaving(true);
    const updatedReportUser = { ...values };

    if (existingReportUser) {
      const { id } = existingReportUser;
      console.log('id is: ', id);
      api.updateReportUser(id, updatedReportUser).then(() => {
        setSaving(false);
        handleClose({ id, ...updatedReportUser });
        setExistingReportUser(null);
        setFormInitialised(false);
        setSelectedUser(null);
        console.log('on submit close afer null: ', existingReportUser);
      }).catch(() => {
        setSaving(false);
      });
    } else {
      api.createReportUser(updatedReportUser).then((id) => {
        api.getReportUsers().then(setReportUsers);
        setSaving(false);
        handleClose({ id, ...updatedReportUser });
        setFormInitialised(false);
      }).catch(() => {
        setSaving(false);
      });
    }
  };

  return (
    // Prevent interaction with modal from triggering panel open/close
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        type="secondary"
        icon={<PlusOutlined />}
        onClick={onCreateClicked}
      >
        Manage users
      </Button>

      <CustomModal
        title="Manage Authorised Users"
        centered
        visible={modalOpen}
        onCancel={() => handleClose()}
        footer={[
          <div className="auth-modal-footer">
            <Button
              key="delete"
              danger
              onClick={handleDelete}
            >
              Delete user
            </Button>
            <div className="auth-modal-footer-right">
              <Button
                key="cancel"
                onClick={() => handleClose()}
                disabled={saving}
              >
                Cancel
              </Button>
              <Button
                form="user-management-form"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={saving}
              >
                {existingReportUser ? 'Update user' : 'Add user'}
              </Button>
            </div>
          </div>,
        ]}
      >
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            id="user-management-form"
            initialValues={existingReportUser}
          >
            <Form.Item
              name="email"
              label="User email"
              rules={[{ required: true, message: 'Please input user email' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="User name"
              rules={[{ required: true, message: 'Please input users name' }]}
            >
              <Input />
            </Form.Item>
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              className="auth-list"
              placeholder="Select an existing user..."
            >
              {reportUsers && reportUsers.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.email}
                </Select.Option>
              ))}
            </Select>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};

ReportUserAuth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

ReportUserAuth.defaultProps = {
  disableButton: false,
};

export default ReportUserAuth;
