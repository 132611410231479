import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8010'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/start-manual-sync';

export default ({ onSettled, onSuccess, onError }) => useMutation({
  mutationKey: ['start_manual_sync'],
  mutationFn: async () => (await axios({
    method: 'POST',
    url: URL,
  })).data,
  onSettled,
  onSuccess,
  onError,
});
