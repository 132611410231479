import React from 'react';
import './Home.less';
import { HomeCard } from 'components';
import { Typography } from 'antd';
import { capitalCase } from 'change-case';
import links from './links';

const { Title } = Typography;

const Home = () => (
  <div>
    {Object.entries(links).map(([sectionTitle, sectionLinks]) => (
      <div className="home-card-section" key={sectionTitle}>
        <Title level={4}>{capitalCase(sectionTitle)}</Title>
        <div className="home-card-grid">
          {sectionLinks.map((link) => (
            <HomeCard
              key={link.title}
              {...link}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default Home;
