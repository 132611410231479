import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './LabelGeneratorFormContent.less';
import {
  Form, Input, Select, Alert, Avatar, Typography, Tooltip, InputNumber, Checkbox,
} from 'antd';
import { DatePicker, HoverHelp } from 'components';
import { capitalCase } from 'change-case';
import { isNull } from 'lodash';

const { Text } = Typography;

const { Option } = Select;

const LabelGeneratorFormContent = ({
  type, products, form, modalOpen,
}) => {
  // Label generators are all single selection type, so
  // the products array should only have one item
  const [product] = products;

  const [unitSizeWarning, setUnitSizeWarning] = useState(null);

  // Keeping track of the selected unit type allows controlling which
  // date types are shown when generating pallet labels.
  // For example, don't show 'canned on' when 'case' is selected.
  const [selectedPalletType, setSelectedPalletType] = useState(null);
  // Change the date type to the correct alternative if a conflicting
  // unit type has been selected. For example, pallet of slabs changed
  // to pallet of cases, so 'canned on' becomes 'bottled on'.
  const onPalletTypeChanged = (palletType) => {
    setSelectedPalletType(palletType);
    form.resetFields();
    form.setFieldsValue({ palletType });

    const dateType = form.getFieldValue('dateType');

    if (palletType === 'slab' && ['bottled', 'kegged'].includes(dateType)) {
      form.setFieldsValue({ dateType: 'canned' });
    } else if (palletType === 'case' && ['canned', 'kegged'].includes(dateType)) {
      form.setFieldsValue({ dateType: 'bottled' });
    } else if (palletType === 'keg' && ['bottled', 'canned'].includes(dateType)) {
      form.setFieldsValue({ dateType: 'kegged' });
    }

    // Clear unit size when unit type changes
    form.setFieldsValue({ unitSize: null });
    setUnitSizeWarning(null);
  };

  // Check and show a warning if a barcode does not exists for the selected unit size.
  const onUnitSizeChanged = (unitSize) => {
    const barcodeType = type === 'pallet' ? form.getFieldValue('palletType') : type;
    setUnitSizeWarning(() => (((
      // TODO: Improve/remove the quick workaround added below for CF slabs of differing sizes
      !product[`barcode${capitalCase(barcodeType)}.${unitSize}`])
      // Kegs no longer require barcodes
      && barcodeType !== 'keg'
    ) ? (
        `Warning: A barcode has not been added for ${barcodeType}s of this unit size`
      ) : null));
  };

  // Clear selected unit types and warnings when the modal closes
  useEffect(() => {
    if (!modalOpen) {
      setSelectedPalletType(null);
      setUnitSizeWarning(null);
    }
  }, [modalOpen]);

  if (!product) {
    return null;
  }

  return (
    <>
      <div className="label-generator-product-name">
        <Avatar src={product.tapBadgeUrl} className="generator-modal-product-avatar" />
        {/* <Text>{product.name}</Text> */}
        <Form.Item
          name="customProductName"
          label="Product Name"
          value={product.name}
          initialValue={product.name}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input defaultValue={product.name} />
        </Form.Item>
      </div>
      {type === 'pallet' && (
        <Form.Item
          name="palletType"
          label="Pallet of"
          rules={[{ required: true, message: 'Pallet type is required' }]}
        >
          <Select onChange={(palletType) => onPalletTypeChanged(palletType)}>
            <Option value="slab">Slabs</Option>
            <Option value="case">Cases</Option>
            <Option value="keg">Kegs</Option>
          </Select>
        </Form.Item>
      )}
      <Tooltip
        title={(type === 'pallet' && !selectedPalletType) ? (
          'Select what the pallet contains first using the field above'
        ) : null}
        placement="bottom"
      >
        <Form.Item
          className="unit-size-selection"
          name="unitSize"
          label="Unit size"
          help={unitSizeWarning}
          rules={[
            {
              required: true,
              message: 'Unit size is required',
            },
          ]}
        >
          <Select
            disabled={(type === 'pallet' && !selectedPalletType)}
            onChange={onUnitSizeChanged}
          >
            {[selectedPalletType, type].includes('slab') && (
            <>
              <Option value="24x.440">24 x 440ml Cans</Option>
              <Option value="15x.440">15 x 440ml Cans</Option>
              <Option value="16x.440">16 x 440ml Cans</Option>
              <Option value="440.4">440ml Cans (6 x 4-packs)</Option>
              <Option value="375">375ml Cans</Option>
              <Option value="330.12">330ml Cans (2 x 12-packs)</Option>
              <Option value="330.10">330ml Cans (3 x 10-packs)</Option>
              <Option value="330.6">330ml Cans (4 x 6-packs)</Option>
              <Option value="330.4">330ml Cans (6 x 4-packs)</Option>
              <Option value="24x.330">24 x 330ml Cans</Option>
              <Option value="16x.330">16 x 330ml Cans</Option>
              <Option value="250.4">250ml Cans (6 x 4-packs)</Option>
              <Option value="250">250ml Cans</Option>
            </>
            )}
            {[selectedPalletType, type].includes('case') && (
            <>
              <Option value="750.12">750ml Bottles (Crushed 12-pack)</Option>
              <Option value="750">750ml Bottles</Option>
              <Option value="650">650ml Bottles</Option>
              <Option value="375.6">375ml Bottles (4 x 6-packs)</Option>
              <Option value="375">375ml Bottles</Option>
              <Option value="330.6">330ml Bottles (4 x 6-packs)</Option>
              <Option value="330">330ml Bottles</Option>
            </>
            )}
            {[selectedPalletType, type].includes('keg') && (
            <>
              <Option value="50">50L</Option>
              <Option value="30">30L</Option>
              <Option value="20">20L</Option>
              <Option value="19.5">19.5L</Option>
            </>
            )}
          </Select>
        </Form.Item>
      </Tooltip>

      <Form.Item
        name="certifiedFresh"
        valuePropName="checked"
        className="certified-fresh-checkbox"
        labelCol={{ span: 24 }}
      >
        <Checkbox>
          Certified Fresh
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="gyle"
        label="Gyle"
        rules={[{ required: true, message: 'Gyle is required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="quantity"
        rules={type === 'pallet' ? [{ required: true, message: `Quantity of ${selectedPalletType}s on pallet is required` }] : null}
        label={(
          <>
            {type === 'pallet' ? `Quantity${selectedPalletType ? ` of ${selectedPalletType}s` : ''}` : 'Number of labels'}
            <HoverHelp message={
                type === 'pallet' ? (
                  "This is the number that will be displayed as 'quantity' on the label."
                ) : (
                  'This is the minimum number of labels required and may be rounded up to fill the nearest full page.')
                }
            />
          </>
            )}
      >
        <InputNumber placeholder={1} />
      </Form.Item>
      <div className="label-generator-date-selection">
        <span className="label-generator-date-type-asterisk">*</span>
        <Form.Item
          name="dateType"
          rules={[{ required: true, message: 'Date type is required' }]}
          style={{ flex: 1 }}
          className="label-generator-date-type"
        >
          <Select onDropdownVisibleChange={() => (
            setSelectedPalletType(form.getFieldValue('palletType')))}
          >
            { [selectedPalletType, type].includes('slab') && <Option value="canned">Canned on:</Option>}
            { [selectedPalletType, type].includes('case') && <Option value="bottled">Bottled on:</Option>}
            { [selectedPalletType, type].includes('keg') && <Option value="kegged">Kegged on:</Option>}
            <Option value="bestBefore">Best before:</Option>
            <Option value="packed">Packed on:</Option>
            <Option value="expiry">Expiry:</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="date"
          rules={[{ required: true, message: 'A date is required' }]}
          style={{ flex: 2 }}
        >
          <DatePicker
            picker="day"
            placeholder={null}
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </div>
      {/* <Alert
        message="We've built a better way to generate labels. Please enable V2 of the label generator below, and if you have issues with it, please submit an IT request to let us know! 🙏"
        type="info"
        showIcon
        style={{ marginBottom: 10 }}
      /> */}
      <Form.Item
        name="printAsPdf"
        valuePropName="checked"
        style={{ flex: 1, marginBottom: 0 }}
        labelCol={{ span: 24 }}
      >
        <Checkbox>
          Use label generator V2 (recommended)
        </Checkbox>
      </Form.Item>
    </>
  );
};

LabelGeneratorFormContent.propTypes = {
  type: PropTypes.oneOf(['keg', 'case', 'slab', 'pallet']).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Disabled since ant form shape may change
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default LabelGeneratorFormContent;
