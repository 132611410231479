import React, { useState, useEffect, useContext } from 'react';
import * as api from 'util/api.js';
import './QRCodeTable.less';
import {
  Table as AntTable, Button, Space, Modal,
} from 'antd';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomTable } from 'components';
import { AuthContext } from 'util/Auth';
import { Link } from 'react-router-dom';
import QRCodeEditor from '../QRCodeEditor/QRCodeEditor';
import { getFileNameFromFirebaseUrl } from '../FileUpload/FileUpload';

const { Column } = AntTable;

const QRCodeTable = () => {
  const [qrCodes, setQRCodes] = useState(null);

  // Used to store the object of the item currently being edited.
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    api.getQRCodes().then(setQRCodes);
  }, []);

  const onItemEditorClosed = (updatedItem) => {
    if (editItem) {
      setEditItem(null);
    }
    if (updatedItem) {
      console.log('updatedItem', updatedItem);
      if (qrCodes) {
        const newData = qrCodes.filter((item) => item.id !== updatedItem.id);
        setQRCodes([
          ...newData,
          updatedItem,
        ]);
      }
    }
  };

  const onDelete = (itemToDelete) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this QR code?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteQRCode(itemToDelete.id)
        .then(() => {
          setQRCodes(qrCodes.filter((qrCode) => (
            qrCode.id !== itemToDelete.id
          )));
        })
        .catch(),
    });
  };

  return (
    <CustomTable
      key="qr-codes"
      id="qr-codes"
      pluralLabel="QR codes"
      searchableColumns={['name', 'id', 'description']}
      dataSource={qrCodes?.map((item) => (
        {
          ...item,
          key: item.id, // Each row must have a unique key
        }
      ))}
      controls={(
        <QRCodeEditor
          existingItem={editItem}
          onClose={onItemEditorClosed}
          disableButton={!qrCodes}
          existingIds={qrCodes ? qrCodes.map(({ id }) => id) : []}
        />
      )}
    >
      <Column
        title="Name"
        key="name"
        dataIndex="name"
        sortType="string"
      />
      <Column
        title="ID"
        key="id"
        dataIndex="id"
        sortType="string"
      />
      <Column
        title="Description"
        key="description"
        dataIndex="description"
        sortType="string"
      />
      <Column
        style={{ whiteSpace: 'nowrap' }}
        title="Last modified"
        dataIndex="dateLastModified"
        key="dateLastModified"
        sortType="date"
        render={(value) => (dayjs.isDayjs(value) ? value.format('DD/MM/YYYY') : value)}
      />
      <Column
        title="Links to"
        key="destinationUrl"
        sortType="string"
        render={({ destinationUrl, urlType }) => (
          <Link to={{ pathname: destinationUrl }} target="_blank" className={`destination-url ${urlType === 'url' ? 'clipped' : null}`}>
            {urlType === 'file' ? getFileNameFromFirebaseUrl(destinationUrl) : destinationUrl}
          </Link>
        )}
      />
      <Column
        title=""
        key="actions"
        render={(item) => (
          <Space size="middle">
            <a
              href={item.qrCodeImageUrl}
              download
              target="_blank"
              rel="noreferrer"
            >
              View QR Code
            </a>
            <Button
              type="link"
              onClick={() => {
                setEditItem(item);
              }}
            >
              Edit
            </Button>
            <Button
              type="link"
              danger
              onClick={() => onDelete(item)}
            >
              Delete
            </Button>
          </Space>
        )}
      />
    </CustomTable>
  );
};

export default QRCodeTable;
