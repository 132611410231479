import React, { useState, useCallback, useMemo } from 'react';
import {
  Form, Input, Select, Image, Checkbox, Switch, Skeleton, Modal,
  TreeSelect, Tooltip,
} from 'antd';
import { ProductStyleSelector, ProductTagSelector } from 'components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { briefProperties } from 'config/language';

import productCategories from 'config/productCategories';

const { Option } = Select;

const { TextArea } = Input;

const Creative = ({ form, onNameChange }) => {
  // Set the initial types if they exist (e.g. editing)
  const [productType, setProductType] = useState(form.getFieldValue('productType'));
  const [baseUnitType, setBaseUnitType] = useState(form.getFieldValue('baseUnitType'));

  const handleProductTypeChange = useCallback((value) => {
    // Reset any fields that are type specific
    const onSetType = () => {
      setProductType(value);
      form.setFieldsValue({
        style: null,
        tags: [],
      });
    };

    // Revert the field if the user changes their mind
    const onCancel = () => {
      form.setFieldsValue({ productType });
    };

    // If style or tags have been set, warn the user that changing
    // the type will clear these
    if (form.getFieldValue('style') || form.getFieldValue('tags')?.length) {
      Modal.confirm({
        title: 'Are you sure you want to change the product type?',
        icon: <ExclamationCircleOutlined />,
        content: 'This will clear the style and any tags that have been set.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        centered: true,
        onOk: onSetType,
        onCancel,
      });
    } else {
      onSetType();
    }
  }, [form, setProductType]);

  const handleBaseUnitTypeChange = useCallback((value) => {
    console.log(!!form.getFieldValue('baseUnitSize') || !!form.getFieldValue('baseUnitColour'));
    console.log(form.getFieldValue('baseUnitSize'));

    // Reset any fields that are type specific
    const onSetType = () => {
      setBaseUnitType(value);
      form.setFieldsValue({
        baseUnitSize: null,
        baseUnitColour: null,
      });
    };

    // Revert the field if the user changes their mind
    const onCancel = () => {
      form.setFieldsValue({ productType });
    };

    // If base unit size or colour have been set, warn the
    // user that changing the type will clear these
    if (form.getFieldValue('baseUnitSize') || form.getFieldValue('baseUnitColour')) {
      Modal.confirm({
        title: 'Are you sure you want to change the base unit type?',
        icon: <ExclamationCircleOutlined />,
        content: 'This will clear the base unit size and colour if these have been set.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        centered: true,
        onOk: onSetType,
        onCancel,
      });
    } else {
      onSetType();
    }
  }, [form, setProductType]);

  return (
    <div className="columns-container">
      <div className="column">
        <Form.Item
          name="name"
          label={briefProperties.name.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input onChange={(event) => {
            onNameChange(event.target.value);
          }}
          />
        </Form.Item>
        <Form.Item
          name="purpose"
          label={briefProperties.purpose.labels.field}
          labelCol={{ span: 24 }}
        >
          <TextArea
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
        <Form.Item
          name="oneLiner"
          label={briefProperties.oneLiner.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="blurb"
          label={briefProperties.blurb.labels.field}
          labelCol={{ span: 24 }}
        >
          <TextArea
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
        <Form.Item
          name="productType"
          label={briefProperties.productType.labels.field}
          labelCol={{ span: 24 }}
          style={{ flex: 2 }}
        >
          <Select onChange={handleProductTypeChange}>
            <Option value="beer">Beer</Option>
            <Option value="wine">Wine</Option>
            <Option value="seltzer">Seltzer</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="style"
          label={briefProperties.style.labels.field}
          labelCol={{ span: 24 }}
        >
          <ProductStyleSelector type={productType} />
        </Form.Item>
        <Form.Item
          name="tags"
          label={briefProperties.tags.labels.field}
          labelCol={{ span: 24 }}
        >
          <ProductTagSelector type={productType} />
        </Form.Item>
      </div>
      <div className="column">
        <Form.Item
          name="contractedArtist"
          label={briefProperties.contractedArtist.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="artistBrief"
          label={briefProperties.artistBrief.labels.field}
          labelCol={{ span: 24 }}
        >
          <TextArea
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>
        <Form.Item
          name="artDueDate"
          label={briefProperties.artDueDate.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="baseUnitType"
          label={briefProperties.baseUnitType.labels.field}
          labelCol={{ span: 24 }}
        >
          <Select
            onChange={handleBaseUnitTypeChange}
            options={[
              { label: 'Can', value: 'can' },
              { label: 'Bottle', value: 'bottle' },
            ]}
          />
        </Form.Item>
        <Tooltip
          title={!baseUnitType ? 'Please specify a base unit type first' : null}
          placement="bottom"
        >
          <Form.Item
            name="baseUnitSize"
            label={briefProperties.baseUnitSize.labels.field}
            labelCol={{ span: 24 }}
          >
            <Select
              disabled={!baseUnitType}
              options={baseUnitType ? productCategories.baseUnitSizes[baseUnitType].data : []}
            />
          </Form.Item>
        </Tooltip>
        <Tooltip
          title={!baseUnitType ? 'Please specify a base unit type first' : null}
          placement="bottom"
        >
          <Form.Item
            name="baseUnitColour"
            label={briefProperties.baseUnitColour.labels.field}
            labelCol={{ span: 24 }}
          >
            <Select
              disabled={!baseUnitType}
              options={baseUnitType ? productCategories.baseUnitColours[baseUnitType].data : []}
            />
          </Form.Item>
        </Tooltip>
      </div>
    </div>
  );
};

export default Creative;
