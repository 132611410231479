import React, { useState, useCallback } from 'react';
import axios from 'axios';
import './SiteBuilds.less';
import { Modal, Card, Button } from 'antd';

// Currently this page is only for triggering manual rebuilds of the websites if the
// "smart" cloud builds do not correctly update Shopify data e.g. product does not go
// out of stock when it should. Future functionality could be added once the Gatsby Cloud
// management API has been implemented by the Gatsby team (currently marked as planned on
// the roadmap: https://gatsby.canny.io/gatsby-cloud/p/expose-a-gatsby-cloud-management-api)

const SITES = [
  {
    title: 'Garage Project New Zealand',
    url: 'https://garageproject.co.nz',
    netlifyBuildHookId: '650b3eda735a5135c75ac116',
  },
  {
    title: 'Garage Project Australia',
    url: 'https://garageproject.com.au',
    netlifyBuildHookId: '650b3f0f3e4166321fc9ce79',
  },
];

const RebuildCard = ({ title, url, netlifyBuildHookId }) => {
  const [triggering, setTriggering] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const triggerRebuild = useCallback(async () => {
    setTriggering(true);
    try {
      // https://support.gatsbyjs.com/hc/en-us/articles/360052324394-Build-and-Preview-Webhooks
      await axios.post(`https://api.netlify.com/build_hooks/${netlifyBuildHookId}?clear_cache=true&trigger_title=Rebuild+triggered+from+The+Matrix`);

      setWaiting(true);

      Modal.success({
        title: 'Rebuild started successfully!',
        centered: true,
        content: `
        This usually takes about 5 - 10 minutes. Please wait and check
        the site again shortly to see if the content is appearing as expected.`,
      });

      // Wait 10 minutes before allowing retry
      setTimeout(() => {
        setWaiting(false);
      }, 600000);
    } catch (error) {
      Modal.error({
        title: 'Error triggering site rebuild',
        centered: true,
        content: 'Please contact the website developer for help.',
      });
    } finally {
      setTriggering(false);
    }
  }, [netlifyBuildHookId, setTriggering, setWaiting]);

  return (
    <Card
      className="site-build-card"
      actions={[(
        <Button
          key="cancel"
          type="primary"
          onClick={triggerRebuild}
          loading={triggering}
          disabled={waiting}
        >
          Clear cache and rebuild
        </Button>
      )]}
    >
      <Card.Meta
        title={title}
        description={(
          <Button type="link" href={url} className="inline-link-button">
            {url}
          </Button>
        )}
      />
    </Card>
  );
};

const SiteBuilds = () => (
  <div id="site-builds-page">
    {SITES.map((siteData) => <RebuildCard {...siteData} />)}
  </div>
);

export default SiteBuilds;
