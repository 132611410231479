import React, { useState, useEffect } from 'react';
import './AddOnetimes.less';
import * as api from 'util/api';
import {
  Popover,
  Button,
  Modal,
  Input,
  message,
  Divider,
  Pagination,
  Badge,
  Popconfirm,
  Select,
} from 'antd';
import { ShoppingCartOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FullPageLoader, StickyHeader } from 'components';
import STORES from 'config/stores';
import WebstoreProductCard from './WebstoreProductCard/WebstoreProductCard';
import ConfirmRequestModal from './ConfirmOnetimesModal/ConfirmRequestModal';
import WebstoreProductList from '../WebstoreProductList/WebstoreProductList';

const { Option } = Select;
const { Search } = Input;

const filterByCategory = (products, selectedCategory) => (selectedCategory === 'all' ? (
  products
) : (
  products.filter(({ categories }) => categories.includes(selectedCategory))
));

const filterBySearch = (products, searchQuery) => (
  products.filter(({ title }) => (
    title?.toUpperCase().includes(searchQuery.toUpperCase())
  ))
);

const evaluateIndexRange = (currentPage, productsPerPage) => {
  const start = (currentPage - 1) * productsPerPage;
  const end = currentPage * productsPerPage;
  return [start, end];
};

const AddOnetimes = ({ webstoreAdmin, defaultStore }) => {
  const [store, setStore] = useState(defaultStore);
  const storeIsNZ = [STORES.GPNZ, STORES.DWNZ].includes(store);

  const [selectedCategory, setSelectedCategory] = useState('all');
  const [availableProducts, setAvailableProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [cart, setCart] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const [confirmOnetimesOpen, setConfirmOnetimesOpen] = useState(false);

  useEffect(() => {
    setAvailableProducts(null);
    setFilteredProducts(null);
    setSelectedCategory('all');
    setCurrentPage(1);
    setSearch('');
    if (cart && cart.length > 0) {
      setCart([]);
    }
    api.getAvailableShopifyProducts(store)
      .then(setAvailableProducts);
  }, [store]);

  const getScrollArea = () => document.getElementById('scrollable-page-area-container');

  useEffect(() => {
    if (availableProducts) {
      let filtered = filterByCategory(availableProducts, selectedCategory);
      filtered = filterBySearch(filtered, search);
      setFilteredProducts(filtered);
      // Reset current page to default.
      setCurrentPage(1);
      // Scroll back to top of page.
      getScrollArea().scrollTop = 0;
    }
  }, [search, selectedCategory]);

  const onClear = () => {
    setCart([]);
    message.info('Cart cleared!');
  };

  const onSearch = (searchQuery) => {
    setSearch(searchQuery);
    if (selectedCategory !== 'all') {
      setSelectedCategory('all');
    }
  };

  const onPaginationChange = (page, pageSize) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      // Scroll back to top of page.
      getScrollArea().scrollTop = 0;
    }
    if (pageSize !== productsPerPage) {
      setProductsPerPage(pageSize);
    }
  };

  const onProductSettingsChange = (productId, settingKey, enabled) => {
    // Update the setting for the product in both in the list of available
    // products as well as the filtered list (if filtering is applied)
    const updateSetting = (stateSetter) => {
      stateSetter((current) => {
        const productIndex = current.findIndex(({ id }) => id === productId);
        const newProducts = [...current];
        newProducts[productIndex].settings[settingKey] = enabled;
        return newProducts;
      });
    };
    updateSetting(setAvailableProducts);
    if (filteredProducts !== null) {
      updateSetting(setFilteredProducts);
    }
  };

  const onAdd = ({ quantity, variant, product }) => {
    const newCart = [...cart];
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    if (existingProductIndex === -1) {
      newCart.push({
        ...product,
        imageUrl: product.image.src,
        variants: [
          {
            ...variant,
            quantity,
          },
        ],
      });
    } else {
      const existingVariantIndex = cart[existingProductIndex].variants.findIndex(
        (item) => item.id === variant.id,
      );
      if (existingVariantIndex === -1) {
        newCart[existingProductIndex].variants.push({
          ...variant,
          quantity,
        });
      } else {
        newCart[existingProductIndex].variants[existingVariantIndex].quantity += quantity;
      }
    }
    setCart(newCart);
    message.info((variant.title === 'Default Title') ? (
      `${quantity} x ${product.title} added to cart!`
    ) : (
      `${quantity} x ${product.title} (${variant.title}) added to cart!`
    ));
  };

  const onRemove = (productId) => {
    setCart((current) => current.filter((product) => product.id !== productId));
  };

  const onStoreChange = (newStore) => {
    if (cart && cart.length > 0) {
      Modal.confirm({
        title: 'You still have items in your cart, are you sure you want to switch stores?',
        icon: <ExclamationCircleOutlined />,
        content: 'Switching stores will clear your cart.',
        okText: 'Yes',
        okType: 'warn',
        cancelText: 'No',
        centered: true,
        onOk: () => setStore(STORES[newStore]),
      });
    } else {
      setStore(STORES[newStore]);
    }
  };

  const onCloseConfirmRequest = () => {
    setConfirmOnetimesOpen(false);
  };

  const onRequestSubmitted = () => {
    onCloseConfirmRequest();
    setCart([]);
  };

  const emptyCart = cart.length === 0;

  const totalItems = cart.reduce(
    (total, product) => total + product.variants.reduce(
      (productTotal, variant) => (
        productTotal + variant.quantity
      ), 0,
    ), 0,
  );

  const productData = filteredProducts || availableProducts;

  const loadingProducts = availableProducts === null;

  return (
    <div id="webstore-requests-page">
      <ConfirmRequestModal
        open={confirmOnetimesOpen}
        onClose={onCloseConfirmRequest}
        onSubmitted={onRequestSubmitted}
        cart={cart}
        store={store}
        storeIsNZ={storeIsNZ}
        webstoreAdmin={webstoreAdmin}
      />
      <StickyHeader>
        <Select
          defaultValue={defaultStore}
          style={{ width: 180 }}
          value={store}
          onChange={onStoreChange}
          disabled={loadingProducts}
        >
          <Option value={STORES.GPNZ}>Garage Project NZ</Option>
          <Option value={STORES.GPAU}>Garage Project AU</Option>
        </Select>
        {/* Hide category select for Dirty Water stores */}
        {['GPNZ', 'GPAU'].includes(store) && (
          <Select
            style={{ width: 140 }}
            value={selectedCategory}
            onChange={setSelectedCategory}
            disabled={loadingProducts}
          >
            <Option value="all">All products</Option>
            <Option value="beer">Beer</Option>
            <Option value="wine">Wine</Option>
            <Option value="seltzer">Seltzer</Option>
            {/* Only NZ store has art collection */}
            {store === 'GPNZ' && <Option value="art">Art</Option>}
            <Option value="merch">Merch</Option>
          </Select>
        )}
        <Search
          id="webstore-product-search"
          disabled={loadingProducts}
          placeholder="Search..."
          onPressEnter={(event) => onSearch(event.target.value)}
          onSearch={onSearch}
          allowClear
        />
        <Popover
          placement="bottom"
          content={(
            <div id="cart-content">
              <WebstoreProductList
                products={cart}
                emptyText="There are no items in your cart"
                allowRemove
                onRemove={onRemove}
                showRRP
                storeIsNZ={storeIsNZ}
              />
              {!emptyCart && (
              <div className="cart-footer">
                <Divider />
                <div className="cart-footer-buttons">
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    onConfirm={onClear}
                    icon={(
                      <ExclamationCircleOutlined
                        style={{ color: 'red' }}
                      />
                    )}
                  >
                    <Button danger>
                      Clear cart
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    onClick={() => setConfirmOnetimesOpen(true)}
                  >
                    Request products
                  </Button>
                </div>
              </div>
              )}
            </div>
        )}
          trigger="click"
        >
          <Badge count={totalItems} offset={[-2, 3]}>
            <Button
              type="primary"
              icon={<ShoppingCartOutlined />}
              disabled={loadingProducts}
            >
              View cart
              <DownOutlined />
            </Button>
          </Badge>
        </Popover>
      </StickyHeader>
      {loadingProducts ? (<FullPageLoader message="Loading available products..." />) : (
        <>
          {productData && productData.length > 0 ? (
            <>
              <div className="webstore-card-grid">
                {productData.slice(
                  ...evaluateIndexRange(currentPage, productsPerPage),
                ).map((product) => (
                  <WebstoreProductCard
                    key={product.id}
                    store={store}
                    product={product}
                    onAdd={onAdd}
                    cart={cart}
                    webstoreAdmin={webstoreAdmin}
                    onSettingsChange={onProductSettingsChange}
                    storeIsNZ={storeIsNZ}
                  />
                ))}

              </div>
              <div id="pagination-container">
                <Pagination
                  responsive
                  hideOnSinglePage
                  defaultCurrent={currentPage}
                  defaultPageSize={productsPerPage}
                  total={productData.length}
                  onChange={onPaginationChange}
                />
              </div>
            </>
          ) : (
            <div id="no-results">
              {filteredProducts ? (
                'No products found for the given search term'
              ) : (
                'There are currently no products available, please check back later'
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddOnetimes;
