import React, {
  useCallback, useState, useEffect, useContext,
} from 'react';
import './ProductEditor.less';
import {
  Tabs, Form, Button, Tooltip,
} from 'antd';
import * as api from 'util/api.js';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { TableConfigContext } from 'util/TableConfig';
import EditorLayout from 'components/EditorLayout/EditorLayout';
import About from './tabs/About/index';
import KeyDates from './tabs/KeyDates';
import Artwork from './tabs/Artwork';
import Pricing from './tabs/Pricing/index';
import Barcodes from './tabs/Barcodes';
import Extras from './tabs/Extras/index';
import { MONDAY_PRODUCT_DATABASE_BOARD_ID } from '../MondayIntegration/constants';
import PricingNotes from './tabs/PricingNotes';
import Foodstuffs from './tabs/Foodstuffs';

const { TabPane } = Tabs;

const { useForm } = Form;

// Update table data if it exists by adding new product for faster subsequent loads.
const updateExistingTableData = (
  tableConfig,
  setTableConfig,
  id,
  {
    name,
    type,
    abv,
    abvRange,
    oneLiner,
    dateAdded,
    dateReleased,
    dateLastModified,
    complete,
    creativeReady,
    salesReady,
    productionReady,
  },
) => {
  const { data: existingData } = tableConfig;

  if (existingData) {
    const newData = existingData.filter((product) => Number(product.id) !== Number(id));
    setTableConfig('product-database', {
      ...tableConfig,
      data: [
        ...newData,
        {
          key: id,
          id,
          name,
          type,
          abv,
          oneLiner,
          dateAdded,
          dateReleased,
          dateLastModified,
          complete,
          creativeReady,
          salesReady,
          productionReady,
        },
      ],
    });
  }
};

const ProductEditor = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [savingStatus, setSavingStatus] = useState({
    active: false,
    error: false,
    unsavedChanges: false,
  });

  const [productName, setProductName] = useState(null);

  // TODO: Show warning if unsaved changes.
  // TODO: Delete uploaded images if declining to save changes and image has been uploaded.
  // const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [productId, setProductId] = useState(null);

  const urlParams = useParams();

  // const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  // Product name and tap badge image URL must be stored locally so they can be used across tabs.
  const [tapBadgeImageUrl, setTapBadgeImageUrl] = useState();
  useEffect(() => {
    setProductName(initialValues?.name);
    setTapBadgeImageUrl(initialValues?.tapBadgeUrl || null);
  }, [initialValues]);

  const { getTableConfig, setTableConfig } = useContext(TableConfigContext);
  const tableConfig = getTableConfig('product-database');

  // Fetch and set fields when a product ID is provided or changes in URL params.
  useEffect(async () => {
    const { productId: id } = urlParams;

    // TODO: Handle invalid ID provided in URL params.
    setProductId(id || null);

    const initialProductData = id ? await api.getProduct(id) : null;

    const parsedArtists = !!initialProductData && initialProductData.artist && typeof initialProductData.artist === 'string'
      ? initialProductData.artist.split(',').map((artist) => artist.trim())
      : [];

    setInitialValues(initialProductData ? {
      ...initialProductData,
      artist: parsedArtists,
      abvIsRange: initialProductData.abvMin !== null || initialProductData.abvMax !== null,
    } : ({
      dateAdded: dayjs(),
      artist: [],
      abvIsRange: false,
    }));

    if (formInitialised) {
      form.resetFields();
    } else {
      setFormInitialised(true);
    }
  }, [urlParams.productId]);

  const onSave = (values) => {
    setSavingStatus((current) => ({
      ...current,
      active: true,
      error: false,
      lastSaved: null,
    }));

    const {
      artist, abvIsRange, abv, abvMin, abvMax, ...remainingValues
    } = values;

    const productData = {
      ...remainingValues,
      artist: artist.length > 0 ? artist.join(', ') : null,
      ...(abvIsRange ? {
        abv: null,
        abvMin: abvMin ?? null,
        abvMax: abvMax ?? null,
      } : {
        abv: abv ?? null,
        abvMin: null,
        abvMax: null,
      }),
    };

    if (productId) {
      api.updateProduct(productId, productData)
        .then(() => {
          updateExistingTableData(tableConfig, setTableConfig, productId, productData);
          setSavingStatus({
            active: false,
            error: false,
            unsavedChanges: false,
            lastSaved: dayjs(),
          });
        })
        .catch((error) => {
          console.log('Error', error); // TODO: log error
          setSavingStatus((current) => ({
            ...current,
            active: false,
            error: true,
          }));
        });
    } else {
      api.createProduct(productData)
        .then((id) => {
          setProductId(id);
          updateExistingTableData(tableConfig, setTableConfig, id, productData);
          // Change from the 'create' path to the 'edit' path to reload product if refreshing.
          window.history.pushState('', '', `/dashboard/product-database/products/${id}/edit`);
          setSavingStatus({
            active: false,
            error: false,
            unsavedChanges: false,
            lastSaved: dayjs(),
          });
        })
        .catch((error) => {
          console.log('Error', error); // TODO: log error
          setSavingStatus((current) => ({
            ...current,
            active: false,
            error: true,
          }));
        });
    }
  };

  const onDelete = () => api.deleteProduct(productId).then(() => {
    // Clear unsaved changes to avoid showing alert when redirecting
    setSavingStatus((current) => ({
      ...current,
      unsavedChanges: false,
    }));
    history.push('/dashboard/product-database/products');
  });

  const onSaveFailed = useCallback((values) => {
    // console.log('onSaveFailed', values);
    // TODO: Show errors in tabs on left.
  }, []);

  const onValuesChange = () => {
    if (!savingStatus.unsavedChanges) {
      setSavingStatus((current) => ({
        ...current,
        unsavedChanges: true,
      }));
    }
  };

  const onArtworkChange = ({ fieldName, newImageUrl }) => {
    if (fieldName === 'tapBadgeUrl') {
      setTapBadgeImageUrl(newImageUrl);
    }
    onValuesChange();
  };

  return (
    <EditorLayout
      id="product-editor-form"
      contextLabel="product"
      title={productName}
      thumbnail={tapBadgeImageUrl}
      form={form}
      initialValues={initialValues}
      enableDelete={!!productId}
      onDelete={onDelete}
      onSave={onSave}
      onSaveFailed={onSaveFailed}
      onValuesChange={onValuesChange}
      savingStatus={savingStatus}
      additionalControls={(
        <Tooltip title={initialValues?.mondayId === null ? 'This product hasn\'t been linked to an item in Monday yet.' : undefined}>
          <Button
            type="primary"
            disabled={!initialValues?.mondayId}
            onClick={() => window.open(`https://garageproject.monday.com/boards/${MONDAY_PRODUCT_DATABASE_BOARD_ID}/pulses/${initialValues.mondayId}`, '_blank')}
          >
            View in Monday
          </Button>
        </Tooltip>
)}
    >
      <TabPane tab="About" key="about" className="padded-tab" forceRender>
        <About
          form={form}
          tapBadgeImageUrl={tapBadgeImageUrl}
          onNameChange={setProductName}
        />
      </TabPane>
      <TabPane tab="Key dates" key="keyDates" className="padded-tab" forceRender>
        <KeyDates form={form} />
      </TabPane>
      {/* <TabPane tab="Units" key="units" forceRender>
            <Units form={form} productId={productId} />
          </TabPane> */}
      <TabPane tab="Artwork" key="artwork" className="padded-tab" forceRender>
        <Artwork
          form={form}
          productId={productId}
          saving={savingStatus.active}
          onArtworkChange={onArtworkChange}
        />
      </TabPane>
      <TabPane tab="Pricing" key="pricing" forceRender>
        <Tabs tabPosition="left">
          <TabPane tab="Slab/Case" key="pack" className="padded-tab" forceRender>
            <Pricing type="pack" />
          </TabPane>
          <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
            <Pricing type="keg" />
          </TabPane>
          <TabPane tab="Venues" key="venues" className="padded-tab" forceRender>
            <Pricing type="venues" />
          </TabPane>
          <TabPane tab="Pricing notes" key="pricing-notes" className="padded-tab" forceRender>
            <PricingNotes />
          </TabPane>
        </Tabs>
      </TabPane>
      <TabPane tab="Barcodes" key="barcodes" forceRender>
        <Tabs tabPosition="left">
          <TabPane tab="Base" key="base" className="padded-tab" forceRender>
            <Barcodes type="base" />
          </TabPane>
          <TabPane tab="Slab" key="slab" className="padded-tab" forceRender>
            <Barcodes type="slab" />
          </TabPane>
          <TabPane tab="Case" key="case" className="padded-tab" forceRender>
            <Barcodes type="case" />
          </TabPane>
          {/* <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
            <Barcodes type="keg" />
          </TabPane> */}
        </Tabs>
      </TabPane>
      <TabPane tab="Foodstuffs" key="foodstuffs" className="padded-tab" forceRender>
        <Foodstuffs />
      </TabPane>
      <TabPane tab="Extras" key="extras" className="padded-tab" forceRender>
        <Extras />
      </TabPane>
    </EditorLayout>
  );

  // return (!initialValues) ? (
  //   <FullPageLoader />
  // ) : (
  //   <Form
  //     id="product-editor-form"
  //     name="product-editor-form"
  //     form={form}
  //     initialValues={initialValues}
  //     onFinish={onSubmit}
  //     onFinishFailed={onSubmitFailed}
  //   >

  //     <Card
  //       id="product-editor-card"
  //       title={(
  //         <>
  //           <Title level={5}>
  //             {productName || <span className="unnamed-product-title">Untitled Product</span>}
  //           </Title>
  //           { productId ? (
  //             <Button
  //               danger
  //               type="outline"
  //               onClick={() => onDelete(productId)}
  //             >
  //               Delete product
  //             </Button>
  //           ) : (
  //             null
  //           )}
  //         </>
  //       )}
  //       size="small"
  //     >
  //       <Tabs tabPosition="left">
  // <TabPane tab="About" key="about" className="padded-tab" forceRender>
  //   <About
  //     form={form}
  //     tapBadgeImageUrl={tapBadgeImageUrl}
  //     onNameChange={setProductName}
  //   />
  // </TabPane>
  // <TabPane tab="Key dates" key="keyDates" className="padded-tab" forceRender>
  //   <KeyDates form={form} />
  // </TabPane>
  // {/* <TabPane tab="Units" key="units" forceRender>
  //   <Units form={form} productId={productId} />
  // </TabPane> */}
  // <TabPane tab="Artwork" key="artwork" className="padded-tab" forceRender>
  //   <Artwork form={form} onTapBadgeChanged={setTapBadgeImageUrl} />
  // </TabPane>
  // <TabPane tab="Pricing" key="pricing" forceRender>
  //   <Tabs tabPosition="left">
  //     <TabPane tab="Slab" key="slab" className="padded-tab" forceRender>
  //       <Pricing type="slab" />
  //     </TabPane>
  //     <TabPane tab="Case" key="case" className="padded-tab" forceRender>
  //       <Pricing type="case" />
  //     </TabPane>
  //     <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
  //       <Pricing type="keg" />
  //     </TabPane>
  //     <TabPane tab="Venue tap" key="venues" className="padded-tab" forceRender>
  //       <Pricing type="venues" />
  //     </TabPane>
  //   </Tabs>
  // </TabPane>
  // <TabPane tab="Barcodes" key="barcodes" forceRender>
  //   <Tabs tabPosition="left">
  //     <TabPane tab="Base" key="base" className="padded-tab" forceRender>
  //       <Barcodes type="base" />
  //     </TabPane>
  //     <TabPane tab="Slab" key="slab" className="padded-tab" forceRender>
  //       <Barcodes type="slab" />
  //     </TabPane>
  //     <TabPane tab="Case" key="case" className="padded-tab" forceRender>
  //       <Barcodes type="case" />
  //     </TabPane>
  //     <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
  //       <Barcodes type="keg" />
  //     </TabPane>
  //   </Tabs>
  // </TabPane>
  // <TabPane tab="Extras" key="extras" className="padded-tab" forceRender>
  //   <Extras />
  // </TabPane>
  //       </Tabs>
  //       { currentUser.admin && (
  //       <FixedButton
  //         htmlType="submit"
  //         loading={saving}
  //         icon={<CheckOutlined />}
  //       >
  //         Save
  //       </FixedButton>
  //       )}
  //     </Card>
  //   </Form>
  // );
};

export default ProductEditor;
