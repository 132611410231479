import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProductAdminSettings.less';
import {
  Popover, Button, Typography, Switch,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { snakeCase } from 'change-case';
import * as api from 'util/api';
import { omit } from 'lodash';

const { Text } = Typography;

const ProductAdminSettings = ({
  store, productId, settings, onChange,
}) => {
  const [visible, setVisible] = useState(false);
  const [updating, setUpdating] = useState({});
  const [transferModalVisible, setTransferModalVisible] = useState(false);

  const onTransferStockClicked = () => {
    setTransferModalVisible(true);
  };

  const onSwitchChange = async (key, enabled) => {
    setUpdating((current) => ({ ...current, [key]: enabled }));
    try {
      // Convert to snake case for consistent compatibility with backend
      await api.updateShopifyProductSetting(store, productId, snakeCase(key), enabled);
      onChange(key, enabled);
    } finally {
      setUpdating((current) => omit(current, key));
    }
  };

  const switchConfig = {
    // TODO: Check if a price has been set, show warning if not
    requestableInDraftMode: {
      label: 'Requestable in draft mode',
    },
    salesSamplesOnly: {
      label: 'Sales sample requests only',
    },
  };

  // If one of the settings is currently updating, keep the popover open until completed
  const preventClose = !!Object.keys(updating).length;

  return (
    <Popover
      trigger="click"
      visible={preventClose || visible}
      onVisibleChange={preventClose ? null : setVisible}
      getPopupContainer={() => document.getElementById('scrollable-page-area-container')}
      overlayStyle={{ zIndex: 900 }}
      content={(
        <ul className="webstore-product-admin-settings unstyled-list">
          {Object.entries(switchConfig).map(([key, { label }]) => {
            const updateInProgress = Object.keys(updating).includes(key);
            return (
              <li key={key}>
                <Text>{label}</Text>
                <Switch
                  disabled={preventClose}
                  checked={updateInProgress ? updating[key] : settings[key]}
                  loading={updateInProgress}
                  onChange={(enabled) => {
                    onSwitchChange(key, enabled);
                  }}
                />
              </li>
            );
          })}
        </ul>
      )}
    >
      <Button
        className="product-settings-button"
        type="secondary"
      >
        <SettingOutlined />
      </Button>
    </Popover>
  );
};

ProductAdminSettings.propTypes = {
  settings: PropTypes.shape({
    requestableInDraftMode: PropTypes.bool,
    salesSamplesOnly: PropTypes.bool,
  }).isRequired,
};

export default ProductAdminSettings;
