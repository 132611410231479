import React, {
  useCallback, useState, useEffect, useContext,
} from 'react';

import PropTypes from 'prop-types';
import './GoogleMap.less';
import { GoogleMap as MapComponent, useJsApiLoader } from '@react-google-maps/api';
import { FullPageLoader } from 'components';
import { useResizeDetector } from 'react-resize-detector';
import { GoogleMapsContext } from 'util/GoogleMapsProvider';

// https://react-google-maps-api-docs.netlify.app/

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GoogleMap = ({
  children, onLoad, ...props
}) => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyCGubkchHkQLQM5GfOtj3IJFQxuDhvQ9WE',
  // });

  const { loaded } = useContext(GoogleMapsContext);

  // const [map, setMap] = useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  // const onUnmount = React.useCallback(() => {
  //   setMap(null);
  // }, []);

  return loaded ? (
    <MapComponent
      mapContainerStyle={containerStyle}
      zoom={10}
      onLoad={onLoad}
      {...props}
    >
      {children}
    </MapComponent>
  ) : <FullPageLoader />;
};

GoogleMap.propTypes = {
  children: PropTypes.node,
  onLoad: PropTypes.func,
};

GoogleMap.defaultProps = {
  children: <></>,
  onLoad: () => {},
};

export default GoogleMap;
