const styles = {
  beer: {
    title: 'Beer',
    dataTree: [
      {
        title: 'Lager',
        value: 'lager',
        children: [
          {
            title: 'Pilsner',
            value: 'pilsner',
          },
          {
            title: 'Czech Lager',
            value: 'czech-lager',
          },
          {
            title: 'Helles',
            value: 'helles',
          },
          {
            title: 'Kölsch',
            value: 'kolsch',
          },
          {
            title: 'German Pils',
            value: 'german-pils',
          },
          {
            title: 'Vienna Lager',
            value: 'vienna-lager',
          },
          {
            title: 'Kellerbier',
            value: 'kellerbier',
          },
          {
            title: 'Bock',
            value: 'bock',
          },
          {
            title: 'Dunkel',
            value: 'dunkel',
          },
          {
            title: 'Schwarzbier',
            value: 'schwarzbier',
          },
          {
            title: 'Doppelbock',
            value: 'doppelbock',
          },
          {
            title: 'Rice Lager',
            value: 'rice-lager',
          },
        ],
      },
      {
        title: 'American Ale',
        value: 'american-ale',
        children: [
          {
            title: 'Cream Ale',
            value: 'cream-ale',
          },
          {
            title: 'American Wheat Beer',
            value: 'american-wheat-beer',
          },
          {
            title: 'American Amber Ale',
            value: 'american-amber-ale',
          },
          {
            title: 'California Common',
            value: 'california-common',
          },
          {
            title: 'American Brown Ale',
            value: 'american-brown-ale',
          },
          {
            title: 'American Barleywine',
            value: 'american-barleywine',
          },
        ],
      },
      {
        title: 'British Ale',
        value: 'british-ale',
        children: [
          {
            title: 'English IPA',
            value: 'english-ipa',
          },
          {
            title: 'British Brown Ale',
            value: 'british-brown-ale',
          },
          {
            title: 'Wee Heavy',
            value: 'wee-heavy',
          },
          {
            title: 'English Barleywine',
            value: 'english-barleywine',
          },
          {
            title: 'Ordinary Bitter',
            value: 'ordinary-bitter',
          },
          {
            title: 'ESB',
            value: 'esb',
          },
          {
            title: 'Strong Ale',
            value: 'strong-ale',
          },
        ],
      },
      {
        title: 'European Ale',
        value: 'european-ale',
        children: [
          {
            title: 'Belgian Blond Ale',
            value: 'belgian-blond-ale',
          },
          {
            title: 'Saison',
            value: 'saison',
          },
          {
            title: 'Belgian Golden Strong Ale',
            value: 'belgian-golden-strong-ale',
          },
          {
            title: 'Dubbel',
            value: 'dubbel',
          },
          {
            title: 'Tripel',
            value: 'tripel',
          },
          {
            title: 'Witbier',
            value: 'witbier',
          },
          {
            title: 'German Wheat',
            value: 'german-wheat',
          },
          {
            title: 'Table Beer',
            value: 'table-beer',
          },
          {
            title: 'Farmhouse Ale',
            value: 'farmhouse-ale',
          },
          {
            title: 'Weissbier',
            value: 'weissbier',
          },
          {
            title: 'Dunkles Weissbier',
            value: 'dunkles-weissbier',
          },
          {
            title: 'Rye Ale',
            value: 'rye-ale',
          },
        ],
      },
      {
        title: 'Pale Ale',
        value: 'pale-ale',
        children: [
          {
            title: 'Hazy Pale',
            value: 'hazy-pale',
          },
          {
            title: 'XPA',
            value: 'xpa',
          },
          {
            title: 'American Pale Ale',
            value: 'american-pale-ale',
          },
          {
            title: 'Pacific Pale Ale',
            value: 'pacific-pale-ale',
          },
        ],
      },
      {
        title: 'IPA',
        value: 'ipa',
        children: [
          {
            title: 'West Coast IPA',
            value: 'west-coast-ipa',
          },
          {
            title: 'Hazy IPA',
            value: 'hazy-ipa',
          },
          {
            title: 'Belgian IPA',
            value: 'belgian-ipa',
          },
          {
            title: 'Black IPA',
            value: 'black-ipa',
          },
          {
            title: 'Rye IPA',
            value: 'rye-ipa',
          },
        ],
      },
      {
        title: 'Stout',
        value: 'stout',
        children: [
          {
            title: 'Irish Stout',
            value: 'irish-stout',
          },
          {
            title: 'Milk Stout',
            value: 'milk-stout',
          },
          {
            title: 'Oatmeal Stout',
            value: 'oatmeal-stout',
          },
          {
            title: 'Foreign Extra Stout',
            value: 'foreign-extra-stout',
          },
          {
            title: 'Golden Stout',
            value: 'golden-stout',
          },
        ],
      },
      {
        title: 'Porter',
        value: 'porter',
        children: [
          {
            title: 'Baltic Porter',
            value: 'baltic-porter',
          },
          {
            title: 'English Porter',
            value: 'english-porter',
          },
        ],
      },
      {
        title: 'Sour',
        value: 'sour',
        children: [
          {
            title: 'Kettle Sour',
            value: 'kettle-sour',
          },
          {
            title: 'Berliner Weisse',
            value: 'berliner-weisse',
          },
          {
            title: 'Flanders Red Ale',
            value: 'flanders-red-ale',
          },
          {
            title: 'Oud Bruin',
            value: 'oud-bruin',
          },
          {
            title: 'Lambic',
            value: 'lambic',
          },
          {
            title: 'Gueuze',
            value: 'gueuze',
          },
          {
            title: 'Bière de Garde',
            value: 'biere-de-Garde',
          },
          {
            title: 'Gose',
            value: 'Gose',
          },
        ],
      },
    ],
  },
  wine: {
    title: 'Wine',
    dataTree: [
      {
        title: 'Red',
        value: 'red',
        children: [
          {
            title: 'Merlot',
            value: 'merlot',
          },
          {
            title: 'Pinot Noir',
            value: 'pinot-noir',
          },
          {
            title: 'Zinfandel',
            value: 'zinfandel',
          },
          {
            title: 'Shiraz',
            value: 'shiraz',
          },
          {
            title: 'Syrah',
            value: 'syrah',
          },
          {
            title: 'Cabernet Sauvignon',
            value: 'cabernet-sauvignon',
          },
        ],
      },
      {
        title: 'White',
        value: 'white',
        children: [
          {
            title: 'Riesling',
            value: 'riesling',
          },
          {
            title: 'Pinot Gris',
            value: 'pinot-gris',
          },
          {
            title: 'Sauvignon Blanc',
            value: 'sauvignon-blanc',
          },
          {
            title: 'Chardonnay',
            value: 'chardonnay',
          },
        ],
      },
      {
        title: 'Rosé',
        value: 'rose',
      },
      {
        title: 'Sparkling',
        value: 'sparkling',
      },
      {
        title: 'Dessert',
        value: 'dessert',
      },
      {
        title: 'Fortified',
        value: 'fortified',
      },
    ],
  },
  seltzer: {
    title: 'Seltzer',
    dataTree: [],
  },
};

const tags = {
  beer: {
    title: 'Beer',
    dataTree: [
      {
        title: 'Hazy',
        value: 'hazy',
      },
      {
        title: 'Bretted',
        value: 'bretted',
      },
      {
        title: 'Fruit',
        value: 'fruit',
      },
      {
        title: 'Triple',
        value: 'triple',
      },
      {
        title: 'Double',
        value: 'double',
      },
      {
        title: 'Rauchbier',
        value: 'rauchbier',
      },
      {
        title: 'Spiced',
        value: 'spiced',
      },
      {
        title: 'Chilli',
        value: 'chilli',
      },
      {
        title: 'Unfiltered',
        value: 'unfiltered',
      },
      {
        title: 'Nitro',
        value: 'nitro',
      },
      {
        title: 'Sour',
        value: 'sour',
      },
      {
        title: 'Smoked',
        value: 'smoked',
      },
      {
        title: 'Dry-Hopped',
        value: 'dry-hopped',
      },
      {
        title: 'Wood-Aged',
        value: 'wood-aged',
      },
      {
        title: 'Foeder-Aged',
        value: 'foeder-aged',
      },
      {
        title: 'Bourbon Barrel-Aged',
        value: 'bourbon-barrel-aged',
      },
      {
        title: 'Rum Barrel-Aged',
        value: 'rum-barrel-aged',
      },
      {
        title: 'Tequila Barrel-Aged',
        value: 'tequila-barrel-aged',
      },
      {
        title: 'Mixed-Fermentation',
        value: 'mixed-fermentation',
      },
      {
        title: 'Spontaneous Fermentation',
        value: 'spontaneous-fermentation',
      },
    ],
  },
  wine: {
    title: 'Wine',
    dataTree: [],
  },
  seltzer: {
    title: 'Seltzer',
    dataTree: [],
  },
};

const baseUnitSizes = {
  can: {
    title: 'Can',
    data: [
      {
        label: '250ml',
        value: '250',
      },
      {
        label: '330ml',
        value: '330',
      },
      {
        label: '375ml',
        value: '375',
      },
      {
        label: '440ml',
        value: '440',
      },
    ],
  },
  bottle: {
    title: 'Bottle',
    data: [
      {
        label: '330ml',
        value: '330',
      },
      {
        label: '375ml',
        value: '375',
      },
      {
        label: '650ml',
        value: '650',
      },
      {
        label: '750ml',
        value: '750',
      },
    ],
  },
};

const baseUnitColours = {
  can: {
    title: 'Can',
    data: [
      {
        label: 'Black',
        value: 'black',
      },
      {
        label: 'White',
        value: 'white',
      },
      {
        label: 'Silver (no colour)',
        value: 'silver',
      },
      {
        label: 'Printed',
        value: 'printed',
      },
    ],
  },
  bottle: {
    title: 'Bottle',
    data: [
      {
        label: 'Brown',
        value: 'brown',
      },
      {
        label: 'Green',
        value: 'green',
      },
      {
        label: 'Clear',
        value: 'clear',
      },
    ],
  },
};

export default {
  styles,
  tags,
  baseUnitSizes,
  baseUnitColours,
};
