import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8012'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/resolve-discrepancies';

export default ({ onSettled, onSuccess, onError }) => useMutation({
  mutationKey: ['resolve_discrepancies'],
  mutationFn: async (discrepancyResolutions) => (await axios({
    method: 'POST',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
    },
    data: discrepancyResolutions,
  })).data,
  onSettled,
  onSuccess,
  onError,
});
