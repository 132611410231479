import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8013'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/get-artists';

export default () => useQuery({
  queryKey: ['get_artists'],
  queryFn: async () => (await axios({
    method: 'GET',
    url: URL,
  })).data.artists,
});
