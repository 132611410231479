import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import LandingLayout from 'layouts/LandingLayout/LandingLayout';
import { AuthContext } from 'util/Auth';
import { FullPageLoader } from 'components';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { CountryDetectionContext } from 'util/CountryDetection';

// Routes using this will require authenticaiton to access.
export const AuthRoute = ({ component: Component, ...rest }) => {
  const { currentUser, userTriggeredLogout } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => (currentUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              snackbar: userTriggeredLogout ? ({
                message: 'Successfully logged out!',
                type: 'success',
              }) : ({
                message: 'Please login before continuing',
                type: 'error',
              }),
            },
          }}
        />
      ))}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const RouteHandler = () => {
  // Redirect to the primary domain if the site is accessed via a firebase domain.
  // if (['garage-project-matrix.web.app', 'garage-project-matrix.firebaseapp.com'].includes(window.location.host)) {
  //   window.location = `https://beta.gpmatrix.co.nz${window.location.pathname}`;
  // }

  const countryDetection = useContext(CountryDetectionContext);

  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  if (countryDetection.initialising || auth.initialising) {
    return <FullPageLoader />;
  }

  return (
    <Switch>
      <AuthRoute path="/dashboard" component={DashboardLayout} />
      <Route exact path={['/login']} component={LandingLayout} />
      <Redirect from="*" to={currentUser ? '/dashboard' : '/login'} />
    </Switch>
  );
};

export default RouteHandler;
