import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import './CustomTable.less';
import dayjs from 'dayjs';
import {
  Table as AntTable, Input, Button, Tooltip, Checkbox, Typography, Radio,
} from 'antd';
import { LoadingOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { TableConfigContext } from 'util/TableConfig';

// import Highlight from 'react-highlighter';
const { Column } = AntTable;
const { Search } = Input;
const { Text } = Typography;

// const filterBySearch = (dataSource, searchQuery) => (
// dataSource.filter((product) => (
//   Object.values(product).some((value) => value && (
//     value.toString().toUpperCase().includes(searchQuery.toUpperCase())
//   ))
// ))
// );

const renderColumns = (children, tableConfig) => children.map((column) => {
  const { searchQuery, filters } = tableConfig;
  return ({
    ...column,
    props: {
      ...column.props,
      filteredValue: filters ? filters[column.key] : null,
      // Enforces that at least one column is being sorted at all times.
      // sortDirections: column.props.sortType ? ['ascend', 'descend', 'ascend'] : null,
      sorter: column.props.sortType ? ((a, b) => {
        const aValue = a[column.key];
        const bValue = b[column.key];
        switch (column.props.sortType) {
          case 'string':
            if (!!aValue && !!bValue) {
              return aValue.localeCompare(bValue);
            }
            return aValue ? -1 : 1;

          case 'number':
            return Number(aValue) > Number(bValue);
          case 'date':
            if (aValue && !bValue) {
              return 1;
            } if (!aValue && bValue) {
              return -1;
            } if (!aValue && !bValue) {
              return 0;
            }
            if (dayjs.isDayjs(aValue)) {
              return aValue.isAfter(bValue) ? 1 : 0;
            }
            return dayjs(aValue).isAfter(dayjs(bValue)) ? 1 : 0;
          default:
            return null;
        }
      }) : null,
      render: (value, record, index) => {
        // If there is no value, indicate that the cell is empty.
        // A valid value may be a false boolean or 0, so can't use a logical "not" check.
        if (!value && value !== false && value !== 0) {
          return (<div className="empty-cell">N/A</div>);
        }
        return (
          <>
            {/* TODO */}
            {/* <Highlight search={searchQuery}> */}
            {column.props?.render ? column.props.render(value, record, index) : value}
            {/* </Highlight> */}
          </>
        );
      },
    },
  }); });

const CustomTable = ({
  children,
  id,
  dataSource,
  searchableColumns,
  loading,
  pluralLabel,
  controls,
  controlsPlacement,
  disableControls,
  pagination,
  enableSelection,
  selectionType,
  onSelectionChange,
  nested, // Nested tables don't cache data or show table controls
  cacheData,
  disabledMessage,
  scroll,
  customRowClick,
  secondaryControl,
  ...props
}) => {
  const { getTableConfig, setTableConfig } = useContext(TableConfigContext);
  const tableConfig = getTableConfig(id);
  const {
    selected, searchQuery, searcher, filters,
  } = tableConfig;

  const data = (nested || !cacheData) ? dataSource : tableConfig.data;

  const onSearch = (event) => {
    setTableConfig(id, {
      ...tableConfig,
      sorter: null, // Reset sort so that the search results are shown in order of score
      searchQuery: event.target.value,
    });
  };

  const onTableConfigChange = (newPagination, newFilters, newSorter) => {
    setTableConfig(id, {
      ...tableConfig,
      // Set filters object itself to null if all filter values are null.
      // (Makes it easier to determine when no filters are set.)
      filters: newFilters && Object.values(newFilters).some(
        (filter) => filter !== null,
      ) ? newFilters : null,
      sorter: newSorter,
    });
  };

  const onClearSearchAndFilters = () => {
    setTableConfig(id, {
      ...tableConfig,
      searchQuery: null,
      filters: null,
    });
  };

  const onClearSelection = () => {
    setTableConfig(id, {
      ...tableConfig,
      selected: [],
    });
  };

  const onRowSelectionChange = (record) => {
    const { key } = record;
    let newSelection;

    if (selectionType === 'single') {
      newSelection = [record];
    } else if (selectionType === 'multiple') {
      if (selected) {
        newSelection = (selected.find((item) => item.key === record.key) ? (
          selected.filter((item) => item.key !== key)
        ) : (
          [...selected, record]
        ));
      } else {
        newSelection = [record];
      }
    }

    setTableConfig(id, {
      ...tableConfig,
      selected: newSelection,
    });
  };

  // const onSelectDeselectAll = () => {
  //   console.log('toggle');
  // };

  useEffect(() => {
    if (dataSource && !nested) {
      setTableConfig(id, {
        ...tableConfig,
        data: dataSource,
        searcher: new Fuse(dataSource, {
          keys: searchableColumns,
          threshold: 0.3,
          ignoreLocation: true,
        }),
      });
    }
  }, [dataSource, searchableColumns]);

  useEffect(() => {
    if (!!enableSelection && onSelectionChange) {
      onSelectionChange(selected);
    }
  }, [selected]);

  return (
    <div className="custom-table">
      { (!disableControls && !nested) && (
        <div className="table-controls">
          {controlsPlacement === 'left' && controls}
          {secondaryControl}
          {/* Only display search bar if searchable column keys have been provided */}
          {searchableColumns.length > 0 && (
            <Search
              disabled={!data}
              placeholder={pluralLabel ? `Search for ${pluralLabel}...` : 'Search...'}
              allowClear
              value={searchQuery}
              onChange={onSearch}
              className="table-search"
            />
          )}
            {/* Re enable once table filters have been implemented */}
            {/* <Button
              type="primary"
              icon={<CloseOutlined />}
              onClick={onClearSearchAndFilters}
              disabled={!data || ((filters ? filters.length === 0 : !filters) && !searchQuery)}
            >
              {`Clear${searchableColumns.length > 0 ? ' search and ' : ' '}filters`}
            </Button> */}
          {searchableColumns.length > 0 && (
          <Tooltip title={!searchQuery ? 'There is no search term' : null}>
            <Button
              type="primary"
              icon={<CloseOutlined />}
              onClick={onClearSearchAndFilters}
              disabled={!data || !searchQuery}
            >
              Clear search
            </Button>
          </Tooltip>
          )}
          {enableSelection && (
            <div className="clear-selection-container">
              <Text className="selection-count-label">{`${selected.length} selected`}</Text>
              <Tooltip title={!selected.length ? `There are no ${pluralLabel} selected.` : null}>
                <Button
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={onClearSelection}
                  disabled={!data || !selected.length}
                >
                  Clear selection
                </Button>
              </Tooltip>
            </div>
          )}
          {/* TODO: Button to reload data */}
          {/* <Button
            type="primary"
            icon={<ReloadOutlined />}
            onClick={onClearSearchAndFilters}
            disabled={!data}
          >
            Reload
          </Button> */}
          {controlsPlacement === 'right' && controls}
        </div>
      )}
      <AntTable
        {...props}
        id={id}
        sortOrder
        scroll={{ x: 'max-content', y: null }}
        pagination={pagination}
        onChange={onTableConfigChange}
        dataSource={(!!data && searchQuery) ? (
          searcher.search(searchQuery).map(({ item }) => item)
        ) : data}
        loading={{
          spinning: !data,
          indicator: <LoadingOutlined className="table-loading-indicator" spin />,
        }}
        rowClassName={(record) => {
          let className = '';
          if (record.disabled) {
            className += ' disabled-row';
          }
          if (enableSelection && !record.disabled) {
            className += ' clickable-row';
          }
          // Combine class names is provided through props.
          return props.rowClassName ? `${props.rowClassName} ${className}` : className;
        }}
        onRow={(record) => {
          const handleClick = () => {
            if (customRowClick) {
              return customRowClick(record);
            }
            if (enableSelection && !record.disabled) {
              return () => onRowSelectionChange(record);
            }
            return null;
          };
          return { onClick: handleClick };
        }}
      >
        {/* TODO: Fix issue with highlighting not showing
        on inlinelinks and spacing within words. */}
        {/* {searchQuery ? addHighlighterToColumns(children, searchQuery) : children} */}
        {enableSelection ? (
          <Column
            title={null}
            // TODO: Finish implement select/deselect all checkbox
            // title={(
            //   <Checkbox
            //     checked={selected.length === data.length}
            //     indeterminate={selected.length > 0 && selected.length < data.length}
            //     onChange={() => onSelectDeselectAll()}
            //   />
            // )}
            key="checked"
            width={64}
            className="centred-column"
            render={(record) => {
              const SelectionComponent = selectionType === 'single' ? Radio : Checkbox;
              return (
                <Tooltip title={record.disabled ? disabledMessage : null}>
                  <SelectionComponent
                    disabled={record.disabled}
                    checked={selected?.find((item) => item.key === record.key)}
                    onChange={() => onRowSelectionChange(record)}
                  />
                </Tooltip>
              ); }}
          />
        ) : <></>}
        {renderColumns(children, tableConfig)}
      </AntTable>
    </div>
  );
};

CustomTable.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  searchableColumns: PropTypes.arrayOf(
    PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)),
  ),
  pluralLabel: PropTypes.string,
  controls: PropTypes.node,
  controlsPlacement: PropTypes.oneOf(['left', 'right']),
  disableControls: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.number]),
  enableSelection: PropTypes.bool,
  selectionType: PropTypes.oneOf('single', 'multiple'),
  onSelectionChange: PropTypes.func,
  nested: PropTypes.bool,
  cacheData: PropTypes.bool,
  disabledMessage: PropTypes.string,
  scroll: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // onReload: PropTypes.func.isRequired,
};

CustomTable.defaultProps = {
  loading: false,
  dataSource: null,
  searchableColumns: [],
  pluralLabel: null,
  controls: null,
  controlsPlacement: 'right',
  disableControls: false,
  pagination: { showSizeChanger: true, defaultPageSize: '10' },
  enableSelection: false,
  selectionType: 'multipe',
  onSelectionChange: null,
  nested: false,
  cacheData: true,
  disabledMessage: 'A disabled row cannot be selected',
  scroll: { y: false },
};

export default CustomTable;
