import React from 'react';
import './StockAvailability.less';
import * as api from 'util/api.js';
import { Switch, Route, Redirect } from 'react-router-dom';
import StockTable from './StockTable/StockTable';

const StockAvailability = () => (
  <Switch>
    {Object.values(api.STOCK_LOCATIONS).map((location) => (
      <Route
        key={location}
        exact
        path={`/dashboard/stock/${location}`}
        render={() => <StockTable location={location} />}
      />
    ))}
    <Redirect to="/dashboard/stock/toll" />
  </Switch>
);

export default StockAvailability;
