import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import './FileUpload.less';
import { Upload, Button, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;

let storageRef;

export const getFileNameFromFirebaseUrl = (url) => {
  const regex = /https:\/\/firebasestorage.googleapis\.com\/v0\/b\/garage-project-matrix\.appspot\.com\/o\/qr-codes%2F.+%2F(.+)\?alt=media&token=.+/g;
  const matches = regex.exec(url);
  return matches && matches.length > 1 ? matches[1].replaceAll('%20', ' ') : url;
};

const FileUpload = ({
  form, value, onChange, onUploadingStateChange, onImageUploaded, otherIds,
}) => {
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    onUploadingStateChange(uploading);
  }, [uploading]);

  useEffect(() => {
    if (!storageRef) {
      storageRef = firebase.storage().ref();
    }
  }, []);

  // TODO: Delete previous image if replacing.
  // TODO: Delete all images if leaving without saving.
  // TODO: Save in database immediately after upload success
  const handleUpload = ({
    file, onSuccess, onError, onProgress,
  }) => {
    setUploading(true);

    const qrCodeId = form.getFieldValue('id');

    if (!qrCodeId) {
      form.validateFields();
      onError(new Error('The file cannot be uploaded without first setting an ID'));
      setUploading(false);
      return;
    }

    if (otherIds.includes(qrCodeId)) {
      form.validateFields();
      onError(new Error('The file cannot be uploaded unless the ID is unique'));
      setUploading(false);
      return;
    }

    const uploadTask = storageRef.child(`qr-codes/${qrCodeId}/${file.name}`).put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
      next: (snapshot) => onProgress({
        percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      }),
      error: (error) => {
        onError(error);
        setUploading(false);
      },
      complete: () => {
        uploadTask.snapshot.ref.getDownloadURL()
          .then((newUrl) => {
            onSuccess();
            onChange(newUrl);
            onImageUploaded();
            setUploading(false);
          });
      },
    });
  };

  const uploadProps = {
    className: 'file-upload',
    maxCount: 1,
    customRequest: handleUpload,
    accept: 'image/*,.pdf',
    // TODO
    // max file size
    showUploadList: {
      showRemoveIcon: false,
      showPreviewIcon: false,
      showDownloadIcon: false,
    },
  };

  const fileName = value ? getFileNameFromFirebaseUrl(value) : null;

  return (
    <div className="qr-file-upload-container">
      <Upload {...uploadProps} className={uploading ? null : 'hide-file-list'}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      <div className="file-name">
        <Text>{fileName}</Text>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
};

FileUpload.defaultProps = {
};

export default FileUpload;
