import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './UserEditor.less';
import {
  Button, Form, Input, Alert, Tooltip,
} from 'antd';
import * as api from 'util/api.js';
import { CustomModal } from 'components';
import {
  MailOutlined, UserOutlined, UserAddOutlined, ApartmentOutlined,
} from '@ant-design/icons';
import CreateStaffUserConfirm from './CreateStaffUserConfirm/CreateStaffUserConfirm';

const { useForm } = Form;

const UserEditor = ({
  existingUser, onClose, disableButton, external,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  // If editing an existing user, fill the fields with the existing data.
  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
      form.setFieldsValue(existingUser ? { ...existingUser } : {});
    } else {
      setFormInitialised(true);
    }
    if (existingUser) {
      setModalOpen(true);
    }
  }, [existingUser]);

  const onCreateClicked = () => {
    setModalOpen(true);
  };

  const handleClose = (updated) => {
    setModalOpen(false);
    setTimeout(() => {
      onClose(updated);
      form.resetFields();
    }, 500); // Prevent fields appearing empty while closing
  };

  const onSubmit = (values) => {
    setSaving(true);

    const userData = { ...values, external };

    const updatedUser = { ...userData };

    if (existingUser) {
      const { uid } = existingUser;
      api.updateUser(uid, userData).then(() => {
        setSaving(false);
        handleClose({
          uid,
          ...updatedUser,
        });
      }).catch(() => {
        setSaving(false);
      });
    } else {
      api.createUser(userData).then((uid) => {
        setSaving(false);
        handleClose({
          uid,
          provider: 'password',
          ...updatedUser,
        });
      }).catch((error) => {
        if (error.response?.data) {
          const { code } = error.response.data;
          if (code === 'auth/email-already-exists') {
            form.setFields([
              {
                name: 'email',
                errors: ['An account with this email address already exists'],
              },
            ]);
          }
        }
        setSaving(false);
      });
    }
  };

  return (
    <>
      {external ? (
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          disabled={disableButton}
          onClick={onCreateClicked}
        >
          Create new external user
        </Button>
      ) : (
        <CreateStaffUserConfirm
          onConfirm={onCreateClicked}
        >
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            disabled={disableButton}
          >
            Create new staff user
          </Button>
        </CreateStaffUserConfirm>
      )}

      <CustomModal
        width={400}
        title={existingUser ? (
          `Edit ${external ? 'external' : 'staff'} user details`
        ) : (
          `Create new ${external ? 'external' : 'staff'} user`
        )}
        centered
        visible={modalOpen}
        onCancel={handleClose}
        closable={!saving}
        footer={[
          <Button
            key="cancel"
            type="secondary"
            onClick={handleClose}
            disabled={saving}
          >
            Cancel
          </Button>,
          <Button
            form="user-editor-form"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={saving}
          >
            {existingUser ? 'Save' : 'Create'}
          </Button>,
        ]}
      >
        <>
          {!existingUser && (
            <div className="modal-description-area">
              <Alert
                message="After a creating a new user, a confirmation email with their login details will be sent to them."
                type="info"
                showIcon
              />
            </div>
          )}
          <div>
            <Form
              id="user-editor-form"
              name="user-editor-form"
              form={form}
              onFinish={onSubmit}
              validateTrigger="onSubmit"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input
                  prefix={<UserOutlined className="field-icon-prefix" />}
                />
              </Form.Item>
              {external && (
                <Form.Item
                  name="organisation"
                  fbHvYA1WvkRX7szK9WqybRgHLrO2
                  label="Organisation"
                  rules={[{ required: true, message: 'Organisation is required' }]}
                >
                  <Input
                    prefix={<ApartmentOutlined className="field-icon-prefix" />}
                  />
                </Form.Item>
              )}
              <Tooltip title={existingUser ? 'Email cannot be changed for an existing account' : null} placement="bottom">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{
                    required: true,
                    message: 'An email address is required',
                  },
                  {
                    validator: (_, value) => {
                      if (value && (value.endsWith('@garageproject.co.nz') || (value.endsWith('@garageproject.com.au')))) {
                        return Promise.reject(new Error('Creating an account manually is not required for Garage Project email addresses'));
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  }]}
                >
                  <Input
                    disabled={!!existingUser}
                    prefix={<MailOutlined className="field-icon-prefix" />}
                  />
                </Form.Item>
              </Tooltip>
            </Form>
          </div>
        </>
      </CustomModal>
    </>
  );
};

UserEditor.propTypes = {
  // Disabled since user shape may change
  // eslint-disable-next-line react/forbid-prop-types
  existingUser: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  external: PropTypes.bool.isRequired,
};

UserEditor.defaultProps = {
  existingUser: null,
  disableButton: false,
};

export default UserEditor;
