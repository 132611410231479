import React from 'react';
import PropTypes from 'prop-types';
import './ProductStyleSelector.less';
import { TreeSelect, Tooltip } from 'antd';
import productCategories from 'config/productCategories';

const ProductStyleSelector = ({ value, type, onChange }) => {
  const styleDataTree = type ? productCategories.styles[type].dataTree : [];
  return (
    <Tooltip
      title={!type ? 'Please specify a product type first' : null}
      placement="bottom"
    >
      <TreeSelect
        disabled={!type}
        style={{ width: '100%' }}
        showSearch
        value={value}
        treeData={styleDataTree}
        onChange={onChange}
      />
    </Tooltip>
  );
};

ProductStyleSelector.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(productCategories.styles)),
};

ProductStyleSelector.defaultProps = {
  value: null,
  type: null,
};

export default ProductStyleSelector;
