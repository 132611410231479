/**
* The ID for the product database board in Monday.
*/
export const MONDAY_PRODUCT_DATABASE_BOARD_ID = '4047924680';

/**
 * The possible event types for a Monday webhook.
 * Refer to: https://developer.monday.com/api-reference/reference/webhooks#fields
 */
export const MondayWebhookEventType = {
  ChangeColumnValue: 'change_column_value',
  ChangeStatusColumnValue: 'change_status_column_value',
  ChangeSubitemColumnValue: 'change_subitem_column_value',
  ChangeSpecificColumnValue: 'change_specific_column_value',
  ChangeName: 'change_name',
  CreateItem: 'create_item',
  ItemArchived: 'item_archived',
  ItemDeleted: 'item_deleted',
  ItemMovedToAnyGroup: 'item_moved_to_any_group',
  ItemMovedToSpecificGroup: 'item_moved_to_specific_group',
  ItemRestored: 'item_restored',
  CreateSubitem: 'create_subitem',
  ChangeSubitemName: 'change_subitem_name',
  MoveSubitem: 'move_subitem',
  SubitemArchived: 'subitem_archived',
  SubitemDeleted: 'subitem_deleted',
  CreateColumn: 'create_column',
  CreateUpdate: 'create_update',
  EditUpdate: 'edit_update',
  DeleteUpdate: 'delete_update',
  CreateSubitemUpdate: 'create_subitem_update',
};

export const WebhookStatus = {
  Checking: 'checking',
  ErrorChecking: 'error_checking',
  Established: 'established',
  NotEstablished: 'not_established',
};
