import {
  HomeOutlined,
  DatabaseOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  MailOutlined,
  BookOutlined,
  TagOutlined,
  ReadOutlined,
  FormOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  QrcodeOutlined,
  ShopOutlined,
  BankFilled,
} from '@ant-design/icons';

import Home from 'pages/dashboard/Home/Home';
import ProductTable from 'pages/dashboard/ProductTable/ProductTable';
import ProductEditor from 'pages/dashboard/ProductEditor/ProductEditor';
import BriefEditor from 'pages/dashboard/BriefEditor/BriefEditor';
import StockAvailability from 'pages/dashboard/StockAvailability/StockAvailability';
import Reports from 'pages/dashboard/Reports/Reports';
import Mailchimp from 'pages/dashboard/Mailchimp/Mailchimp';
import BeerBible from 'pages/dashboard/BeerBible/BeerBible';
import TapBadges from 'pages/dashboard/TapBadges/TapBadges';
import Foodstuffs from 'pages/dashboard/Foodstuffs/Foodstuffs';
import LabelBuilder from 'pages/dashboard/LabelBuilder/LabelBuilder';
import UserManagement from 'pages/dashboard/UserManagement/UserManagement';
import Webstore from 'pages/dashboard/Webstore/Webstore';
import QRCodes from 'pages/dashboard/QRCodes/QRCodes';
import BriefTable from 'pages/dashboard/BriefTable/BriefTable';
import CertifiedFresh from 'pages/dashboard/CertifiedFresh/CertifiedFresh';
import DTCProductBuilder from 'pages/dashboard/DTCProductBuilder/DTCProductBuilder';
import MondayIntegration from 'pages/dashboard/MondayIntegration';

export default [
  {
    menuLabel: 'Home',
    id: 'home',
    icon: HomeOutlined,
    description: 'Welcome to the Matrix',
    Component: Home,
    allowExternal: true,
  },
  {
    menuLabel: 'Product Database',
    id: 'product-database',
    icon: DatabaseOutlined,
    allowExternal: true,
    subItems: [
      {
        id: 'products',
        menuLabel: 'Products',
        description: 'Manage products that have developed from a brief',
        Component: ProductTable,
        subItems: [
          {
            id: 'add',
            menuLabel: 'Add Product',
            description: 'Add a new product',
            hidden: true,
            backPath: '/dashboard/product-database/products',
            Component: ProductEditor,
          },
          {
            id: 'edit',
            menuLabel: 'Edit Product',
            description: 'Edit an existing product',
            hidden: true,
            backPath: '/dashboard/product-database/products',
            pathParam: 'productId',
            Component: ProductEditor,
          },
          {
            id: 'view',
            menuLabel: 'View Product',
            description: 'This is a read-only view of the product\'s details',
            hidden: true,
            backPath: '/dashboard/product-database/products',
            pathParam: 'productId',
            Component: ProductEditor,
            allowExternal: true,
          },
        ],
      },
      {
        id: 'briefs',
        menuLabel: 'Product Briefs',
        description: 'Plan and develop future products',
        Component: BriefTable,
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'sally@garageproject.co.nz',
          'gina@garageproject.co.nz',
          'hannah.lloyd@garageproject.co.nz',
          'hannah@garageproject.co.nz',
          'janna@garageproject.co.nz',
          'rhonda@garageproject.co.nz',
          'ian@garageproject.co.nz',
          'sean@garageproject.co.nz',
          'pete@garageproject.co.nz',
          'peter.fenton@garageproject.co.nz',
          'jos@garageproject.co.nz',
          'josh@garageproject.co.nz',
        ],
        subItems: [
          {
            id: 'create',
            menuLabel: 'Create Product Brief',
            description: 'Create a new product brief',
            hidden: true,
            backPath: '/dashboard/product-database/briefs',
            Component: BriefEditor,
          },
          {
            id: 'edit',
            menuLabel: 'Edit Product Brief',
            description: 'Edit an existing product brief',
            hidden: true,
            backPath: '/dashboard/product-database/briefs',
            pathParam: 'briefId',
            Component: BriefEditor,
          },
        ],
      },
      {
        id: 'monday-integration',
        menuLabel: 'Monday Integration',
        description: 'Manage the syncing of products between The Matrix and product database board on Monday.',
        Component: MondayIntegration,
        tabQueryLabel: 'tab',
        tabs: [
          {
            label: 'Sync Status',
            id: 'product-sync-statuses',
          },
          {
            label: 'Connection Status',
            id: 'connection-status',
          },
          {
            label: 'Webhook Statuses',
            id: 'webhook-statuses',
          },
        ],
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'mitchell@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'sally@garageproject.co.nz',
          'hannah.lloyd@garageproject.co.nz',
          'hannah@garageproject.co.nz',
        ],
      },
    ],
  },
  {
    menuLabel: 'DEAR Stock Availability',
    id: 'stock',
    icon: EnvironmentOutlined,
    Component: StockAvailability,
    hasNestedSwitch: true,
    subItems: [
      {
        menuLabel: 'TGE - NZ', // Team Global Express, Previously known as Toll Logistics
        id: 'toll',
        description: 'Check out the stock we have available at Toll',
      },
      {
        menuLabel: 'Scott Base - NZ',
        id: 'scott-base',
        description: 'Check out the stock we have available at Scott Base for Venues and Local Biz',
      },
      {
        menuLabel: 'Cellaring Stock - NZ',
        id: 'cellaring-stock',
        description: 'Check out the cellaring stock we have available',
      },
      {
        menuLabel: 'Motus - AU',
        id: 'motus',
        description: 'Check out the stock we have available at Motus',
      },
      {
        menuLabel: 'Peter Sadler - AU',
        id: 'peter-sadler',
        path: '/stock/peter-sadler',
        description: 'Check out the stock we have available at Peter Sadler',
      },
      {
        menuLabel: 'Growler Depot - AU',
        id: 'growler-depot',
        description: 'Check out the stock we have available at Growler Depot',
      },
    ],
  },
  {
    menuLabel: 'Reports',
    id: 'reports',
    icon: LineChartOutlined,
    description: 'View and manage reports',
    Component: Reports,
  },
  {
    menuLabel: 'Webstore',
    id: 'webstore',
    icon: ShoppingOutlined,
    Component: Webstore,
    hasNestedSwitch: true,
    subItems: [
      {
        id: 'request',
        menuLabel: 'Request products',
        description: 'Request products from webstore inventory',
      },
      {
        id: 'review-requests',
        menuLabel: 'Review requests',
        description: 'Approve or reject webstore product requests',
        tabQueryLabel: 'status',
        tabs: [
          {
            label: 'Pending',
            id: 'pending',
          },
          {
            label: 'Approved',
            id: 'approved',
          },
          {
            label: 'Rejected',
            id: 'rejected',
          },
          {
            label: 'Failed',
            id: 'failed',
          },
        ],
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'gina@garageproject.co.nz',
          'sally@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'elliot@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
          'adam.holliday@garageproject.co.nz',
          'adam@garageproject.co.nz',
        ],
      },
      {
        id: 'shipping-rates',
        menuLabel: 'Shipping rates',
        description: 'Manage custom shipping rates',
        tabQueryLabel: 'shipFromCountry',
        tabs: [
          {
            label: 'New Zealand Webstores',
            id: 'NZ',
          },
          {
            label: 'Australia Webstores',
            id: 'AU',
          },
        ],
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'elliot@garageproject.co.nz',
          'george@garageproject.co.nz',
          'gina@garageproject.co.nz',
          'sally@garageproject.co.nz',
          'janna@garageproject.co.nz',
          'matt.cameron@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
        ],
      },
      {
        id: 'builds',
        menuLabel: 'Site builds',
        description: 'Trigger manual rebuilds of the Garage Project websites',
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'sally@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'elliot@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
        ],
      },
      {
        id: 'order-merch',
        menuLabel: 'Order merch',
        description: 'Merch stock numbers for Venues team',
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'elliot@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
          'lily@garageproject.co.nz',
          'ryan@garageproject.co.nz',
          'sam@garageproject.co.nz',
          'soph@garageproject.co.nz',
          'cellardoor@garageproject.co.nz',
          'lucy@garageproject.co.nz',
          'kingsland@garageproject.co.nz',
        ],
      },
      {
        id: 'fresh-club',
        menuLabel: 'FRESH Club Insights',
        description: 'Manage upcoming FRESH Club sales',
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'tyler.h@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
          'peter.fenton@garageproject.co.nz',
        ],
      },
      {
        id: 'fresh-club-onetimes',
        menuLabel: 'FRESH Club Onetimes',
        description: 'Add onetimes to Customer Subscriptions',
        emailWhitelist: [
          'dev@garageproject.co.nz',
          'leyton@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'kyle@garageproject.co.nz',
          'tyler@garageproject.co.nz',
          'tyler.h@garageproject.co.nz',
          'websales@garageproject.co.nz',
          'websales@garageproject.com.au',
          'peter.fenton@garageproject.co.nz',
        ],
      },
    ],
  },
  {
    menuLabel: 'Mailchimp',
    id: 'mailchimp',
    icon: MailOutlined,
    description: 'Quickly create new stock update emails from scratch',
    Component: Mailchimp,
  },
  {
    menuLabel: 'Beer Bible',
    id: 'beer-bible',
    icon: BookOutlined,
    description: 'Learn about the stories and details behind Garage Project creations',
    Component: BeerBible,
    allowExternal: true,
  },
  {
    menuLabel: 'QR Codes',
    id: 'qr-codes',
    icon: QrcodeOutlined,
    description: 'Create and update QR codes without needing to reprint them',
    Component: QRCodes,
  },
  {
    menuLabel: 'Tap Badges',
    id: 'tap-badges',
    icon: TagOutlined,
    description: 'Generate tap badges ready for printing',
    Component: TapBadges,
    allowExternal: true,
  },
  {
    menuLabel: 'Foodstuffs',
    id: 'foodstuffs',
    icon: ReadOutlined,
    Component: Foodstuffs,
    hasNestedSwitch: true,
    subItems: [
      {
        menuLabel: 'North Island',
        title: 'Foodstuffs (North Island)',
        id: 'north-island',
      },
      {
        menuLabel: 'South Island',
        title: 'Foodstuffs (South Island)',
        id: 'south-island',
      },
    ]
    ,
  },
  {
    menuLabel: 'Label Builder',
    id: 'label-builder',
    icon: FormOutlined,
    Component: LabelBuilder,
    hasNestedSwitch: true,
    allowExternal: true,
    subItems: [
      {
        menuLabel: 'Slab',
        title: 'Slab label builder',
        id: 'slab',
        description: 'Generate slab labels ready for printing',
        allowExternal: true,
      },
      {
        menuLabel: 'Case',
        title: 'Case label builder',
        id: 'case',
        description: 'Generate case labels ready for printing',
        allowExternal: true,
      },
      {
        menuLabel: 'Keg',
        title: 'Keg label builder',
        id: 'keg',
        description: 'Generate keg labels ready for printing',
        allowExternal: true,
      },
      {
        menuLabel: 'Pallet',
        title: 'Pallet label builder',
        id: 'pallet',
        description: 'Generate pallet labels ready for printing',
        allowExternal: true,
      },
    ],
  },
  {
    menuLabel: 'Certified Fresh',
    id: 'certified-fresh',
    icon: ShopOutlined,
    description: 'Manage Certified Fresh retailers',
    Component: CertifiedFresh,
    emailWhitelist: [
      'dev@garageproject.co.nz',
      'hannah.lloyd@garageproject.co.nz',
      'mary@garageproject.co.nz',
      'sally@garageproject.co.nz',
      'tim.witton@garageproject.co.nz',
      'amelia@garageproject.co.nz',
      'liam@garageproject.co.nz',
      'molly@garageproject.co.nz',
      'seanj@garageproject.co.nz',
      'crocky@garageproject.co.nz',
      'quinn@garageproject.co.nz',
      'mitchell@garageproject.co.nz',
      'kyle@garageproject.co.nz',
      'websales@garageproject.co.nz',
      'peter.fenton@garageproject.co.nz',
    ],
  },
  {
    menuLabel: 'DTC Product Builder',
    id: 'dtc-product-builder',
    icon: BankFilled,
    description: 'Generate Product Imports for Kounta and Shopify',
    Component: DTCProductBuilder,
    hasNestedSwitch: true,
    allowExternal: true,
    subItems: [
      // {
      //   menuLabel: 'Kounta',
      //   title: 'Kounta product generator',
      //   id: 'kounta',
      //   description: 'Generate Kounta product import',
      //   allowExternal: true,
      // },
      {
        menuLabel: 'Shopify',
        title: 'Shopify product generator',
        id: 'shopify',
        description: 'Generate Shopify product import',
        allowExternal: false,
      },
    ],
    emailWhitelist: [
      'dev@garageproject.co.nz',
      'kyle@garageproject.co.nz',
      'leyton@garageproject.co.nz',
      'tyler.h@garageproject.co.nz',
      'tyler@garageproject.co.nz',
    ],
  },
  {
    menuLabel: 'User Management',
    requiresAdmin: true,
    id: 'users',
    icon: UsergroupAddOutlined,
    description: 'Manage details and permissions for staff and external user accounts',
    Component: UserManagement,
    tabQueryLabel: 'type',
    tabs: [
      {
        label: 'Staff',
        id: 'staff',
      },
      {
        label: 'External',
        id: 'external',
      },
    ],
    emailWhitelist: [
      'dev@garageproject.co.nz',
      'leyton@garageproject.co.nz',
      'mattie@garageproject.co.nz',
      'laura@garageproject.co.nz',
      'kyle@garageproject.co.nz',
    ],
  },
];
