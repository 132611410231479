import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { CopyOutlined } from '@ant-design/icons';
import {
  Button, Typography, message, Divider,
} from 'antd';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import beautify from 'js-beautify';
import { copyTextToClipboard } from 'util/copy-to-clipboard';

const { html: beautifyHtml } = beautify;

const { Paragraph } = Typography;

const SnippetBlock = ({
  id, showCode, children,
}) => {
  const rawHtml = ReactDOMServer.renderToStaticMarkup(children);
  const formattedHtml = beautifyHtml(rawHtml.replaceAll('>', '>\n'), { indent_size: 2 });
  const highlightedHtml = hljs.highlightAuto(formattedHtml).value;
  return (
    <div className="row" id={`row-${id}`}>
      <div className="column preview">
        {children}
        {/* <Button
          className="copy-button"
          type="primary"
          icon={<CopyOutlined />}
          onClick={() => {
            const styles = "<style>h1,h2,h3,h4,h5,h6{margin-top:0;margin-bottom:.5em;color:rgba(0,0,0,.85);font-weight:500;font-size:20px}#stocktitle{font-size:26px}#beertitle{font-size:17px;padding-top:10px}a{letter-spacing:.3px;font-weight:200;font-size:14px;line-height:1.5;color:#1890ff;text-decoration:none;background-color:transparent;outline:0;font-variant:tabular-nums;cursor:pointer;transition:color .3s}p{font-weight:200;font-size:14px;line-height:21px;word-wrap:break-word;color:rgba(0,0,0,.65);font-size:14px;font-variant:tabular-nums;list-style:none;font-feature-settings:'tnum'}.contents{font-size:13px;letter-spacing:.2px}h6{font-size:9px;margin-top:4px}body{background-color:#fff;font-family:system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif}</style>";
            copyToClipboard(styles + formattedHtml);
            message.success('Code copied to clipboard!');
          }}
        >
          Copy section
        </Button> */}
      </div>
      {showCode && (
        <div className="column code">
          <Paragraph>
            <pre
              className="email-snippet-code-block"
              dangerouslySetInnerHTML={{
                __html: highlightedHtml,
              }}
            />
          </Paragraph>
          <Button
            className="copy-button"
            type="primary"
            icon={<CopyOutlined />}
            onClick={() => {
              // const styles = "<style>h1,h2,h3,h4,h5,h6{margin-top:0;margin-bottom:.5em;color:rgba(0,0,0,.85);font-weight:500;font-size:20px}#stocktitle{font-size:26px}#beertitle{font-size:17px;padding-top:10px}a{letter-spacing:.3px;font-weight:200;font-size:14px;line-height:1.5;color:#1890ff;text-decoration:none;background-color:transparent;outline:0;font-variant:tabular-nums;cursor:pointer;transition:color .3s}p{font-weight:200;font-size:14px;line-height:21px;word-wrap:break-word;color:rgba(0,0,0,.65);font-size:14px;font-variant:tabular-nums;list-style:none;font-feature-settings:'tnum'}.contents{font-size:13px;letter-spacing:.2px}h6{font-size:9px;margin-top:4px}body{background-color:#fff;font-family:system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif}</style>";
              copyTextToClipboard(formattedHtml);
              message.success('Code copied to clipboard!');
            }}
          >
            Copy code
          </Button>
        </div>
      )}
    </div>
  );
};

export default SnippetBlock;
