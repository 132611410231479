// TODO send output to Slack directly

const HandleMerchCart = (cart) => {
  const sortedCart = [...cart].sort((a, b) => a.title.localeCompare(b.title));
  let cartString = '';
  sortedCart.forEach((product) => {
    cartString += `${product.title}:\n`;
    product.variants.forEach((variant) => {
      cartString += `  ${variant.title}: ${variant.quantity}x\n`;
    });
  });
  return cartString;
};

export default HandleMerchCart;
