import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AddressSearch.less';
import {
  AutoComplete, Spin, Divider, Button, Radio, Space, Input, Typography,
} from 'antd';
import debounce from 'lodash/debounce';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useStateRef from 'react-usestateref';
import { AuthContext } from 'util/Auth';
import axios from 'axios';
import * as api from 'util/api';
import { GoogleMapsContext } from 'util/GoogleMapsProvider';

const { Text } = Typography;

// const EmptyContent = React.memo(({
//   fetching,
//   errorFetching,
//   customerContacts,
// }) => {
//   if (fetching) {
//     return (
//       <Spin
//         indicator={(
//           <LoadingOutlined style={{ fontSize: 22 }} spin />
//         )}
//       />
//     );
//   }

//   if (errorFetching) {
//     return <div>Error loading customer contacts</div>;
//   }

//   if (customerContacts === null) {
//     return <div>Start typing to search...</div>;
//   }

//   return <div>No exisiting customer contacts match your search</div>;
// });

const AddressSearch = ({
  // onChange,
  // value,
  disabled,
  permittedCountryCodes,
}) => {
  const [sessionToken, setSessionToken] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  // const [locationBias, setLocationBias] = useState(null);

  const { google } = useContext(GoogleMapsContext);

  // Set the bias for the search to the user's approximate location
  // useEffect(async () => {
  //   axios.get('https://ipapi.co/json/').then((response) => {
  //     const { data } = response;
  //     const { latitude, longitude } = data;
  //     setLocationBias(new google.maps.LatLng(longitude, latitude));
  //   }).catch((error) => {
  //     // If there was an issue determining the country, assume NZ.
  //     setLocationBias(new google.maps.LatLng(-41.290955217017554, 174.77452289367798));
  //     api.reportError(error);
  //   });
  // }, []);

  const onSearch = (searchTerm) => {
    if (!sessionToken) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }
    const trimmedSearchTerm = searchTerm ? searchTerm.trim() : searchTerm;
    if (!trimmedSearchTerm) {
      setSuggestions(null);
    } else {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input: searchTerm,
        // location: locationBias,
        componentRestrictions: {
          country: permittedCountryCodes,
        },
        sessionToken,
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions.map(
            ({ description, place_id: placeId }) => ({
              label: description,
              value: placeId,
            }),
          ));
        }
      });
    }
  };

  const onSelect = (placeId) => {
    console.log('selection', placeId);

    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ placeId })
      .then(({ results }) => {
        console.log('results', results);
        // TODO: Extract sections and set fields
      });

    // TODO: Lookup place ID
    if (placeId.value) {
      // onChange({
      //   id: selection.value,
      //   contactName: selection.label,
      //   firstName: '',
      //   lastLame: '',
      //   email: {
      //     value: email || null,
      //     custom: !email,
      //   },
      // });
    }
    setTimeout(() => {
      setSuggestions(null);
      setSessionToken(null);
    }, 300);
  };

  return (
    <AutoComplete
      // labelInValue
      disabled={disabled}
      options={suggestions}
      // value={value ? {
      //   label: value.contactName,
      //   value: value.id,
      // } : null}
      onSelect={onSelect}
      onSearch={onSearch}
      dropdownClassName="address-search-dropdown"
    />
  );
};

AddressSearch.propTypes = {
  // onChange: PropTypes.func,
  // value: PropTypes.object,
  disabled: PropTypes.bool,
  permittedCountryCodes: PropTypes.arrayOf(PropTypes.string),
};

AddressSearch.defaultProps = {
  // onChange: () => {},
  // value: null,
  disabled: false,
  permittedCountryCodes: null,
};

export default AddressSearch;
