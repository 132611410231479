import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'antd';
import TweenOne from 'rc-tween-one';
import { CloseOutlined } from '@ant-design/icons';
import measureElement from 'util/measure-element';
import { useResizeDetector } from 'react-resize-detector';
import './AnimatedAlert.less';

const DURATION = {
  enter: 1000,
  exit: 600,
};

const AnimatedAlert = ({
  id, visible, closable, onClose, ...alertProps
}) => {
  const [initialElementHeight, setInitialElementHeight] = useState(0);
  const [mount, setMount] = useState(visible);

  const { width: currentParentWidth, ref: containerResizeRef } = useResizeDetector();
  const { height: currentAlertHeight, ref: alertResizeRef } = useResizeDetector();

  // Mount/unmount component when visibility changes, allowing for the animation durations
  useEffect(async () => {
    if (visible) {
      if (!mount) {
        // Set the initial alert height by virtually measuring a copy of the element
        const { height } = await measureElement(
          <div style={{ width: currentParentWidth }}>
            <CustomAlert
              closable={closable}
              onClose={onClose}
              {...alertProps}
            />
          </div>,
        );
        setInitialElementHeight(height);
        setMount(true);
      }
    } else if (mount) {
      setTimeout(() => setMount(false), DURATION.exit);
    }
  }, [visible]);

  return (
    <div ref={containerResizeRef}>
      {mount ? (
        <TweenOne
          id={id}
          className="animated-alert"
          animation={{
            height: visible ? (
              // Use current rendered alert height if it exists, otherwise use measured
              currentAlertHeight || initialElementHeight
            ) : 0,
            repeat: 0,
            duration: visible ? DURATION.enter : DURATION.exit,
          }}
          style={{ height: 0 }}
          paused={false}
        >
          <CustomAlert
            ref={alertResizeRef}
            closable={closable}
            onClose={onClose}
            {...alertProps}
          />
        </TweenOne>
      ) : null}
    </div>
  );
};

const CloseButton = ({ onClose }) => (
  <Button
    type="link"
    className="custom-alert-close"
    onClick={onClose}
  >
    <CloseOutlined />
  </Button>
);

const CustomAlert = React.forwardRef(({ closable, onClose, ...alertProps }, ref) => (
  <div className="custom-alert" ref={ref}>
    <Alert
      {...alertProps}
      action={(
        <div className="custom-alert-actions">
          {closable ? (
            <CloseButton onClose={onClose} />
          ) : null}
        </div>
      )}
    />
  </div>
));

AnimatedAlert.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

AnimatedAlert.defaultProps = {
  id: null,
  onClose: null,
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CustomAlert.propTypes = {
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

CustomAlert.defaultProps = {
  onClose: null,
};

export default AnimatedAlert;
