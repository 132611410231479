import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ResponsiveCard.less';
import { useResizeDetector } from 'react-resize-detector';
import {
  Card, Button, Menu, Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

const ResponsiveCard = ({ actions, actionsDropdownLabel, ...props }) => {
  const { width, ref } = useResizeDetector();
  return (
    <div ref={ref}>
      <Card
        {...props}
        // TODO: Measure link elements using refs and base breakpoint on this
        actions={width < 500 ? ([
          <Dropdown
            placement="bottomCenter"
            overlay={(
              <Menu>
                {actions.map((link) => (
                  <Menu.Item key={link.key}>{link}</Menu.Item>
                ))}
              </Menu>
            )}
          >
            <Button type="link">
              View reports
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]) : actions}
      />
    </div>
  );
};

ResponsiveCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  actionsDropdownLabel: PropTypes.string,
};

ResponsiveCard.defaultProps = {
  actions: null,
  actionsDropdownLabel: 'View actions',
};

export default ResponsiveCard;
