const UNIT_DETAILS = {
  slab: {
    250: {
      label: '250ml Cans',
      quantity: 24,
      weight: 6.5,
    },
    250.4: {
      label: '4-pack of 250ml Cans',
      quantity: 6,
      weight: 6.5,
    },
    '16x.330': {
      label: '330ml Cans',
      quantity: 16,
      weight: 6.2,
    },
    '24x.330': {
      label: '330ml Cans',
      quantity: 24,
      weight: 8.75,
    },
    330.4: {
      label: '4-pack of 330ml Cans',
      quantity: 6,
      weight: 8.75,
    },
    330.6: {
      label: '6-pack of 330ml Cans',
      quantity: 4,
      weight: 8.75,
    },
    330.12: {
      label: '12-pack of 330ml Cans',
      quantity: 2,
      weight: 8.75,
    },
    '330.10': {
      label: '10-pack of 330ml Cans',
      quantity: 3,
      weight: 10.85,
    },
    375: {
      label: '375ml Cans',
      quantity: 24,
      weight: 9.75,
    },
    440.4: {
      label: '4-pack of 440ml Cans',
      quantity: 6,
      weight: 11.5,
    },
    '15x.440': {
      label: '440ml Cans',
      quantity: 15,
      weight: 7.125,
    },
    '16x.440': {
      label: '440ml Cans',
      quantity: 16,
      weight: 7.6,
    },
    '24x.440': {
      label: '440ml Cans',
      quantity: 24,
      weight: 11.5,
    },
  },
  case: {
    330: {
      label: '330ml Bottles',
      quantity: 24,
      weight: 13.75,
    },
    330.6: {
      label: '6-pack of 330ml Bottles',
      quantity: 4,
      weight: 13.75,
    },
    375: {
      label: '375ml Bottles',
      quantity: 12,
      weight: 10.05,
    },
    375.6: {
      label: '6-pack of 375ml Bottles',
      quantity: 4,
      weight: 20.1,
    },
    650: {
      label: '650ml Bottles',
      quantity: 12,
      weight: 13,
    },
    750: {
      label: '750ml Bottles',
      quantity: 6,
      weight: 8.5,
    },
    750.12: {
      label: '750ml Bottles (Crushed 12-pack)',
      quantity: 12,
      weight: 18,
    },
  },
  keg: {
    19.5: {
      label: '19.5L Keg',
      quantity: 1,
    },
    20: {
      label: '20L Keg',
      quantity: 1,
    },
    30: {
      label: '30L Keg',
      quantity: 1,
    },
    50: {
      label: '50L Keg',
      quantity: 1,
    },
  },
};

export default UNIT_DETAILS;
