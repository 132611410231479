import React, { useState, useEffect } from 'react';
import './ConfirmRequestModal.less';
import {
  Button, Alert, Form, Modal, Input, Select, Steps, Checkbox, Divider, Radio, Space, DatePicker, Result, Tooltip,
} from 'antd';
import * as api from 'util/api.js';
import { CustomStepper, AddressInput, CustomModal } from 'components';
import { MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { UnmountClosed } from 'react-collapse';
import { pick } from 'lodash';
import CustomerContactSelect from './CustomerContactSelect/CustomerContactSelect';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const ConfirmRequestModal = ({
  open, onClose, onSubmitted, cart, store, storeIsNZ, webstoreAdmin,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [shippingFieldsDisabled, setShippingFieldsDisabled] = useState(true);
  const [billToEmailFieldDisabled, setBillToEmailFieldDisabled] = useState(false);

  const [form] = Form.useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const reset = () => {
    form.resetFields();
    setRequestType(null);
    setSubmitting(false);
    setSubmitted(false);
    setShippingFieldsDisabled(true);
  };

  const giftNoteEnabled = Form.useWatch('addGiftNote', form);

  const [gettingShippingOptions, setGettingShippingOptions] = React.useState(false);
  const [shippingOptions, setShippingOptions] = React.useState([]);

  const getShippingOptions = async () => {
    form.validateFields([
      ['shippingAddress', 'firstName'],
      ['shippingAddress', 'lastName'],
      ['shippingAddress', 'address1'],
      ['shippingAddress', 'city'],
      ['shippingAddress', 'province'],
      ['shippingAddress', 'zip'],
      ['shippingAddress', 'country'],
      ['shippingAddress', 'phone'],
      ['shippingAddress', 'email'],
    ]).then(() => {
      setGettingShippingOptions(true);

      const shippingAddress = form.getFieldValue('shippingAddress');

      const {
        firstName,
        lastName,
        country,
        zip,
        province,
        city,
        address1,
        address2,
        phone,
        companyName,
      } = shippingAddress;

      // Destination must be in format as per example:
      // "country": "CA",
      // "postal_code": "K1M1M4",
      // "province": "ON",
      // "city": "Ottawa",
      // "name": "Bob Norman",
      // "address1": "24 Sussex Dr.",
      // "address2": "",
      // "phone": null,
      // "company_name": null
      const destination = {
        country: country.value,
        postal_code: zip,
        province: province.value,
        city,
        name: firstName ? `${firstName} ${lastName}` : lastName,
        address1,
        address2,
        phone,
        companyName: companyName ?? null,
      };

      api.evaluateShippingRates({
        internalWebstore: true,
        store: storeIsNZ ? 'GPNZ' : 'GPAU',
        destination,
        variants: cart.reduce((variants, product) => (
          [
            ...variants,
            ...product.variants.map((variant) => ({
              variant_id: variant.id,
              ...pick(variant, [
                'productId',
                'name',
                'sku',
                'quantity',
                'grams',
                'price',
                'requiresShipping',
                'taxable',
                'fulfillmentService',
              ]),
            })),
          ]
        ), []),
      })
        .then(({ rates }) => {
          if (!rates.length) {
            // No shipping rates available
            Modal.error({
              centered: true,
              content: `There are no shipping methods available for this address, please
                  enter a different address or instead, or collect from the webstore.`,
            });
            setShippingOptions([]);
          } else {
            setShippingOptions(rates);
            const [defaultOption] = rates;
            form.setFieldValue('shippingOption', defaultOption.serviceCode);
            form.validateFields(['shippingOption']);
          }
        })
        .catch(() => {
          setShippingOptions([]);
        }).finally(() => {
          setGettingShippingOptions(false);
        });
    }).catch(() => {
      Modal.warning({
        centered: true,
        content: 'Please enter a valid address to get shipping options for.',
      });
    });
  };

  const onFieldsChange = (changedFields, allFields) => {
    const findField = (fieldName, changedOnly = false) => {
      const fields = changedOnly ? changedFields : allFields;
      // Check each part of the field name if provided with an array
      if (Array.isArray(fieldName)) {
        return fields.find((field) => (
          (field.name.length === fieldName.length)
          && field.name.every((part, index) => part === fieldName[index])
        ));
      }
      // Otherwise if provided a string, just check the first part
      return (fields.find((field) => field.name.length === 1 && field.name[0] === fieldName));
    };

    const findChangedField = (fieldName) => findField(fieldName, true);

    const requestTypeField = findChangedField('requestType');
    if (requestTypeField && requestTypeField.value !== requestType) {
      // Delay resetting form to allow dropdown closing animation to complete
      const { value } = requestTypeField;
      setRequestType(value);
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({ requestType: value });
        form.validateFields();
        setBillToEmailFieldDisabled(true);
      }, 300);
    }

    // Clear any existing shipping options if the address is changed
    if (!!shippingOptions.length && changedFields.some(({ name }) => name[0] === 'shippingAddress')) {
      setShippingOptions([]);
      form.resetFields(['shippingOption']);
    }

    const shipField = findChangedField('ship');
    if (shipField) {
      setShippingFieldsDisabled(!shipField.value);
      // Clear all shipping fields when changing to collect
      form.resetFields(['shippingOption', ...allFields.filter(
        (field) => field.name[0] === 'shippingAddress',
      ).map((field) => field.name)]);
    }

    // Selecting doesn't re-validate the field, so this must be done manually
    const billToField = findChangedField('billTo');
    if (billToField) {
      setBillToEmailFieldDisabled(!billToField.value?.email?.custom);

      if (!!billToField.value && billToField.errors.length > 0) {
        form.validateFields(['billTo']);
      }

      // Clear email related errors if changing billing contact
      const billToEmailField = findField(['billTo', 'email', 'value']);
      if (billToEmailField?.value && billToEmailField.errors.length > 0) {
        form.validateFields([['billTo', 'email', 'value']]);
      }
    }
  };

  useEffect(() => {
    if (formInitialised) {
      reset();
    } else {
      setFormInitialised(true);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmitSuccess = () => {
    handleClose();
    onSubmitted();
  };

  const onNext = (success) => {
    form.validateFields()
      .then(() => success())
      .catch(
        (error) => console.log('error', error),
      );
  };

  const onPrevious = (success) => {
    success();
  };

  const onSubmit = async ({
    requestType: type,
    month,
    ship,
    shippingAddress,
    billTo,
    shippingOption: shippingRateCode,
    ...values
  }) => {
    setSubmitting(true);
    setShippingFieldsDisabled(true);

    api.createShopifyProductRequest(
      store,
      {
        type,
        month: month ? month.format('MMMM') : null,
        ship,
        shippingRateCode,
        shippingAddress: ship && shippingAddress ? {
          ...shippingAddress,
          province: shippingAddress.province?.label,
          province_code: shippingAddress.province?.value,
          country: shippingAddress.country?.label,
          countryCode: shippingAddress.country?.value,
        } : null,
        billing: billTo ? {
          email: billTo.email.value,
          contactName: billTo.contactName,
          xeroContactId: billTo.id,
        } : null,
        ...values,
        products: cart.map(({ id: productId, variants }) => ({
          id: productId,
          variants: variants.map(({ id: variantId, quantity }) => ({
            id: variantId,
            quantity,
          })),
        })),
      },
    ).then(() => {
      setSubmitted(true);
      setSubmitting(false);
    }).catch(() => {
      setSubmitting(false);
      setShippingFieldsDisabled(!form.getFieldValue('ship'));
    });
  };

  return (
    <CustomModal
      width={500}
      title="Request products"
      centered
      visible={open}
      onCancel={submitted ? onSubmitSuccess : handleClose}
      closable={!submitting}
      destroyOnClose
      wrapClassName="confirm-webstore-request-wrapper"
      footer={null}
    >
      <div id="confirm-webstore-request-modal-inner-content">
        {submitted ? (
          <Result
            status="success"
            title="Request submitted!"
            subTitle={webstoreAdmin ? (
              "Since you're a webstore admin, your request has automatically been approved."
            ) : (
              'You will be notified by email of updates on the status of your request as it is processed.'
            )}
            extra={(
              <Button type="primary" onClick={onSubmitSuccess}>
                Close
              </Button>
            )}
          />
        ) : (
          <>
            {webstoreAdmin && (
            <div className="modal-description-area">
              <Alert
                message="You're a webstore admin, so your request will automatically be approved after submitting."
                type="info"
                showIcon
              />
            </div>
            )}
            <Form
              id="webstore-product-request-form"
              name="webstore-product-request-form"
              form={form}
              scrollToFirstError={{
                behavior: 'smooth',
              }}
              onFinish={onSubmit}
              validateTrigger="onSubmit"
              validateMessages={{
                types: {
                  email: 'Please enter a valid email address',
                },
              }}
              initialValues={{
                requestType: null,
                month: dayjs(),
                billTo: null,
                ship: false,
                shippingAddress: {
                  country: storeIsNZ ? {
                    label: 'New Zealand',
                    value: 'NZ',
                  } : {
                    label: 'Australia',
                    value: 'AU',
                  },
                },
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFieldsChange={onFieldsChange}
            >
              <CustomStepper
                onNext={onNext}
                onPrevious={onPrevious}
                submitting={submitting}
                submitButton={(
                  <Button
                    type="primary"
                    form="webstore-product-request-form"
                    key="submit"
                    htmlType="submit"
                    loading={submitting}
                  >
                    Submit request
                  </Button>
                )}
                steps={[
                  {
                    title: 'Type',
                    content: (
                      <>
                        <Form.Item
                          name="requestType"
                          label="Request type"
                          labelCol={{ span: 24 }}
                          // extra="We must make sure that your are a human."
                          rules={[{
                            required: true,
                            message: 'Please specify the type of request',
                          },
                          {
                            validator: (_, value) => {
                              if (value === 'shirt_allowance') {
                                if (!cart.every(({ productType }) => productType === 'Clothing')) {
                                  return Promise.reject(new Error('You have products in your cart that aren\'t t-shirts, please remove these first'));
                                }

                                // There is a staff embargo if released within the past week
                                // (or not published at all)
                                const hasEmbargoItems = !!cart.find(({ publishedAt }) => (
                                  !publishedAt || dayjs(publishedAt).isAfter(dayjs().subtract(1, 'week'))
                                ));

                                if (hasEmbargoItems) {
                                  return Promise.reject(new Error('One or more of the items you are requesting have a staff embargo due to being a new release'));
                                }
                              } else if (value !== 'sales_samples') {
                                // If one or more of the products are restricted to sales samples,
                                // and the request is NOT sales samples, then prevent progression
                                const hasSalesSampleRestrictedItems = !!cart.find(
                                  ({ settings }) => settings.salesSamplesOnly,
                                );

                                if (hasSalesSampleRestrictedItems) {
                                  return Promise.reject(new Error('One or more of the items you are requesting are reserved for sales samples, please change your request type or remove the items'));
                                }
                              }
                              return Promise.resolve();
                            },
                          }]}
                        >
                          <Select>
                            <OptGroup label="Billed externally (invoiced)">
                              <Option value="staff_sale">Staff sale</Option>
                              <Option value="investor_sale">Investor sale</Option>
                              <Option value="trade_sale">Trade sale</Option>
                            </OptGroup>
                            <OptGroup label="Billed to Garage Project">
                              <Option value="comp">Comp</Option>
                              <Option value="sales_samples">Sales samples</Option>
                              { !storeIsNZ && <Option value="staffies">Staffies</Option>}
                              <Option value="shirt_allowance">Staff t-shirt allowance</Option>
                              {webstoreAdmin && <Option value="wastage">Webstore wastage</Option>}
                              {webstoreAdmin && storeIsNZ && <Option value="office_beers">Office beers</Option>}
                              {webstoreAdmin && storeIsNZ && <Option value="hapi_pack">Hāpi Research pack</Option> }
                            </OptGroup>
                          </Select>
                        </Form.Item>
                      </>
                    ),
                  },

                  // Details section
                  {
                    title: 'Details',
                    inactive: ['shirt_allowance', 'wastage', 'office_beers'].includes(requestType),
                    content: (
                      <>
                        {['comp', 'sales_samples'].includes(requestType) && (
                        <Form.Item
                          name="reason"
                          label="Reason for request"
                          rules={[{ required: true, message: 'A reason for your request is required' }]}
                          labelCol={{ span: 24 }}
                        >
                          <TextArea
                            autoSize={{ minRows: 3 }}
                          />
                        </Form.Item>

                        )}

                        <Form.Item
                          name="specialRequirements"
                          label="Add any special requirements here"
                          labelCol={{ span: 24 }}
                        >
                          <TextArea
                            autoSize={{ minRows: 3 }}
                          />
                        </Form.Item>

                        {!['staffies'].includes(requestType) && (
                          <div id="add-gift-note-container">
                            <Form.Item
                              name="addGiftNote"
                              valuePropName="checked"
                              style={{ marginBottom: 0 }}
                            >
                              <Checkbox>
                                Add a handwritten gift note?
                              </Checkbox>
                            </Form.Item>
                            <UnmountClosed isOpened={giftNoteEnabled}>
                              <Form.Item
                                name="giftNoteMessage"
                                label="Gift note message"
                                rules={[
                                  { required: true, message: 'Please enter your gift note message' },
                                  { max: 80, message: 'Your gift note message must be 80 characters or less' },
                                ]}
                                labelCol={{ span: 24 }}
                              >
                                <TextArea
                                  autoSize={{ minRows: 3 }}
                                />
                              </Form.Item>
                            </UnmountClosed>
                          </div>
                        )}

                        {['staffies'].includes(requestType) && (
                        <Form.Item
                          name="month"
                          label="Which month is this staffie allocation for?"
                          rules={[{ required: true, message: 'A month is required' }]}
                          labelCol={{ span: 24 }}
                        >
                          <DatePicker
                            picker="month"
                            format="MMMM"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        )}

                        {requestType === 'shirt_allowance' && (
                        <div>
                          <Alert
                            message="You are entitled to 3 free GP t-shirts as a full-time employee and 1 as a part timer, annually."
                            type="info"
                            showIcon
                          />
                        </div>
                        )}
                      </>
                    ),
                  },

                  // Billing section
                  {
                    title: 'Billing',
                    inactive: !['staff_sale', 'investor_sale', 'trade_sale'].includes(requestType),
                    content: (
                      <>
                        <Alert
                          className="sale-total-disclaimer"
                          message="Please note that the total RRP does not necessarily reflect the amount charged, any applicable discounts will be applied to the invoice (e.g. staff discount)."
                          type="info"
                          showIcon
                        />
                        <Form.Item
                          label="Who should this sale be billed to?"
                          name="billTo"
                          labelCol={{ span: 24 }}
                          rules={[{ required: true, message: 'The name of an individual or company is required' }]}
                        >
                          <CustomerContactSelect
                            storeIsNZ={storeIsNZ}
                            showCurrentUserAsOption={requestType === 'staff_sale'}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ marginTop: 5 }}
                          label="Email address to send invoice to"
                          name={['billTo', 'email', 'value']}
                          labelCol={{ span: 24 }}
                          rules={[{
                            required: !billToEmailFieldDisabled,
                            message: 'An email address is required',
                          }, {
                            type: 'email',
                            message: 'Please enter a valid email address',
                          }]}
                        >
                          <Input
                            disabled={billToEmailFieldDisabled}
                            prefix={<MailOutlined className="field-icon-prefix" />}
                          />
                        </Form.Item>
                      </>
                    ),
                  },
                  {
                    title: 'Logistics',
                    content: (
                      <>
                        <Form.Item name="ship">
                          <Radio.Group disabled={submitting}>
                            <Space direction="vertical">
                              <Radio value={false}>Items will be collected from Marion St.</Radio>
                              <Radio value>Deliver items to the below address</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Divider plain />
                        <div id="shipping-address-fields" className={shippingFieldsDisabled ? 'disabled' : null}>
                          <AddressInput
                            form={form}
                            disabled={shippingFieldsDisabled || gettingShippingOptions}
                            permittedCountryCodes={storeIsNZ ? ['NZ', 'AU'] : ['AU']}
                          />
                          <Divider plain />
                          <div id="shipping-options-button-container">
                            <Button
                              type="primary"
                              id="shipping-options-button"
                              disabled={shippingFieldsDisabled}
                              loading={gettingShippingOptions}
                              onClick={getShippingOptions}
                            >
                              Get shipping options
                            </Button>
                          </div>
                          <Form.Item
                            label="Shipping"
                            labelCol={{ span: 24 }}
                            name="shippingOption"
                            rules={[{
                              required: !shippingFieldsDisabled,
                              message: shippingOptions.length ? (
                                'Please select a shipping option'
                              ) : 'Press the button above to get available shipping options',
                            }]}
                          >
                            <Select
                              disabled={
                                shippingFieldsDisabled
                                || gettingShippingOptions
                                || !shippingOptions.length
                              }
                              options={
                              shippingOptions.map((shippingOption) => ({
                                value: shippingOption.serviceCode,
                                label: shippingOption.serviceName,
                              }))
                            }
                            />
                          </Form.Item>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </Form>
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default ConfirmRequestModal;
