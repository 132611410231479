import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const URL = process.env.REACT_APP_NODE_ENV === 'development'
  ? 'http://localhost:8009'
  : 'https://australia-southeast1-product-sync-handler.cloudfunctions.net/get-cached-sync-status';

export default ({ refetchInterval }) => useQuery({
  queryKey: ['get_cached_sync_status'],
  queryFn: async () => (await axios({
    method: 'GET',
    url: URL,
  })).data,
  refetchInterval,
});
