import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Table as AntTable, Avatar, Button, Progress, Spin, Tag, Tooltip, Typography, notification,
} from 'antd';
import { capitalCase, sentenceCase } from 'change-case';

import { CustomTable } from 'components';
import * as api from 'util/api.js';

import {
  CheckCircleOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined, LoadingOutlined, MinusCircleOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { PresetColorTypes } from 'antd/lib/_util/colors';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import useCachedProductSyncStatusQuery from './use-cached-product-sync-status-query';

import './index.less';
import useStartManualSyncMutation from './use-start-manual-sync-mutation';
import DiscrepanciesModal from './DiscrepanciesModal';
import { MONDAY_PRODUCT_DATABASE_BOARD_ID } from '../constants';

const { Title, Paragraph } = Typography;
const { Column } = AntTable;

const REVIEWING_DISCREPANCIES_DEFAULT_VALUE = {
  active: false,
  productName: null,
  matrixId: null,
  mondayId: null,
  discrepancies: null,
};

const DEFAULT_META_QUERY_INTERVAL = 60 * 1000 * 2; // 2 minutes

const ProductSyncStatusTable = () => {
  /**
   * All products in the database.
   */
  const [products, setProducts] = useState(null);

  /**
   * Whether products have been fetched.
   */
  const hasFetchedProducts = useMemo(() => Array.isArray(products), [products]);

  /**
   * Handles fetching products and setting them in state.
   */
  const fetchProducts = useCallback(async () => {
    setProducts(await api.getProducts());
  }, [setProducts]);

  /**
   * Fetch all products from the database on mount.
   */
  useEffect(() => {
    fetchProducts();
  }, []);

  /**
   * The interval to refetch the status of the product syncing, defaults
   * to 2 minutes seconds until a sync is invoked, or is already being performed.
   */
  const [
    metaQueryRefetchInterval,
    setMetaQueryRefetchInterval,
  ] = useState(DEFAULT_META_QUERY_INTERVAL);

  /**
   * Fetch the current cached state for synced products.
   */
  const cachedProductSyncStatusQuery = useCachedProductSyncStatusQuery({
    refetchInterval: metaQueryRefetchInterval,
  });

  /**
 * Deconstruct the query data.
 */
  const { data: cachedProductSyncStatus } = cachedProductSyncStatusQuery;

  /**
   * Whether a manual sync has been successfully requested - this allows the sync progress to be
   * displayed in the period between the request being made, and the meta being re-fetched.
   */
  const [successfullyRequestedSync, setSuccessfullyRequestedSync] = useState(false);

  /**
   * Reset the flag indicating that a sync has been requested if the flag has been set and
   * the meta data updates to reflect that the sync is active.
   */
  useEffect(() => {
    if (cachedProductSyncStatusQuery.isSuccess) {
      if (cachedProductSyncStatus.meta.syncActive && successfullyRequestedSync) {
        setSuccessfullyRequestedSync(false);
      }
    }
  }, [cachedProductSyncStatusQuery, cachedProductSyncStatus, successfullyRequestedSync]);

  /**
   * Whether products are currently being manually synced.
   */
  const isSyncing = useMemo(() => (
    successfullyRequestedSync || (
      cachedProductSyncStatusQuery.isSuccess && cachedProductSyncStatus.meta.syncActive
    )
  ), [successfullyRequestedSync, cachedProductSyncStatusQuery, cachedProductSyncStatus]);

  /**
   * Update the meta query sync interval when the sync is active and
   * it still set to the default interval, or revert to the default if
   * it is no longer active.
   */
  useEffect(() => {
    if (cachedProductSyncStatusQuery.isSuccess) {
      if (isSyncing) {
        if (metaQueryRefetchInterval === DEFAULT_META_QUERY_INTERVAL) {
          setMetaQueryRefetchInterval(1000);
        }
      } else if (metaQueryRefetchInterval !== DEFAULT_META_QUERY_INTERVAL) {
        setMetaQueryRefetchInterval(DEFAULT_META_QUERY_INTERVAL);
      }
    }
  }, [cachedProductSyncStatusQuery, isSyncing, metaQueryRefetchInterval]);

  /**
   * Whether products are currently being manually synced.
   */
  const lastSyncSuccess = useMemo(() => {
    if (cachedProductSyncStatus?.meta.syncActive) {
      return null;
    }
    return !cachedProductSyncStatus?.meta.syncError;
  }, [cachedProductSyncStatus]);

  /**
   * The total number of products that have discrepancies.
   */
  const totalProductsWithDiscrepancies = useMemo(() => (!cachedProductSyncStatus?.statuses ? 0 : (
    cachedProductSyncStatus?.statuses.filter(
      ({ discrepancies }) => Array.isArray(discrepancies) && discrepancies.length > 0,
    ).length)
  ), [cachedProductSyncStatus]);

  /**
   * Whether the data for the table is ready to be evaluated.
   */
  const dataIsReady = useMemo(() => (
    hasFetchedProducts && cachedProductSyncStatusQuery.isSuccess
  ), [hasFetchedProducts, cachedProductSyncStatusQuery]);

  /**
 * Calculate the sync percentage.
 */
  const syncPercentage = useMemo(() => (
    !dataIsReady || !isSyncing || successfullyRequestedSync ? 0 : (
      (cachedProductSyncStatus.meta.totalProcessed / products.length) * 100
    ).toFixed(2)
  ), [dataIsReady, isSyncing, cachedProductSyncStatus, products]);

  /**
   * Define the data for the table.
   */
  const tableData = useMemo(() => (!dataIsReady ? (
    undefined
  ) : (
    products.map((product) => {
    /**
     * Attempt to find the status for the product from the fetched data.
     */
      const { discrepancies, lastChecked, lastSynced } = cachedProductSyncStatus.statuses.find(
        ({ matrixId }) => product.id === matrixId,
      ) ?? {};

      return ({
        key: product.id,
        tapBadgeUrl: product.tapBadgeUrl,
        productName: product.name,
        matrixId: product.id,
        mondayId: product.mondayId ?? null,
        lastChecked: lastChecked ? dayjs(lastChecked).format('DD/MM/YYYY h:mma') : null,
        lastSynced: lastSynced ? dayjs(lastSynced).format('DD/MM/YYYY h:mma') : null,
        discrepancies: lastChecked && discrepancies ? discrepancies : null,
      });
    }).sort(
      ({ discrepancies: discrepanciesA }, { discrepancies: discrepanciesB }) => {
        if (discrepanciesA === null && discrepanciesB === null) {
          return 0;
        }
        if (discrepanciesA === null && discrepanciesB !== null) {
          return 1;
        }
        if (discrepanciesB === null && discrepanciesA !== null) {
          return -1;
        }
        return (
          discrepanciesB.length - discrepanciesA.length
        );
      },
    )
  )), [dataIsReady, products, cachedProductSyncStatus]);

  /**
   * Access the query client.
   */
  const queryClient = useQueryClient();

  /**
   * Show a success notification when the manual sync has been successfully started.
   */
  const onStartManualSyncSuccess = useCallback(() => {
    /**
     * Increase the frequency of the meta query to every second.
     */
    setMetaQueryRefetchInterval(1000);

    /**
     * Set the flag indicating that a sync has been requested.
     */
    setSuccessfullyRequestedSync(true);

    /**
     * Invalidate the query for the sync status.
     */
    queryClient.invalidateQueries('get_cached_sync_status');

    /**
     * Show the notification.
     */
    notification.success({
      message: 'Successfully started manual product sync!',
    });
  }, []);

  /**
   * Show an error notification when the manual sync failed to start.
   */
  const onStartManualSyncError = useCallback(() => {
    notification.error({
      message: 'There was an error starting the manual product sync. Please try again.',
    });
  }, []);

  /**
   * Mutation for invoking the manual product syncing.
   */
  const {
    isLoading: isStartingManualProductSync,
    mutate: startManualProductSync,
  } = useStartManualSyncMutation({
    onSuccess: onStartManualSyncSuccess,
    onError: onStartManualSyncError,
  });

  /**
   * Discrepancies that are currently being reviewed.
   */
  const [reviewingDiscrepancies, setReviewingDiscrepancies] = useState(
    REVIEWING_DISCREPANCIES_DEFAULT_VALUE,
  );

  /**
   * Handle showing the modal to decide discrepancies for a product sync.
   */
  const onReviewDiscrepancies = useCallback(({
    matrixId, mondayId, productName, discrepancies,
  }) => {
    setReviewingDiscrepancies({
      active: true,
      matrixId,
      mondayId,
      productName,
      discrepancies,
    });
  }, []);

  const onCloseDiscrepanciesModal = useCallback((success) => {
    /**
     * Refetch the products and invalidate the sync status query if
     * discrepancies were successfully resolved.
     */
    if (success) {
      fetchProducts();
      queryClient.invalidateQueries('get_cached_sync_status');
    }

    /**
     * Reset the modal state.
     */
    setReviewingDiscrepancies(REVIEWING_DISCREPANCIES_DEFAULT_VALUE);
  }, []);

  return (
    <>
      <DiscrepanciesModal
        visible={reviewingDiscrepancies.active}
        onClose={onCloseDiscrepanciesModal}
        {...reviewingDiscrepancies}
      />
      <div id="monday-sync-status-container">
        <div style={{ marginBottom: 10 }}>
          <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
            The table below shows the sync status of the products in
            the Matrix with Monday.
          </Paragraph>
          <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
            A manual sync can be performed to ensure that all data in the
            Matrix is up to date with the products in Monday.
          </Paragraph>
          <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
            When a sync is performed, both data in the Matrix and Monday
            may be updated. If there are discrepancies in data between what
            is in the Matrix and what is in Monday, you will need to review
            the data you would like to set as being correct.
          </Paragraph>
        </div>

        <div>
          <Title level={5}>Sync progress</Title>
          {!dataIsReady ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            />
          ) : (
            <>
              {isSyncing ? (
                <div style={{ maxWidth: '100%', marginRight: 20 }}>
                  <Progress percent={syncPercentage} status={isSyncing ? 'active' : 'normal'} />
                </div>
              ) : (
                <>
                  {lastSyncSuccess !== null ? (
                    <>
                      {lastSyncSuccess ? (
                        <>
                          {totalProductsWithDiscrepancies === 0 ? (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="green"
                              style={{
                                marginBottom: 8,
                                transform: 'scale(1.25)',
                                transformOrigin: 'left',
                              }}
                            >
                              All products are in sync!
                            </Tag>
                          ) : (
                            <Tag
                              icon={<ExclamationCircleOutlined />}
                              color="orange"
                              style={{
                                marginBottom: 8,
                                transform: 'scale(1.25)',
                                transformOrigin: 'left',
                              }}
                            >
                              {`There ${totalProductsWithDiscrepancies === 1 ? 'is' : 'are'} ${pluralize('products', totalProductsWithDiscrepancies, true)} with discrepancies to review.`}
                            </Tag>
                          )}
                        </>
                      ) : (
                        <Tag
                          icon={<ExclamationCircleOutlined />}
                          color="red"
                          style={{
                            marginBottom: 8,
                            transform: 'scale(1.25)',
                            transformOrigin: 'left',
                          }}
                        >
                          The last sync failed.
                        </Tag>
                      )}
                    </>
                  ) : null}
                  <Paragraph style={{ opacity: 0.7, fontStyle: 'italic' }}>Manual sync is not currently running, click the button below to perform a manual sync/audit.</Paragraph>
                </>
              )}
            </>
          )}
        </div>

        <Title level={5}>Products</Title>
        <CustomTable
          id="product-sync-statuses"
          pluralLabel="products"
          dataSource={tableData}
          searchableColumns={['productName', 'matrixId', 'mondayId']}
          controls={(
            <Button
              type="primary"
              onClick={startManualProductSync}
              loading={isSyncing || isStartingManualProductSync}
              disabled={!dataIsReady}
            >
              {isSyncing ? 'Syncing products...' : 'Perform manual sync'}
            </Button>
        )}
        >
          <Column
            title={null}
            dataIndex="tapBadgeUrl"
            key="tapBadge"
            width={64}
            className="avatar-column"
            render={(tapBadgeUrl) => (
              <Avatar src={tapBadgeUrl} />
            )}
          />
          <Column
            title="Product"
            key="productName"
            dataIndex="productName"
          />
          <Column
            title="Matrix ID"
            key="matrixId"
            dataIndex="matrixId"
          />
          <Column
            title="Monday ID"
            key="mondayId"
            dataIndex="mondayId"
          />
          <Column
            title="Last checked"
            key="lastChecked"
            dataIndex="lastChecked"
          />
          <Column
            title="Last synced"
            key="lastSynced"
            dataIndex="lastSynced"
          />
          <Column
            title="Discrepancies"
            key="discrepancies"
            dataIndex="discrepancies"
            render={(discrepancies) => {
              if (discrepancies === null) {
                return <div className="empty-cell">N/A</div>;
              }
              return discrepancies.length ? (
                <div className="has-discrepancies-label">{discrepancies.length}</div>
              ) : (
                <div className="no-discrepancies-label">None</div>
              );
            }}

          />
          <Column
            title="Actions"
            key="actions"
            render={(rowData) => {
              /**
               * Deconstruct the required properties from the product data.
               */
              const {
                productName, discrepancies, matrixId, mondayId,
              } = rowData;

              /**
               * Determine whether there are any discrepancies.
               */
              const hasDiscrepancies = !discrepancies || discrepancies.length === 0;

              return (
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 10,
                }}
                >
                  <Tooltip title={mondayId === null ? 'This product hasn\'t been linked to an item in Monday yet.' : undefined}>
                    <Button
                      type="ghost"
                      disabled={mondayId === null}
                      onClick={() => window.open(`https://garageproject.monday.com/boards/${MONDAY_PRODUCT_DATABASE_BOARD_ID}/pulses/${mondayId}`, '_blank')}
                    >
                      View in Monday
                    </Button>
                  </Tooltip>

                  <Tooltip title={hasDiscrepancies ? 'There are no discrepancies to review.' : undefined}>
                    <Button
                      type="primary"
                      disabled={hasDiscrepancies}
                      onClick={() => onReviewDiscrepancies({
                        matrixId, mondayId, productName, discrepancies,
                      })}
                    >
                      Review discrepancies
                    </Button>
                  </Tooltip>
                </div>
              ); }}
          />
        </CustomTable>
      </div>
    </>
  ); };

export default ProductSyncStatusTable;
