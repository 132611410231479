import React, { useState, useEffect } from 'react';
import {
  Button, Divider, PageHeader, notification, Typography,
} from 'antd';
import './Login.less';
import firebase from 'firebase';
import TweenOne from 'rc-tween-one';
import EmailLoginForm from './EmailLoginForm/EmailLoginForm';

const { Text } = Typography;

const Login = () => {
  const [redirecting, setRedirecting] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);
  const [showStaffLoginOptions, setShowStaffLoginOptions] = useState(false);

  const signInWithGoogle = () => {
    setRedirecting(true);
    firebase.auth()
      // Always keep staff members signed in for convenience.
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithRedirect(provider);
      }).catch((error) => {
        setRedirecting(false);
        notification.error({
          message: 'Error logging in',
          description: error.message,
        });
      });
  };

  useEffect(() => {
    if (emailLogin && showStaffLoginOptions) {
      setTimeout(() => setShowStaffLoginOptions(false), 500);
    }
  }, [emailLogin]);

  return (
    <div id="login-content-container">

      <TweenOne
        className="login-content"
        animation={{
          opacity: emailLogin ? 0 : 1,
          duration: 300,
          delay: emailLogin ? 0 : 150,
        }}
        style={{
          pointerEvents: emailLogin ? 'none' : 'auto',
          opacity: 1,
        }}
      >
        <>
          <PageHeader title="Welcome to the Matrix" />

          <Button
            type="primary"
            onClick={() => setShowStaffLoginOptions(true)}
            disabled={showStaffLoginOptions}
          >
            Staff login
          </Button>
          <TweenOne
            animation={{
              height: showStaffLoginOptions ? 100 : 0,
              opacity: showStaffLoginOptions ? 1 : 0,
            }}
            style={{ height: 0, overflow: 'hidden' }}
          >
            <div className="staff-login-options">
              <Text>Do you have a Garage Project email address?</Text>
              <div className="staff-email-buttons">
                <Button
                  type="secondary"
                  onClick={() => setEmailLogin(true)}
                  disabled={redirecting}
                >
                  No
                </Button>
                <Button
                  type="primary"
                  onClick={signInWithGoogle}
                  loading={redirecting}
                >
                  Yes
                </Button>
              </div>
            </div>
          </TweenOne>
          <Divider plain>OR</Divider>
          <Button
            type="primary"
            disabled={redirecting}
            onClick={() => setEmailLogin(true)}
            style={{ width: '100%' }}
          >
            External user login
          </Button>
        </>
      </TweenOne>

      <TweenOne
        className="login-content"
        animation={{
          opacity: emailLogin ? 1 : 0,
          duration: 300,
          delay: emailLogin ? 150 : 0,
        }}
        style={{
          pointerEvents: emailLogin ? 'auto' : 'none',
          opacity: 0,
        }}
      >
        <PageHeader onBack={() => setEmailLogin(false)} title="Email login" />
        <EmailLoginForm />
      </TweenOne>

    </div>
  );
};

export default Login;
