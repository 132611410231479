import React, { useContext } from 'react';
import { PageTabsContext } from 'util/PageTabs';
import WebhookStatusTable from './WebhookStatusTable';
import ConnectionStatus from './ConnectionStatus';

import ProductSyncStatusTable from './ProductSyncStatusTable';

import './index.less';

const MondayIntegration = () => {
  /**
   * Get the ID of the current tab.
   */
  const tab = useContext(PageTabsContext);

  return (
    <div id="monday-integration-page-content">
      {tab === 'connection-status' ? <ConnectionStatus /> : null}
      {tab === 'webhook-statuses' ? <WebhookStatusTable /> : null}
      {tab === 'product-sync-statuses' ? <ProductSyncStatusTable /> : null}
    </div>
  ); };

export default MondayIntegration;
