import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Form, Modal, Radio, notification,
} from 'antd';
import { CustomModal, CustomTable } from 'components';
import { useForm } from 'antd/lib/form/Form';
import * as api from 'util/api.js';
import PropTypes from 'prop-types';
import Column from 'antd/lib/table/Column';
import * as diff from 'diff';

import './index.less';
import useResolveDiscrepanciesMutation from './use-resolve-discrepancies-mutation';

const ValueDifference = ({ difference }) => (
  <div>
    {difference.map((part) => {
      if (part.removed) {
        return (
          <span
            key={part.value}
            style={{
              borderRadius: 4,
              backgroundColor: '#f87171',
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            {part.value}
          </span>
        );
      } if (part.added) {
        return (
          <span
            key={part.value}
            style={{
              borderRadius: 4,
              backgroundColor: '#4ade80',
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            {part.value}
          </span>
        );
      }
      return <span key={part.value}>{part.value}</span>;
    })}
  </div>
);

const DiscrepanciesModal = ({
  visible, onClose, productName, matrixId, mondayId, discrepancies,
}) => {
  /**
   * Handle the resolution of discrepancies succeeding.
   */
  const onResolveDiscrepanciesSuccess = useCallback(() => {
    /**
     * Show a success notification.
     */
    notification.success({
      message: `Discrepancies for ${productName} have been resolved!`,
    });

    /**
     * Close the modal.
     */
    onClose(true);

    // TODO: Invalidate query?
  }, [productName, onClose]);

  /**
   * Handle the resolution of discrepancies failing.
   */
  const onResolveDiscrepanciesError = useCallback(() => {
    /**
     * Show an error notification.
     */
    notification.error({
      message: `There was an error resolving the discrepancies for ${productName}.`,
    });
  }, [productName]);

  /**
   * Mutation for invoking the resolution of discrepancies.
   */
  const {
    isLoading: isResolvingDiscrepancies,
    mutate: resolveDiscrepancies,
  } = useResolveDiscrepanciesMutation({
    onSuccess: onResolveDiscrepanciesSuccess,
    onError: onResolveDiscrepanciesError,
  });

  /**
   * Resolutions for discrepancies, with the key being the ID of the Matrix
   * column, and the value being either 'matrix' or 'monday' depending on the
   * value that has been chosen to keen.
   */
  const [selections, setSelections] = useState(!visible ? {} : (
    Object.fromEntries(discrepancies.map((discrepancy) => [discrepancy.matrix.columnId, null]))));

  /**
   * Clear the discrepancy resolutions when the modal closes.
   */
  useEffect(() => {
    if (!visible && Object.entries(selections).length > 0) {
      setSelections({});
    }
  }, [visible, selections]);

  const onSubmit = useCallback(async () => {
    /**
     * Get the column titles for the discrepancies that do not have a resolution selected.
     */
    const missingResolutions = discrepancies.filter(({ matrix: { columnId } }) => (
      !(columnId in selections))).map(({ columnTitle }) => columnTitle);

    /**
     * Show an error message if there are missing resolutions, and do not proceed.
     */
    if (missingResolutions.length > 0) {
      Modal.error({
        title: 'Resolutions still need to be selected for the following fields:',
        centered: true,
        content: (
          <ul>
            {missingResolutions.map((missingResolution) => <li>{missingResolution}</li>)}
          </ul>
        ),
      });
      /**
       * Do not proceed.
       */
      return;
    }

    /**
     * Construct the payload for the resolution of the discrepancies.
     */
    const payload = {
      matrixId,
      mondayId,
      productName,
      resolutions: Object.entries(selections).map(([matrixColumnId, useValueFrom]) => {
        /**
           * Find the applicable discrepancy.
           */
        const discrepancy = discrepancies.find(
          ({ matrix: { columnId } }) => columnId === matrixColumnId,
        );

        /**
         * Construct and return the resolution data.
         */
        return {
          matrixColumnId,
          mondayColumnId: discrepancy.monday.columnId,
          value: useValueFrom === 'matrix' ? discrepancy.matrix.value : discrepancy.monday.value,
        };
      }),
    };

    /**
     * Attempt to resolve the discrepancies.
     */
    resolveDiscrepancies(payload);
  }, [discrepancies, selections, matrixId, mondayId, productName]);

  /**
   * Define the data for the table.
   */
  const tableData = useMemo(() => (!visible ? (
    undefined
  ) : (
    discrepancies.map((discrepancy) => ({
      key: discrepancy.columnTitle,
      ...discrepancy,
    })))
  ), [discrepancies]);

  /**
   * Handle selecting all Matrix values as the values to keep.
   */
  const onSelectAllMatrixValues = useCallback(() => {
    setSelections(
      Object.fromEntries(discrepancies.map((discrepancy) => [discrepancy.matrix.columnId, 'matrix'])),
    );
  }, [discrepancies]);

  /**
   * Handle selecting all Monday values as the values to keep.
   */
  const onSelectAllMondayValues = useCallback(() => {
    setSelections(
      Object.fromEntries(discrepancies.map((discrepancy) => [discrepancy.matrix.columnId, 'monday'])),
    );
  }, [discrepancies]);

  /**
   * Handle a Matrix being selected as the value to keep.
   */
  const onSelectMatrixValue = useCallback((columnId) => {
    setSelections((current) => ({ ...current, [columnId]: 'matrix' }));
  }, []);

  /**
   * Handle a Monday being selected as the value to keep.
   */
  const onSelectMondayValue = useCallback((columnId) => {
    setSelections((current) => ({ ...current, [columnId]: 'monday' }));
  }, []);

  return (
    <CustomModal
      className="review-discrepancies-modal"
      width="100%"
      title={`Resolve discrepancies${visible ? ` for ${productName ?? 'Unnamed Product'}` : ''}`}
      centered
      visible={visible}
      onCancel={() => onClose(false)}
      footer={[
        <div
          key="select-all-buttons"
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            marginRight: 'auto',
            columnGap: 8,
          }}
        >
          <Button
            type="primary"
            disabled={isResolvingDiscrepancies}
            onClick={onSelectAllMatrixValues}
          >
            Select all Matrix values
          </Button>
          <Button
            type="primary"
            disabled={isResolvingDiscrepancies}
            onClick={onSelectAllMondayValues}
          >
            Select all Monday values
          </Button>
        </div>,
        <Button
          key="cancel"
          type="secondary"
          onClick={onClose}
          disabled={isResolvingDiscrepancies}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isResolvingDiscrepancies}
          onClick={onSubmit}
        >
          {isResolvingDiscrepancies ? 'Resolving discrepancies...' : 'Resolve discrepancies'}
        </Button>,
      ]}
    >
      {visible ? (
        <div>
          {visible ? (
            <CustomTable
              id="discrepancies"
              pluralLabel="discrepancies"
              dataSource={tableData}
              disableControls
            >
              <Column
                title="Field"
                key="columnTitle"
                dataIndex="columnTitle"
              />
              <Column
                title="Matrix value"
                key="matrixValue"
                render={({
                  matrix: {
                    columnId,
                    value: matrixValue,
                  },
                  monday: {
                    value: mondayValue,
                  },
                }) => {
                  /**
                   * Attempt to evaluate a diff for the Matrix vs Monday values.
                   */
                  const difference = matrixValue !== null && mondayValue !== null ? (
                    diff.diffChars(mondayValue, matrixValue)
                  ) : null;

                  /**
                   * Determine whether the value is an image URL.
                   */
                  const isImageValue = ['tapBadgeUrl', 'webRenderUrl'].includes(columnId);

                  return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 24,
                    }}
                    >
                      {/* Visual difference */}
                      {difference !== null ? (
                        <ValueDifference difference={difference} />
                      ) : null}

                      <Radio.Button
                        checked={selections[columnId] === 'matrix'}
                        onChange={() => onSelectMatrixValue(columnId)}
                        style={{
                          borderRadius: 5,
                        }}
                        className={
                          `value-radio-button${
                            isImageValue && !!matrixValue ? (
                              ' image-value-radio-button'
                            ) : ''
                          }`
                        }
                      >
                        {!matrixValue ? (
                          <span style={{
                            fontStyle: 'italic',
                            opacity: 0.5,
                          }}
                          >
                            No value
                          </span>
                        ) : (
                          <>
                            {isImageValue ? (
                              <img
                                src={matrixValue}
                                alt="File in the Matrix"
                                style={{
                                  objectFit: 'contain',
                                  objectPosition: 'center',
                                  maxHeight: 100,
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                              />
                            ) : matrixValue}
                          </>
                        )}
                      </Radio.Button>
                    </div>
                  );
                }}
              />
              <Column
                title="Monday value"
                key="mondayValue"
                render={({
                  matrix: {
                    columnId,
                    value: matrixValue,
                  },
                  monday: {
                    value: mondayValue,
                  },
                }) => {
                  /**
                   * Attempt to evaluate a diff for the Matrix vs Monday values.
                   */
                  const difference = matrixValue !== null && mondayValue !== null ? (
                    diff.diffChars(matrixValue, mondayValue)
                  ) : null;

                  /**
                   * Determine whether the value is an image URL.
                   */
                  const isImageValue = ['tapBadgeUrl', 'webRenderUrl'].includes(columnId);

                  return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 24,
                    }}
                    >
                      {/* Visual difference */}
                      {difference !== null ? (
                        <ValueDifference difference={difference} />
                      ) : null}

                      <Radio.Button
                        className="value-radio-button"
                        checked={selections[columnId] === 'monday'}
                        onChange={() => onSelectMondayValue(columnId)}
                        style={{
                          borderRadius: 5,
                        }}
                      >
                        {!mondayValue ? (
                          <span style={{
                            fontStyle: 'italic',
                            opacity: 0.5,
                          }}
                          >
                            No value
                          </span>
                        ) : (
                          <>
                            {isImageValue ? (
                              <img src={mondayValue} alt="File in Monday" />
                            ) : mondayValue}
                          </>
                        )}
                      </Radio.Button>
                    </div>
                  );
                }}
              />
            </CustomTable>
          ) : null}
        </div>
      ) : null}
    </CustomModal>
  );
};

DiscrepanciesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  matrixId: PropTypes.number.isRequired,
  mondayId: PropTypes.string.isRequired,
  productName: PropTypes.string,
  discrepancies: PropTypes.arrayOf(PropTypes.shape({
    columnType: PropTypes.string,
    matrix: PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
    monday: PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  })),
};

DiscrepanciesModal.defaultProps = {
  productName: null,
  discrepancies: null,
};

export default DiscrepanciesModal;
