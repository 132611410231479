import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomerContactSelect.less';
import {
  Select, Spin, Divider, Button, Radio, Space, Input, Typography,
} from 'antd';
import debounce from 'lodash/debounce';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useStateRef from 'react-usestateref';
import { AuthContext } from 'util/Auth';
import axios from 'axios';
import * as api from 'util/api';

const { Text } = Typography;

// ================================================================
// TODO: Add store to api request when fetching customer contacts
// ================================================================

const EmptyContent = React.memo(({
  fetching,
  errorFetching,
  customerContacts,
}) => {
  if (fetching) {
    return (
      <Spin
        indicator={(
          <LoadingOutlined style={{ fontSize: 22 }} spin />
        )}
      />
    );
  }

  if (errorFetching) {
    return <div>Error loading customer contacts</div>;
  }

  if (customerContacts === null) {
    return <div>Start typing to search...</div>;
  }

  return <div>No exisiting customer contacts match your search</div>;
});

const CustomerContactSelect = ({
  storeIsNZ, onChange, value, showCurrentUserAsOption,
}) => {
  const { currentUser } = useContext(AuthContext);

  const [customerContacts, setCustomerContacts] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [errorFetching, setErrorFetching, errorFetchingRef] = useStateRef(false);
  // Search field value refers to the actual value in the field, this avoids
  // issues with async calls using different search terms
  const [searchFieldValue, setSearchFieldValue, searchFieldValueRef] = useStateRef(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  useEffect(() => {
    // If no value exists but the user is shown as an option, then
    // set the current user as selected by default
    if (!value && showCurrentUserAsOption) {
      onChange({
        id: null,
        contactName: currentUser.displayName,
        firstName: '', // TODO: Split name
        lastName: '',
        email: {
          value: currentUser.email,
          custom: false,
        },
      });
    }
  }, []);

  const fetchCustomerContacts = (searchTerm) => {
    if (fetching && cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    setFetching(true);
    setCustomerContacts(null);
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    api.getCustomerContacts(storeIsNZ ? 'NZ' : 'AU', searchTerm, newCancelTokenSource.token)
      .then(((data) => {
        if (searchFieldValueRef.current) {
          setCustomerContacts(
            data.map(({
              id, contactName, email,
            }) => ({
              label: contactName,
              value: id,
              email,
            })),
          );
          // Clear any previous errors
          if (errorFetchingRef.current) {
            setErrorFetching(false);
          }
        }
      }))
      .catch(() => {
        setErrorFetching(true);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const debounceFetchCustomerContacts = React.useCallback(debounce(fetchCustomerContacts, 300), []);

  const onSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm ? searchTerm.trim() : searchTerm;
    if (!trimmedSearchTerm) {
      debounceFetchCustomerContacts.cancel();
      setCustomerContacts(null);
    } else {
      debounceFetchCustomerContacts(trimmedSearchTerm);
    }
    setSearchFieldValue(trimmedSearchTerm);
  };

  const onCreateNewContact = () => {
    const contact = {
      label: searchFieldValue,
      value: null,
    };
    // Notify parent of change
    onChange({
      id: contact.value,
      contactName: contact.label,
      firstName: '',
      lastLame: '',
      email: {
        value: null,
        custom: true,
      },
    });
    setCustomerContacts([contact]);
    setTimeout(() => {
      // Manually close the select dropdown
      document.getElementsByClassName('customer-contact-select-dropdown')[0]
        .firstChild
        .querySelector('.rc-virtual-list')
        .querySelector('.rc-virtual-list-holder')
        .firstChild
        .querySelector('.rc-virtual-list-holder-inner')
        .querySelector('.ant-select-item')
        .click();
    }, 50);
  };

  const onSelect = (selection) => {
    // Notify parent of change if not a custom name
    // (since this would have already been performed in 'onCreateNewContact')
    if (selection.value) {
      const { email } = customerContacts.find((contact) => contact.value === selection.value);
      onChange({
        id: selection.value,
        contactName: selection.label,
        firstName: '',
        lastLame: '',
        email: {
          value: email || null,
          custom: !email,
        },
      });
    }
    setTimeout(() => {
      setCustomerContacts(null);
    }, 300);
  };

  const onRadioChange = (event) => {
    const radioValue = event.target.value;
    if (radioValue === 'currentUser') {
      onChange({
        id: null,
        contactName: currentUser?.displayName,
        firstName: '',
        lastLame: '',
        email: {
          value: currentUser?.email,
          custom: false,
        },
      });
    } else if (radioValue === 'other') {
      onChange(null);
    }
  };

  const currentUserSelected = currentUser?.displayName === value?.contactName;

  let radioValue;
  if (currentUserSelected) {
    radioValue = 'currentUser';
  } else {
    radioValue = 'other';
  }

  const disableCustomFields = showCurrentUserAsOption && radioValue !== 'other';

  return (
    <div className="customer-contact-select">
      {showCurrentUserAsOption && (
        <Radio.Group
          value={radioValue}
          onChange={onRadioChange}
        >
          <Space direction="vertical">
            <Radio value="currentUser">{`Me (${currentUser.displayName})`}</Radio>
            <Radio value="other">
              Someone else:
            </Radio>
          </Space>
        </Radio.Group>
      )}

      <div className="customer-contact-other">
        <Select
          labelInValue
          options={customerContacts}
          value={(value && !currentUserSelected) ? {
            label: value.contactName,
            value: value.id,
          } : undefined}
          disabled={disableCustomFields}
          onSelect={onSelect}
          showSearch
          filterOption={false}
          onSearch={onSearch}
          dropdownRender={(menu) => {
            // Don't show the add button if loading/no search term,
            // Or if there is a customer contact that exactly matches the search term
            if (customerContacts === null
          || customerContacts.find(({ label }) => (
            searchFieldValue && label.toUpperCase() === searchFieldValue.toUpperCase()
          ))
            ) {
              return menu;
            }
            return (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div>
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={onCreateNewContact}
                    // Xero API restricts name to a maximum length of 255 chars
                    disabled={searchFieldValue && searchFieldValue.length > 255}
                  >
                    {`Add ${searchFieldValue}`}
                  </Button>
                </div>
              </div>
            ); }}
          notFoundContent={(
            <EmptyContent
              fetching={fetching}
              errorFetching={errorFetching}
              customerContacts={customerContacts}
            />
          )}
        />

        {/* <Text>Contact person</Text>
        <Input
          disabled={disableCustomFields || !value?.contactName}
          value={value?.firstName}
        />
        <Input
          disabled={disableCustomFields || !value?.contactName}
          value={value?.firstName}
        /> */}
      </div>
    </div>
  );
};

CustomerContactSelect.propTypes = {
  storeIsNZ: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    contactName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    value: PropTypes.string,
    email: PropTypes.shape({
      value: PropTypes.string,
      custom: PropTypes.bool,
    }),
  }),
  showCurrentUserAsOption: PropTypes.bool,
};

CustomerContactSelect.defaultProps = {
  onChange: () => {},
  value: null,
  showCurrentUserAsOption: false,
};

export default CustomerContactSelect;
