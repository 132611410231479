import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './SignatureGeneratorModal.less';
import {
  Button, Form, Radio, Input, Checkbox, Select, message, Tooltip,
} from 'antd';
import { AuthContext } from 'util/Auth';
import { CustomModal, HoverHelp } from 'components';
import { CopyOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { copyElementToClipboard } from 'util/copy-to-clipboard';
import { CountryDetectionContext } from 'util/CountryDetection';
import Signature from './Signature';

const { useForm } = Form;

const { Option } = Select;

const nonCustomValues = [null, 'He / Him / His', 'She / Her / Hers', 'They / Them / Theirs'];

const PronounSelector = ({ value, onChange }) => {
  const [custom, setCustom] = useState(false);

  useEffect(() => {
    if (!nonCustomValues.includes(value)) {
      setCustom(true);
    }
  }, []);

  const onSelectChange = (selected) => {
    if (selected === 'custom') {
      setCustom(true);
      onChange('');
    } else {
      setCustom(false);
      if (selected === 'unspecified') {
        onChange(null);
      } else {
        onChange(selected);
      }
    }
    setCustom(selected === 'custom');
  };

  const selectValue = nonCustomValues.includes(value) ? value : 'custom';

  return (
    <div>
      <Select value={selectValue ?? 'unspecified'} onChange={onSelectChange}>
        <Option value="unspecified">Unspecified</Option>
        <Option value="He / Him / His">He / Him / His</Option>
        <Option value="She / Her / Hers">She / Her / Hers</Option>
        <Option value="They / Them / Theirs">They / Them / Theirs</Option>
        <Option value="custom">My pronouns are not listed</Option>
      </Select>
      { custom && (
        <Input
          id="custom-pronouns-field"
          placeholder="Enter your pronouns here..."
          value={value}
          disabled={!custom}
          onChange={onChange}
        />
      )}
    </div>
  );
};

const INITIAL_BRAND = 'GP';

const SignatureGeneratorModal = ({ open, onClose }) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (open && !modalVisible) {
      setModalVisible(true);
    }
  }, [open]);

  const [form] = useForm();

  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);
  const [signatureData, setSignatureData] = useState(null);

  const [brand, setBrand] = useState(INITIAL_BRAND);
  // Remove secondary Garage Project logo if GP is selected as brand
  useEffect(() => {
    const extras = form.getFieldValue('extras');
    if (extras.includes('garageProject')) {
      form.setFieldsValue({
        extras: extras.filter((value) => value !== 'garageProject'),
      });
    }
  }, [brand]);

  useEffect(() => {
    if (modalVisible && !formInitialised) {
      setFormInitialised(true);
    }
  }, [modalVisible]);

  // Track when the Cicerone Certification is enabled
  const [ciceroneEnabled, setCiceroneEnabled] = useState(false);
  const onExtrasChange = (values) => {
    if (!ciceroneEnabled && values.includes('ciceroneCertification')) {
      setCiceroneEnabled(true);
    } else if (ciceroneEnabled && !values.includes('ciceroneCertification')) {
      setCiceroneEnabled(false);
    }
  };

  // TODO: Allow dispaying how signature will look in dark email clients
  // const [previewDarkMode, setPreviewDarkMode] = useState(true);

  const detectedCountry = useContext(CountryDetectionContext);

  const { currentUser } = useContext(AuthContext);

  const handleClose = () => {
    setModalVisible(false);
    setTimeout(() => {
      onClose();
      form.resetFields();
      setSignatureData(null);
      setCiceroneEnabled(false);
      setBrand(INITIAL_BRAND);
    }, 500);
  };

  const handleSubmit = ({ extras, ciceroneCertification, ...values }) => {
    setSignatureData({
      ...values,
      // Add Cicerone certification level to extras based on sub-field
      extras: extras.includes('ciceroneCertification') ? (
        [
          ciceroneCertification,
          ...extras.filter((value) => value !== 'ciceroneCertification'),
        ]
      ) : extras,
    });
  };

  const onCopyClicked = () => {
    copyElementToClipboard({
      elementId: 'signature-container',
      inner: true,
    });
    message.success('Email signature copied to clipboard!');
  };

  return (
    <CustomModal
      width={500}
      title="Generate email signature"
      centered
      visible={modalVisible}
      onCancel={handleClose}
      maskClosable={false}
      forceRender // Allows form content to listen to close event
      footer={[
        ...(signatureData ? ([
          <Button
            type="secondary"
            onClick={() => setSignatureData(null)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>,
          <Button
            type="primary"
            icon={<CopyOutlined />}
            onClick={onCopyClicked}
          >
            Copy signature
          </Button>,
          <Button
            type="primary"
            icon={<QuestionCircleOutlined />}
            style={{ marginLeft: 8 }}
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/garage-project-matrix.appspot.com/o/hosting%2FHow%20to%20set%20your%20email%20signature.pdf?alt=media&token=53fee43f-9971-4d5a-b13f-0be67a06cd90"
          >
            How do I set it?
          </Button>,
        ]) : ([
          <Button
            type="primary"
            form="email-signature-generator-form"
            key="submit"
            htmlType="submit"
          >
            Next
          </Button>,
        ])),
      ]}
    >
      <div>
        {signatureData ? (
          // <div className={`signature-container ${previewDarkMode ? 'dark' : 'light'}`}>
          <div id="signature-container">
            <Signature {...signatureData} />
          </div>
        ) : (
          <Form
            id="email-signature-generator-form"
            name="email-signature-generator-form"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              name: currentUser.displayName,
              pronouns: null,
              email: currentUser.email,
              brand: INITIAL_BRAND,
              country: detectedCountry.country.code,
              extras: [],
              ciceroneCertification: null,
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'A name is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Job title"
              name="title"
              rules={[{ required: true, message: 'Please enter your job title' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pronouns"
              name="pronouns"
            >
              <PronounSelector />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phone"
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{
                required: true,
                message: 'An email address is required',
              }, {
                type: 'email',
                message: 'Please enter a valid email address',
              }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="brand"
              label="Which primary branding would you like to use?"
              labelCol={{ span: 24 }}
              rules={[{
                required: true,
                message: 'Please select an option',
              }]}
            >
              <Radio.Group onChange={(e) => setBrand(e.target.value)}>
                <Radio value="GP">Garage Project</Radio>
                <Radio value="DW">Dirty Water</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="country"
              label="Which webstore should your signature link to?"
              labelCol={{ span: 24 }}
              rules={[{
                required: true,
                message: 'Please select an option',
              }]}
            >
              <Radio.Group>
                <Radio value="NZ">New Zealand</Radio>
                <Radio value="AU">Australia</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="extras"
              label="Select any additional logos/badges/banners you would like to include"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Checkbox.Group className="vertical-checkbox-group" onChange={onExtrasChange}>
                <Tooltip title={brand === 'GP' ? (
                  'The Garage Project logo will already be included if Garage Project is the selected brand above.'
                ) : (
                  null
                )}
                >
                  <Checkbox value="garageProject" disabled={brand === 'GP'}>
                    Garage Project Logo (secondary)
                  </Checkbox>
                </Tooltip>
                <Checkbox value="pridePledge">
                  Pride Pledge
                </Checkbox>
                <Checkbox value="ekosZeroCarbon">
                  Ekos Zero Carbon Certified
                </Checkbox>
                <Checkbox value="sustainableBusinessNetwork">
                  Sustainable Business Network Member
                </Checkbox>
                <Checkbox value="millionMetresStreamsProject">
                  Million Metres Streams Project
                </Checkbox>
                <Checkbox value="livingWageEmployer">
                  Accredited Living Wage Employer
                </Checkbox>
                <Checkbox value="mentalHealthResponder">
                  CoLiberate Mental Health First Responder
                </Checkbox>
                <Checkbox value="auSalesPortal">
                  Australia Sales Portal Banner
                </Checkbox>
                <Checkbox value="ciceroneCertification">
                  Cicerone Certification
                </Checkbox>
                <div id="cicerone-certification-level-fields">
                  <Form.Item
                    name="ciceroneCertification"
                    wrapperCol={{ span: 24 }}
                    required={ciceroneEnabled}
                    rules={[() => ({
                      validator: (_, value) => {
                        // Explicit null check since value could be 0 (falsy value)
                        if (value === null && ciceroneEnabled) {
                          return Promise.reject(new Error('Please select the level of your Cicerone Certification'));
                        }
                        return Promise.resolve();
                      },
                    })]}
                  >
                    <Radio.Group disabled={!ciceroneEnabled}>
                      <Radio value="certifiedBeerServer">Certified Beer Server</Radio>
                      <Radio value="certifiedCicerone">Certified Cicerone®</Radio>
                      {/* <Radio value='advancedCicerone'>Advanced Cicerone®</Radio> */}
                      {/* <Radio value='masterCicerone'>Master Cicerone®</Radio> */}
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        )}
      </div>
    </CustomModal>
  );
};

SignatureGeneratorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignatureGeneratorModal;
