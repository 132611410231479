import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'util/Auth';
import { TableConfigProvider } from 'util/TableConfig';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'components';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMapsProvider } from 'util/GoogleMapsProvider';
import { CountryDetectionProvider } from 'util/CountryDetection';

import * as api from 'util/api';

import RouteHandler from 'pages/RouteHandler';
import './global.less';

// Initialise firebase
import './util/firebase';

// Initialise dayjs
import 'dayjs/locale/en-nz';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

dayjs.locale('en-nz');
dayjs.tz.setDefault('Pacific/Auckland');

const queryClient = new QueryClient();

export default () => (
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={api.reportError}>
    <QueryClientProvider client={queryClient}>
      <GoogleMapsProvider>
        <Router>
          <CountryDetectionProvider>
            <AuthProvider>
              <TableConfigProvider>
                <RouteHandler />
              </TableConfigProvider>
            </AuthProvider>
          </CountryDetectionProvider>
        </Router>
      </GoogleMapsProvider>
    </QueryClientProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);
