/**
 * name (key): The field key
 * labels: Used to reference the instance in various locations.
 *    - field: The label to display for the field.
 *    - inline: The label to display when referring to the field in a sentence.
 * determiner: The word to prefix the inline label with when using in a sentence.
 * verbOverrides: Used to override verbs indicating changes to the field.
 *    - set: The verb used to refer to a field being set for the first time.
 *    - changed: The verb used to refer to a field changing value.
 * valuesUnreadable: Boolean value to indicate whether the value is suitable to use in a sentence.
 * identifier: Boolean value to indicate whether the property is the primary linguistic identifier
 */
export const productProperties = {
  name: {
    labels: {
      field: 'Name',
      inline: 'name',
    },
    determiner: 'the',
    identifier: true,
  },
  productType: {
    labels: {
      field: 'Type',
      inline: 'type',
    },
    determiner: 'the',
  },
  abvIsRange: {
    labels: {
      field: 'Is the ABV a range? (i.e. for a pack)',
      inline: 'ABV type',
    },
    determiner: '',
  },
  abv: {
    labels: {
      field: 'ABV',
      inline: 'ABV',
    },
    determiner: 'the',
  },
  abvMin: {
    labels: {
      field: 'Minimum ABV',
      inline: 'minimum ABV',
    },
    determiner: 'the',
  },
  abvMax: {
    labels: {
      field: 'Maximum ABV',
      inline: 'maximum ABV',
    },
    determiner: 'the',
  },
  oneLiner: {
    labels: {
      field: 'One liner',
      inline: 'one liner',
    },
    determiner: 'the',
  },
  // callOut: {
  //   labels: {
  //     field: 'Call out',
  //     inline: 'call out',
  //   },
  //   determiner: 'the',
  // },
  blurb: {
    labels: {
      field: 'Blurb',
      inline: 'blurb',
    },
    determiner: 'the',
  },
  dearSku: {
    labels: {
      field: 'Dear SKU',
      inline: 'Dear SKU',
    },
    determiner: 'the',
  },
  foodstuffsArticleNumberNorthIsland: {
    labels: {
      field: 'Article Number (North Island)',
      inline: 'North Island Foodstuffs article number',
    },
    determiner: 'the',
  },
  foodstuffsArticleNumberSouthIsland: {
    labels: {
      field: 'Article Number (South Island)',
      inline: 'South Island Foodstuffs article number',
    },
    determiner: 'the',
  },
  rrp: {
    labels: {
      field: 'RRP - New Zealand',
      inline: 'NZ RRP',
    },
    determiner: 'the',
  },
  rrpAud: {
    labels: {
      field: 'RRP - Australia',
      inline: 'AU RRP',
    },
    determiner: 'the',
  },
  pricingNotes: {
    labels: {
      field: 'Pricing Notes',
      inline: 'pricing notes',
    },
    determiner: 'the',
  },
  dateAdded: {
    labels: {
      field: 'Added',
      inline: 'added date',
    },
    determiner: 'the',
  },
  dateReleased: {
    labels: {
      field: 'Released',
      inline: 'release date',
    },
    determiner: 'the',
    // Format for displaying date depends on whether release month
    // is unknown, so it's easier to avoid displaying the date
    valuesUnreadable: true,
  },
  releaseMonthUnknown: {
    labels: {
      field: 'Release month unknown',
      inline: 'release month unknown',
    },
    determiner: '',
  },
  dateEdited: {
    labels: {
      field: 'Last edited',
      inline: 'date last edited',
    },
    determiner: 'the',
  },
  dateBrewed: {
    labels: {
      field: 'Brewed',
      inline: 'brewed date',
    },
    determiner: 'the',
  },
  datePacked: {
    labels: {
      field: 'Packed',
      inline: 'packed date',
    },
    determiner: 'the',
  },
  complete: {
    labels: {
      field: 'Mark product as complete',
      inline: 'completion status',
    },
    determiner: 'the',
  },
  creativeReady: {
    labels: {
      field: 'Creative requirements ready',
      inline: 'creative requirements ready',
    },
    determiner: '',
  },
  salesReady: {
    labels: {
      field: 'Sales requirements ready',
      inline: 'sales requirements ready',
    },
    determiner: '',
  },
  productionReady: {
    labels: {
      field: 'Production requirements ready',
      inline: 'production requirements ready',
    },
    determiner: '',
  },
  webRenderUrl: {
    labels: {
      field: 'Web render',
      inline: 'web render image',
    },
    determiner: 'the',
    verbOverrides: {
      set: 'uploaded',
      changed: 'replaced',
      cleared: 'removed',
    },
    valuesUnreadable: true, // Displaying the URL would be too verbose
  },
  tapBadgeUrl: {
    labels: {
      field: 'Tap badge',
      inline: 'tap badge image',
    },
    determiner: 'the',
    verbOverrides: {
      set: 'uploaded',
      changed: 'replaced',
      cleared: 'removed',
    },
    valuesUnreadable: true, // Displaying the URL would be too verbose
  },
  // TODO: Pricing fields
  // TODO: Barcode fields
  malts: {
    labels: {
      field: 'Malts',
      inline: 'malts',
    },
    determiner: 'the',
  },
  hops: {
    labels: {
      field: 'Hops',
      inline: 'hops',
    },
    determiner: 'the',
  },
  tastingNotes: {
    labels: {
      field: 'Tasting notes',
      inline: 'tasting notes',
    },
    determiner: 'the',
  },
  story: {
    labels: {
      field: 'Story',
      inline: 'story',
    },
    determiner: 'the',
  },
  style: {
    labels: {
      field: 'Style',
      inline: 'style',
    },
    determiner: 'the',
  },
  artist: {
    labels: {
      field: 'Artist',
      inline: 'artist',
    },
    determiner: 'the',
  },
  accolades: {
    labels: {
      field: 'Accolades',
      inline: 'accolades',
    },
    determiner: 'the',
  },
  brewSize: {
    labels: {
      field: 'Brew size',
      inline: 'brew size',
    },
    determiner: 'the',
  },
  channelsNz: {
    labels: {
      field: 'NZ Channels',
      inline: 'NZ channels',
    },
    determiner: '',
  },
  channelsAu: {
    labels: {
      field: 'AU Channels',
      inline: 'AU channels',
    },
    determiner: '',
  },
  eventsAndMarketing: {
    labels: {
      field: 'Events and marketing',
      inline: 'events and marketing',
    },
    determiner: '',
  },
  other: {
    labels: {
      field: 'Other',
      inline: 'other',
    },
    determiner: '',
  },
};

export const briefProperties = {
  // Creative
  name: {
    labels: {
      field: 'Tentative product name',
      inline: 'name',
    },
    determiner: 'the',
    identifier: true,
  },
  purpose: {
    labels: {
      field: 'What is the product and its purpose?',
      inline: 'name',
    },
    determiner: 'the',
    identifier: true,
  },
  blurb: {
    labels: {
      field: 'Blurb',
      inline: 'blurb',
    },
    determiner: 'the',
    identifier: true,
  },
  oneLiner: {
    labels: {
      field: 'One liner',
      inline: 'one liner',
    },
    determiner: 'the',
    identifier: true,
  },
  productType: {
    labels: {
      field: 'Product type',
      inline: 'product type',
    },
    determiner: 'the',
  },
  style: {
    labels: {
      field: 'Style',
      inline: 'style',
    },
    determiner: 'the',
  },
  tags: {
    labels: {
      field: 'Tags',
      inline: 'tags',
    },
    determiner: 'the',
  },
  contractedArtist: {
    labels: {
      field: 'Contracted artist',
      inline: 'contracted artist',
    },
    determiner: 'the',
  },
  artistBrief: {
    labels: {
      field: 'Artist brief',
      inline: 'artist brief',
    },
    determiner: 'the',
  },
  artDueDate: {
    labels: {
      field: 'Completed art due date',
      inline: 'completed art due date',
    },
    determiner: 'the',
  },
  baseUnitType: {
    labels: {
      field: 'Base unit type',
      inline: 'base unit type',
    },
    determiner: 'the',
  },
  baseUnitSize: {
    labels: {
      field: 'Base unit size',
      inline: 'base unit size',
    },
    determiner: 'the',
  },
  baseUnitColour: { // Only applies to cans
    labels: {
      field: 'Base unit colour',
      inline: 'base unit colour',
    },
    determiner: 'the',
  },
  // Pre production
  initialBrewDate: {
    labels: {
      field: 'Initial brew date',
      inline: 'initial brew date',
    },
    determiner: 'the',
  },
  packingDate: {
    labels: {
      field: 'Packing date',
      inline: 'packing date',
    },
    determiner: 'the',
  },
  qcCompletionDate: {
    labels: {
      field: 'QC completion date',
      inline: 'QC completion date',
    },
    determiner: 'the',
  },
  recipeLink: {
    labels: {
      field: 'Recipe link',
      inline: 'recipe link',
    },
    determiner: 'the',
  },
  sourcingDetails: {
    labels: {
      field: 'Raw ingredient sourcing and pricing',
      inline: 'sourcing details',
    },
    determiner: 'the',
  },
  // Sales
  idealReleaseDate: {
    labels: {
      field: 'Ideal release date',
      inline: 'ideal release date',
    },
    determiner: 'the',
  },
  socialsReleaseDate: {
    labels: {
      field: 'Social media release date',
      inline: 'social media release date',
    },
    determiner: 'the',
  },
  initialBrewSize: {
    labels: {
      field: 'Initial brew size',
      inline: 'initial brew size',
    },
    determiner: 'the',
  },
  contingencyPlan: {
    labels: {
      field: 'Contingency plan',
      inline: 'contingency plan',
    },
    determiner: 'the',
  },
  salesChannels: {
    labels: {
      field: 'Sales channels',
      inline: 'sales channels',
    },
    determiner: 'the',
  },
  // rrpMinNzd: {
  //   labels: {
  //     field: 'AU RRP min',
  //     inline: 'minimum NZ RRP price',
  //   },
  //   determiner: 'the',
  // },
  // rrpMaxNzd: {
  //   labels: {
  //     field: 'AU RRP max',
  //     inline: 'maximum NZ RRP price',
  //   },
  //   determiner: 'the',
  // },
  // rrpMinAud: {
  //   labels: {
  //     field: 'AU RRP min',
  //     inline: 'minimum AU RRP price',
  //   },
  //   determiner: 'the',
  // },
  // rrpMaxAud: {
  //   labels: {
  //     field: 'AU RRP max',
  //     inline: 'maximum AU RRP price',
  //   },
  //   determiner: 'the',
  // },
  packagingNeeds: {
    labels: {
      field: 'Packaging needs',
      inline: 'packaging needs',
    },
    determiner: 'the',
  },
  // General
  dateEdited: {
    labels: {
      field: 'Last edited',
      inline: 'date last edited',
    },
    determiner: 'the',
  },
  dateCreated: {
    labels: {
      field: 'Created',
      inline: 'created date',
    },
    determiner: 'the',
  },
};
