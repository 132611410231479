import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Image } from 'antd';
import './FullPageMessage.less';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const FullPageMessage = ({
  title, content, image, icon: Icon, imageShadow, imageHeight, buttonLabel, linkTo, onClick,
}) => {
  const button = (
    <Button
      className="return-button"
      type="primary"
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
  return (
    <div className="full-page-message">
      {image && (
        <Image
          className={`message-image${imageShadow ? ' shadowed-image' : ''}`}
          style={{
            height: imageHeight,
            minHeight: imageHeight,
          }}
          preview={false}
          src={image}
          alt={title}
        />
      )}
      {Icon && (
        <div style={{ marginBottom: 16 }}>
          <Icon
            style={{
              fontSize: imageHeight,
            }}
          />
        </div>
      )}
      <Title level={2}>{title}</Title>
      <div className="message-content">
        <Text>{content}</Text>
      </div>
      {linkTo ? (
        <Link to={linkTo}>
          {button}
        </Link>
      ) : button}
    </div>
  );
};

FullPageMessage.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  image: PropTypes.string,
  imageShadow: PropTypes.bool,
  imageHeight: PropTypes.number,
  buttonLabel: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

FullPageMessage.defaultProps = {
  image: null,
  imageShadow: false,
  imageHeight: 150,
  linkTo: null,
  onClick: null,
};

export default FullPageMessage;
