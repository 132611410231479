import React from 'react';
import PropTypes from 'prop-types';
import './ProductTagSelector.less';
import {
  TreeSelect, Tooltip,
} from 'antd';

import productCategories from 'config/productCategories';

const ProductTagSelector = ({ value, type, onChange }) => {
  const tagDataTree = type ? productCategories.tags[type].dataTree : [];
  return (
    <Tooltip
      title={!type ? 'Please specify a product type first' : null}
      placement="bottom"
    >
      <TreeSelect
        disabled={!type}
        style={{ width: '100%' }}
        value={value || []}
        treeData={tagDataTree}
        multiple
        treeCheckable
        onChange={onChange}
      />
    </Tooltip>
  );
};

ProductTagSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(Object.keys(productCategories.tags)),
};

ProductTagSelector.defaultProps = {
  value: null,
  type: null,
};

export default ProductTagSelector;
