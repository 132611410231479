import axios from 'axios';
import firebase from 'firebase';
import dayjs from 'dayjs';
import { notification } from 'antd';

const showNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

const baseURL = process.env.REACT_APP_NODE_ENV === 'development' ? (
  'http://localhost:8080'
) : (
  'https://morpheus-gateway-apa76zgs.ts.gateway.dev'
);

const client = axios.create({
  baseURL,
  json: true,
  headers: {
    'X-Api-Key': 'AIzaSyDRJEGhXFnZeV2zu1aUXm2Soc-JmAR358U',
  },
});

// Intercept outgoing requests to include auth token and decamelize
client.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    if (firebase.auth().currentUser) {
      newConfig.headers = {
        ...newConfig.headers,
        'X-Auth-Token': await firebase.auth().currentUser.getIdToken(true),
      };
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

// Get all briefs
export const getBriefs = () => client({
  method: 'GET',
  url: '/products/briefs',
})
  .then((response) => response.data)
  .catch(() => {
    showNotification('error', 'Error loading briefs');
  });

// Create a new brief
export const createBrief = ({ data }) => client({
  method: 'POST',
  url: '/products/briefs',
  data,
}).then((response) => response.data);

// Get a brief by ID
export const getBrief = (id) => client({
  method: 'GET',
  url: `/products/briefs/${id}`,
})
  .then(({ data: brief }) => brief)
  .catch(() => {
    showNotification('error', 'Error loading brief');
  });

// Update an existing brief
export const updateBrief = ({
  id,
  data,
  sessionId = null,
}) => client({
  method: 'PUT',
  url: `/products/briefs/${id}`,
  params: {
    sessionId,
  },
  data,
})
  .then((response) => response.data)
  .catch((error) => Promise.reject(error));

// Delete a brief
export const deleteBrief = (id) => client({
  method: 'DELETE',
  url: `/products/briefs/${id}`,
}).then(() => {
  showNotification('success', 'Brief deleted!');
}).catch(() => {
  showNotification('error', 'Error deleting brief');
});

// Generate packing labels for a product
export const generatePackingLabels = ({
  type, productId, product, formData,
}) => client({
  method: 'POST',
  url: `/products/${productId}/packing-labels`,
  params: { type },
  data: {
    product,
    ...formData,
    date: dayjs(formData.date).startOf('day').format(), // Convert from dayjs to string
  },
}).then(({ data }) => {
  const { pdf } = data;
  return pdf;
}).catch((error) => {
  showNotification('error', 'Error generating labels');
  return Promise.reject(error);
});
