import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './ReviewWebstoreRequests.less';
import * as api from 'util/api.js';
import {
  UserOutlined, CheckOutlined, CloseOutlined, PhoneOutlined, MailOutlined, ReloadOutlined,
} from '@ant-design/icons';
import { PageTabsContext } from 'util/PageTabs';
import {
  Tag, Space, Button, Select, Avatar, Table as AntTable, Typography, Popover, Tooltip, Tabs,
} from 'antd';
import { CustomTable } from 'components';
import { WEBSTORE_REQUEST_TAGS } from 'config/tags';
import { useMediaQuery } from 'react-responsive';
import STORES from 'config/stores';
import { capitalCase } from 'change-case';
import { Link } from 'react-router-dom';

import WebstoreProductList from '../WebstoreProductList/WebstoreProductList';

const { Column } = AntTable;
const { Option } = Select;

const ReviewWebstoreRequests = ({ defaultStore }) => {
  const [store, setStore] = useState(defaultStore);
  const storeIsNZ = [STORES.GPNZ, STORES.DWNZ].includes(store);

  // Used to store the list of all requests
  const [requests, setRequests] = useState();

  const [approving, setApproving] = useState([]);
  const [rejecting, setRejecting] = useState([]);

  const fixActions = useMediaQuery({
    query: '(min-width: 576px)', // TODO: Change to sm breakpoint
  });

  // TODO: Disable tabs while loading
  const status = useContext(PageTabsContext);

  // TODO: Save using table config provider
  // TODO: Paginate API response
  useEffect(() => {
    if (requests || status) {
      setRequests(null);
      api.getShopifyProductRequests(store, status).then(setRequests);
    }
  }, [store, status]);

  // Clear requests when the status changes
  const onApprove = (requestId) => {
    const approveRequest = () => {
      setApproving((current) => [...current, requestId]);
      api.approveShopifyProductRequest(requestId)
        .then(() => {
          setRequests((current) => {
            const newRequests = [...current];
            newRequests[current.findIndex(({ id }) => id === requestId)].status = 'approved';
            return newRequests;
          });
        })
        .finally(() => {
          setApproving((current) => current.filter((id) => id !== requestId));
        });
    };
    approveRequest();
  };

  const onReject = (requestId) => {
    setRejecting((current) => [...current, requestId]);
    api.rejectShopifyProductRequest(requestId)
      .then(() => {
        setRequests((current) => {
          const newRequests = [...current];
          newRequests[current.findIndex(({ id }) => id === requestId)].status = 'rejected';
          return newRequests;
        });
      })
      .finally(() => {
        setRejecting((current) => current.filter((id) => id !== requestId));
      });
  };

  const loadingRequests = !status || requests === null;

  return (
    <>
      <CustomTable
        className="webstore-requests-table"
        id={`review-${status}-webstore-requests-${store}`}
        pluralLabel="requests"
        searchableColumns={[['user', 'name'], 'type', 'reason']}
        cacheData={false}
        dataSource={requests?.map((request) => (
          {
            ...request,
            key: request.id, // Each row must have a unique key
          }
        ))}
        // TODO: Replace empty text for other tables
        locale={{ emptyText: loadingRequests ? <div style={{ height: 150 }} /> : `No ${status} requests found` }}
        controlsPlacement="left"
        controls={(
          <Select
            defaultValue={defaultStore}
            style={{ width: 180 }}
            value={store}
            onChange={(newStore) => setStore(STORES[newStore])}
            disabled={loadingRequests}
          >
            <Option value={STORES.GPNZ}>Garage Project NZ</Option>
            <Option value={STORES.GPAU}>Garage Project AU</Option>
          </Select>
        )}
      >
        <Column
          title={null}
          dataIndex={['user', 'profilePhotoUrl']}
          key="photo"
          width={64}
          className="avatar-column"
          render={(photoURL) => (
            <Avatar
              icon={<UserOutlined />}
              src={photoURL}
            />
          )}
        />
        <Column
          title="Requested by"
          dataIndex={['user', 'name']}
          key="name"
          sortType="string"
        />
        <Column
          title="Products"
          dataIndex="products"
          key="products"
          render={(products) => (
            <WebstoreProductList
              bordered
              products={products}
              compact
              expandable
              showRRP={false}
              storeIsNZ={storeIsNZ}
            />
          )}
        />
        <Column
          title="Status"
          key="status"
          render={(request) => (
            <Tag color={WEBSTORE_REQUEST_TAGS[request.status]}>
              {capitalCase(request.status)}
            </Tag>
          )}
        />
        {status === 'approved' ? (
          <Column
            title="Order"
            dataIndex="order"
            key="order"
            render={(order) => ((order && order.url && order.reference) ? (
              <Link
                to={{ pathname: order.url }}
                target="_blank"
              >
                {order.reference}
              </Link>
            ) : null)}
          />
        ) : <></>}
        {/* TODO
        {status === 'approved' ? (
          <Column
            title="Date approved"
            dataIndex="dateApproved"
            key="dateApproved"
            sortType="date"
            defaultSortOrder="ascend"
            render={(value) => value.format('D MMM YYYY h:mma')}
          />
        ) : <></>} */}

        {/* TODO: Change sort order when changing tabs, but still allow manual sort toggling */}
        <Column
          title="Date requested"
          dataIndex="dateRequested"
          key="dateRequested"
          sortType="date"
          defaultSortOrder={['approved', 'rejected'].includes(status) ? 'descend' : 'ascend'}
          sortOrder={['approved', 'rejected'].includes(status) ? 'descend' : 'ascend'}
          showSorterTooltip={false}
          render={(value) => value.format('D MMM YYYY h:mma')}
        />
        <Column
          title="Request type"
          dataIndex="type"
          key="type"
          render={(type) => capitalCase(type)}
        />
        <Column
          title="Reason"
          dataIndex="reason"
          key="reason"
          style={{ minWidth: 100 }}
        />
        <Column
          title="Bill to"
          dataIndex={['billing', 'contactName']}
          key="billing"
          style={{ minWidth: 100 }}
        />
        <Column
          title="Requires shipping"
          key="ship"
          render={({
            ship,
            shippingAddress,
            // TODO: Investigate how ship can be true without shipping address
          }) => (ship && shippingAddress ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Yes</span>
              <Popover
                placement="bottom"
                content={(
                  // TODO: Add 'and x more items' to avoid long lists
                  <ul className="unstyled-list">
                    <li>{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</li>
                    {shippingAddress.company && <li>{shippingAddress.company}</li>}
                    <li>{shippingAddress.address1}</li>
                    {shippingAddress.address2 && <li>{shippingAddress.address2}</li>}
                    <li>{shippingAddress.city}</li>
                    <li>{`${shippingAddress.province}, ${shippingAddress.zip}`}</li>
                    <li>{shippingAddress.country}</li>
                    <li>
                      <a href={`mailto:${shippingAddress.phone}`}>
                        <PhoneOutlined style={{ marginRight: 5 }} />
                        {shippingAddress.phone}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${shippingAddress.email}`}>
                        <MailOutlined style={{ marginRight: 5 }} />
                        {shippingAddress.email}
                      </a>
                    </li>
                  </ul>
                )}
              >
                <Button
                  type="link"
                  className="inline-link-button"
                >
                  View shipping details
                </Button>
              </Popover>
            </div>
          ) : 'No')}
        />
        {['pending', 'failed'].includes(status) ? (
          <Column
            className="actions-column"
            title=""
            key="action"
            fixed={fixActions ? 'right' : false}
            render={(request) => {
              const disabled = (
                approving.includes(request.id) || rejecting.includes(request.id) || !['pending', 'failed'].includes(request.status)
              );
              return (
                <Space size="middle">
                  <>
                    <Button
                      type="link"
                      icon={status === 'pending' ? <CheckOutlined /> : <ReloadOutlined />}
                      onClick={() => onApprove(request.id)}
                      loading={approving.includes(request.id)}
                      disabled={disabled}
                    >
                      {status === 'pending' ? 'Approve' : 'Retry approval'}
                    </Button>
                    {status === 'pending' && (
                      <Button
                        type="link"
                        danger
                        icon={<CloseOutlined />}
                        onClick={() => onReject(request.id)}
                        loading={rejecting.includes(request.id)}
                        disabled={disabled}
                      >
                        Reject
                      </Button>
                    )}
                  </>
                </Space>
              );
            }}
          />
        ) : <></>}
      </CustomTable>
    </>
  );
};

ReviewWebstoreRequests.propTypes = {
  // defaultStore: PropTypes.oneOf(['GPNZ', 'GPAU', 'DWNZ', 'DWAU']).isRequired,
};

export default ReviewWebstoreRequests;
