import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './UsersTable.less';
import * as api from 'util/api.js';
import { AuthContext } from 'util/Auth';
import {
  Button, Modal, Switch, Form, Tooltip, Avatar, Table as AntTable, Popover, message, Typography,
  Menu, Dropdown,
} from 'antd';
import {
  UserOutlined, ExclamationCircleOutlined, CopyOutlined, MailOutlined,
  DownOutlined, SelectOutlined,
} from '@ant-design/icons';
import { CustomTable, CustomModal } from 'components';
import { copyTextToClipboard } from 'util/copy-to-clipboard';
import googleLogo from 'assets/google-g-logo.svg';

import dayjs from 'dayjs';

import UserEditor from '../UserEditor/UserEditor';

const { confirm } = Modal;

const Text = Typography;

const { Column } = AntTable;

const UsersTable = ({ userType }) => {
  // Used to store the list of all users
  const [users, setUsers] = useState();

  // Used to store the object of the external user currently being edited
  const [editUser, setEditUser] = useState(null);

  // Used to store the object of the user currently being mnanaged
  const [manageUserPermissions, setManageUserPermissions] = useState(null);

  const [updatingAdminStatus, setUpdatingAdminStatus] = useState(false);
  const [errorUpdatingAdminStatus, setErrorUpdatingAdminStatus] = useState(false);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    api.getUsers().then(setUsers);
  }, []);

  const setAdminStatus = (userId, admin) => {
    setUpdatingAdminStatus(true);
    api.setAdminStatus(userId, admin)
      .then(() => {
        setManageUserPermissions({ ...manageUserPermissions, admin });
        // If there was previously an error, clear it
        if (errorUpdatingAdminStatus) {
          setErrorUpdatingAdminStatus(false);
        }
        // Update the user in the array to reflect change in table
        const newUsers = [...users];
        newUsers[users.findIndex((item) => item.uid === userId)].admin = admin;
        setUsers(newUsers);
      })
      .catch(() => setErrorUpdatingAdminStatus(true))
      .finally(() => {
        setUpdatingAdminStatus(false);
      });
  };

  const onDeleteUser = (userToDelete) => {
    Modal.confirm({
      title: `Are you sure you want to delete the user account for ${userToDelete.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteUser(userToDelete.uid)
        .then(() => {
          setUsers(users.filter((user) => (
            user.uid !== userToDelete.uid
          )));
        }).catch(),
    });
  };

  const onUserEditorClosed = (updatedUser) => {
    if (editUser) {
      setEditUser(null);
    }
    if (updatedUser) {
      if (users) {
        const newData = users.filter((user) => user.uid !== updatedUser.uid);
        setUsers([
          ...newData,
          updatedUser,
        ]);
      }
    }
  };

  const onManagePermissionsClicked = (user) => {
    setManageUserPermissions(user);
  };

  const closeManagePermissions = () => {
    setManageUserPermissions(null);
    // Reset any errors.
    setErrorUpdatingAdminStatus(false);
  };

  const loadingUsers = !users || !userType;

  return (
    <div id="users-table-container">
      <CustomTable
        id={`${userType}-users-table`}
        pluralLabel={`${userType} users`}
        searchableColumns={['uid', 'name', 'organisation']}
        dataSource={(userType === 'external' ? (
          users?.filter((user) => user.external)
        ) : (
          users?.filter((user) => !user.external)
        ))?.map((user) => (
          {
            ...user,
            key: user.uid, // Each row must have a unique key
          }
        ))}
        locale={{ emptyText: loadingUsers ? <div style={{ height: 150 }} /> : 'No users found' }}
        controls={(
          <UserEditor
            existingUser={editUser}
            onClose={onUserEditorClosed}
            disableButton={!users}
            external={userType === 'external'}
          />
        )}
      >
        {userType === 'staff' ? (
          <Column
            title={null}
            dataIndex="photoURL"
            key="photo"
            width={64}
            className="avatar-column"
            render={(photoURL) => (
              <Avatar
                icon={<UserOutlined />}
                src={photoURL}
              />
            )}
          />
        ) : <></>}
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          sortType="string"
          defaultSortOrder="ascend"
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
        />
        <Column
          title="Provider"
          dataIndex="provider"
          key="provider"
          render={(provider) => (
            <div className="user-account-provider">
              <div className="user-account-provider-icon">
                {provider === 'google.com' && <img src={googleLogo} alt="google-g-logo" />}
                {provider === 'password' && <MailOutlined />}
              </div>
              <Text>
                {provider === 'google.com' && 'Google Workspace'}
                {provider === 'password' && 'Email/Password'}
              </Text>
            </div>
          )}
        />

        <Column
          title="ID"
          dataIndex="uid"
          key="uid"
          render={(uid) => (
            <Popover placement="bottom" content={uid}>
              <Button
                className="inline-link-button"
                type="link"
                icon={<CopyOutlined />}
                onClick={() => {
                  copyTextToClipboard(uid);
                  message.success('User ID copied to clipboard!');
                }}
              >
                Copy
              </Button>
            </Popover>
          )}
        />
        {userType === 'external' ? (
          <Column
            title="Organisation"
            dataIndex="organisation"
            key="organisation"
            sortType="string"
          />
        ) : <></>}
        <Column
          title="Last signed in"
          dataIndex="lastSignInTime"
          key="lastSignInTime"
          sortType="date"
          render={(value) => (dayjs.isDayjs(value) ? value.format('ddd, D MMM YYYY h:mma') : value)}
        />

        <Column
          className="actions-column"
          title=""
          key="action"
          render={(user) => {
            const rowIsCurrentUser = user.uid === currentUser.uid;
            const isGoogleWorkspaceUser = user.provider === 'google.com';
            return (
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item
                      key="edit-details"
                      onClick={() => {
                        if (isGoogleWorkspaceUser) {
                          confirm({
                            icon: null,
                            content: 'Staff user accounts that log into the Matrix with Garage Project email addresses must be managed through the Google Workspace admin console.',
                            centered: true,
                            cancelText: 'Close',
                            okText: (
                              <span>
                                Go to admin console
                                <SelectOutlined style={{
                                  marginLeft: 5,
                                  transform: 'scaleX(-1)',
                                }}
                                />
                              </span>
                            ),
                            onOk: () => window.open('https://admin.google.com/u/0/ac/users', '_blank'),
                          });
                        } else {
                          setEditUser(user);
                        }
                      }}
                    >
                      Edit details
                    </Menu.Item>
                    <Menu.Item
                      key="manage-permissions"
                      disabled={rowIsCurrentUser}
                      onClick={() => onManagePermissionsClicked(user)}
                    >
                      <Tooltip title={rowIsCurrentUser ? "You can't change your own permissions" : null}>
                        Edit permissions
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      key="delete"
                      danger
                      disabled={rowIsCurrentUser}
                      onClick={() => onDeleteUser(user)}
                    >
                      <Tooltip title={rowIsCurrentUser ? "You can't delete your own account" : null}>
                        Delete
                      </Tooltip>
                    </Menu.Item>
                  </Menu>
              )}
                trigger={['click']}
              >
                <Button type="link" onClick={(e) => e.preventDefault()}>
                  Manage user
                  {' '}
                  <DownOutlined />
                </Button>
              </Dropdown>
            );
          }}
        />
      </CustomTable>
      <CustomModal
        centered
        title={manageUserPermissions?.name}
        visible={!!manageUserPermissions}
        onCancel={closeManagePermissions}
        footer={[
          <Button
            key="done"
            type="primary"
            onClick={closeManagePermissions}
          >
            Done
          </Button>,
        ]}
      >
        <Form>
          <Form.Item
            label="Set user as admin"
            name="admin"
            colon={false}
            validateStatus={errorUpdatingAdminStatus ? 'error' : 'success'}
            help={errorUpdatingAdminStatus ? 'An error occurred, could not update admin status.' : null}
          >
            <Switch
              id="admin-toggle-switch"
              checked={manageUserPermissions?.admin}
              onChange={() => (
                setAdminStatus(manageUserPermissions?.uid, !manageUserPermissions?.admin)
              )}
              loading={updatingAdminStatus}
            />
          </Form.Item>
        </Form>
      </CustomModal>
    </div>
  );
};

UsersTable.propTypes = {
  userType: PropTypes.oneOf(['staff', 'external']),
};
UsersTable.defaultProps = {
  userType: null,
};

export default UsersTable;
