import React, { useState } from 'react';
import './ShippingRateCard.less';
import {
  Button, Card, Typography, Tooltip, Switch,
} from 'antd';
import { GoogleMap, HoverHelp, CustomModal } from 'components';
import { Polygon } from '@react-google-maps/api';
import {
  CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import arrayToSentence from 'array-to-sentence';
import { headerCase } from 'change-case';
import countryCodeLookup from 'country-code-lookup';
import dayjs from 'dayjs';

const { Text } = Typography;

const { Meta } = Card;

const MapAreaCondition = ({ title, points }) => {
  const [mapOpen, setMapOpen] = useState(false);
  const [polygon, setPolygon] = useState(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  // const onToggleEdit = () => {
  //   setEditing((current) => !current);
  // };

  // const onSave = () => {
  //   setSaving(true);
  //   console.log('polygon', polygon);
  //   // TODO: Save polygon points
  // };

  const path = points.map(([lng, lat]) => ({ lat, lng }));

  const focusArea = (map) => {
    const latPoints = path.map(({ lat }) => lat);
    const lngPoints = path.map(({ lng }) => lng);

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: Math.min(...latPoints), lng: Math.min(...lngPoints) });
    bounds.extend({ lat: Math.max(...latPoints), lng: Math.max(...lngPoints) });
    map.fitBounds(bounds);
  };

  return (
    <>
      <CustomModal
        className="map-area-modal"
        title={title}
        centered
        maskClosable={false}
        closable={!saving}
        visible={mapOpen}
        onCancel={() => setMapOpen(false)}
        footer={[
          // <Form
          //   key="edit"
          //   className="map-area-edit-form"
          //   name="map-area-edit-form"
          //   onFinish={onSave}
          // >
          //   <Form.Item
          //     label="Enable editing"
          //     name="enable"
          //     colon={false}
          //   >
          //     <Tooltip title="Coming soon">
          //       <Switch
          //         checked={editing}
          //         onChange={onToggleEdit}
          //       // disabled={saving} // Uncomment when implemented
          //         disabled
          //       />
          //     </Tooltip>
          //   </Form.Item>
          //   <Form.Item>
          //     <Button
          //       type="primary"
          //       htmlType="submit"
          //       disabled={!editing}
          //       loading={saving}
          //     >
          //       Save
          //     </Button>
          //   </Form.Item>
          // </Form>,
          <Button
            key="close"
            type="primary"
            onClick={() => setMapOpen(false)}
            disabled={saving}
          >
            Close
          </Button>,
        ]}
      >
        <div className="map-area-container">
          {mapOpen && (
            <GoogleMap
              clickableIcons={false}
              onLoad={focusArea}
              options={{
                disableDefaultUI: !editing,
                // draggable: false,
                // scrollwheel: false,
                // panControl: false,
              }}
            >
              <Polygon
                path={path}
                options={{
                  fillColor: '#1890ff',
                  strokeColor: '#1890ff',
                  editable: editing && !saving,
                }}
                onLoad={setPolygon}
              />
            </GoogleMap>
          )}
        </div>
      </CustomModal>
      <>
        {'Address is within '}
        <Button
          type="link"
          className="inline-link-button"
          onClick={() => setMapOpen(true)}
        >
          {title}
        </Button>
      </>
    </>
  );
};

const Condition = ({ type, data, ...props }) => {
  let content;

  if (type === 'operator') {
    const { operator, conditions } = props;
    content = (
      <>
        <span>{`${operator === 'AND' ? 'ALL' : 'SOME'} of the below are true:`}</span>
        <ul>
          {conditions.map((condition) => <Condition key={condition.id} {...condition} />)}
        </ul>
      </>
    );
  } else if (type === 'field') {
    const { comparator, field } = data;
    let { values } = data;
    // Convert country codes to the full country names
    if (field === 'country') {
      // Revert to country code if lookup returns null
      values = values.map((countryCode) => (
        countryCodeLookup.byIso(countryCode)?.country || countryCode
      ));
    }
    if (comparator === '==') {
      content = `${headerCase(field)} is ${arrayToSentence(values, {
        lastSeparator: ' or ',
      })}`;
    } else if (comparator === '!=') {
      content = `${headerCase(field)} is not ${arrayToSentence(values, {
        lastSeparator: ' or ',
      })}`;
    }
  } else if (type === 'total_value') {
    const { comparator, value } = data;
    if (comparator === '==') {
      content = `Total order value equals $${value}`;
    } else if (comparator === '>=') {
      content = `Total order value is greater than or equal to $${value}`;
    } else if (comparator === '<=') {
      content = `Total order value is less than or equal to $${value}`;
    } else if (comparator === '>') {
      content = `Total order value is greater than $${value}`;
    } else if (comparator === '<') {
      content = `Total order value is less than $${value}`;
    }
  } else if (type === 'store') {
    const { comparator, stores } = data;
    if (comparator === '==') {
      content = `Store is ${arrayToSentence(stores, {
        lastSeparator: ' or ',
      })}`;
    } else if (comparator === '!=') {
      content = `Store is not ${arrayToSentence(stores, {
        lastSeparator: ' or ',
      })}`;
    }
  } else if (type === 'within_polygon') {
    content = <MapAreaCondition {...data} />;
  } else if (type === 'excludes_alcohol') {
    content = 'Order does not contain alcohol';
  } else if (type === 'timeframe') {
    const { between, start, end } = data;
    content = `The date of checkout is ${between ? 'between' : 'not between'} ${dayjs(start).format('DD/MM/YYYY h:mma')} and ${dayjs(end).format('DD/MM/YYYY h:mma')}`;
  } else if (type === 'not_subscription') {
    content = 'Order does not contain subscription variants';
  } else if (type === 'is_subscription') {
    content = 'Order contains subscription variants';
  } else if (type === 'not_internal_webstore') {
    content = 'Order is not being placed via the internal webstore';
  } else if (type === 'active_fresh_club_subscription') {
    content = 'Customer is an active FRESH Club subscriber';
  }

  if (content) {
    return <li>{content}</li>;
  }

  throw new Error('Invalid condition');
};

const PricingModifier = ({ type, ...modifier }) => {
  if (type === 'internal') {
    let content;
    const { conditions, operation, operator } = modifier;
    if (operation.type === 'subtract') {
      const { value } = operation.data;
      content = `Subtract $${value} when ${operator === 'AND' ? 'ALL' : 'SOME'} of the below are true:`;
    } else if (operation.type === 'set') {
      const { value } = operation.data;
      content = `Set to ${value === 0 ? 'FREE' : `$${value}`} when ${operator === 'AND' ? 'ALL' : 'SOME'} of the below are true:`;
    }

    if (content) {
      return (
        <li>
          {content}
          <ul>
            {conditions.map(({ id, ...condition }) => (
              <Condition key={id} {...condition} />
            )) }
          </ul>
        </li>
      );
    }
  }

  if (type === 'external') {
    const { source } = modifier;
    return <li>{`Set to rate calculated by ${source.label}`}</li>;
  }

  throw new Error('Invalid modifier');
};

const ShippingRateCard = ({
  serviceName, emoji, description, active, phoneRequired, visibility, price,
}) => {
  const [updatingActivity, setUpdatingActivity] = useState(false);

  // const freeShipping = true;

  return (
    <Card
      className="shipping-rate-card"
      bordered={false}
      actions={[
        <Tooltip title="Coming soon">
          <Button type="link" disabled>Edit</Button>
        </Tooltip>,
        // <Tooltip title="Coming soon">
        //   <Button type="link" disabled>{`${freeShipping ? 'Stop' : 'Start'} free shipping`}</Button>
        // </Tooltip>,
        <Tooltip title="Coming soon">
          <Button type="link" danger disabled>Delete</Button>
        </Tooltip>,
      ]}
    >

      <Meta title={(
        <div className="shipping-rate-card-header">
          <span className="shipping-rate-card-title">
            {`${serviceName}${emoji ? ` ${emoji}` : ''}`}
          </span>
          <Tooltip title="Coming soon">
            <Switch
              disabled // TODO: Enable once implemented
              checked={active}
              checkedChildren={<CheckOutlined className="shipping-rate-switch-icon" />}
              unCheckedChildren={<CloseOutlined className="shipping-rate-switch-icon" />}
              onChange={() => {
              // setAdminStatus(manageUserPermissions?.uid, !manageUserPermissions?.admin)
                console.log();
              }}
              loading={updatingActivity}
            />
          </Tooltip>
        </div>
      )}
      />
      {description && (
      <p className="shipping-rate-description">
        {description}
      </p>
      )}
      <ul className="shipping-rate-content-list">
        <li>
          {visibility.conditional ? (
            <>
              <strong>{`Only show this rate when ${visibility.operator === 'AND' ? 'ALL' : 'SOME'} of the below are true:`}</strong>
              <ul>
                {visibility.conditions.map(({ id, ...condition }) => (
                  <Condition key={id} {...condition} />
                ))}
              </ul>
            </>
          ) : 'Always show this rate.'}
        </li>
        <li>
          <div>
            {/* <div className="disabled-price"> */}
            <strong>Price:</strong>
            {price.conditional ? (
              <>
                <em>{' (modifiers are applied in the order listed below)'}</em>
                <ul>
                  <li className="default-price">
                    <span>{`Default to $${price.default}`}</span>
                    <HoverHelp message="This is the price that will be used if none of the following modifiers are applicable, or if an error occurs while applying the modifiers" />
                  </li>
                  {price.modifiers.map(({ id, ...modifier }) => (
                    <PricingModifier key={id} {...modifier} />
                  ))}
                </ul>
              </>
            ) : (
              ` $${price.value}`
            )}
          </div>
        </li>
        <li>
          <strong>{'Phone number required: '}</strong>
          {phoneRequired ? 'Yes' : 'No'}
        </li>
      </ul>
    </Card>
  );
};

export default ShippingRateCard;
