import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import './Reports.less';
import { FullPageLoader } from 'components';
import * as api from 'util/api';
import {
  Card, Button, Modal, Radio, Select,
} from 'antd';
import {
  ExclamationCircleOutlined, MailOutlined,
} from '@ant-design/icons';
import { AuthContext } from 'util/Auth';
import AccessDenied from 'pages/AccessDenied/AccessDenied';
import DashboardEditor from './DashboardEditor/DashboardEditor';
import ReportUserAuth from './ReportUserAuth/ReportUserAuth';

const { Option } = Select;

const PERMITTED_EMAILS = {
  write: [
    'dev@garageproject.co.nz',
    'clea@garageproject.co.nz',
    'kyle@garageproject.co.nz',
  ],
};
// todo
// add backend auth for write access

const Dashboards = () => {
  const { currentUser } = useContext(AuthContext);
  const [selectedReport, setSelectedReport] = useState('All reports');
  const [editorOpen, setEditorOpen] = useState(false);
  const [reportBeingEdited, setReportBeingEdited] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [reportUser, setReportUser] = useState([]);
  const [isLoadingReportUsers, setIsLoadingReportUsers] = useState(true);

  useEffect(() => {
    api.getReportUsers().then((users) => {
      const emails = users.map((user) => user.email);
      setReportUser(emails);
      setIsLoadingReportUsers(false);
    }).catch((error) => {
      console.error('Failed to fetch authorized users:', error);
      setIsLoadingReportUsers(false);
    });
  }, []);

  const fetchDashboards = () => {
    if (!isLoadingReportUsers && !reportUser.includes(currentUser.email)) {
      console.error('User is not authorized to fetch dashboards.');
      return;
    }
    api.getDashboards().then((data) => {
      setDashboards(data);
      console.log('loading...');
    }).catch((error) => {
      console.error('Failed to fetch dashboards', error);
    });
  };

  useEffect(() => {
    fetchDashboards();
  }, [reportBeingEdited]);

  const canEdit = useMemo(() => (
    PERMITTED_EMAILS.write.includes(currentUser.email)
  ), [currentUser]);

  if (isLoadingReportUsers) {
    return <FullPageLoader />;
  }
  // todo
  // backend user auth
  if (!reportUser.includes(currentUser.email)) {
    return <AccessDenied />;
  }

  const filteredReports = selectedReport === 'All reports'
    ? dashboards
    : dashboards.filter((report) => report.title === selectedReport);

  const handleEditReport = (report) => {
    setReportBeingEdited(report);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setReportBeingEdited(null);
    setEditorOpen(false);
  };
  const handleDeleteReport = (dashboardToDelete) => {
    Modal.confirm({
      title: `Are you sure you want to delete the report "${dashboardToDelete.title}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => {
        api.deleteDashboard(dashboardToDelete.id)
          .then(() => {
            setDashboards((prevDashboards) => prevDashboards.filter((dashboard) => dashboard.id !== dashboardToDelete.id));
          })
          .catch((error) => {
            console.error('Error deleting dashboard:', error);
          });
      },
    });
  };
  return filteredReports ? (
    <div id="reports-page">
      <div className="report-controls">
        <Select
          value={selectedReport}
          onChange={(value) => setSelectedReport(value)}
          style={{ width: '12rem' }}
        >
          <Option value="All reports">All reports</Option>
          {dashboards.map((report) => (
            <Option key={report.title} value={report.key}>{report.title}</Option>
          ))}
        </Select>
        <div className="admin-buttons">
          {canEdit && (
            <DashboardEditor
              visible={editorOpen}
              onClose={handleEditorClose}
              existingDashboard={reportBeingEdited}
              onNewDashboard={fetchDashboards}

            />
          )}
          {canEdit && (
            <ReportUserAuth
              visible={editorOpen}
              onClose={handleEditorClose}
            />
          )}
        </div>
      </div>
      <div className="reports-grid">
        {filteredReports.map((report) => (
          <div className="reports-card">
            <Card
              hoverable
              key={report.title}
              className="report-card"
              title={(
                <div className="card-title-container">
                  <p className="card-title-text">
                    {report.title}
                  </p>
                  {canEdit && (
                    <div className="card-admin-buttons">
                      <Button onClick={() => handleEditReport(report)}>
                        Edit
                      </Button>
                      <Button type="danger" onClick={() => handleDeleteReport(report)}>
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
              )}
              actions={[
                <div className="action-wrapper">
                  <Button className="action-button" type="primary" href={report.dashboardLink}>
                    View report
                  </Button>
                  <div className="card-sign-off">
                    <a href={`mailto:${report.authorEmail}`}>
                      <MailOutlined />
                      &nbsp;
                      {report.authorName}
                    </a>
                  </div>
                </div>,
              ]}
            >
              <div className="card-body">
                {report.content}
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <FullPageLoader />
  );
};

export default Dashboards;
