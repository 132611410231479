import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { capitalCase } from 'change-case';
import FormatSelectField from './FormatSelect';

const Pricing = ({ type }) => {
  const label = capitalCase(type);
  return (
    <>
      { type === 'venues' ? (
        <div className="columns-container" style={{ width: '100%' }}>
          <div className="column">
            <Form.Item
              name="pricePerLitreVenueTap"
              label="Tap Price"
              labelCol={{ span: 24 }}
              className="half-width"
            >
              <Input type="number" prefix="$" suffix="NZD / Litre" />
            </Form.Item>
            <Form.Item
              name="pintPrice"
              label="Pint Price (440ml)"
              labelCol={{ span: 24 }}
              className="half-width"
            >
              <Input type="number" prefix="$" suffix="NZD" />
            </Form.Item>
            <Form.Item
              name="halfPintPrice"
              label="Half Pint Price (285ml)"
              labelCol={{ span: 24 }}
              className="half-width"
            >
              <Input type="number" prefix="$" suffix="NZD" />
            </Form.Item>
          </div>
        </div>
      ) : (
        <div style={{
          width: '100%', display: 'flex', flexDirection: 'column',
        }}
        >
          {type === 'pack' ? (
            <>
              <div className="columns-container" style={{ flex: 0 }}>
                <div className="column">
                  <FormatSelectField fieldName="packFormat" type="pack" />
                </div>
              </div>
              <div className="columns-container">
                <div className="column">
                  <h2>New Zealand</h2>
                  <Form.Item
                    name={`${type}OnNz`}
                    label="On-Premise (Nationwide)"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="NZD" type="number" />
                  </Form.Item>
                  <Form.Item
                    name={`${type}OffNz`}
                    label="Off-Premise (Nationwide)"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="NZD" type="number" />
                  </Form.Item>
                  <Form.Item
                    name={`${type}ProgressiveNz`}
                    label="Progressive (Nationwide)"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="NZD" type="number" />
                  </Form.Item>
                </div>
                <div className="column">
                  <h2>Australia</h2>
                  <Form.Item
                    name={`${type}VicSa`}
                    label="Victoria / South Australia"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="AUD" type="number" />
                  </Form.Item>
                  <Form.Item
                    name={`${type}QldWa`}
                    label="Queensland / Western Australia"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="AUD" type="number" />
                  </Form.Item>
                </div>
              </div>
            </>
          ) : null}
          {type === 'keg' ? (
            <>
              <div className="columns-container" style={{ flex: 0 }}>
                <div className="column">
                  <FormatSelectField fieldName="kegFormat" type="keg" />
                </div>
              </div>
              <div className="columns-container">
                <div className="column">
                  <h2>New Zealand</h2>
                  <Form.Item
                    name={`${type}Nz`}
                    label="Nationwide"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="NZD" type="number" />
                  </Form.Item>
                </div>
                <div className="column">
                  <h2>Australia</h2>
                  <Form.Item
                    name={`${type}VicSa`}
                    label="Victoria / South Australia"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="AUD" type="number" />
                  </Form.Item>
                  <Form.Item
                    name={`${type}QldWa`}
                    label="Queensland / Western Australia"
                    labelCol={{ span: 24 }}
                  >
                    <Input prefix="$" suffix="AUD" type="number" />
                  </Form.Item>
                </div>
              </div>
            </>
          ) : null}

          {/* <Form.Item
              name={`${type}OnFreightInclusive`}
              label={`${label} On Freight Inclusive`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="NZD" type="number" />
            </Form.Item>
            <Form.Item
              name={`${type}OffFreightInclusive`}
              label={`${label} Off Freight Inclusive`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="NZD" type="number" />
            </Form.Item>
            <Form.Item
              name={`${type}On`}
              label={`${label} On`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="NZD" type="number" />
            </Form.Item>
            <Form.Item
              name={`${type}Off`}
              label={`${label} Off`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="NZD" type="number" />
            </Form.Item> */}
          {/* <div className="column">
            <Form.Item
              name={`${type}Progressive`}
              label={`${label} Progressive`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="NZD" type="number" />
            </Form.Item>
            <Form.Item
              name={`${type}Au`}
              label={`${label} AUS`}
              labelCol={{ span: 24 }}
            >
              <Input prefix="$" suffix="AUD" type="number" />
            </Form.Item>
          </div> */}
        </div>
      ) }
    </>
  );
};

Pricing.propTypes = {
  type: PropTypes.oneOf(['pack', 'keg', 'venues']).isRequired,
};

export default Pricing;
