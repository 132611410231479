import React from 'react';
import { Modal } from 'antd';

import './CustomModal.less';

const CustomModal = (props) => (
  <Modal
    maskClosable={false}
    {...props}
  />
);

export default CustomModal;
