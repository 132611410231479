import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, notification, Radio,
} from 'antd';
import { CustomModal } from 'components';
import { AuthContext } from 'util/Auth';
import axios from 'axios';
import './SendFeedbackModal.less';
import * as api from 'util/api.js';

const { TextArea } = Input;

const { useForm } = Form;

const SendFeedback = ({ open, onClose, bugMode }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { email } = currentUser;

  const [form] = useForm();

  useEffect(() => {
    if (open && !modalVisible) {
      setModalVisible(true);
    }
  }, [open]);

  const handleClose = () => {
    setModalVisible(false);
    setTimeout(() => {
      setSubmitting(false);
      form.resetFields();
      onClose();
    }, 300);
  };

  const submitFeedback = async ({ type, message }) => {
    setSubmitting(true);
    try {
      const feedbackType = bugMode ? 'bug' : type;
      await axios({
        method: 'POST',
        url: 'https://feeder-node-1337.herokuapp.com/feedback/create',
        data: {
          projectId: '6178a7c087cf1b000474d32f',
          feedbackMsg: message,
          feedbackType,
          feedbackEmail: email,
        },
      });
      let messageSuffix;
      if (bugMode) {
        messageSuffix = 'bug report';
      } else {
        messageSuffix = type === 'idea' ? 'suggestion' : 'feedback';
      }
      notification.success({
        message: `Thanks for submitting your ${messageSuffix}!`,
      });
      handleClose();
    } catch (error) {
      api.reportError(error);
      notification.error({
        message: `Error submitting ${bugMode ? 'bug report' : 'suggestion/feedback'}`,
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <CustomModal
        width={400}
        title={bugMode ? 'Report a bug' : 'Suggestions or feedback'}
        centered
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          <Button
            key="cancel"
            type="secondary"
            onClick={handleClose}
            disabled={submitting}
          >
            Cancel
          </Button>,
          <Button
            form="feedback-form"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={submitting}
          >
            Send
          </Button>,
        ]}
      >
        <div>
          <Form
            name="feedback-form"
            form={form}
            onFinish={submitFeedback}
            initialValues={{
              type: 'idea',
              message: '',
            }}
          >
            {!bugMode && (
            <Form.Item name="type">
              <Radio.Group>
                <Radio value="idea">Suggestion</Radio>
                <Radio value="general">General feedback</Radio>
              </Radio.Group>
            </Form.Item>
            )}
            <Form.Item
              label="Message"
              name="message"
              labelCol={{ span: 24 }}
            >
              <TextArea
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

SendFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bugMode: PropTypes.bool.isRequired,
};

export default SendFeedback;
