import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import * as api from 'util/api.js';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    initialising: true,
    currentUser: null,
    // This indicates whether the user has manually logged themselves out.
    userTriggeredLogout: false,
  });

  // Add a listener on mount to set current user when authentication state changes.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      let newCurrentUser = null;
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true)
          .catch((error) => {
            api.reportError(error);
          });
        newCurrentUser = {
          ...user,
          // Determine if the current user is an admin.
          admin: !!idTokenResult.claims?.admin,
          external: !!idTokenResult.claims?.external,
        };
      }
      setAuthState((current) => {
        // User triggered logout if there was was previously a logged in user but now there is none.
        const userTriggeredLogout = !user && !!current.currentUser;

        return ({
          initialising: false,
          userTriggeredLogout,
          currentUser: newCurrentUser,
        });
      });
    });

    // Remove the listener on dismount.
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={authState}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
