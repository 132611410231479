import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@googlemaps/js-api-loader';
import * as api from 'util/api';

const loader = new Loader({
  apiKey: 'AIzaSyCGubkchHkQLQM5GfOtj3IJFQxuDhvQ9WE',
  version: 'weekly',
  libraries: ['places', 'drawing'],
});

export const GoogleMapsContext = React.createContext();

export const GoogleMapsProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(true);
  const [instance, setInstance] = useState(null);
  useEffect(() => {
    loader.load()
      .then((google) => {
        setInstance(google);
        setLoaded(true);
      }).catch((error) => {
        console.log('Error loading the Google Maps JavaScript API script');
        api.reportError(error);
      });
  }, []);
  return (
    <GoogleMapsContext.Provider value={{
      loaded,
      google: instance,
    }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

GoogleMapsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
