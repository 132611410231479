import React, { useState, useEffect, useContext } from 'react';
import useStateRef from 'react-usestateref';
import {
  Button, Badge, Popover, Typography, List, Avatar, Divider, Collapse, Skeleton,
} from 'antd';
import {
  FieldTimeOutlined, DownOutlined, CloseOutlined, LoadingOutlined, UserOutlined,
  SyncOutlined, InfoCircleOutlined, PlusOutlined, DeleteOutlined, CaretRightOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import * as api from 'util/api';
import { sentenceCase } from 'change-case';
import numberToWords from 'number-to-words';
import { productProperties } from 'config/language';
import faker from 'faker';
import { AuthContext } from 'util/Auth';
import { MiscDataContext } from 'util/MiscData';
import RecentActivityDetail from './RecentActivityDetail/RecentActivityDetail';
import './RecentActivity.less';

const { Title, Text } = Typography;

const { Panel } = Collapse;

const SKELETON_COUNT = 10;

const EventSkeleton = () => (
  <List.Item className="skeleton-list-item">
    <Skeleton avatar title={false} loading active>
      <List.Item.Meta
        avatar={<Avatar icon={<UserOutlined />} />}
        description={faker.lorem.words(4)}
      />
    </Skeleton>
  </List.Item>
);

const RecentActivity = () => {
  const [hasUnseenActivity, setHasUnseenActivity, hasUnseenActivityRef] = useStateRef(false);
  const [visible, setVisible, visibleRef] = useStateRef(false);
  const [recentActivity, setRecentActivity, recentActivityRef] = useStateRef(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const [listeningForNewActivity, setListeningForNewActivity] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const onNewActivity = (event) => {
    // Only enable the unseen activity badge if the popover isn't open and the
    // event wasn't created by the user. If the user did create the event but had
    // unseen recent activity, the badge would already be visible so does not need
    // to be renabled.
    if (!visibleRef.current && event.user.id !== currentUser?.uid) {
      setHasUnseenActivity(true);
    }
    // Add the new event to the start of the recentActivity array, if it has been intially loaded
    if (recentActivityRef.current) {
      setRecentActivity((current) => [event, ...current]);
    }
  };

  const listenForNewActivity = () => {
    api.listenForNewActivity(onNewActivity);
    setListeningForNewActivity(true);
  };

  // Determine whether the user has unseen activity on initial load
  const miscData = useContext(MiscDataContext);
  useEffect(() => {
    if (miscData) {
      if (miscData.hasUnseenActivity) {
        setHasUnseenActivity(true);
      }
      // Only set up the new activity listener if the user has no unseen activity
      else if (!listeningForNewActivity && !hasUnseenActivityRef.current) {
        listenForNewActivity();
      }
    }
  }, [miscData]);

  // Fetch recent activity when the popover opens, and setup periodic refresh.
  useEffect(() => {
    if (visible) {
      // The user has 'acknowledged' the recent acttivty only when
      // the popover is open and has loaded
      if (hasUnseenActivity && recentActivity) {
        api.acknowledgeActivity();
        setHasUnseenActivity(false);
      }
      // Get recent activity events if not yet initially fetched
      if (!recentActivity) {
        api.getRecentActivity()
          .then(setRecentActivity)
          .catch(() => {
            setErrorLoading(true);
          });
        // Start listening for new events after initial fetch of recent activity
        // (if listener was not already set on mount)
      } else if (!listeningForNewActivity) {
        listenForNewActivity();
      }
    }
  }, [visible, recentActivity]);

  // const onViewMore = () => {
  //   //
  //   console.log('onViewMore');
  // };

  return (
    <span id="recent-activity-popover-container">
      <Popover
        visible={visible}
        onVisibleChange={setVisible}
        overlayClassName="recent-activity-overlay"
        trigger="click"
        content={(
          <>
            <div id="recent-activity-header">
              <Title level={5}>Recent Activity</Title>
              <Button
                id="recent-activity-close-button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setVisible((current) => !current)}
              />
            </div>
            <Divider />
            {errorLoading && (
              <Text className="placeholder-container">
                Unable to load recent activity, please try again later.
              </Text>
            )}
            <List
              id="recent-activity-list"
              itemLayout="horizontal"
              dataSource={recentActivity || Array.from(Array(SKELETON_COUNT).keys())}
              renderItem={(event) => {
                if ((!errorLoading && !recentActivity) || !event || !event.context) {
                  return (<EventSkeleton />);
                }

                let message;

                const actionBadge = {
                  className: 'generic',
                  icon: <InfoCircleOutlined />,
                };

                // TODO: Make language config and links dynamic based on context
                const languageConfig = event.context === 'product' ? productProperties : {};

                const contextLabel = sentenceCase(event.context).toLowerCase();

                const identifierLink = (
                  <>
                    {event.context === 'product' && event.type !== 'delete' ? (
                      <Link to={`/dashboard/product-database/products/${event.instanceId}/edit`}>
                        <Button
                          type="link"
                          className="inline-link-button"
                          onClick={() => setVisible(false)}
                        >
                          {event.instanceName}
                        </Button>
                      </Link>
                    ) : (
                      event.instanceName
                    )}
                  </>
                );

                if (event.type === 'create') {
                  message = (
                    <>
                      {`${event.user.name} added the ${contextLabel} `}
                      {identifierLink}
                      {event.changes.length > 1 ? ` and set ${numberToWords.toWords(event.changes.length)} properties.` : '.'}
                    </>
                  );
                  actionBadge.className = 'create';
                  actionBadge.icon = <PlusOutlined />;
                } else if (event.type === 'update') {
                  message = event.changes.length === 1 ? (
                    <RecentActivityDetail
                      languageData={languageConfig[event.changes[0].field]}
                      field={event.changes[0].field}
                      previousValue={event.changes[0].previousValue}
                      newValue={event.changes[0].newValue}
                      userName={event.user.name}
                      contextLabel={contextLabel}
                      identifierLink={identifierLink}
                    />
                  ) : (
                    <>
                      {`${event.user.name} updated ${numberToWords.toWords(event.changes.length)} properties for the ${contextLabel} `}
                      {identifierLink}
                      .
                    </>
                  );
                  actionBadge.className = 'update';
                  actionBadge.icon = <SyncOutlined />;
                } else if (event.type === 'delete') {
                  message = (
                    <>
                      {`${event.user.name} deleted the ${contextLabel} `}
                      {identifierLink}
                      .
                    </>
                  );
                  actionBadge.className = 'delete';
                  actionBadge.icon = <DeleteOutlined />;
                }

                return (
                  <>
                    <List.Item>
                      <List.Item.Meta
                        avatar={(
                          <Badge
                            count={(
                              <div className={`action-badge ${actionBadge.className}`}>
                                {actionBadge.icon}
                              </div>
                              )}
                          >
                            <Avatar
                              icon={<UserOutlined />}
                              src={event.user.profilePhotoUrl}
                            />
                          </Badge>
                          )}
                        title={(
                          <>
                            <Text className="event-message-container">{message}</Text>
                            {event.changes.length > 1 && (
                              <Collapse
                                ghost
                              >
                                <Panel
                                  header={`View changes (${event.changes.length})`}
                                  key="1"
                                  showArrow
                                >
                                  <ul>
                                    {event.changes.map((change) => (
                                      <li key={change.field}>
                                        <RecentActivityDetail
                                          {...change}
                                          languageData={languageConfig[change.field]}
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                </Panel>
                              </Collapse>
                            )}
                          </>
                            )}
                        description={dayjs().to(event.timestamp)}
                      />
                    </List.Item>
                  </>
                );
              }}
            />
            {/* TODO: Enable loading more */}
            {/* <div id="view-more-button-container">
              <Button
                disabled={!recentActivity || errorLoading}
                type="link"
                onClick={onViewMore}
                icon={<DownOutlined />}
              >
                View more
              </Button>
            </div> */}
          </>
        )}
      >
        <div id="recent-activity-button-container">
          <Badge dot={hasUnseenActivity} offset={[-4, 6]}>
            <Button
              id="recent-activity-button"
              shape="circle"
              icon={<FieldTimeOutlined id="recent-activity-icon" />}
            />
          </Badge>
        </div>
      </Popover>
    </span>
  ); };

export default RecentActivity;
