import {
  Form, Select,
} from 'antd';
import React, { useMemo } from 'react';
import useChannelsQuery from './use-channels-query';

const ChannelsSelectField = ({ fieldName, label, country }) => {
  /**
   * Fetch the existing channel options for the specified country (NZ or AU).
   */
  const channelsQuery = useChannelsQuery(country);

  /**
   * When the array of channels has loaded, map the channel values to a
   * set of options for the autocomplete suggestions.
   */
  const options = useMemo(() => (
    channelsQuery.isSuccess ? channelsQuery.data.map((channel) => ({
      key: channel,
      value: channel,
      label: channel,
    })) : []
  ), [channelsQuery]);

  return (
    <Form.Item
      name={fieldName}
      label={(
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {label}
          <p style={{
            opacity: 0.7,
            fontSize: 12,
            marginBottom: 0,
          }}
          >
            Select from an existing channel, or enter a new one.
          </p>
        </div>
      )}
      labelCol={{ span: 24 }}
      className="product-editor-form-format"
    >
      <Select
        showSearch
        showArrow
        options={options}
        loading={channelsQuery.isLoading}
        notFoundContent={(() => {
          if (channelsQuery.isSuccess) {
            return 'No existing channels found';
          } if (channelsQuery.isError) {
            return 'Error loading channels';
          }
          return 'Loading channels...';
        })()}
        onBlur={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  );
};

export default ChannelsSelectField;
