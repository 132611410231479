// https://medium.com/trabe/measuring-non-rendered-elements-in-react-with-portals-c5b7c51aec25

import React from 'react';
import ReactDOM from 'react-dom';

export const containerStyle = {
  display: 'inline-block',
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
};

const measureElement = async (element) => {
  // Creates the hidden div appended to the document body
  const container = document.createElement('div');
  container.style = containerStyle;
  document.body.appendChild(container);

  // Renders the React element into the hidden div
  ReactDOM.render(element, container);

  // Short timeout to allow element to render fully before measuring
  const size = await new Promise((resolve) => {
    setTimeout(() => {
      // Gets the element size
      const height = container.clientHeight;
      const width = container.clientWidth;

      resolve({ height, width });
    }, 50);
  });

  // Removes the element and its wrapper from the document
  // ReactDOM.unmountComponentAtNode(container);
  // container.parentNode.removeChild(container);
  return size;
};

export default measureElement;
