import React, { useState, useEffect } from 'react';
import './FreshClub.less';
import * as api from 'util/api';
import {
  ShoppingCartOutlined,
  ShoppingOutlined,
  LoadingOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { FullPageLoader, StickyHeader, CustomTable } from 'components';
import STORES from 'config/stores';
import {
  Button,
  Select,
  Card,
  Spin,
  Table,
  Modal,
} from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';

const { Column } = Table;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CustomSpinner = ({ fontSize = 24 }) => (
  <Spin
    indicator={(
      <LoadingOutlined
        style={{ fontSize }}
        spin
      />
)}
  />
);

const { Option } = Select;

const rechargeConfigs = {
  GPNZ: {
    dashboard_link: 'https://lookerstudio.google.com/reporting/cb7fe12c-0edb-49b3-b603-7f2eadaab231/page/tEnnC',
    recharge_admin: 'garage-project-sp',
  },
  GPAU: {
    dashboard_link: 'https://lookerstudio.google.com/reporting/35e39418-56fb-4ab0-af25-fea69297590a/page/tEnnC',
    recharge_admin: 'garageproject-australia-sp',
  },
};

const FreshClub = ({ webstoreAdmin, defaultStore }) => {
  const [store, setStore] = useState(defaultStore);
  const storeIsNZ = [STORES.GPNZ, STORES.DWNZ].includes(store);

  const [data, setData] = useState([]);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [allSubscriptionData, setAllSubscriptionData] = useState([]);

  const [loading, setLoading] = useState(null);
  const [loadingCustomers, setLoadingCustomers] = useState(null);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(null);
  const [showMetrics, setShowMetrics] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [customerData, setCustomerData] = useState([]);

  const metrics = data.metrics || {};
  const productSummary = data.productSummary || {};
  const {
    totalCansReserved,
    uniqueCustomers,
    totalValue,
  } = metrics;

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // const [availableProducts, setAvailableProducts] = useState(null);

  // const fetchAvailableProducts = () => {
  //   setAvailableProducts(null);
  //   api.getAvailableShopifyProducts(STORES[store])
  //     .then((response) => {
  //       console.log('...Fetched products');
  //       setAvailableProducts(response);
  //     });
  // };

  const fetchData = () => {
    setLoading(true);
    console.log('Fetching onetimes...');
    api.getAllOnetimesData(STORES[store])
      .then((response) => {
        console.log('...Fetched onetimes');
        setData(response);
      })
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCustomerData = () => {
    setLoadingCustomers(true);
    console.log('Fetching customer data...');
    api.getAllCustomerData(STORES[store])
      .then((response) => {
        console.log('...Fetched customer data');
        setAllCustomerData(response);
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
      })
      .finally(() => {
        setLoadingCustomers(false);
      });
  };

  const fetchSubscriptionData = () => {
    setLoadingSubscriptions(true);
    console.log('Fetching subscription data...');
    api.getAllSubscriptionData(STORES[store])
      .then((response) => {
        console.log('...Fetched subscription data');
        setAllSubscriptionData(response);
      })
      .catch((error) => {
        console.error('Error fetching subscription data:', error);
      })
      .finally(() => {
        setLoadingSubscriptions(false);
      });
  };

  useEffect(() => {
    setAllCustomerData(null);
    setAllSubscriptionData(null);
    fetchData();
    fetchCustomerData();
    fetchSubscriptionData();
    // fetchAvailableProducts();
  }, [store]);

  if (!data) {
    return <FullPageLoader />;
  }

  const findCustomersByProductId = (onetimesData, productId) => {
    // Create a map to hold customer data and aggregate unit counts
    const customerAggregationMap = new Map();

    onetimesData.forEach((onetime) => {
      if (onetime.externalProductId.ecommerce === productId) {
        const { customerId } = onetime;
        const customerObject = allCustomerData?.find((customer) => customer.id === customerId);

        if (customerAggregationMap.has(customerId)) {
          const existingEntry = customerAggregationMap.get(customerId);
          customerAggregationMap.set(customerId, {
            ...existingEntry,
            unitCount: existingEntry.unitCount + onetime.unitCount,
          });
        } else {
          customerAggregationMap.set(customerId, {
            onetimesId: onetime.id,
            productId,
            customerId,
            unitCount: onetime.unitCount,
            customerUrl: `https://${rechargeConfigs[store].recharge_admin}.admin.rechargeapps.com/merchant/customers/${customerId}`,
            customerName: `${customerObject?.firstName} ${customerObject?.lastName}`,
            customerObject,
          });
        }
      }
    });

    return Array.from(customerAggregationMap.values());
  };

  const totalSubscribers = () => {
    const countedData = allCustomerData?.filter((customer) => customer?.subscriptionsActiveCount >= 1).length;
    return countedData;
  };

  const totalUnits = () => {
    let unitCount = 0;
    allSubscriptionData?.forEach((subscription) => {
      const quantity = parseInt(subscription.variantQuantity, 10);
      if (!Number.isNaN(quantity)) {
        unitCount += quantity;
      }
    });
    return unitCount;
  };

  const [downloadCsv, setdownloadCsv] = useState(false);
  const handleDownload = React.useCallback(() => {
    setdownloadCsv(true);
    setTimeout(() => setdownloadCsv(false), 1000);
  }, []);

  return (
    <div id="fresh-club-container">
      <StickyHeader id="fresh-club-header">
        <Select
          defaultValue={defaultStore}
          style={{ width: 180 }}
          value={store}
          disabled={loading || loadingCustomers}
          onChange={(newStore) => {
            setStore(STORES[newStore]);
          }}
        >
          <Option value={STORES.GPNZ}>Garage Project NZ</Option>
          <Option value={STORES.GPAU}>Garage Project AU</Option>
        </Select>
        <Button
          id="recharge-data-refresh-button"
          type="primary"
          onClick={fetchData}
          disabled={loading}
        >
          Refresh Onetimes Data
        </Button>
        <Button
          id="recharge-customer-refresh-button"
          type="primary"
          onClick={fetchCustomerData}
          disabled={loadingCustomers}
        >
          Refresh Customer Data
        </Button>
        <Button
          id="toggle metrics"
          type="secondary"
          onClick={() => setShowMetrics(!showMetrics)}
        >
          Toggle Metrics
        </Button>
        <Button
          id="dowload-csv"
          type="secondary"
          onClick={handleDownload}
        >
          Download CSV
        </Button>
        {downloadCsv
          && (
            <CSVDownload
              data={data.productSummary}
              headers={[
                {
                  label: 'Product Name',
                  key: 'productName',
                },
                {
                  label: 'Unit Count',
                  key: 'unitCount',
                },
                {
                  label: 'Product ID',
                  key: 'productId',
                },
              ]}
              target="_blank"
              rel="noopener noreferrer"
            />
          )}
      </StickyHeader>
      {showMetrics ? (
        <div id="onetimes-metrics-container">
          <Card title="Total number of cans reserved" extra={<ShoppingCartOutlined />}>
            {metrics && !loading ? totalCansReserved : <CustomSpinner />}
          </Card>
          <Card title="Total value of upcoming onetimes" extra={<ShoppingOutlined />}>
            {metrics && !loading ? `$${totalValue}` : <CustomSpinner />}
          </Card>
          <Card title="Unique customers with onetimes" extra={<UserAddOutlined />}>
            {metrics && !loading ? uniqueCustomers : <CustomSpinner />}
          </Card>
          <Card title="Total FRESH Club members" extra={<TeamOutlined />}>
            {totalSubscribers() && !loading ? totalSubscribers() : <CustomSpinner />}
          </Card>
          <Card title="Total FRESH cans reserved" extra={<TeamOutlined />}>
            {totalUnits() && !loading ? totalUnits() : <CustomSpinner />}
          </Card>
        </div>
      ) : null}
      <CustomTable
        id="onetimes-summary-table"
        pluralLabel="onetimes"
        searchableColumns={['productName', 'unitcount']}
        scroll={{ y: 400 }}
        customRowClick={(record) => {
          if (!data.processedData || !allCustomerData) {
            console.error('Waiting for data');
          }
          const customers = findCustomersByProductId(data.processedData, record.productId);
          setCustomerData(null);
          delay(1000);
          setCustomerData({ name: record.productName, product: record.productId, customers });
          setModalOpen(true);
        }}
        pagination={{ pageSize: showMetrics ? 5 : 8 }}
        dataSource={data?.productSummary ? data.productSummary?.map((product) => (
          {
            ...product,
            key: product.productId,
          }
        )) : null}
      >
        <Column
          title="Product Name"
          dataIndex="productName"
          key="productName"
          width="50%"
        />
        <Column
          title="Number of units"
          dataIndex="unitCount"
          key="unitCount"
          sortType="number"
          sortDirections={['descend', 'ascend', 'descend']}
          defaultSortOrder="descend"
          width="50%"

        />
      </CustomTable>
      <Modal
        title={`Customers with upcoming ${customerData.name}`}
        visible={modalOpen}
        onCancel={handleModalClose}
      >
        {customerData.customers?.map((customer) => (
          <div id="customer-row" key={customer.customerId}>
            {console.log(customer)}
            <p>
              {customer.customerName}
              :&nbsp;
              {customer.unitCount}
              {customer.unitCount === 1 ? 'x unit ( ID:' : 'x units ( ID:'}
              &nbsp;
            </p>
            <p>
              <a href={customer.customerUrl} target="blank">
                {customer.customerId}
              </a>
              &nbsp; )
            </p>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default FreshClub;

// {`https://${rechargeConfigs[store].recharge_admin}.admin.rechargeapps.com/merchant/customers/${customerId}`}
