import React from 'react';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import './GlobalFooter.less';

const { Text } = Typography;

const GlobalFooter = () => (
  <footer>
    <Text type="secondary" className="footer-content">
      <span className="faded">Made with</span>
      <span id="heart-icon">
        <HeartFilled />
        <HeartOutlined />
      </span>
      <span className="faded">by Garage Project</span>
    </Text>
    {/* TODO: Versioning for cache busting */}
    {/* https://dev.to/flexdinesh/cache-busting-a-react-app-22lk */}
    {/* <Text type="secondary" id="version-number">
      v1.0.0
    </Text> */}
  </footer>
);

export default GlobalFooter;
