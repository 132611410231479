import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DTCProductModal.less';
import {
  Button, Form, Alert,
} from 'antd';
import { CustomModal } from 'components';
import { useHistory } from 'react-router-dom';
import dot from 'dot-object';
import { ToolOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Player } from '@lottiefiles/react-lottie-player';
import downloadBase64Pdf from 'util/downloadBase64Pdf';
import print from 'print-js';

const { useForm } = Form;

const DTCProductModal = ({
  prices,
  type,
  selectionType,
  pluralLabel,
  products,
  open,
  onClose,
  onGenerate,
  generatorStatus,
  setGeneratorStatus,
  generatorOutput,
  FormContent,
  store,
}) => {
  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const formId = `${pluralLabel}-generator-form`;

  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
    } else {
      setFormInitialised(true);
    }
  }, [open]);

  const handleSubmit = (formData) => {
    if (selectionType === 'multiple') {
      onGenerate({
        products,
        formData: dot.object(formData),
        prices,
      });
    } else {
      const product = products[0];
      onGenerate({
        product,
        formData,
        prices,
      });
    }
  };

  return (
    <CustomModal
      width={400}
      title={`Generate ${pluralLabel}`}
      centered
      visible={open}
      onCancel={generatorStatus.active ? null : onClose}
      maskClosable={false}
      forceRender // Allows form content to listen to close event
      footer={generatorStatus.success ? (
        <Button
          type="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      ) : [
        <Button
          key="cancel"
          type="secondary"
          onClick={onClose}
          disabled={generatorStatus.active}
        >
          Cancel
        </Button>,
        <Button
          form={formId}
          key="submit"
          htmlType="submit"
          type="primary"
          icon={<ToolOutlined />}
          loading={generatorStatus.active}
        >
          Generate
        </Button>,
      ]}
    >
      <div>

        {generatorStatus.active && (
          <div className="lottie-message-container">
            <Player
              autoplay
              loop
              // https://lottiefiles.com/6798-gear-loading
              src="https://assets2.lottiefiles.com/packages/lf20_qarwrwmv.json" // #808080
              style={{ height: '150px', width: '150px' }}
            />
            <span>{`Generating ${pluralLabel}...`}</span>
          </div>
        )}

        {generatorStatus.error && (
          <div>
            <div className="lottie-message-container">
              <Player
                autoplay
                loop={false}
                keepLastFrame
                // https://lottiefiles.com/85714-error
                src="https://assets1.lottiefiles.com/packages/lf20_cjn1jdte.json"
                style={{ height: '150px', width: '150px' }}
              />
            </div>
            <div className="generator-output-container">
              { generatorStatus.error }
            </div>
          </div>
        )}

        {generatorStatus.success && (
          <div>
            <div className="lottie-message-container">
              <Player
                autoplay
                loop={false}
                keepLastFrame
                // https://lottiefiles.com/69380-success-check
                src="https://assets2.lottiefiles.com/packages/lf20_4qldwfx4.json"
                style={{ height: '150px', width: '150px' }}
              />
            </div>
          </div>

        )}

        {!generatorStatus.active && !generatorStatus.error && !generatorStatus.success && (
          <Form
            id={formId}
            name={formId}
            form={form}
            onFinish={handleSubmit}
          >
            <FormContent
              prices={prices}
              type={type}
              products={products}
              form={form}
              modalOpen={open}
              store={store}
            />
          </Form>
        )}

      </div>
    </CustomModal>
  );
};

DTCProductModal.propTypes = {
  type: PropTypes.oneOf(['shopify', 'kounta']).isRequired,
  selectionType: PropTypes.oneOf(['single', 'multiple']).isRequired,
  pluralLabel: PropTypes.string.isRequired,
  // Disable since product attributes may change often
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
  generatorStatus: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
  generatorOutput: PropTypes.shape({
    type: PropTypes.oneOf(['pdf']),
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any,
  }).isRequired,
  FormContent: PropTypes.elementType.isRequired,
};

export default DTCProductModal;
