import {
  Alert, Button, Typography,
} from 'antd';
import React, { useCallback } from 'react';

import './index.less';
import useMondayConnectionStatusQuery from './use-monday-connection-status-query';

const { Paragraph } = Typography;

const MONDAY_OAUTH_URL = 'https://auth.monday.com/oauth2/authorize?client_id=a4d74b7eba0a5da1019d19c9e95fd10c';

const ConnectionStatus = () => {
  /**
   * Invoke the Monday OAuth flow by opening the OAuth URL in a new tab.
   */
  const invokeOAuthFlow = useCallback(() => {
    window.open(MONDAY_OAUTH_URL, '_blank').focus();
  });

  const connectionStatusQuery = useMondayConnectionStatusQuery();

  const {
    tokenValid,
    boardPermissionsValid,
    authenticatedUserName,
  } = connectionStatusQuery.data ?? {};

  return (
    <div id="connection-status-container">
      <div style={{ marginBottom: 10 }}>
        <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
          The Matrix requires a connection through a Monday account in order to enable
          syncing products in the Matrix with the product database board in Monday.
        </Paragraph>
        <Paragraph style={{ opacity: 0.7, marginBottom: 6 }}>
          The Monday account used to connect MUST be one of the owners of the product
          database board.
        </Paragraph>
      </div>
      <div id="connection-status-content">
        {connectionStatusQuery.isLoading && <Alert type="info" showIcon message="Checking Monday connection status..." />}
        {connectionStatusQuery.isError && <Alert type="error" showIcon message="Error checking Monday connection status" />}
        {connectionStatusQuery.isSuccess && (
        <>
          {tokenValid && boardPermissionsValid ? (
            <Alert type="success" showIcon message="The Matrix is connected to Monday!" />
          ) : (
            <Alert
              type="error"
              showIcon
              message={(
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}
                >
                  <div>The Matrix is unable to connect to Monday.</div>
                  {!tokenValid ? (
                    <>
                      <div>A user has connected, but the connection is no longer valid.</div>
                      <div>
                        This could be because the Monday account that was
                        previously used to connect with The Matrix no longer
                        exists, or doesn&apos;t have the correct account role.
                      </div>
                    </>
                  ) : null}
                  {tokenValid && !boardPermissionsValid ? (
                    <div>
                      {`The Matrix is connected with user account "${authenticatedUserName}",
                    but this user is not an owner of the product database board.`}
                    </div>
                  ) : null}
                </div>
              )}
            />
          )}
        </>
        )}
        <Button
          type="primary"
          onClick={invokeOAuthFlow}
          loading={
            connectionStatusQuery.isFetching
        }
        >
          {connectionStatusQuery.isSuccess && connectionStatusQuery.data.tokenValid ? 'Reconnect to Monday' : 'Connect to Monday'}
        </Button>
      </div>

    </div>
  ); };

export default ConnectionStatus;
