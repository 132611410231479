import React, { useContext } from 'react';
import { Button } from 'antd';
import './AccessDenied.less';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'util/Auth';
import FullPageMessage from 'pages/FullPageMessage/FullPageMessage';

const AccessDenied = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  return (
    <FullPageMessage
      title="Access denied"
      content={(
        <>
          Your account hasn&apos;t been granted permission to access this page.
          <br />
          {'If you would like to request access, please '}
          <Button
            className="inline-link-button"
            type="link"
            href={`mailto:matrix@garageproject.co.nz?subject=Matrix access request&body=Hi,%0D%0A%0D%0AI tried to access the page at "${location.pathname}" in the Matrix, but access was denied.%0D%0A%0D%0ACan you please enable access for my account?%0D%0A%0D%0AThanks,%0D%0A${currentUser.displayName}.`}
          >
            click here
          </Button>
          .
        </>
      )}
      image="/assets/weird-flex-celery.png"
      imageHeight={200}
      buttonLabel="Back to Home"
      linkTo="/dashboard/home"
    />
  );
};

export default AccessDenied;
