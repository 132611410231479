import React, { useContext } from 'react';
import { PageTabsContext } from 'util/PageTabs';
import UsersTable from './UsersTable/UsersTable';

const UserManagement = () => {
  const userType = useContext(PageTabsContext);
  console.log('page', userType);
  return <UsersTable userType={userType} />;
};

export default UserManagement;
