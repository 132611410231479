import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Image, Checkbox, Switch, Skeleton, InputNumber,
} from 'antd';

import { briefProperties } from 'config/language';

const { TextArea } = Input;

const Sales = () => (
  <div className="columns-container">
    <div className="column">
      <Form.Item
        name="idealReleaseDate"
        label={briefProperties.idealReleaseDate.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="socialsReleaseDate"
        label={briefProperties.socialsReleaseDate.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="initialBrewSize"
        label={briefProperties.initialBrewSize.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input type="number" suffix="Litres" />
      </Form.Item>
      <Form.Item
        name="contingencyPlan"
        label={briefProperties.contingencyPlan.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>

    </div>
    <div className="column">
      <Form.Item
        name="salesChannels"
        label={briefProperties.salesChannels.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 3 }}
        />
      </Form.Item>

      <Form.Item
        label="Base unit RRP"
        labelCol={{ span: 24 }}
        className="form-item-rows"
      >
        <div>
          <Form.Item
            className="horizontal-field-pair"
            style={{ marginBottom: 10 }}
            label="Min"
          >
            <Form.Item
              name={['rrp', 'min', 'nzd']}
            >
              <Input type="number" prefix="$" suffix="NZD" />
            </Form.Item>
            <Form.Item
              name={['rrp', 'min', 'aud']}
            >
              <Input type="number" prefix="$" suffix="AUD" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            className="horizontal-field-pair"
            label="Max"
          >
            <Form.Item
              name={['rrp', 'max', 'nzd']}
            >
              <Input type="number" prefix="$" suffix="NZD" />
            </Form.Item>
            <Form.Item
              name={['rrp', 'max', 'aud']}
            >
              <Input type="number" prefix="$" suffix="AUD" />
            </Form.Item>
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        name="packagingNeeds"
        label={briefProperties.packagingNeeds.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
    </div>
  </div>
);

export default Sales;
