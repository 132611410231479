import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import './Mailchimp.less';
import { Switch, Route } from 'react-router-dom';
import GeneratorProductTable from 'components/GeneratorProductTable/GeneratorProductTable';
import { ToolOutlined, CopyOutlined } from '@ant-design/icons';
import {
  Modal, Button, Typography, Divider, message,
} from 'antd';
import 'highlight.js/styles/github.css';
import { CustomModal } from 'components';
import { copyElementToClipboard } from 'util/copy-to-clipboard';
import MailchimpUpdateGenerator from './MailchimpUpdateGenerator/MailchimpUpdateGenerator';
import SnippetBlock from './SnippetBlock/SnippetBlock';
import ProductSnippet from './snippets/ProductSnippet';
import IntroSnippet from './snippets/IntroSnippet';

const Mailchimp = () => {
  const [snippetConfig, setSnippetConfig] = useState(null);

  const onCopyEmail = () => {
    copyElementToClipboard({ elementId: 'email-snippets-container' });
    message.success('Email copied to clipboard!');
  };

  return (
    <React.Fragment key="mailchimp">
      <Switch>
        <Route
          exact
          path="/dashboard/mailchimp"
          render={() => (
            <GeneratorProductTable
              type="mailchimp"
              selectionType="multiple"
              pluralLabel="stock update email"
              GeneratorFormContent={MailchimpUpdateGenerator}
              initialFormValues={{
                generateIntro: true,
                generateSectionBanners: true,
              }}
              onGenerate={setSnippetConfig}
            />
          )}
        />
      </Switch>
      <CustomModal
        title="Copy stock update email"
        centered
        width="90%"
        visible={!!snippetConfig}
        footer={[
          (!snippetConfig?.formData.showCode ? (
            <Button
              type="primary"
              icon={<CopyOutlined />}
              onClick={onCopyEmail}
            >
              Copy email to clipboard
            </Button>
          ) : null),
          <Button type="primary" onClick={() => setSnippetConfig(null)}>Done</Button>,
        ]}
        onCancel={() => setSnippetConfig(null)}
      >
        <div id="email-snippets-container">
          {/* INTRO */}
          {snippetConfig?.formData.generateIntro && (
            <>
              <SnippetBlock
                id="email-intro"
                showCode={snippetConfig?.formData.showCode}
              >
                <IntroSnippet />
              </SnippetBlock>
              <Divider />
            </>
          )}
          {snippetConfig?.products.map((product, index) => (
            <React.Fragment key={product.id}>
              <SnippetBlock
                id={product.id}
                showCode={snippetConfig?.formData.showCode}
              >
                <ProductSnippet
                  pricingType={snippetConfig.formData.pricingType}
                  product={product}
                />
              </SnippetBlock>
              {/* Don't render divder below last item */}
              {index < snippetConfig?.products.length - 1 && <Divider />}
            </React.Fragment>
          ))}

        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default Mailchimp;
