import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Menu, Dropdown, Avatar, Typography,
} from 'antd';
import {
  LogoutOutlined, UserOutlined,
  BugOutlined, ContainerOutlined,
} from '@ant-design/icons';
import './HeaderDropdownOptions.less';
import firebase from 'firebase';
import { AuthContext } from 'util/Auth';
import * as api from 'util/api.js';

const { Text } = Typography;

const HeaderDropdownOptions = ({
  showFeedbackButtons,
  onReportBugClicked,
  onFeedbackClicked,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { displayName, email } = currentUser;

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      api.reportError(error);
    }
  };

  return (
    <Dropdown
      trigger="click"
      overlay={(
        <Menu>
          <Menu.Item disabled id="name-menu-item">
            <Text strong>{displayName || email}</Text>
          </Menu.Item>
          {showFeedbackButtons && (
            <>
              <Menu.Divider />
              <Menu.Item
                icon={<BugOutlined />}
                onClick={onReportBugClicked}
              >
                Report a bug
              </Menu.Item>
              <Menu.Item
                icon={<ContainerOutlined />}
                onClick={onFeedbackClicked}
              >
                Suggestions or feedback
              </Menu.Item>
            </>
          )}
          <Menu.Divider />
          <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout}>
            Logout
          </Menu.Item>
        </Menu>
      )}
      placement="bottomCenter"
    >
      <div id="current-user-avatar">
        <Avatar
          icon={<UserOutlined />}
          src={currentUser?.photoURL}
        />
      </div>
    </Dropdown>
  );
};

HeaderDropdownOptions.propTypes = {
  showFeedbackButtons: PropTypes.bool,
  onReportBugClicked: PropTypes.func.isRequired,
  onFeedbackClicked: PropTypes.func.isRequired,
};

HeaderDropdownOptions.defaultProps = {
  showFeedbackButtons: false,
};

export default HeaderDropdownOptions;
