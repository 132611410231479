import React, {
  useCallback, useState, useEffect, useContext,
} from 'react';
import './BriefEditor.less';
import {
  Tabs, Card, Form, Button, Typography, Modal, Tooltip,
} from 'antd';
import { FixedButton, FullPageLoader } from 'components';
import * as api from 'util/api-morpheus.js';
import { AuthContext } from 'util/Auth';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { TableConfigContext } from 'util/TableConfig';
import EditorLayout from 'components/EditorLayout/EditorLayout';
import { deepKeys, getProperty, setProperty } from 'dot-prop';
import Creative from './tabs/Creative';
import PreProduction from './tabs/PreProduction';
import Sales from './tabs/Sales';
// import About from './tabs/About';
// import KeyDates from './tabs/KeyDates';
// import Artwork from './tabs/Artwork';
// import Pricing from './tabs/Pricing';
// import Barcodes from './tabs/Barcodes';
// import Units from './tabs/Units/Units';
// import Extras from './tabs/Extras';

const { TabPane } = Tabs;

const { useForm } = Form;

const { Title } = Typography;

// Update table data if it exists by adding new product for faster subsequent loads.
const updateExistingTableData = (
  tableConfig,
  setTableConfig,
  id,
  {
    name,
    // TODO
    // dateCreated,
    // dateEdited,
  },
) => {
  const { data: existingData } = tableConfig;

  if (existingData) {
    const newData = existingData.filter((brief) => Number(brief.id) !== Number(id));
    setTableConfig('brief-database', {
      ...tableConfig,
      data: [
        ...newData,
        {
          key: id,
          id,
          name,
          // TODO
          // dateCreated,
          // dateEdited,
        },
      ],
    });
  }
};

const BriefEditor = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [savingStatus, setSavingStatus] = useState({
    active: false,
    error: false,
    unsavedChanges: false,
    lastSaved: null,
  });

  const [briefName, setBriefName] = useState(null);
  const [briefId, setBriefId] = useState(null);

  const urlParams = useParams();

  const history = useHistory();

  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  // Brief name must be stored locally so it can be used across tabs
  useEffect(() => {
    setBriefName(initialValues?.name);
  }, [initialValues]);

  const { getTableConfig, setTableConfig } = useContext(TableConfigContext);
  const tableConfig = getTableConfig('product-brief-database');

  // Fetch and set fields when a brief ID is provided or changes in URL params
  useEffect(async () => {
    const { briefId: id } = urlParams;

    // TODO: Handle invalid ID provided in URL params.
    setBriefId(id || null);

    if (initialValues) {
      setInitialValues(null);
    }

    setInitialValues(id ? (await api.getBrief(id)) : {});

    // If intialised from a previous brief, clear the form
    if (formInitialised) {
      form.resetFields();
    } else {
      setFormInitialised(true);
    }
  }, [urlParams.briefId]);

  const onSave = (values) => {
    setSavingStatus((current) => ({
      ...current,
      active: true,
      error: false,
    }));

    const briefData = { ...values };

    // Convert all fields with empty strings to null
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'object') {
        // Convert nested values with empty string to null
        const nestedKeys = deepKeys(value);
        nestedKeys.forEach((nestedKey) => {
          if (getProperty(value, nestedKey) === '') {
            console.log(nestedKey, 'is an empty string');
            setProperty(briefData, `${key}.${nestedKey}`, null);
          }
        });
      }
      if (value === '') {
        briefData[key] = null;
      }
    });

    console.log('briefData', briefData);

    if (briefId) {
      api.updateBrief({ id: briefId, data: briefData, sessionId: null })
        .then(({ sessionId }) => {
          console.log('sessionId', sessionId);
          updateExistingTableData(tableConfig, setTableConfig, briefId, briefData);
          setSavingStatus({
            active: false,
            error: false,
            unsavedChanges: false,
            lastSaved: dayjs(),
          });
        })
        .catch((error) => {
          console.log('Error', error); // TODO: log error
          setSavingStatus((current) => ({
            ...current,
            active: false,
            error: true,
          }));
        });
    } else {
      api.createBrief({ data: briefData })
        .then(({ id, sessionId }) => {
          console.log('sessionId', sessionId);
          setBriefId(id);
          updateExistingTableData(tableConfig, setTableConfig, id, briefData);
          // Change from the 'create' path to the 'edit' path to reload brief if refreshing
          window.history.pushState('', '', `/dashboard/product-database/briefs/${id}/edit`);
          setSavingStatus({
            active: false,
            error: false,
            unsavedChanges: false,
            lastSaved: dayjs(),
          });
        })
        .catch((error) => {
          console.log('Error', error); // TODO: log error
          setSavingStatus((current) => ({
            ...current,
            active: false,
            error: true,
          }));
        });
    }
  };

  const onDelete = () => api.deleteBrief(briefId).then(() => {
    // Clear unsaved changes to avoid showing alert when redirecting
    setSavingStatus((current) => ({
      ...current,
      unsavedChanges: false,
    }));
    history.push('/dashboard/product-database/briefs');
  });

  const onSaveFailed = useCallback((values) => {
    // console.log('onSaveFailed', values);
    // TODO: Show errors in tabs on left.
  }, []);

  const onValuesChange = useCallback(() => {
    if (!savingStatus.unsavedChanges) {
      setSavingStatus((current) => ({
        ...current,
        unsavedChanges: true,
      }));
    }
  }, [form, savingStatus, setSavingStatus]);

  return (
    <EditorLayout
      id="brief-editor-form"
      contextLabel="brief"
      title={briefName}
      form={form}
      initialValues={initialValues}
      enableDelete={!!briefId}
      onDelete={onDelete}
      onSave={onSave}
      onSaveFailed={onSaveFailed}
      onValuesChange={onValuesChange}
      savingStatus={savingStatus}
      additionalControls={[
        // TODO: Change to a dropdown on narrow screens
        <Tooltip title="Coming soon..." key="summary">
          <Button type="primary" key="summary" disabled>
            Download PDF
          </Button>
        </Tooltip>,
        <Tooltip title="Coming soon..." key="convert">
          <Button type="primary" key="convert" disabled>
            Convert to product
          </Button>
        </Tooltip>,
      ]}
    >
      <TabPane tab="Creative" key="creative" className="padded-tab" forceRender>
        <Creative
          form={form}
          onNameChange={setBriefName}
        />
      </TabPane>
      <TabPane tab="Pre-production" key="pre-production" className="padded-tab" forceRender>
        <PreProduction form={form} />
      </TabPane>
      <TabPane tab="Sales" key="sales" className="padded-tab" forceRender>
        <Sales form={form} />
      </TabPane>
    </EditorLayout>
  );
};

//   return (!initialValues) ? (
//     <FullPageLoader />
//   ) : (
//     <Form
//       id="brief-editor-form"
//       name="brief-editor-form"
//       form={form}
//       initialValues={initialValues}
//       onFinish={onSubmit}
//       onFinishFailed={onSubmitFailed}
//     >

//       <Card
//         id="brief-editor-card"
//         title={(
//           <>
//             <Title level={5}>
//               {briefName || <span className="unnamed-bried-title">Untitled Brief</span>}
//             </Title>
//             { briefId ? (
//               <Button
//                 danger
//                 type="outline"
//                 onClick={() => onDelete(briefId)}
//               >
//                 Delete brief
//               </Button>
//             ) : (
//               null
//             )}
//           </>
//         )}
//         size="small"
//       >
//         <Tabs tabPosition="left">
//           <TabPane tab="About" key="about" className="padded-tab" forceRender>
//             <Creative
//               form={form}
//               tapBadgeImageUrl={tapBadgeImageUrl}
//               onNameChange={setBriefName}
//             />
//           </TabPane>
//         </Tabs>
//         { currentUser.admin && (
//         <FixedButton
//           htmlType="submit"
//           loading={saving}
//           icon={<CheckOutlined />}
//         >
//           Save
//         </FixedButton>
//         )}
//       </Card>
//     </Form>
//   );
// };

export default BriefEditor;

// <TabPane tab="About" key="about" className="padded-tab" forceRender>
//             <About
//               form={form}
//               tapBadgeImageUrl={tapBadgeImageUrl}
//               onNameChange={setBriefName}
//             />
//           </TabPane>
//           <TabPane tab="Key dates" key="keyDates" className="padded-tab" forceRender>
//             <KeyDates form={form} />
//           </TabPane>
//           {/* <TabPane tab="Units" key="units" forceRender>
//             <Units form={form} productId={productId} />
//           </TabPane> */}
//           <TabPane tab="Artwork" key="artwork" className="padded-tab" forceRender>
//             <Artwork form={form} onTapBadgeChanged={setTapBadgeImageUrl} />
//           </TabPane>
//           <TabPane tab="Pricing" key="pricing" forceRender>
//             <Tabs tabPosition="left">
//               <TabPane tab="Slab" key="slab" className="padded-tab" forceRender>
//                 <Pricing type="slab" />
//               </TabPane>
//               <TabPane tab="Case" key="case" className="padded-tab" forceRender>
//                 <Pricing type="case" />
//               </TabPane>
//               <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
//                 <Pricing type="keg" />
//               </TabPane>
//               <TabPane tab="Venue tap" key="venues" className="padded-tab" forceRender>
//                 <Pricing type="venues" />
//               </TabPane>
//             </Tabs>
//           </TabPane>
//           <TabPane tab="Barcodes" key="barcodes" forceRender>
//             <Tabs tabPosition="left">
//               <TabPane tab="Base" key="base" className="padded-tab" forceRender>
//                 <Barcodes type="base" />
//               </TabPane>
//               <TabPane tab="Slab" key="slab" className="padded-tab" forceRender>
//                 <Barcodes type="slab" />
//               </TabPane>
//               <TabPane tab="Case" key="case" className="padded-tab" forceRender>
//                 <Barcodes type="case" />
//               </TabPane>
//               <TabPane tab="Keg" key="keg" className="padded-tab" forceRender>
//                 <Barcodes type="keg" />
//               </TabPane>
//             </Tabs>
//           </TabPane>
//           <TabPane tab="Extras" key="extras" className="padded-tab" forceRender>
//             <Extras />
//           </TabPane>
