import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './DashboardEditor.less';
import {
  Button, Form, Input, Modal, Tooltip, Checkbox,
} from 'antd';
import { CustomModal } from 'components';
import * as api from 'util/api.js';
import {
  PlusOutlined, LinkOutlined,
} from '@ant-design/icons';
import { omit } from 'lodash';

const { useForm } = Form;

const DashboardEditor = ({
  onClose, onNewDashboard, disableButton, existingDashboard = null,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  useEffect(() => {
    if (existingDashboard) {
      setModalOpen(true);
    }
  }, [existingDashboard]);

  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
      form.setFieldsValue(existingDashboard ? { ...existingDashboard } : {});
    } else {
      setFormInitialised(true);
    }

    if (existingDashboard) {
      setModalOpen(true);
    }
  }, [existingDashboard, form, formInitialised]);

  const onCreateClicked = () => {
    setFormInitialised(false);
    setModalOpen(true);
  };

  const handleClose = (updated) => {
    setModalOpen(false);
    setTimeout(() => onClose(updated), 500);
  };

  // const onSubmit = (values) => {
  //   setSaving(true);
  //   const updatedDashboard = { ...values };

  //   if (existingDashboard) {
  //     const { id } = existingDashboard;
  //     console.log(existingDashboard);
  //     api.updateDashboard(id, updatedDashboard).then(() => {
  //       setSaving(false);
  //       console.log(updatedDashboard);
  //       handleClose({ id, ...updatedDashboard });
  //     }).catch(() => {
  //       console.error("Failed to edit dashboard", error);
  //       setSaving(false);
  //     });
  //   } else {
  //     api.createDashboard(updatedDashboard).then((id) => {
  //       setSaving(false);
  //       handleClose({ id, ...updatedDashboard });
  //       onNewDashboard();
  //     }).catch(() => {

  //       setSaving(false);
  //     });
  //   }
  // };

  const onSubmit = async (values) => {
    setSaving(true);
    const updatedDashboard = { ...values };

    try {
      if (existingDashboard) {
        const { id } = existingDashboard;
        console.log(existingDashboard);
        await api.updateDashboard(id, updatedDashboard);
        console.log(updatedDashboard);
        handleClose({ id, ...updatedDashboard });
        onNewDashboard();
      } else {
        const id = await api.createDashboard(updatedDashboard);
        handleClose({ id, ...updatedDashboard });
        onNewDashboard();
      }
    } catch (error) {
      if (existingDashboard) {
        console.error('Failed to edit dashboard', error);
      } else {
        console.error('Failed to create new dashboard', error);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    // Prevent interaction with modal from triggering panel open/close
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={onCreateClicked}
      >
        Add
      </Button>

      <CustomModal
        title={existingDashboard ? 'Edit Dashboard' : 'Create Dashboard'}
        centered
        visible={modalOpen}
        onCancel={() => handleClose()}
        footer={[
          <Button
            key="cancel"
            onClick={() => handleClose()}
            disabled={saving}
          >
            Cancel
          </Button>,
          <Button
            form="dashboardForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={saving}
            disabled={disableButton}
          >
            {existingDashboard ? 'Save' : 'Create'}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={form}
            id="dashboardForm"
            layout="vertical"
            onFinish={onSubmit}
            initialValues={existingDashboard || {}}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the title' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="dashboardLink"
              label="Dashboard Link URL"
              rules={[{ required: true, type: 'url', message: 'Please input a valid Dashboard URL' }]}
            >
              <Input prefix={<LinkOutlined className="field-icon-prefix" />} />
            </Form.Item>
            <Form.Item
              name="content"
              label="Content"
              rules={[{ required: true, message: 'Please input a description of the report' }]}
            >
              <Input.TextArea />
            </Form.Item>
            {/* <Form.Item
              name="embedable"
              label="Embedable"
              valuePropName="checked"
            >
              <Checkbox>Is Embedable</Checkbox>
            </Form.Item>

            <Form.Item
              name="embedLink"
              label="Embed Link URL"
              rules={[{ type: 'url', message: 'Please input a valid EMBED URL' }]}
            >
              <Input prefix={<LinkOutlined className="field-icon-prefix" />} />
            </Form.Item> */}

            <Form.Item
              name="authorName"
              label="Author Name"
              rules={[{ required: true, message: 'Please input the author name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="authorEmail"
              label="Author Email"
              rules={[{ required: true, type: 'email', message: 'Please input a valid email' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};

DashboardEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  existingDashboard: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

DashboardEditor.defaultProps = {
  existingDashboard: null,
  disableButton: false,
};

export default DashboardEditor;
