import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomStepper.less';
import { Steps, Button } from 'antd';

const { Step } = Steps;

const CustomStepper = ({
  onNext, onPrevious, submitButton, steps, submitting,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    onNext(() => setCurrentStep((current) => current + 1));
  };

  const handlePrevious = () => {
    onPrevious(() => setCurrentStep((current) => current - 1));
  };

  const totalActiveSteps = steps.filter((step) => !step.inactive).length;

  return (
    <div className="custom-stepper">
      <Steps size="small" current={currentStep}>
        {steps.filter((step) => !step.inactive).map(({ title }) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
      {/* Render all steps up to and including the current step */}
      {steps
        .filter((step) => !step.inactive)
        .map(({ title, content }, index) => (index > currentStep ? null : (
        // Setting active and inactive classes allows content for all previous steps to render
        // but remain hidden if not current. This allows forms to return field values across
        // multiple steps when submitting.
          <div
            key={title}
            className={`steps-content ${index === currentStep ? 'active' : 'inactive'}`}
          >
            {content}
          </div>
        )))}
      <div className="steps-action">
        {currentStep > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={handlePrevious}
            disabled={submitting}
          >
            Previous
          </Button>
        )}
        {currentStep < totalActiveSteps - 1 && (
          <Button
            type="primary"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        {currentStep === totalActiveSteps - 1 && submitButton }
      </div>
    </div>
  );
};

CustomStepper.propTypes = {

  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  submitButton: PropTypes.node.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
  })).isRequired,
  submitting: PropTypes.bool,

};

CustomStepper.defaultProps = {
  onNext: ((success) => success()),
  onPrevious: ((success) => success()),
  submitting: false,
};

export default CustomStepper;
