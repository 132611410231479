import React, { useContext, useState, useEffect } from 'react';
import './Webstore.less';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as api from 'util/api.js';
import { AuthContext } from 'util/Auth';
import STORES from 'config/stores';
import AccessDenied from 'pages/AccessDenied/AccessDenied';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { getCountryFromIP } from 'util/geolocation';
import RequestProducts from './RequestProducts/RequestProducts';
import OrderMerch from './OrderMerch/OrderMerch';
import ReviewWebstoreRequests from './ReviewWebstoreRequests/ReviewWebstoreRequests';
import ShippingRates from './ShippingRates/ShippingRates';
import SiteBuilds from './SiteBuilds/SiteBuilds';
import FreshClub from './FreshClub/FreshClub';
import AddOnetimes from './AddOnetimes/AddOnetimes';

const WEBSTORE_ADMINS = [
  'dev@garageproject.co.nz',
  'leyton@garageproject.co.nz',
  'tyler@garageproject.co.nz',
  'kyle@garageproject.co.nz',
  'websales@garageproject.co.nz',
  'websales@garageproject.com.au',
  'tyler.h@garageproject.co.nz',
  'asif@garageproject.co.nz',
];

const Webstore = () => {
  const { currentUser } = useContext(AuthContext);

  // Find the country the user is in to determine which store to initally set.
  const [defaultStore, setDefaultStore] = useState();
  useEffect(async () => {
    const country = await getCountryFromIP();
    setDefaultStore(country.code === 'AU' ? STORES.GPAU : STORES.GPNZ);
  }, []);

  const webstoreAdmin = WEBSTORE_ADMINS.includes(currentUser.email);
  return !defaultStore ? (<FullPageLoader />) : (
    <Switch>
      <Route
        exact
        path="/dashboard/webstore/request"
        render={() => (
          <RequestProducts
            webstoreAdmin={webstoreAdmin}
            defaultStore={defaultStore}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/review-requests"
        // TODO: Implement fine grained permissions
        // render={() => (currentUser.admin ? <ReviewWebstoreRequests /> : <AccessDenied />)}
        render={() => (
          webstoreAdmin ? (
            <ReviewWebstoreRequests defaultStore={defaultStore} />
          ) : (
            <AccessDenied />
          )
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/shipping-rates"
        render={() => (
          webstoreAdmin ? (
            <ShippingRates />
          ) : (
            <AccessDenied />
          )
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/builds"
        render={() => (
          webstoreAdmin ? (
            <SiteBuilds />
          ) : (
            <AccessDenied />
          )
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/order-merch"
        render={() => (
          <OrderMerch
            webstoreAdmin={webstoreAdmin}
            defaultStore={defaultStore}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/fresh-club"
        render={() => (
          <FreshClub
            webstoreAdmin={webstoreAdmin}
            defaultStore={defaultStore}
          />
        )}
      />
      <Route
        exact
        path="/dashboard/webstore/fresh-club-onetimes"
        render={() => (
          <AddOnetimes
            webstoreAdmin={webstoreAdmin}
            defaultStore={defaultStore}
          />
        )}
      />
      <Redirect to="/dashboard/webstore/request" />
    </Switch>
  );
};

export default Webstore;
