import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as api from 'util/api.js';

export const MiscDataContext = React.createContext();

export const MiscDataProvider = ({ children }) => {
  const [miscData, setMiscData] = useState(null);

  // Fetch miscellaneous data once per browser session
  useEffect(() => {
    api.getMiscellaneousData().then(setMiscData);
  }, []);

  return (
    <MiscDataContext.Provider value={miscData}>
      {children}
    </MiscDataContext.Provider>
  );
};

MiscDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
