import React from 'react';

export default () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 282 186"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          className="st0"
          d="M126.5,1.4c1.3,0.4,2.7,0.7,3.9,1.3c3.4,1.6,5.9,4,6.5,7.9c0.4,2.7,0.5,5.5,0.7,8.3c0,0.2,0,0.5,0,0.7
          c0.9,4.9-1.6,8.1-5.4,10.7c-0.4,0.3-0.8,0.5-1.3,0.8c0.2,0.3,0.3,0.6,0.5,0.9c3.3,5.5,6.7,10.9,10,16.4c1,1.6,0.8,1.9-1.1,2.1
c-2.7,0.2-5.5,0.3-8.2,0.5c-1.3,0.1-2.2-0.4-2.9-1.6c-2.5-4.4-5-8.7-7.5-13c-0.1-0.3-0.3-0.5-0.6-0.7c0.2,2.3,0.3,4.6,0.5,6.9
c0.2,2.5,0.4,5,0.6,7.6c0.1,1.1-0.2,1.5-1.3,1.5c-3,0.2-5.9,0.4-8.9,0.6c-1.3,0.1-1.6-0.2-1.7-1.5c-0.5-6.1-0.9-12.1-1.4-18.2
c-0.6-7.9-1.2-15.8-1.8-23.7c-0.1-1.6-0.2-3.2-0.3-4.8c-0.1-1,0.3-1.5,1.3-1.6c2.7-0.2,5.4-0.3,8.1-0.5c1.7-0.1,3.5-0.3,5.2-0.4
C123,1.4,124.7,1.4,126.5,1.4z M119.2,10.7c0.3,4.4,0.7,8.8,1,13.3c1.2-0.1,2.3-0.1,3.4-0.2c1.4-0.1,2.2-1,2.1-2.4
c-0.2-2.9-0.4-5.9-0.6-8.8c-0.1-1.2-0.9-2-2.2-2C121.6,10.4,120.5,10.6,119.2,10.7z"
        />
        <path
          className="st0"
          d="M0.7,90.8c0.2-0.6,0.4-1.2,0.5-1.7c1.9-6,7.5-9.5,13.9-8.7c5.8,0.8,10.3,5.9,10.6,12
c0.3,5.9-3.9,11.3-9.8,12.6c-6.8,1.5-13.5-2.8-15-9.6c0-0.2-0.2-0.4-0.2-0.7C0.7,93.5,0.7,92.1,0.7,90.8z M19.2,92.9
c0-3.4-2.7-6.1-6.1-6C9.7,86.9,7,89.6,7,93s2.7,6.1,6.1,6C16.6,98.9,19.2,96.3,19.2,92.9z"
        />
        <path
          className="st0"
          d="M50.8,48.9c-0.9,0.3-1.7,0.6-2.7,0.9c-1.3-2.9-2.7-5.8-4-8.8c3.5-1.3,7-2.6,10.5-3.8c3.8-1.4,3.2-1.2,4.7,2
c2.4,5.1,4.7,10.2,7,15.3c0.2,0.3,0.3,0.7,0.3,1.1c0.1,5-1.8,8.9-6.3,11.1c-3.4,1.7-7,3.2-11,3.1c-3.8-0.1-7.3-1.3-9.7-4.4
c-0.9-1.2-1.5-2.6-2.1-4c-3.8-8.2-7.4-16.5-11.4-24.7c-2.6-5.4-0.5-10.3,3.2-12.9c3.7-2.7,8-4.2,12.6-4.4c3.4-0.1,6.5,1,9,3.3
c1.5,1.3,2.3,3.1,2.9,4.9c0.1,0.4-0.2,1.2-0.5,1.5c-2.5,2-5,3.9-7.5,5.8c-0.8,0.6-1.3,0.4-1.7-0.5c-0.6-1.3-1.2-2.7-1.9-4
c-0.5-1-1.3-1.6-2.4-1.4c-1.2,0.2-2.5,0.5-3,1.9c-0.1,0.4,0,1,0.2,1.4c4.1,8.9,8.1,17.8,12.2,26.7c0.4,0.9,1.1,1.4,1.9,1.3
c1-0.2,2-0.6,2.9-1.1c0.6-0.4,0.8-1.1,0.4-1.9C53.4,54.5,52.1,51.7,50.8,48.9z"
        />
        <path
          className="st0"
          d="M202.7,39.5c-0.9-0.2-1.8-0.4-2.7-0.7c0.8-3,1.6-6.1,2.5-9.2c1.2,0.2,2.3,0.5,3.4,0.7c3.1,0.7,6.1,1.4,9.2,2
c1.5,0.3,1.7,0.7,1.3,2.2c-1.4,5.2-2.9,10.3-4.2,15.5c-1.2,4.9-4.5,7.6-9.2,8.5c-5.3,1-10.4-0.3-15-2.9c-1.5-0.8-2.7-2.3-3.7-3.7
c-1.6-2.2-1.9-4.7-1.1-7.3c2.6-9.4,5.2-18.7,7.7-28.1c1.3-4.9,4.6-7.5,9.3-8.3c5-0.9,9.7,0.4,14.2,2.5c1.2,0.5,2.2,1.5,3.1,2.4
c3,3.2,3.5,6.9,1.9,10.9c-0.2,0.5-0.9,0.9-1.4,1c-3.2,0.3-6.5,0.5-9.7,0.7c-1.3,0.1-1.5-0.2-1.2-1.5c0.4-1.4,0.8-2.7,1.1-4.1
c0.1-0.3,0.1-0.8,0-1.1c-0.3-1.1-1.7-1.7-3.3-1.7c-1.2,0-1.9,0.6-2.2,1.8c-1.6,6-3.3,11.9-4.9,17.9c-0.9,3.2-1.8,6.4-2.6,9.6
c-0.4,1.5,0.1,2.5,1.6,2.9c2,0.6,3.3,0.1,3.8-1.4C201.1,45.2,201.9,42.4,202.7,39.5z"
        />
        <path
          className="st0"
          d="M164.9,43.1c-2.1-0.2-4.2-0.4-6.2-0.6c-0.6,2.6-1.1,5-1.6,7.5c-0.3,1.3-0.7,1.6-1.9,1.5c-3-0.3-6-0.5-9-0.8
c-1.2-0.1-1.5-0.5-1.1-1.9c3.1-11.1,6.1-22.1,9.2-33.2c1.1-4.1,2.3-8.2,3.4-12.3c0.4-1.4,0.6-1.6,1.9-1.5c4.5,0.4,9,0.8,13.5,1.3
c1,0.1,1.3,0.7,1.4,1.6c0.3,7.3,0.7,14.6,1.1,21.9c0.4,8.4,0.8,16.7,1.2,25.1c0.1,1.6-0.2,1.9-1.8,1.7c-2.9-0.3-5.7-0.5-8.6-0.8
c-1.2-0.1-1.5-0.5-1.5-1.8c0-2.2,0-4.4,0.1-6.5C164.9,43.9,164.9,43.5,164.9,43.1z M165,33.7c0-0.3,0.1-0.4,0.1-0.5
c0-5.7,0.1-11.4,0.1-17.1c0-0.3-0.2-0.5-0.3-0.8c-0.2,0.2-0.5,0.4-0.6,0.7c-0.9,4.1-1.8,8.2-2.7,12.3c-0.4,1.6-0.7,3.3-1.1,5
C162.1,33.4,163.5,33.5,165,33.7z"
        />
        <path
          className="st0"
          d="M90.8,46.9c-2.1,0.5-4,0.9-6.1,1.4c0.4,2.5,0.8,5,1.3,7.4c0.3,1.4,0,1.9-1.4,2.2c-2.7,0.6-5.5,1.3-8.2,1.9
c-1.6,0.4-1.9,0.1-2.1-1.5c-0.8-7.2-1.6-14.3-2.4-21.5c-0.9-7.7-1.7-15.3-2.6-23c-0.1-0.8-0.1-1.5-0.3-2.3
c-0.1-0.9,0.2-1.5,1.1-1.7c4.4-1,8.8-2,13.2-3C84.4,6.6,85,7.1,85.4,8c4.6,10.2,9.3,20.4,13.9,30.6c2,4.3,3.9,8.6,5.9,12.9
c0.6,1.2,0.4,1.6-0.9,1.9c-2.9,0.7-5.8,1.3-8.7,2c-1.1,0.3-1.7,0-2.1-1.2C92.7,51.9,91.8,49.4,90.8,46.9z M87.5,38
c-2.3-5.8-4.5-11.5-6.8-17.2c-0.1,0-0.2,0-0.4-0.1c-0.1,0.3-0.3,0.7-0.2,1c0.7,4.4,1.5,8.9,2.2,13.3c0.2,1.4,0.5,2.7,0.7,4.1
C84.6,38.7,86,38.4,87.5,38z"
        />
        <path
          className="st0"
          d="M242.2,31.5c-1.4,2.8-2.7,5.6-4.1,8.5c0.4,0.2,0.7,0.3,1,0.4c2.6,1,5.3,2.1,7.9,3.1c1.3,0.5,1.5,0.9,0.9,2.2
c-0.9,2-1.9,3.9-2.8,5.9c-0.6,1.2-1,1.4-2.3,0.9c-3-1.1-5.9-2.3-9-3.5c-1.6,3.4-3.3,6.7-4.9,10.2c2,0.8,3.9,1.5,5.8,2.3
c1.6,0.6,3.2,1.2,4.7,1.9c1.2,0.5,1.3,0.8,0.8,2c-0.9,2-1.9,4-2.9,6c-0.6,1.3-1,1.5-2.4,0.9c-4.3-1.7-8.6-3.4-12.9-5.1
c-2.2-0.9-4.4-1.7-6.7-2.6c-1.5-0.6-1.7-0.9-1-2.4c5.4-11.3,10.9-22.5,16.3-33.8c1.4-2.9,2.8-5.7,4.1-8.6c0.6-1.3,1-1.5,2.4-1
c6.6,2.6,13.3,5.2,19.9,7.8c1.2,0.5,1.4,0.9,0.8,2.1c-1,2-1.9,4-2.9,6c-0.6,1.2-1,1.4-2.3,0.9c-3.1-1.2-6.2-2.4-9.3-3.6
C243,31.8,242.6,31.7,242.2,31.5z"
        />
        <path
          className="st0"
          d="M87.5,160.2c0.3,1.8,0.5,3.6,0.8,5.3c0.7,4.3,1.3,8.5,2,12.8c0.2,1.5-0.1,1.8-1.6,1.4c-1.9-0.5-3.8-1-5.7-1.4
c-1.4-0.3-1.7-0.7-1.8-2.1c-0.6-4.5-1.2-8.9-1.7-13.4c0-0.2-0.1-0.5-0.3-0.7c-0.6,2.2-1.1,4.5-1.7,6.7c-0.5,2.1-1.1,4.2-1.5,6.2
c-0.2,1-0.8,1.3-1.7,1c-2.1-0.5-4.2-1-6.2-1.5c-0.9-0.2-1.2-0.7-1-1.7c3.6-14.3,7.2-28.6,10.7-42.9c0.3-1.1,0.6-1.3,1.7-1
c3.8,0.9,7.6,1.9,11.4,2.8c5,1.3,8.1,6.6,7,11.6c-0.8,3.2-1.6,6.4-2.4,9.7c-0.9,3.5-3.4,5.4-6.6,6.7C88.3,159.9,88,160,87.5,160.2
z M84.9,139.1c-1,4.1-2,8.1-3,12.1c0.5,0.1,0.8,0.2,1.1,0.3c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,1.9-0.3,2.2-1.4
c0.7-2.8,1.4-5.6,2.1-8.4c0.3-1.1-0.2-1.9-1.3-2.3C86.5,139.4,85.8,139.3,84.9,139.1z"
        />
        <path
          className="st0"
          d="M113.8,183.8c-1-0.1-2.6-0.2-4.2-0.5c-4.8-0.9-8.3-5.8-7.8-10.6c1-9.4,2.1-18.8,3.1-28.2
c0.6-5.3,5.2-9.2,10.5-8.8c2.7,0.2,5.4,0.3,7.7,1.9c3.2,2.3,4.7,5.5,4.3,9.4c-1,9.4-2,18.7-3,28.1
C123.9,180,119.4,184,113.8,183.8z M113.3,175.3c1.4,0,2-0.6,2.2-2.4c0.9-8.7,1.9-17.5,2.8-26.2c0.1-1.4-0.5-2.2-1.7-2.3
c-1.6-0.2-2.4,0.4-2.5,1.8c-0.2,2-0.4,4.1-0.7,6.1c-0.7,6.9-1.5,13.8-2.2,20.6C110.9,174.4,111.6,175.2,113.3,175.3z"
        />
        <path
          className="st0"
          d="M216.1,166.9c0,4.4-2.8,8.2-7,9.4c-2,0.6-4.1,1.1-6.2,1.1c-4.4,0-8.1-3-9.2-7.2c-2.4-9.2-4.8-18.4-7.2-27.6
c-1.3-5,1.6-10.2,6.6-11.7c2-0.6,4-1.1,6.1-1.2c4.2-0.2,8,2.8,9.3,6.9c0.5,1.6,0.8,3.2,1.3,4.8c0.3,1-0.1,1.6-1.1,2
c-2,0.8-4.1,1.7-6.1,2.6c-0.8,0.4-1.2,0.1-1.3-0.7c-0.5-1.8-0.9-3.6-1.4-5.4c-0.4-1.4-1.2-1.8-2.6-1.5c-1.4,0.4-1.9,1.2-1.6,2.6
c2.2,8.7,4.5,17.3,6.8,26c0.5,1.8,2.5,2.3,3.7,1c0.4-0.4,0.6-1.3,0.5-1.9c-0.6-2.9-1.4-5.7-2.2-8.5c-0.3-1.2-0.3-1.3,0.9-1.5
c2.2-0.3,4.4-0.5,6.6-0.8c0.8-0.1,1.4,0.2,1.7,1.1c0.8,2.9,1.5,5.8,2.2,8.7C216.1,165.8,216.1,166.3,216.1,166.9z"
        />
        <path
          className="st0"
          d="M48.7,149.7c-1.2,2.9-2.4,5.7-3.5,8.5c-0.8,1.9-1.6,3.8-2.4,5.6c-0.5,1.1-0.8,1.2-1.9,0.8
c-1.9-0.8-3.8-1.6-5.7-2.3c-1.3-0.5-1.4-0.8-0.9-2.1c5.1-12.2,10.2-24.4,15.3-36.6c0.5-1.3,1.1-2.6,1.6-3.9
c0.4-1.1,0.8-1.2,1.8-0.8c3.6,1.5,7.2,3,10.8,4.5c4.7,2.1,7.1,7.6,5.2,12.4c-1.3,3.5-2.8,6.9-4.3,10.3c-2.2,4.8-7.7,6.9-12.6,5
C51.1,150.7,50,150.2,48.7,149.7z M52,141.9c0.8,0.3,1.4,0.6,2.1,0.9c1.2,0.5,2,0.1,2.5-1c1.3-2.9,2.5-5.9,3.7-8.8
c0.4-1,0-1.9-0.9-2.3c-0.7-0.4-1.5-0.6-2.3-1C55.4,133.7,53.7,137.8,52,141.9z"
        />
        <path
          className="st0"
          d="M169.7,145.1c0.4,2.9,0.7,5.8,1.1,8.7c1.7-0.2,3.3-0.4,5-0.6c0.9-0.1,1.8-0.2,2.7-0.3c0.8-0.1,1.2,0.3,1.3,1
c0.3,2.2,0.6,4.5,0.8,6.7c0.1,0.9-0.3,1.3-1.2,1.4c-2.2,0.3-4.5,0.5-6.7,0.8c-0.2,0-0.4,0.1-0.7,0.2c0.4,3.4,0.8,6.8,1.3,10.3
c2.3-0.3,4.5-0.5,6.7-0.8c0.7-0.1,1.4-0.2,2.2-0.3c0.7-0.1,1.1,0.2,1.2,0.9c0.3,2.2,0.5,4.5,0.8,6.7c0.1,1-0.3,1.4-1.3,1.5
c-5.3,0.6-10.6,1.3-15.9,2c-1.6,0.2-1.8,0-2-1.6c-1.6-12.7-3.2-25.3-4.8-38c-0.2-1.7-0.4-3.4-0.6-5.2c-0.2-1.4,0-1.6,1.4-1.8
c5.3-0.7,10.6-1.3,15.9-2c1.3-0.2,1.6,0.1,1.8,1.4c0.3,2.1,0.5,4.2,0.8,6.3c0.1,0.9-0.2,1.4-1.1,1.5
C175.5,144.4,172.7,144.8,169.7,145.1z"
        />
        <path
          className="st0"
          d="M153.3,157.2c0,3.2,0,6.4,0,9.7c0,2.6,0.1,5.2,0.1,7.8c0.2,5.9-4.2,10.5-10.2,10.5c-2.2,0-4.4,0.2-6.5-0.8
c-3.7-1.7-5.8-4.6-6-8.6c-0.2-2.8-0.1-5.7-0.1-8.5c0-1,0.4-1.5,1.3-1.7c2.2-0.6,4.3-1.2,6.5-1.8c1.2-0.3,1.4-0.2,1.4,1
c0.1,3.3,0.1,6.6,0.2,9.9c0,1.5,0.8,2.2,2.2,2.1c1.4,0,2.1-0.8,2.1-2.3c-0.1-7.4-0.2-14.7-0.3-22.1c-0.1-4.4-0.1-8.9-0.2-13.3
c0-1.1,0.3-1.5,1.5-1.5c2.1,0,4.2,0,6.3-0.1c1,0,1.4,0.4,1.4,1.4c0,3.7,0.1,7.3,0.1,11c0,2.4,0,4.8,0,7.3
C153.2,157.2,153.3,157.2,153.3,157.2z"
        />
        <path
          className="st0"
          d="M219.4,132.9c-1.6,0.7-3.1,1.3-4.6,1.9c-1.2,0.5-1.5,0.4-2-0.8c-0.8-1.9-1.6-3.8-2.4-5.8
c-0.5-1.1-0.3-1.5,0.8-1.9c6-2.5,11.9-4.9,17.9-7.4c1.2-0.5,1.5-0.4,2.1,0.9c0.8,1.9,1.5,3.7,2.3,5.6c0.5,1.3,0.4,1.6-0.9,2.1
c-1.5,0.6-3,1.2-4.6,1.9c0.7,1.8,1.4,3.5,2.1,5.2c3.9,9.4,7.7,18.8,11.6,28.2c0.6,1.3,0.5,1.6-0.9,2.1c-1.8,0.8-3.6,1.5-5.5,2.3
c-1.3,0.5-1.7,0.3-2.2-1c-4.4-10.7-8.8-21.4-13.2-32.1C219.8,133.7,219.6,133.4,219.4,132.9z"
        />
        <path
          className="st0"
          d="M279.3,92.9c0.3,5.6-4.4,12.4-12.4,12.5c-6.9,0.1-12.5-5.6-12.5-12.5c0-7,5.6-12.6,12.5-12.6
C274.9,80.3,279.7,87.2,279.3,92.9z M266.8,98.9c3.4,0,6-2.7,6-6.1s-2.7-6.1-6.1-6c-3.4,0-6,2.7-6,6.1
C260.7,96.3,263.4,98.9,266.8,98.9z"
        />
        <path
          className="st0"
          d="M105.6,79.2c0-0.9,0-1.6,0-2.4c0.3,0,0.6-0.1,0.9-0.1c4.1,0,8.2,0,12.3,0c1,0,2.1,0.2,3,0.5
c1.9,0.6,2.9,2.1,2.8,4.1c-0.1,1.9-1.1,3.2-3.1,3.8c-0.2,0.1-0.4,0.1-0.8,0.3c0.5,0.2,0.8,0.3,1.1,0.5c1.2,0.5,1.8,1.4,1.8,2.7
c0,1-0.1,2,0,3c0,0.4,0.4,1,0.8,1.1c0.7,0.2,0.9-0.5,1-1s0-1.1,0-1.7c0.7,0,1.3,0,2,0c0,0.9,0,1.7-0.2,2.5
c-0.4,2.3-2.2,3.4-4.7,3.2c-2.8-0.2-4.1-1.7-4.1-4.5c0-0.9,0-1.7,0-2.6c0-1.4-0.4-1.8-1.8-1.8c-0.9,0-1.9,0-2.9,0
c0,1.7-0.1,3.3,0,5c0,0.3,0.6,0.7,1,0.8c0.5,0.1,1,0,1.6,0c0,0.8,0,1.6,0,2.4c-3.5,0-7,0-10.6,0c0-0.8,0-1.5,0-2.4
c0.4,0,0.9,0,1.3,0c1,0,1.4-0.5,1.4-1.4c0-3.6,0-7.1,0-10.7c0-0.9-0.4-1.4-1.4-1.4C106.5,79.2,106.1,79.2,105.6,79.2z M113.6,84.5
c1.4-0.1,2.7-0.1,4-0.3c1.2-0.2,1.6-0.9,1.6-2.4c0-1.3-0.5-2.2-1.6-2.4c-1.3-0.2-2.7-0.2-4.1-0.3C113.6,81,113.6,82.6,113.6,84.5z
"
        />
        <path
          className="st0"
          d="M138.4,76.2c3.8,0.1,7.1,1.2,9,4.7c2.7,5,0.9,11.5-3.8,13.8c-4.2,2.1-10.2,1.2-12.9-2
c-2.9-3.4-3.1-7.3-1.4-11.2C131,77.5,134.4,76.3,138.4,76.2z M142.7,85.9c-0.3-1.6-0.4-3.2-0.9-4.7c-0.5-1.7-1.7-2.5-3.3-2.4
c-1.6,0-2.8,0.8-3.3,2.5c-1,3.1-1,6.2,0,9.3c0.5,1.7,1.8,2.5,3.4,2.5c1.6,0,2.9-0.8,3.4-2.6C142.3,89.1,142.4,87.5,142.7,85.9z"
        />
        <path
          className="st0"
          d="M172.8,77.7c0.7-1.4,0.8-1.4,2.9-1.1c0,2.2,0,4.4,0,6.7c-0.8,0-1.6,0-2.5,0c-0.6-2.5-2.1-4-4.7-4.4
c-0.8-0.1-1.7,0.1-2.5,0.3c-0.9,0.2-1.3,1-1.3,1.9s0.6,1.3,1.3,1.5c1.5,0.4,3,0.7,4.5,1.1c1.1,0.3,2.1,0.6,3.1,1.1
c2.1,1,3.1,2.7,3.1,5s-1,4.1-3.2,5c-3.6,1.5-7.1,1.2-10.6-0.7c-0.4,1.8-1.7,1.5-3,1.3c0-2.4,0-4.7,0-7.2c0.9,0,1.7,0,2.4,0
c0.4,0.8,0.7,1.7,1.2,2.5c1.2,1.9,4,2.8,6.2,2.2c0.3-0.1,0.8-0.2,0.9-0.4c0.4-0.6,1-1.3,1-1.9c0-0.5-0.7-1.2-1.2-1.5
c-1.2-0.5-2.5-0.7-3.7-1c-1.5-0.4-3-0.8-4.4-1.5c-1.9-0.9-2.7-2.6-2.6-4.7c0-2.1,0.8-3.8,2.8-4.7c3.1-1.4,6.3-1.5,9.5,0
C172.2,77.4,172.5,77.5,172.8,77.7z"
        />
        <path
          className="st0"
          d="M104.4,92.6c0,0.9,0,1.6,0,2.5c-3.4,0-6.7,0-10.1,0c0-0.8,0-1.5,0-2.4c0.4,0,0.8,0,1.2,0
c1.1-0.1,1.2-0.3,1-1.4c-0.2-0.6-0.4-1.3-0.6-2c-1.7,0-3.5,0-5.2,0c-0.2,0-0.4,0.3-0.5,0.5c-0.1,0.3-0.2,0.6-0.3,0.9
c-0.6,1.8-0.5,2,1.4,2c0.2,0,0.4,0,0.7,0c0,0.8,0,1.6,0,2.4c-2.7,0-5.3,0-8,0c0-0.8,0-1.5,0-2.5c2,0.5,2.4-1,2.9-2.4
c1.4-4.2,2.8-8.3,4.1-12.5c0.3-0.9,0.7-1.2,1.6-1.1c1.2,0.1,2.4,0.1,3.5,0c0.7,0,0.9,0.2,1.2,0.9c1.4,4.3,2.9,8.5,4.3,12.8
C101.9,91.8,102.3,93.1,104.4,92.6z M93.1,81.3c-0.1,0-0.2,0-0.2,0c-0.6,1.8-1.2,3.6-1.8,5.5c1.4,0,2.5,0,3.9,0
C94.3,84.9,93.7,83.1,93.1,81.3z"
        />
        <path
          className="st0"
          d="M181.3,83.7c-0.9,0-1.7,0-2.6,0c0-2.3,0-4.6,0-6.9c6.1,0,12.2,0,18.4,0c0,2.3,0,4.6,0,6.9c-0.8,0-1.6,0-2.5,0
c-0.3-1.4-0.1-3.1-1.7-3.9c-0.7-0.4-1.5-0.5-2.5-0.8c0,1.2,0,2.2,0,3.2c0,2.8,0,5.6,0,8.4c0,1.9,0.1,2,2,2.1c0.2,0,0.4,0,0.7,0.1
c0,0.8,0,1.6,0,2.4c-3.6,0-7.1,0-10.7,0c0-0.8,0-1.6,0-2.5c0.4,0,0.8,0,1.2,0c1.3,0,1.6-0.3,1.6-1.5c0-3.7,0-7.4,0-11.1
c0-0.3,0-0.5-0.1-0.8C182.6,79.2,181.5,80.4,181.3,83.7z"
        />
        <path
          className="st0"
          d="M72.6,102.3c0,0.5,0,0.9,0,1.5c-1.2,0.1-1,0.8-0.7,1.6c0.6,1.9,1,3.8,1.8,5.7c0.5-1.7,1-3.4,1.4-5.1
c0.2-1-0.5-1.9-1.5-2.2c0-0.5,0-0.9,0-1.5c1.9,0,3.8,0,5.7,0c0,0.5,0,1,0,1.4c-1.4,0.4-1.4,0.4-1,1.9c0.4,1.7,0.9,3.5,1.6,5.2
c0.5-2.1,1.1-4.2,1.5-6.3c0-0.2-0.6-0.6-0.9-1c-0.1,0.1-0.2,0.1-0.2,0.2c0-0.5,0-0.9,0-1.5c1.5,0,2.9,0,4.5,0c0,0.5,0,0.9,0,1.5
c-1,0.1-1.3,0.9-1.5,1.7c-0.7,2.7-1.4,5.4-2.1,8.1c-0.2,0.6-0.4,0.8-1.1,0.8c-2.6,0-2.6,0-3.3-2.5c-0.2-0.7-0.4-1.4-0.8-2.2
c-0.4,1.3-0.8,2.6-1.1,3.9c-0.1,0.5-0.4,0.8-1,0.8c-2.7,0-2.7,0.1-3.4-2.5c-0.6-2.1-1.2-4.3-1.8-6.4c-0.2-0.7-0.4-1.4-1.2-1.8
c-0.3-0.1-0.3-0.9-0.4-1.5C68.9,102.3,70.7,102.3,72.6,102.3z"
        />
        <path
          className="st0"
          d="M138.5,103.8c0-0.5,0-1,0-1.6c1.9,0,3.7,0,5.5,0c0.3,0,0.6,0.4,0.8,0.7c1.2,2.1,2.4,4.3,3.6,6.4
c0.2,0.3,0.3,0.6,0.7,1.1c0-2.2,0-4,0-5.9c0-0.3-0.4-0.6-0.7-0.7s-0.7-0.1-1.1-0.1c0-0.5,0-1,0-1.5c1.9,0,3.7,0,5.6,0
c0,0.5,0,1,0,1.4c-0.1,0-0.1,0.1-0.2,0.1c-1.6,0.2-1.6,0.2-1.6,1.9c0,2.9,0,5.7,0,8.8c-1.2,0-2.3,0-3.4,0c-0.2,0-0.5-0.4-0.7-0.7
c-1.4-2.4-2.7-4.8-4-7.1c-0.2-0.3-0.4-0.6-0.7-1.1c0,2.4,0,4.4,0,6.5c0,0.3,0.4,0.6,0.7,0.7s0.7,0.1,1.1,0.1c0,0.5,0,1,0,1.5
c-1.8,0-3.6,0-5.6,0c0-0.5,0-0.9-0.1-1.5c0.3,0,0.5-0.1,0.7-0.1c0.8,0.1,1.2-0.3,1.2-1.2c0-2,0-4,0-6
C140.2,104,140.2,104,138.5,103.8z"
        />
        <path
          className="st0"
          d="M208.2,103.8c0-0.6,0-1,0-1.5c1.9,0,3.7,0,5.6,0c0,0.5,0,0.9,0,1.4l-0.1,0.1c-1.6,0.2-1.6,0.2-1.6,1.9
c0,2.6,0,5.1,0,7.7c0,0.3,0,0.7,0,1.1c-1.2,0-2.3,0-3.4,0c-0.3,0-0.6-0.4-0.8-0.7c-1.3-2.3-2.6-4.6-3.9-7
c-0.2-0.3-0.4-0.6-0.8-0.9c0,1.3,0,2.6,0,3.8c0,0.4,0,0.8,0,1.1c0,1.8,0,1.8,1.8,2.1c0,0.5,0,0.9,0,1.5c-1.9,0-3.7,0-5.6,0
c0-0.5,0-1,0-1.4c0.1,0,0.1-0.1,0.2-0.1c1.6-0.1,1.6-0.1,1.6-1.8s0-3.5,0-5.2c0-1.8,0-1.8-1.8-2.1c0-0.5,0-0.9,0-1.5
c1.9,0,3.7,0,5.6,0c0.3,0,0.6,0.4,0.7,0.7c1.4,2.4,2.7,4.8,4.3,7.2c0-1.9,0-3.8,0-5.7c0-0.2-0.4-0.5-0.6-0.5
C209.1,103.8,208.7,103.9,208.2,103.8z"
        />
        <path
          className="st0"
          d="M91.9,107.2c1.5,0.2,1.8,0,2-1.8c0.4,0,0.9,0,1.4,0c0,1.8,0,3.6,0,5.5c-0.5,0-0.9,0-1.3,0
c-0.4-1.9-0.5-2.1-2.1-1.8c0,1.2,0,2.4,0,3.5c3.6,0.7,4.6,0,5.2-3.2c0.5,0,1,0,1.7,0c0,0.9,0,1.7,0,2.5s0,1.6,0,2.4
c-4.1,0-8.1,0-12.2,0c0-0.5,0-1,0-1.5c0.3,0,0.6,0,0.8-0.1c0.4-0.2,0.9-0.6,0.9-0.8c0.1-2.4,0.1-4.8,0-7.3c0-1.2-1.1-0.6-1.7-0.9
c0-0.5,0-1,0-1.5c4.1,0,8.1,0,12.1,0c0,1.5,0,3,0,4.6c-0.5,0-1,0-1.6,0.1c0-0.4-0.1-0.7-0.1-1c-0.2-1.2-0.7-1.8-1.9-2
c-1-0.1-2.1,0-3.1,0C91.9,105.1,91.9,106.1,91.9,107.2z"
        />
        <path
          className="st0"
          d="M197.4,108.4c0,4-2.6,6.5-6.7,6.5c-4.1,0-6.6-2.5-6.6-6.5s2.6-6.5,6.7-6.5
C194.8,101.8,197.4,104.4,197.4,108.4z M193.5,108.3c-0.2-1-0.3-2.1-0.6-3.1s-1.1-1.6-2.2-1.6c-1.1,0-1.9,0.6-2.2,1.6
c-0.7,2.1-0.7,4.2,0,6.2c0.4,1.1,1.2,1.6,2.3,1.6s1.9-0.6,2.2-1.7C193.2,110.4,193.3,109.4,193.5,108.3z"
        />
        <path
          className="st0"
          d="M164.4,102.9c0.2-1.3,1.2-0.8,1.9-0.9c0,1.7,0,3.3,0,5c-0.6,0-1.1,0-1.6,0c-0.2-0.6-0.3-1.1-0.5-1.6
c-0.5-1.2-1.6-1.9-2.8-1.8c-1.3,0.1-2.2,0.8-2.6,2.1c-0.5,1.8-0.5,3.6,0,5.3c0.4,1.4,1.5,2.1,2.9,2c0.9-0.1,1.5-0.4,1.4-1.4v-0.1
c-0.1-1.7,0.4-1.7-1.6-2c0-0.5,0-0.9,0-1.5c2.2,0,4.4,0,6.6,0c0,0.5,0,1,0,1.4c-1.5,0.5-1.5,0.5-1.5,2.1c0,1,0,1.9,0,2.9
c-0.9,0.2-1.7,0.5-2.1-0.7c-3.2,1.9-7.3,0.9-9-1.3c-1.7-2.3-1.6-6.4,0.3-8.6C158,101.7,161.3,101.3,164.4,102.9z"
        />
        <path
          className="st0"
          d="M179.5,112.9c0,0.5,0,1,0,1.5c-2.3,0-4.6,0-7,0c0-0.5,0-1,0-1.5c0.3,0,0.7,0.1,0.9-0.1
c0.3-0.2,0.9-0.6,0.9-0.9c0.1-2.6,0-5.3,0-7.9c-1.7-0.2-2.3,0.5-2.7,2.8c-0.5,0-1,0-1.6,0c0-1.5,0-3,0-4.6c4,0,8,0,12.1,0
c0,1.5,0,3,0,4.6c-0.5,0-1.1,0-1.6,0c-0.6-2.5-1-3-2.7-2.8c0,1.9,0,3.8,0,5.7c0,0.4,0,0.8,0,1.2
C177.7,112.7,177.7,112.7,179.5,112.9z"
        />
        <path
          className="st0"
          d="M111.4,109.4c0.6,0,1.1,0,1.6,0c0,1.7,0,3.3,0,5c-3.8,0-7.6,0-11.5,0c0-0.5,0-1,0-1.4c0,0,0.1-0.1,0.2-0.1
c1.7-0.1,1.7-0.1,1.7-1.9c0-1.7,0-3.5,0-5.2c0-1.9,0.2-1.8-1.8-2c0-0.5,0-0.9,0-1.5c2.3,0,4.6,0,7.1,0c0,0.5,0,1,0,1.5
c-0.3,0-0.7,0-0.9,0.1c-0.3,0.2-0.8,0.5-0.8,0.7c-0.1,2.7,0,5.4,0,8C110,113.3,110.9,112.7,111.4,109.4z"
        />
        <path
          className="st0"
          d="M120.2,112.7c3.7,0.3,4.3-0.2,4.6-3.3c0.5,0,1,0,1.6,0c0,1.6,0,3.3,0,5c-3.8,0-7.6,0-11.4,0c0-0.5,0-1,0-1.5
c0.2,0,0.3-0.1,0.5-0.1c1,0.1,1.3-0.4,1.3-1.4c-0.1-1.8,0-3.7,0-5.5c0-2.3,0.1-1.9-1.8-2.2c0-0.5,0-0.9,0-1.5c2.4,0,4.7,0,7.1,0
c0,0.5,0,1,0,1.5c-0.3,0-0.7,0-0.9,0.1c-0.3,0.2-0.8,0.5-0.8,0.8C120.2,107.4,120.2,110,120.2,112.7z"
        />
        <path
          className="st0"
          d="M128.6,103.8c0-0.5,0-0.9,0-1.5c2.4,0,4.7,0,7.1,0c0,0.5,0,1,0,1.4c-0.1,0.1-0.1,0.1-0.2,0.1
c-1.6,0.1-1.6,0.1-1.6,1.8c0,1.8,0,3.5,0,5.3s0,1.8,1.8,1.9c0,0.5,0,1,0,1.5c-2.4,0-4.7,0-7.1,0c0-0.5,0-1,0-1.5
c0.3,0,0.5-0.1,0.8-0.1c0.7,0,1-0.3,1-1c0-2.4,0-4.7,0-7.1C130.4,103.4,129.3,104.1,128.6,103.8z"
        />
        <path className="st0" d="M9.4,92.9c0-2.1,1.6-3.7,3.7-3.7c2.2,0,3.8,1.6,3.8,3.8c0,2.1-1.7,3.7-3.8,3.7C11.1,96.6,9.4,95,9.4,92.9z" />
        <path
          className="st0"
          d="M266.8,89.1c2.1,0,3.8,1.7,3.7,3.8c0,2.1-1.7,3.7-3.8,3.7c-2.1,0-3.8-1.7-3.8-3.8
C263,90.7,264.7,89.1,266.8,89.1z"
        />
      </g>
    </g>
  </svg>
);
