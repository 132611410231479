/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import * as api from 'util/api.js';

const FALLBACK = {
  code: 'NZ',
  name: 'New Zealand',
};

export const getCountryFromIP = async () => {
  try {
    const response = await axios.get('https://ipwhois.app/json/?objects=country,country_code');
    const { data } = response;
    const { country_code: code, country: name } = data;
    return { code, name };
  } catch (error) {
    api.reportError(error);
    // If there was an issue determining the country, assume NZ.
    return FALLBACK;
  }
};
