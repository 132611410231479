import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './MailchimpUpdateGenerator.less';
import { QuestionOutlined } from '@ant-design/icons';
import {
  Form, Input, Select, Avatar, Typography, Checkbox,
} from 'antd';

const { Text } = Typography;

const { Option } = Select;

const MailchimpUpdateGenerator = ({ products, form }) => (
  <>
    <div className="modal-description-area">
      {products.map((product) => (
        <div key={product.id}>
          <Avatar
            src={product.tapBadgeUrl}
            className="generator-modal-product-avatar"
            icon={<QuestionOutlined />}
          />
          <Text>{product.name}</Text>
        </div>
      ))}
    </div>
    <Form.Item
      name="pricingType"
      label="Pricing type"
      rules={[{ required: true, message: 'Pricing type is required' }]}
    >
      <Select>
        <Option value="onPremise">On Premise</Option>
        <Option value="offPremise">Off Premise</Option>
        <Option value="progressive">Progressive</Option>
        <Option value="vicSa">VIC & SA</Option>
        <Option value="qldWa">QLD & WA</Option>
      </Select>
    </Form.Item>

    <Form.Item
      name="generateIntro"
      valuePropName="checked"
      help="Enabling this will generate a generic email introduction"
    >
      <Checkbox>Generate intro</Checkbox>
    </Form.Item>

    {/* <Form.Item
      name="generateSectionBanners"
      valuePropName="checked"
      help="Enabling this will generate banner headings for each section of products"
    >
      <Checkbox>Generate section banners</Checkbox>
    </Form.Item> */}

    <Form.Item
      name="showCode"
      valuePropName="checked"
      help="Enabling this will display snippets of code for each section of the email which can be copied and inserted into existing email templates"
    >
      <Checkbox>Show code snippets</Checkbox>
    </Form.Item>
  </>
);

MailchimpUpdateGenerator.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Disabled since ant form shape may change
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
};

export default MailchimpUpdateGenerator;
