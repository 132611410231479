import React from 'react';
import { Form, Input } from 'antd';
import { productProperties } from 'config/language';

const { TextArea } = Input;

const PricingNotes = () => (
  <div style={{ width: '100%' }}>
    <Form.Item
      name="pricingNotes"
      label={productProperties.pricingNotes.labels.field}
      labelCol={{ span: 24 }}
    >
      <TextArea
        autoSize={{ minRows: 8 }}
      />
    </Form.Item>
  </div>
);

export default PricingNotes;
