import React, { useState, useEffect } from 'react';
import './ConfirmRequestModal.less';
import {
  Button, Form, Input, Select, Result, Table, Image, notification,
} from 'antd';
import * as api from 'util/api.js';
import { CustomStepper, CustomModal } from 'components';
import dayjs from 'dayjs';
import CustomerContactSelect from './CustomerContactSelect/CustomerContactSelect';

const ConfirmRequestModal = ({
  open, onClose, onSubmitted, cart, store, storeIsNZ, webstoreAdmin,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [form] = Form.useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const reset = () => {
    form.resetFields();
    setRequestType(null);
    setSubmitting(false);
    setSubmitted(false);
  };

  const [editablePrices, setEditablePrices] = useState({});

  useEffect(() => {
    if (cart) {
      const initialPrices = {};
      cart.forEach((item) => { item.variants.forEach((variant) => {
        initialPrices[variant.id] = variant.price;
      });
      });
      setEditablePrices(initialPrices);
    }
  }, [cart]);

  const handlePriceChange = (variantId, newPrice) => {
    setEditablePrices((prev) => ({
      ...prev,
      [variantId]: newPrice,
    }));
  };

  const flattenedCartData = cart.flatMap((item) => item.variants.map((variant) => ({
    ...item,
    variant,
  })));

  const cartColumns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 25,
      render: (image) => <Image src={image.src} alt={image.alt} width={25} />,
    },
    {
      title: 'Product',
      key: 'product',
      width: 250,
      render: (_, record) => (
        <>
          <p strong>{`${record.title} ${record.variant.title}`}</p>
        </>
      ),
    },
    {
      title: 'Qty',
      dataIndex: ['variant', 'quantity'],
      key: 'quantity',
      width: 40,
    },
    {
      title: 'Price',
      dataIndex: ['variant', 'id'],
      key: 'price',
      width: 120,
      render: (variantId) => (
        <Input
          value={editablePrices[variantId]}
          onChange={(e) => handlePriceChange(variantId, e.target.value)}
          style={{ width: '100px' }}
        />
      ),
    },
    {
      title: 'Total',
      key: 'total',
      width: 100,
      render: (_, record) => {
        const { variant } = record;
        return `$${(variant.quantity * parseFloat(editablePrices[variant.id])).toFixed(2)}`;
      },
    },
  ];

  const onSubmit = async (values) => {
    setSubmitting(true);
    const requestItems = flattenedCartData.map((item) => ({
      address_id: values.addTo.addressId,
      external_variant_id: {
        commerce: item.variant.id.toString(),
      },
      quantity: item.variant.quantity,
      price: editablePrices[item.variant.id],
    }));
    try {
      const response = await api.submitOnetimes(store, requestItems);
      if (response.success) {
        setSubmitted(true);
        notification.success('Onetime submitted successfully');
      } else {
        throw new Error(response.error || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      notification.error('Failed to submit order. Please try again.');
    } finally {
      setSubmitting(false);
    }
    setTimeout(() => {
      setSubmitted(true);
      setSubmitting(false);
    }, 1000);
  };

  const onFieldsChange = (changedFields, allFields) => {
    const findField = (fieldName, changedOnly = false) => {
      const fields = changedOnly ? changedFields : allFields;
      // Check each part of the field name if provided with an array
      if (Array.isArray(fieldName)) {
        return fields.find((field) => (
          (field.name.length === fieldName.length)
          && field.name.every((part, index) => part === fieldName[index])
        ));
      }
      // Otherwise if provided a string, just check the first part
      return (fields.find((field) => field.name.length === 1 && field.name[0] === fieldName));
    };

    const findChangedField = (fieldName) => findField(fieldName, true);

    const requestTypeField = findChangedField('requestType');
    if (requestTypeField && requestTypeField.value !== requestType) {
      // Delay resetting form to allow dropdown closing animation to complete
      const { value } = requestTypeField;
      setRequestType(value);
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({ requestType: value });
        form.validateFields();
      }, 300);
    }
  };

  useEffect(() => {
    if (formInitialised) {
      reset();
    } else {
      setFormInitialised(true);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmitSuccess = () => {
    handleClose();
    onSubmitted();
  };

  const onNext = (success) => {
    form.validateFields()
      .then(() => success())
      .catch(
        (error) => console.log('error', error),
      );
  };

  const onPrevious = (success) => {
    success();
  };

  const shape = {
    address_id: '178158292',
    external_variant_id: {
      commerce: '46113061798055',
    },
    add_to_next_charge: true,
    quantity: 2,
    price: '13',
  };

  return (
    <CustomModal
      width={500}
      title="Request products"
      centered
      visible={open}
      onCancel={submitted ? onSubmitSuccess : handleClose}
      closable={!submitting}
      destroyOnClose
      wrapClassName="confirm-webstore-request-wrapper"
      footer={null}
    >
      <div id="confirm-webstore-request-modal-inner-content">
        {submitted ? (
          <Result
            status="success"
            title="Request submitted!"
            subTitle="Refer to Recharge for confirmation"
            extra={(
              <Button type="primary" onClick={onSubmitSuccess}>
                Close
              </Button>
            )}
          />
        ) : (
          <>
            <Form
              id="webstore-product-request-form"
              name="webstore-product-request-form"
              form={form}
              scrollToFirstError={{
                behavior: 'smooth',
              }}
              onFinish={onSubmit}
              validateTrigger="onSubmit"
              validateMessages={{
                types: {
                  email: 'Please enter a valid email address',
                },
              }}
              initialValues={{
                requestType: null,
                month: dayjs(),
                billTo: null,
                ship: false,
                shippingAddress: {
                  country: storeIsNZ ? {
                    label: 'New Zealand',
                    value: 'NZ',
                  } : {
                    label: 'Australia',
                    value: 'AU',
                  },
                },
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFieldsChange={onFieldsChange}
            >
              <CustomStepper
                onNext={onNext}
                onPrevious={onPrevious}
                submitting={submitting}
                submitButton={(
                  <Button
                    type="primary"
                    form="webstore-product-request-form"
                    key="submit"
                    htmlType="submit"
                    loading={submitting}
                  >
                    Submit request
                  </Button>
                )}
                steps={[
                  // User section
                  {
                    title: 'User selection',
                    content: (
                      <>
                        <Form.Item
                          name="addTo"
                          label="Who should this onetime be added to?"
                          labelCol={{ span: 24 }}
                          rules={[{
                            required: true,
                            message: 'An Active Recharge subscriber is required',
                            validator: (_, value) => {
                              if (!value || (!value.addressId && (!value.subscriptions || value.subscriptions.length === 0))) {
                                return Promise.reject(new Error('Please select a valid customer with an address'));
                              }
                              return Promise.resolve();
                            },
                          }]}
                        >
                          <CustomerContactSelect
                            storeIsNZ={storeIsNZ}
                            store={store}
                          />
                        </Form.Item>
                      </>
                    ),
                  },
                  {
                    title: 'Cart Summary',
                    content: (
                      <>
                        <Table
                          dataSource={flattenedCartData}
                          columns={cartColumns}
                          pagination={false}
                          rowKey="id"
                        />
                      </>
                    ),
                  },
                ]}
              />
            </Form>
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default ConfirmRequestModal;
