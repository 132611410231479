import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Image, Checkbox, Switch, Skeleton, InputNumber,
} from 'antd';

import { LinkOutlined } from '@ant-design/icons';

import { briefProperties } from 'config/language';

const { TextArea } = Input;

const PreProduction = () => (
  <div className="columns-container">
    <div className="column">
      <Form.Item
        name="initialBrewDate"
        label={briefProperties.initialBrewDate.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="packingDate"
        label={briefProperties.packingDate.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="qcCompletionDate"
        label={briefProperties.qcCompletionDate.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item className="horizontal-field-pair">
        <Form.Item
          name="abvMin"
          label={briefProperties.abvMin.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input type="number" suffix="%" />
        </Form.Item>
        <Form.Item
          name="abvMax"
          label={briefProperties.abvMax.labels.field}
          labelCol={{ span: 24 }}
        >
          <Input type="number" suffix="%" />
        </Form.Item>
      </Form.Item>
    </div>
    <div className="column">
      <Form.Item
        name="recipeLink"
        label={briefProperties.recipeLink.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input prefix={<LinkOutlined />} />
      </Form.Item>
      <Form.Item
        name="sourcingDetails"
        label={briefProperties.sourcingDetails.labels.field}
        labelCol={{ span: 24 }}
      >
        <TextArea
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>
    </div>
  </div>
);

export default PreProduction;
