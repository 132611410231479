import FullPageMessage from 'pages/FullPageMessage/FullPageMessage';
import React from 'react';

const ErrorFallback = () => (
  <FullPageMessage
    title="Uh oh, something went wrong!"
    content="The error has automatically been reported and will be investigated as soon as possible."
    image="/assets/skull.png"
    imageShadow
    buttonLabel="Reload page"
    onClick={() => window.location.reload()}
  />
);

export default ErrorFallback;
