import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import './EditorLayout.less';
import {
  Tabs, Card, Form, Menu, Typography, Modal, Spin, Button, Switch, Dropdown, Avatar,
} from 'antd';
import { FixedButton, FullPageLoader } from 'components';
import { AuthContext } from 'util/Auth';
import { capitalCase } from 'change-case';
import { StarOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router-dom';

const { Title } = Typography;

let autoSaveTimeout;

const EditorLayout = ({
  children,
  id,
  contextLabel,
  title,
  thumbnail,
  form,
  initialValues,
  enableDelete,
  onDelete,
  onSave,
  onSaveFailed,
  onValuesChange,
  savingStatus,
  additionalControls,
}) => {
  const { currentUser } = useContext(AuthContext);

  const [autoSaveEnabled, setAutoSaveEnabled] = useState(
    localStorage.getItem(`${currentUser.uid}.enableAutoSave`) === 'true',
  );

  // Add a warning before reloading/leaving the site if there are unsaved changes
  // Note: Navigating within the site is handled by the Prompt component rendered below
  const unloadWarning = useCallback(() => {
    if (currentUser.admin && savingStatus.unsavedChanges) {
      return 'You have unsaved changes on this page.';
    }
    return null;
  }, [currentUser, savingStatus]);
  useEffect(() => {
    window.onbeforeunload = unloadWarning;
  }, []);

  // If first time viewing page, show the user a modal
  // explaining how to autosave
  useEffect(() => {
    if (currentUser.admin && !localStorage.getItem(`${currentUser.uid}.explainedAutoSave`)) {
      const onClose = (enable) => {
        // Save the user's autosave preference
        setAutoSaveEnabled(enable);
        localStorage.setItem(`${currentUser.uid}.enableAutoSave`, enable);
        // Note that the user has acknowledged autosave to avoid showing again
        localStorage.setItem(`${currentUser.uid}.explainedAutoSave`, true);
      };
      Modal.confirm({
        title: 'Introducing AutoSave',
        icon: <StarOutlined />,
        width: 500,
        centered: true,
        content: (
          <div>
            <p>
              You may now choose to enable automatic saving
              for creating and editing products and briefs.
            </p>
            <p>Would you like to enable this?</p>
            <p>
              <strong>Note: </strong>
              You can toggle this setting at any time by clicking
              the three dots next to the save button.
            </p>
          </div>
        ),
        okText: 'Yes please!',
        okType: 'warn',
        okButtonProps: {
          type: 'primary',
        },
        cancelText: 'No thanks',
        onCancel: () => onClose(false),
        onOk: () => onClose(true),
      });
    }
  }, []);

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this ${contextLabel}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: onDelete,
    });
  };

  const autoSave = useCallback(() => {
    if (currentUser.admin
      && savingStatus.unsavedChanges
      && !savingStatus.active
      && !savingStatus.error) {
      form.submit();
    }
  }, [form, savingStatus]);

  const onToggleAutoSave = (enabled) => {
    localStorage.setItem(`${currentUser.uid}.enableAutoSave`, enabled);
    setAutoSaveEnabled(enabled);
    // Immediately save after enabling autosave
    if (enabled) {
      autoSave();
    } else if (autoSaveTimeout) {
      // Clear the existing timeout if it exists to avoid autosaving after disabling
      clearTimeout(autoSaveTimeout);
    }
  };

  const handleAutoSave = useCallback((values) => {
    // Clear the previous timeout if it exists to debounce saving
    if (autoSaveTimeout) {
      clearTimeout(autoSaveTimeout);
    }

    // Set a new timeout
    if (autoSaveEnabled) {
      autoSaveTimeout = setTimeout(() => autoSave(), 1500);
    }

    // Pass values to parent change function
    onValuesChange(values);
  }, [onValuesChange, autoSaveEnabled, autoSave]);

  const handleOnFinish = (values) => {
    if (currentUser.admin) {
      onSave(values);
    }
  };

  const handleFailedSave = (args) => {
    const { errorFields } = args;
    Modal.warning({
      title: 'Some fields need attention',
      centered: true,
      content: (
        <>
          <p>
            Changes have not been saved yet.
          </p>
          <p>
            Please resolve the issues below and then try saving again.
          </p>
          <ul className="modal-error-list">
            {errorFields.map(({ errors }) => <li key={errors[0]}>{errors[0]}</li>)}
          </ul>
        </>
      ),
    });
    onSaveFailed(args);
  };

  return (!initialValues) ? (
    <FullPageLoader />
  ) : (
    <>
      <Prompt
        when={currentUser.admin && savingStatus.unsavedChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Form
        className="editor-layout-form"
        id={id}
        name={id}
        form={form}
        initialValues={initialValues}
        onValuesChange={handleAutoSave}
        onFinish={handleOnFinish}
        onFinishFailed={handleFailedSave}
      >
        <Card
          className="editor-layout-card"
          size="small"
          title={(
            <>
              <div className="editor-title-container">
                {thumbnail && (
                <div>
                  <Avatar src={thumbnail} />
                </div>
                )}
                <Title level={5}>
                  {title || (
                  <span className="untitled">
                    {`Untitled ${capitalCase(contextLabel)}`}
                  </span>
                  )}
                </Title>
              </div>

              <div className="controls-container">
                {savingStatus.lastSaved && (
                  <>
                    <span className="last-saved">
                      {`Last saved at ${savingStatus.lastSaved.format('h:mm:ss a')}`}
                    </span>
                  </>
                )}

                { currentUser.admin
                && (savingStatus.unsavedChanges || savingStatus.lastSaved)
                && !savingStatus.active
                && !savingStatus.error
                && (
                  <>
                    {savingStatus.unsavedChanges ? (
                      <span className="unsaved-changes">You have unsaved changes</span>
                    ) : (
                      <span className="saved">All changes saved!</span>
                    )}
                  </>
                )}

                {savingStatus.active && (
                  <div className="saving">
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                    />
                    <span>Saving...</span>
                  </div>
                )}

                {!savingStatus.active && savingStatus.error && (
                  <span className="error-saving">Error saving</span>
                )}

                {currentUser.admin && (
                  <>
                    <Dropdown.Button
                      type="primary"
                      htmlType="submit"
                      trigger={['click']}
                  // TODO: Add view only mode rather than showing
                  // editable fields when user is not admin
                      disabled={savingStatus.active || !currentUser.admin}
                      overlay={(
                        <Menu className="save-dropdown-menu">
                          <div className="autosave-toggle-container">
                            <span>Enable autosave</span>
                            <Switch checked={autoSaveEnabled} onChange={onToggleAutoSave} />
                          </div>
                          { enableDelete ? (
                            <div>
                              <Button
                                danger
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={handleDeleteModal}
                              >
                                {`Delete ${contextLabel}`}
                              </Button>
                            </div>

                          ) : (
                            null
                          )}
                        </Menu>
                    )}
                    >
                      Save
                    </Dropdown.Button>

                    { additionalControls }

                  </>
                )}

              </div>
            </>
        )}

        >
          {/* <div className={`editor-interaction-blocker ${savingStatus.active ? 'active' : 'inactive'}`} /> */}
          <Tabs tabPosition="left">
            { children }
          </Tabs>
        </Card>
      </Form>
    </>
  );
};

EditorLayout.propTypes = {
  savingStatus: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.bool,
    unsavedChanges: PropTypes.bool,
    // Dayjs object
    // eslint-disable-next-line react/forbid-prop-types
    lastSaved: PropTypes.object,
  }).isRequired,
  additionalControls: PropTypes.arrayOf(PropTypes.node),
};

EditorLayout.defaultProps = {
  additionalControls: [],
};

export default EditorLayout;
