import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './RetailerEditor.less';
import {
  Button, Form, Input, Checkbox, Select, Tooltip,
} from 'antd';
import { CustomModal } from 'components';
import * as api from 'util/api.js';
import {
  PlusOutlined,
} from '@ant-design/icons';

const { Option } = Select;

const RetailerEditor = ({
  onClose, onNewRetailer, disableButton, existingRetailer = null, salesReps,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [awaitingCoords, setAwaitingCoords] = useState(false);

  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const handleGetCoords = async () => {
    try {
      console.log('Fetching coords');
      setAwaitingCoords(true);
      const address = form.getFieldValue('address');
      const coords = await api.getCoordsFromAddress(address);
      if (coords) {
        form.setFieldsValue({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      }
    } catch (error) {
      console.error('Failed to get coordinates:', error);
    } finally {
      console.log('Finished fetching coords');
      setAwaitingCoords(false);
    }
  };

  useEffect(() => {
    if (existingRetailer) {
      setModalOpen(true);
    }
  }, [existingRetailer]);

  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
      form.setFieldsValue(existingRetailer ? { ...existingRetailer } : {});
    } else {
      console.log('form initialised');
      setFormInitialised(true);
    }

    if (existingRetailer) {
      setModalOpen(true);
    }
  }, [existingRetailer, form, formInitialised]);

  const onCreateClicked = () => {
    setFormInitialised(false);
    setModalOpen(true);
  };

  const handleClose = (updated) => {
    setModalOpen(false);
    setTimeout(() => onClose(updated), 500);
  };

  const onSubmit = async (values) => {
    setSaving(true);
    const updatedRetailer = { ...values };

    try {
      if (existingRetailer) {
        const { id } = existingRetailer;
        console.log(existingRetailer);
        await api.updateCertifiedFresh(id, updatedRetailer);
        console.log('retailer updated');
        handleClose({ id, ...updatedRetailer });
        onNewRetailer();
      } else {
        const id = await api.createRetailer(updatedRetailer);
        handleClose({ id, ...updatedRetailer });
        onNewRetailer();
      }
    } catch (error) {
      if (existingRetailer) {
        console.error('Failed to edit retailer', error);
      } else {
        console.error('Failed to create new retailer', error);
      }
    } finally {
      setSaving(false);
    }
  };

  const repUserId = existingRetailer?.repUserId ?? null;

  const currentRepUser = salesReps.find((rep) => rep.repUserId === repUserId);
  const isLegacyRep = !!repUserId && !currentRepUser;

  const currentRepUserAlt = salesReps.find((rep) => rep.repUserIdAlt === repUserId);
  const isLegacyRepAlt = !!repUserId && !currentRepUserAlt;

  return (
    // Prevent interaction with modal from triggering panel open/close
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={onCreateClicked}
      >
        Add retailer
      </Button>

      <CustomModal
        title={existingRetailer ? 'Edit Retailer' : 'Create Retailer'}
        centered
        visible={modalOpen}
        onCancel={() => handleClose()}
        footer={[
          <Button
            key="cancel"
            onClick={() => handleClose()}
            disabled={saving}
          >
            Cancel
          </Button>,
          <Button
            form="retailerForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={saving}
            disabled={disableButton}
          >
            {existingRetailer ? 'Save' : 'Create'}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={form}
            id="retailerForm"
            layout="vertical"
            onFinish={onSubmit}
            initialValues={existingRetailer || {}}
          >
            <Form.Item
              name="retailerName"
              label="Retailer Name"
              rules={[{ required: true, message: 'Please input the retailer name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              initialValue="NZ"
              rules={[{ required: true, message: 'Please select a country' }]}
            >
              <Select placeholder="Select a region">
                <Option value="NZ">New Zealand</Option>
                <Option value="AU">Australia</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="region"
              label="Region"
              rules={[{ required: true, message: 'Please select a region' }]}
            >
              <Select placeholder="Select a region">
                <Option value="upper_north">Upper North Island</Option>
                <Option value="central_north">Central North Island</Option>
                <Option value="lower_north">Lower North Island</Option>
                <Option value="upper_south">Upper South Island</Option>
                <Option value="lower_south">Lower South Island</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Address">
              <Input.Group compact>
                <Form.Item
                  name="address"
                  noStyle
                  rules={[{ required: true, message: 'Please input a valid address' }]}
                >
                  <Input style={{ width: 'calc(100% - 100px)' }} />
                </Form.Item>
                <Tooltip title="Generate latitude and longitude coordinates - make sure to use an accurate street address!">
                  <Button
                    type="primary"
                    onClick={handleGetCoords}
                    style={{ width: '100px' }}
                    disabled={awaitingCoords}
                  >
                    Get Coords
                  </Button>
                </Tooltip>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name="latitude"
              label="Latitude"
              rules={[{ required: true, message: 'Please input the address latitude coordinate' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="longitude"
              label="Longitude"
              rules={[{ required: true, message: 'Please input the address longitude coordinate' }]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="repUserId"
              label="Sales Rep User ID"
              rules={[{ message: 'Please input the sales rep user ID' }]}
            >
              <Input disabled='true'/>
            </Form.Item> */}
            {isLegacyRep && (
            <Form.Item
              name={['repUser', 'name']}
              label="Legacy Sales Rep"
            >
              <Input disabled="true" />
            </Form.Item>
            )}
            <Form.Item
              name="repUserId"
              label="Sales Rep"
              rules={[{ message: 'Please select a sales rep' }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select a sales rep"
                allowClear
                onChange={(value) => {
                  form.setFieldsValue({ repUserId: value === undefined ? null : value });
                }}
              >
                {salesReps.map((rep) => (
                  <Select.Option key={rep.repUserId} value={rep.repUserId}>
                    {rep.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="repUserIdAlt"
              label="Alt Sales Rep"
              rules={[{ message: 'Please select an alt sales rep' }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select an alt sales rep"
                allowClear
                onChange={(value) => {
                  form.setFieldsValue({ repUserIdAlt: value === undefined ? null : value });
                }}
              >
                {salesReps.map((rep) => (
                  <Select.Option key={rep.repUserId} value={rep.repUserId}>
                    {rep.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="attn"
              label="Retailer receiver name"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Retailer receiver email"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="attnAlt"
              label="Alt retailer receiver name"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="emailAlt"
              label="Alt retailer receiver email"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="pos"
              label="POS"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="additionalPos"
              label="Additional POS"
              rules={[{ max: 255, message: 'Limited to 255 characters.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="certificateSent"
              label="Certificate sent?"
              valuePropName="checked"
            >
              <Checkbox>Sent?</Checkbox>
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};

RetailerEditor.propTypes = {
// eslint-disable-next-line react/forbid-prop-types
  existingRetailer: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

RetailerEditor.defaultProps = {
  existingRetailer: null,
  disableButton: false,
};

export default RetailerEditor;
