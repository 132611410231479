export const baseVariant = (formData, baseWeight, floatedRRP, packConfigs, unitBarcode) => ({
  title: `${formData.unitSize}ml Single ${packConfigs[formData.unitSize].type || 'Can'}`,
  option1: `${formData.unitSize}ml Single ${packConfigs[formData.unitSize].type || 'Can'}`,
  price: floatedRRP.toFixed(2),
  inventory_management: 'shopify',
  sku: `${formData.SKU}-${formData.unitSize}`,
  grams: baseWeight,
  weight: baseWeight / 1000,
  weight_unit: 'kg',
  barcode: unitBarcode,
  metafields: [
    {
      key: 'bundle_base',
      value: true,
      type: 'boolean',
      namespace: 'global',
    },
    {
      key: 'bundle_quant',
      value: 1,
      type: 'number_integer',
      namespace: 'global',
    },
  ],
});
export const packVariant = (title, count, formData, baseWeight, price, packConfigs) => ({
  title,
  option1: title,
  price,
  inventory_management: 'shopify',
  sku: `${formData.SKU}-${formData.unitSize}-${count}`,
  grams: baseWeight * count,
  weight: (baseWeight * count) / 1000,
  weight_unit: 'kg',
  metafields: [
    {
      key: 'bundle_base',
      value: false,
      type: 'boolean',
      namespace: 'global',
    },
    {
      key: 'bundle_quant',
      value: count,
      type: 'number_integer',
      namespace: 'global',
    },
  ],
});
