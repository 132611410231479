import React, { useState, useEffect, useContext } from 'react';
import {
  Layout, PageHeader,
  Tabs, Button,
} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,

  BugOutlined, ContainerOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import {
  Switch, Route, Redirect, useLocation, useHistory,
} from 'react-router-dom';
import {
  HeaderSocialLinks, HeaderDropdownOptions, GlobalFooter, SideMenu,
  ErrorFallback, RecentActivity, BirthdayAnnouncement, SendFeedbackModal,
  FullPageLoader,
} from 'components';

import PageNotFound from 'pages/PageNotFound/PageNotFound';
import AccessDenied from 'pages/AccessDenied/AccessDenied';
import ComingSoon from 'pages/ComingSoon/ComingSoon';
import './DashboardLayout.less';
import logo from 'assets/logo.svg';
import routeConfig from 'config/routes';
import { AuthContext } from 'util/Auth';
import { ErrorBoundary } from 'react-error-boundary';
import { PageTabsProvider } from 'util/PageTabs';
import queryString from 'query-string';
import { MiscDataProvider, MiscDataContext } from 'util/MiscData';

import * as api from 'util/api';
import { isNumber } from 'lodash';

const { TabPane } = Tabs;

const { Header } = Layout;

// Recursively renders routes based on route configuration file
const renderRoutes = (parentPath, items, userIsAdmin, userIsExternal, userEmail) => {
  const result = items.reduce((accumulator, item) => {
    let routes = [];

    let path = parentPath;
    if (item.pathParam) {
      path += `/:${item.pathParam}`;
    }
    if (item.id !== 'view') {
      path += `/${item.id}`;
    }

    // if (item.tabs) {
    //   routes.push(
    //     <Route
    //       path={`${path}`}
    //       render={({ match, location, history }) => {
    //         if (item.comingSoon && !item.earlyAccessEmails.includes(userEmail)) {
    //           return ComingSoon;
    //         }
    //         return (
    //           ((item.requiresAdmin && !userIsAdmin) || (!item.allowExternal && userIsExternal)
    //           ) ? AccessDenied : (
    //             <>
    //               {/* Redirect to first tab if on the base path */}
    //               {location.pathname === path && <Redirect to={`${path}/${item.tabs[0].id}`} />}
    //               <Switch>
    //                 <Tabs
    //                   defaultActiveKey={match.params.tab}
    //                   onChange={(key) => {
    //                     history.push(`${path}/${key}`);
    //                   }}
    //                 >
    //                   {item.tabs.map(({ id, title, Component }) => (
    //                     <TabPane key={id} tab={title}>
    //                       <Component />
    //                     </TabPane>
    //                   ))}
    //                 </Tabs>
    //               </Switch>
    //             </>
    //             ));
    //       }}
    //     />,
    //   );
    // } else

    if (item.Component) {
      let { Component } = item;
      if (item.comingSoon && !item.earlyAccessEmails.includes(userEmail)) {
        Component = ComingSoon;
      } else if ((item.requiresAdmin && !userIsAdmin) || (!item.allowExternal && userIsExternal)) {
        Component = AccessDenied;
      }
      routes.push(
        item.hasNestedSwitch && item.subItems ? (
          <Route
            key={path}
            path={path}
            component={Component}
          />
        ) : (
          <Route
            key={path}
            exact
            path={path}
            component={Component}
          />
        ),
      );
    }
    if (item.subItems) {
      routes = [
        ...routes,
        ...renderRoutes(
          path,
          item.subItems,
          userIsAdmin,
          userIsExternal,
          userEmail,
        ),
      ];
    }
    return [...accumulator, ...routes];
  }, []);
  return result;
};

const PageContent = ({ currentUser }) => (
  <div id="scrollable-page-area-container">
    <div id="scrollable-page-area">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={api.reportError}>
        <main>
          <Switch>
            {renderRoutes('/dashboard', routeConfig, currentUser.admin, currentUser.external, currentUser.email)}
            <Redirect exact from="/dashboard" to={currentUser.external ? '/dashboard/beer-bible' : '/dashboard/home'} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </main>
      </ErrorBoundary>
      <GlobalFooter />
    </div>
  </div>
);

const DashboardLayout = () => {
  const [pageHeaderDetails, setPageHeaderDetails] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(null);
  const onReportBugClicked = () => setFeedbackModalOpen({ bugMode: true });
  const onFeedbackClicked = () => setFeedbackModalOpen({ bugMode: false });

  const miscData = useContext(MiscDataContext);

  const isMobile = useMediaQuery({
    query: '(max-width: 576px)', // TODO: Change to sm breakpoint
  });

  const closeSideMenu = useMediaQuery({
    query: '(max-width: 992px)', // TODO: Change to lg breakpoint
  });

  const [sideMenuOpen, setSideMenuOpen] = useState(!closeSideMenu);

  const feedbackButtonsInHeader = useMediaQuery({
    query: `(min-width: ${sideMenuOpen ? '815' : '625'}px)`,
  });

  // Handle automatic collapse and expanding of side menu based on screen size.
  useEffect(() => {
    if (closeSideMenu && sideMenuOpen) {
      setSideMenuOpen(false);
    } else if (!closeSideMenu && !sideMenuOpen) {
      setSideMenuOpen(true);
    }
  }, [closeSideMenu]);

  const location = useLocation();
  const { pathname } = location;

  const history = useHistory();

  const { currentUser } = useContext(AuthContext);

  // When the pathname changes, handle setting page title details and track route
  useEffect(() => {
    if (pathname === '/dashboard') {
      // Wait for redirect to specific path within dashboard
      return;
    }
    // Separate the location pathname into separate parts (excluding 'dashboard')
    const pathParts = pathname.substring('/dashboard/'.length).split('/');

    const route = pathParts.reduce((currentRouteItem, pathPart) => {
      // Find the root route item on first iteration
      if (!currentRouteItem) {
        return routeConfig.find(({ id }) => id === pathPart);
      }

      // Skip the path part if an ID (number)
      if (/^-?\d+$/.test(pathPart)) {
        return currentRouteItem;
      }

      if (currentRouteItem.subItems) {
        return currentRouteItem.subItems?.find(({ id }) => id === pathPart);
      }

      return currentRouteItem;
    }, null);

    if (route) {
      setPageHeaderDetails({
        title: route.title || route.menuLabel, // Title takes priority over menu label
        description: route.description,
        backPath: route.backPath,
      });
    }

    setCurrentRoute(route);
  }, [location.pathname]);

  // When the route changes, check for and apply any existing tab query param
  useEffect(() => {
    if (currentRoute?.tabs) {
      const queryParams = queryString.parse(location.search);
      const tabParam = queryParams[currentRoute.tabQueryLabel || 'tab'];
      const tabParamIsValid = !!currentRoute.tabs.find(({ id }) => id === tabParam);

      // Set the provided tab based on the param if it exists and is valid
      // otherwise set the first tab as default
      setActiveTab(tabParamIsValid ? tabParam : currentRoute.tabs[0].id);
    } else {
      // Clear the active tab if the page does not have tabs
      setActiveTab(null);
    }
  }, [currentRoute]);

  // Update the tab query param whenever the active tab changes
  // TODO: Handle future case where other query params may be used
  useEffect(() => {
    if (currentRoute) {
      if (activeTab) {
        // Set the active tab as the query param
        history.push({
          pathname,
          search: queryString.stringify({
            [currentRoute.tabQueryLabel || 'tab']: activeTab,
          }),
        });
      } else {
        // No active tab, so clear the query param
        history.push({
          pathname,
          search: null,
        });
      }
    }
  }, [activeTab]);

  return (
    <MiscDataProvider>
      <Layout>
        <SideMenu
          open={sideMenuOpen}
          isMobile={isMobile}
          onClose={() => setSideMenuOpen(false)}
        />
        <Layout>
          <Header className="site-header">
            { isMobile && (
            <div className="site-header-logo-overlay">
              <img src={logo} alt="logo" />
            </div>
            ) }
            <div
              className="side-menu-toggle"
              role="button"
              tabIndex={0}
              onClick={() => setSideMenuOpen(!sideMenuOpen)}
              onKeyDown={() => {}}
            >
              {sideMenuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </div>
            <div className="site-header-content">

              <SendFeedbackModal
                open={!!feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(null)}
                bugMode={!!feedbackModalOpen?.bugMode}
              />

              {/* Show feedback buttons in dropdown whens screen is too narrow */}
              { feedbackButtonsInHeader && (
              <div id="feedback-header-buttons">
                <Button
                  type="secondary"
                  icon={<BugOutlined />}
                  onClick={onReportBugClicked}
                >
                  Report a bug
                </Button>
                <Button
                  type="secondary"
                  icon={<ContainerOutlined />}
                  onClick={onFeedbackClicked}
                >
                  Suggestions or feedback
                </Button>
              </div>
              ) }

              {/* Dont show social links on mobile */}
              {/* { !isMobile && <HeaderSocialLinks /> } */}

              {/* Disable viewing recent activity for external users */}
              { !currentUser.external && <RecentActivity />}

              <HeaderDropdownOptions
                showFeedbackButtons={!feedbackButtonsInHeader}
                onReportBugClicked={onReportBugClicked}
                onFeedbackClicked={onFeedbackClicked}
              />
            </div>
          </Header>

          <div id="page-content">
            <BirthdayAnnouncement
              forceClose={location.pathname !== '/dashboard/home'}
            />
            {pageHeaderDetails && (
            <PageHeader
              className="page-header"
              title={pageHeaderDetails.title}
              onBack={pageHeaderDetails.backPath ? (
                () => history.push(pageHeaderDetails.backPath)
              ) : null}
              subTitle={pageHeaderDetails.description}
            />
            )}
            {/* TODO: Hide tabs if the user does not have access to the page */}
            {currentRoute?.tabs && (
            <Tabs
              className="page-tabs"
              onChange={setActiveTab}
              activeKey={activeTab}
            >
              {currentRoute.tabs.map(({ label, id }) => (
                <TabPane
                  tab={label}
                  key={id}
                />
              ))}
            </Tabs>
            )}
            <PageTabsProvider activeTab={activeTab}>
              <PageContent currentUser={currentUser} />
            </PageTabsProvider>
          </div>
        </Layout>
      </Layout>
    </MiscDataProvider>
  );
};

export default DashboardLayout;
