import React, { useRef, useEffect } from 'react';
import './MatrixLogo.scss';
import MatrixLogoSvg from './MatrixLogoSvg';

const MatrixLogo = () => (
  <div
    className="matrix-logo-container"
    title="01001000 01100101 01101100 01101100 01101111 00100000 01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01001100 01100101 01111001 01110100 01101111 01101110"
  >
    <MatrixLogoSvg />
    <MatrixLogoSvg />
    <MatrixLogoSvg />
    <img src="/assets/matrix-rain.gif" alt="Matrix Rain" className="matrix-rain" />
  </div>
);

export default MatrixLogo;
