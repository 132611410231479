import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FoodstuffsItemEditor.less';
import {
  Button, Form, Input, Modal, InputNumber,
} from 'antd';
import { CustomModal } from 'components';
import * as api from 'util/api.js';
import { PlusOutlined } from '@ant-design/icons';

const { useForm } = Form;

const FoodstuffsItemEditor = ({
  region, existingItem, onClose, disableButton,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  // If editing an existing item, fill the fields with the existing data.
  useEffect(() => {
    if (formInitialised) {
      form.resetFields();
      form.setFieldsValue(existingItem ? { ...existingItem } : {});
    } else {
      setFormInitialised(true);
    }
    if (existingItem) {
      setModalOpen(true);
    }
  }, [existingItem]);

  const onAddClicked = () => {
    Modal.confirm({
      title: 'Article numbers have moved',
      width: 500,
      centered: true,
      content: (
        <div>
          <p>
            Foodstuffs article numbers are now set on products in the product database.
          </p>
          <p>
            This allows the Foodstuffs article numbers to be linked to the products
            themselves, rather than being stored separately here in this table.
          </p>
          <p>
            {`To add a new Foodstuffs article number, please either find or create the product
            from the products table, then add the article number in the "Foodstuffs" tab in the
            product editor.`}
          </p>
        </div>
      ),
      okText: 'Go to products',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Cancel',
      onCancel: () => onClose(false),
      onOk: () => {
        window.location = '/dashboard/product-database/products';
      },
    });
  };

  const close = (updatedUser) => {
    setModalOpen(false);
    onClose(updatedUser);
  };

  const onSubmit = (values) => {
    setSaving(true);

    if (existingItem) {
      const { id } = existingItem;
      api.updateFoodstuffsItem(region, id, values).then(() => {
        setSaving(false);
        close({ id, ...values });
      }).catch(() => {
        setSaving(false);
      });
    } else {
      api.createFoodstuffsItem(region, values).then((id) => {
        setSaving(false);
        close({ id, ...values });
      }).catch(() => {
        setSaving(false);
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        disabled={disableButton}
        onClick={onAddClicked}
      >
        Add
      </Button>
      <CustomModal
        width={400}
        title={existingItem ? 'Edit item' : 'Add item'}
        centered
        visible={modalOpen}
        onCancel={close}
        footer={[
          <Button
            key="cancel"
            type="secondary"
            onClick={close}
            disabled={saving}
          >
            Cancel
          </Button>,
          <Button
            form="foodstuffs-item-editor-form"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={saving}
          >
            {existingItem ? 'Save' : 'Add'}
          </Button>,
        ]}
      >
        <div>
          <Form
            id="foodstuffs-item-editor-form"
            name="foodstuffs-item-editor-form"
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="articleNumber"
              label="Article Number"
              rules={[{ required: true, message: 'Article number is required' }]}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

FoodstuffsItemEditor.propTypes = {
  region: PropTypes.oneOf(Object.values(api.FOODSTUFFS_REGIONS)).isRequired,
  existingItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    articleNumber: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

FoodstuffsItemEditor.defaultProps = {
  existingItem: null,
  disableButton: false,
};

export default FoodstuffsItemEditor;
