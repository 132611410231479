import SignatureGeneratorModal from 'components/SignatureGeneratorModal/SignatureGeneratorModal';

export default {
  quickLinks: [
    {
      title: 'Webstore request',
      type: 'link',
      linkTo: '/dashboard/webstore/request',
      description: 'Request products from webstore inventory',
      image: 'shopping-cart.png', // https://www.flaticon.com/free-icon/shopping-cart_5336503
    },
    {
      title: 'Sales Rep Portals',
      type: 'multiple-links',
      links: [
        {
          title: 'New Zealand',
          linkTo: 'https://gp-nzrelations.dearportal.com/Account/SaleRep',
        },
        {
          title: 'Australia - Melbourne',
          linkTo: 'https://GPVIC-Relations.dearportal.com',
        },
        {
          title: 'Australia - Sydney',
          linkTo: 'https://GPNSW-relations.dearportal.com',
        },
        {
          title: 'Australia - Perth',
          linkTo: 'https://GPWA-Relations.dearportal.com',
        },
      ],
      description: 'Access each of the sales rep ordering portals',
      image: 'sales-rep.png', // https://www.flaticon.com/premium-icon/trader_5342663
    },
    // {
    //   title: 'AU Discount Evaluator',
    //   type: 'link',
    //   linkTo: '/dashboard/au-discounts',
    //   description: 'Verify available discount rates for bulk sales',
    //   image: 'discount.png', // https://www.flaticon.com/premium-icon/tag_2205349
    // },
    {
      title: 'Stock update',
      type: 'link',
      linkTo: '/dashboard/mailchimp',
      description: 'Build stock update emails for Mailchimp',
      image: 'mc.png',
    },
    {
      title: 'Tap Badges',
      type: 'link',
      linkTo: '/dashboard/tap-badges',
      description: 'Generate tap badges ready for printing',
      image: 'tap.png',
    },
    {
      title: 'Beer Bible',
      type: 'link',
      linkTo: '/dashboard/beer-bible',
      description: 'All GP creations in one place',
      image: 'bible.png',
    },
    {
      title: 'Slab Labels',
      type: 'link',
      linkTo: '/dashboard/label-builder/slab',
      description: 'Generate slab labels ready for printing',
      image: 'slab.png', // https://www.flaticon.com/free-icon/package_572680
    },
    {
      title: 'Case Labels',
      type: 'link',
      linkTo: '/dashboard/label-builder/case',
      description: 'Generate case labels ready for printing',
      image: 'case.png', // https://www.flaticon.com/free-icon/package_572680
    },
    {
      title: 'Keg Labels',
      type: 'link',
      linkTo: '/dashboard/label-builder/keg',
      description: 'Generate keg labels ready for printing',
      image: 'keg.png', // https://www.flaticon.com/packs/brewery-1
    },
    {
      title: 'Pallet Labels',
      type: 'link',
      linkTo: '/dashboard/label-builder/pallet',
      description: 'Generate pallet labels ready for printing',
      image: 'pallet.png',
    },
  ],
  forms: [
    {
      title: 'Request Artwork',
      type: 'link',
      linkTo: 'https://forms.monday.com/forms/94e11c8a44b9ad88c7d083b0e1d5f17a?r=use1',
      description: 'Submit a request for artwork',
      image: 'paint.png', // https://www.flaticon.com/free-icon/paint_1158164
    },
    // {
    //   title: 'Raising Concerns at GP',
    //   type: 'link',
    //   linkTo: 'https://drive.google.com/drive/folders/1DWUnn_iE2VVCyZvhbKN51pFxHU4g3X8w?usp=sharing',
    //   description: 'See here for how to raise a workplace concern',
    //   // https://www.flaticon.com/free-icon/anxiety_3997910
    //   image: 'concern.png',

    //   // https://www.flaticon.com/free-icon/employee_554857
    //   // image: 'anonymous-disclosure.png',
    // },
    // {
    //   title: 'Stock Destruction',
    //   type: 'link',
    //   linkTo: 'https://docs.google.com/forms/d/e/1FAIpQLSdFNZUSJxsjK-l8ZiJmkDLnxW4MeH1oCCV1AVxTtNSklPalDQ/viewform',
    //   description: 'Submit a request for stock to be destroyed',
    //   image: 'wrecking-ball.png', // https://www.flaticon.com/free-icon/wrecking-ball_1833105

    // },
    {
      title: 'Request IT Support',
      type: 'link',
      linkTo: 'https://forms.monday.com/forms/13a7df63360b8237bbb4e3d074e617ff?r=use1',
      description: 'Log an IT Support tiket',
      image: 'it.png', // https://www.flaticon.com/free-icon/paint_1158164
    },
    {
      title: 'Aussie Transfer',
      type: 'link',
      linkTo: 'https://docs.google.com/forms/d/e/1FAIpQLSdQMNXHHtNf0vf3ChYG1a3HAoPWw667u-V8kLGu5lzB_x1-aQ/viewform?c=0&w=1',
      description: 'Set aside stock for pre-order',
      image: 'aus.jpg',
    },
    {
      title: 'AUS Ongoing Listings',
      type: 'link',
      linkTo: 'https://docs.google.com/forms/d/e/1FAIpQLScVqpkvWyy3RP7BGvBtxQLBZTsKlMkht3R27WdcFvyBpW3EZg/viewform?vc=0&c=0&w=1&flr=0&gxids=7757',
      description: 'Permanent menu listings + permanent taps only',
      image: 'ongoing.png',
    },
  ],
  resources: [
    {
      title: 'Generate Email Signature',
      type: 'modal',
      modal: SignatureGeneratorModal,
      description: 'Generate a personalised signature to include in your company emails',
      image: 'signature.png', // https://www.flaticon.com/free-icon/signature_985320
    },
    {
      title: 'Employment Hero HR & Payroll Services',
      type: 'link',
      linkTo: 'https://secure.employmenthero.com/app/v2/organisations/158567',
      description: 'Employment Hero HR and Payroll',
      image: 'hr.png', // https://www.flaticon.com/free-icon/candidates_2258588
    },
    {
      title: 'Safety & Wellbeing',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/1YdzthRnfRabx2TGOnI8QCAbVsCiQBZEJ?usp=sharing',
      description: 'Keeping safe at GP',
      image: 'safety.png', // https://www.flaticon.com/free-icon/safety_2345454
    },
    {
      title: 'Travel & Bookings',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/14aGziaTZ-HPCVgDo3vwWr7rw2O97A43h?usp=sharing',
      description: 'How to make bookings when travelling for work',
      image: 'luggage.png', // https://www.flaticon.com/free-icon/luggage_3069175?related_id=3069208&origin=search
    },

    {
      title: 'IT Resources',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/11eyXotmris4-C8rYwmes-2EwHFSFGxBt?usp=sharing',
      description: 'Resources for troubleshooting IT issues',
      image: 'it.png', // https://www.flaticon.com/free-icon/help_7366336
    },
    {
      title: 'Expense Templates',
      type: 'multiple-links',
      links: [
        {
          title: 'New Zealand',
          linkTo: 'https://docs.google.com/spreadsheets/d/1mwIu2nAYzrA3c-LNxp7irAlcc7eS2SmAjC0MbudYqPc/template/preview',
        },
        {
          title: 'Australia',
          linkTo: 'https://docs.google.com/spreadsheets/d/1OvV_1-eIjjpxyb8k8uFuqlPf_kJELZ4RRsfK5wZzGRM/template/preview',
        },
      ],
      description: 'Templates for submitting expenses, reimbursements, mileage and timesheets',
      image: 'expense.png', // https://www.flaticon.com/free-icon/calculator_4686226
    },
    {
      title: 'Branded Templates',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/16pGx1CewzLEHy8qhEEVbEUmD6nWTfr1g?usp=sharing',
      description: 'General purpose GP branded document templates',
      image: 'branded-template.png', // https://flaticon.com/free-icon/web-design_1085728
    },
    {
      title: 'Crew Contact Details',
      type: 'link',
      linkTo: 'https://docs.google.com/spreadsheets/d/1WOH7q10vdbYwaBTjzrOppzd_qGTNHKHSnanGgdXmGdY/edit?usp=sharing',
      description: 'View contact details for all staff members',
      image: 'contact.png', // https://www.flaticon.com/premium-icon/contact_3677281
    },
    {
      title: 'Social Photos',
      type: 'multiple-links',
      description: 'A collection of professionally captured photos for social media',
      image: 'camera.png',
      links: [
        {
          title: '2024 Photos (Zac)',
          linkTo: 'https://drive.google.com/drive/folders/1LNHBLhuDh8WX4oRyHJLUgItBJQ1oxxhu',
        },
        {
          title: '2024 Video Content (Zac)',
          linkTo: 'https://drive.google.com/drive/folders/1Xw9qXQFaLsQ110ffkCbG7nWqlYjakBLy',
        },
        {
          title: '2022-2023 (Mitch)',
          linkTo: 'https://drive.google.com/drive/folders/1V0COJAG3jYVG3PTkM6yq16wwoMMoHwkF',
        },
        {
          title: 'Pre-2022 (Bryson)',
          linkTo: 'https://www.dropbox.com/sh/430t7qn7vupd1rh/AABH4Xus1yu1T4hflS2ej_EKa?dl=0',
        },
      ],
    },
    {
      title: 'One Pagers',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/u/0/folders/1v-V953IXT7ZW4G_-Ds4Lv75LAnRAi5kn',
      description: 'Master Collection',
      image: 'newmoney.png',
      circle: true,
    },
    {
      title: 'Art Assets',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/0B6yi5C9FBc4hTUIzc0tPNTZLaVk',
      description: 'Main Drive Folder',
      image: 'drive.png',
    },
    // {
    //   title: 'How To',
    //   type: 'link',
    //   linkTo: 'https://drive.google.com/drive/u/0/folders/1fOuRod9zamer4UW7lkbQd7bOkCR6MBFB',
    //   description: 'Useful Documents',
    //   image: 'drive.png',
    // },
    // {
    //   title: 'Crushed Renders',
    //   type: 'link',
    //   linkTo: 'https://drive.google.com/drive/folders/10qd3jWpeQKlhecAlnCoRhp74zZqx1zm1',
    //   description: 'All In One Place',
    //   image: 'crushed.png',
    // },
    // {
    //   title: 'Crushed AYNTK',
    //   type: 'link',
    //   linkTo: 'https://docs.google.com/spreadsheets/d/1ewQgMvJvD14n2s3hZj9q8Xlzfo3QmqAb0-T0OG-qYps/edit#gid=0',
    //   description: 'All You Need To Know',
    //   image: 'ayntk.png',
    // },
    {
      title: 'Payment Portal',
      type: 'link',
      linkTo: 'https://sec.paymentexpress.com/pxaccess/pxpay/payform?userid=GarageProjectPF',
      description: 'Payment Portal Login',
      image: 'payment.png',
    },
    {
      title: 'Logos',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/u/1/folders/0B2zXLrxO2-CzR1J0amJjeEJhc2s',
      description: 'Logos for Garage Project brands',
      image: 'drive.png',
    },
    {
      title: 'Sales Assets',
      type: 'link',
      linkTo: 'https://drive.google.com/drive/folders/1hBJPcU0etrVZIOhbyITC31U4OGFp9flB',
      description: 'Relations Assets',
      image: 'salesassets.jpg',
      circle: true,
    },
  ],
};
