import React, { useState, useEffect, useContext } from 'react';
import './CertifiedFresh.less';
import * as api from 'util/api.js';
import { AuthContext } from 'util/Auth';
import {
  Space, Button, Table as AntTable, Modal, Typography,
} from 'antd';
import { CustomTable, FullPageLoader } from 'components';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import AccessDenied from 'pages/AccessDenied/AccessDenied';
import RetailerEditor from './RetailerEditor/RetailerEditor';

const { Text } = Typography;

const { Column } = AntTable;

const certifiedFreshReader = [
  'dev@garageproject.co.nz',
  'hannah.lloyd@garageproject.co.nz',
  'mary@garageproject.co.nz',
  'sally@garageproject.co.nz',
  'tim.witton@garageproject.co.nz',
  'amelia@garageproject.co.nz',
  'liam@garageproject.co.nz',
  'molly@garageproject.co.nz',
  'seanj@garageproject.co.nz',
  'crocky@garageproject.co.nz',
  'quinn@garageproject.co.nz',
  'mitchell@garageproject.co.nz',
  'websales@garageproject.co.nz',
  'kyle@garageproject.co.nz',
  'peter.fenton@garageproject.co.nz',
];

const certifiedFreshUser = [
  'dev@garageproject.co.nz',
  'hannah.lloyd@garageproject.co.nz',
  'mary@garageproject.co.nz',
  'sally@garageproject.co.nz',
  'tim.witton@garageproject.co.nz',
  'amelia@garageproject.co.nz',
  'liam@garageproject.co.nz',
  'molly@garageproject.co.nz',
  'seanj@garageproject.co.nz',
  'crocky@garageproject.co.nz',
  'quinn@garageproject.co.nz',
  'mitchell@garageproject.co.nz',
  'websales@garageproject.co.nz',
  'kyle@garageproject.co.nz',
];

const salesReps = [
  {
    name: 'Hannah Lloyd',
    repUserId: 'RpgbvcuTLthPIqP8WG1iPBAu1S73',
  },
  {
    name: 'Tim Witton',
    repUserId: '3VmUVebg4HbCl720Ri7syFreJdr2',
  },
  {
    name: 'Amelia Dunnett',
    repUserId: 'DKZKLPMSqcXXyZ0oiLc81w1owKb2',
  },
  {
    name: 'Liam Bowater',
    repUserId: 'yT7VAorncQQbAkcsh6JCFTT5dQA2',
  },
  {
    name: 'Molly Jones',
    repUserId: '8k2JexYFwEgiyj29eaH41ebxbww2',
  },
  {
    name: 'Sean Jeremiah',
    repUserId: 'UNXlOwbiEURHYQ3CS7gzMOfzPfN2',
  },
  {
    name: 'Crocky Hargreaves',
    repUserId: 'hjYR5PjtpZPPLl7seB2G2frLOZK2',
  },
  {
    name: 'Quinn Ryan',
    repUserId: 'wVWAUgeVYUQv2JKLrJeJUhG7wAS2',
  },
];

const CertifiedFresh = () => {
  const [retailerBeingEdited, setRetailerBeingEdited] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [certifiedFresh, setCertifiedFresh] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [retailerSaving, setRetailerSaving] = useState(false);
  const [isLoadingRetailers, setIsLoadingRetailers] = useState(true);

  const isAuthorisedUser = certifiedFreshUser.includes(currentUser.email);

  const fetchRetailers = () => {
    if (!isAuthorisedUser) {
      console.log(JSON.stringify(currentUser.email));
      console.error('User is not authorised to fetch Certified Fresh retailers');
      setIsLoadingRetailers(false);
      return;
    }
    console.log('loading retailers...');
    api.getCertifiedFresh().then((data) => {
      setCertifiedFresh(data);
      console.log('finished loading');
    }).catch((error) => {
      console.error('Failed to fetch Certified Fresh retailers', error);
    }).finally(
      setIsLoadingRetailers(false),
    );
  };

  useEffect(() => {
    fetchRetailers();
  }, [retailerBeingEdited]);

  if (isLoadingRetailers) {
    return <FullPageLoader />;
  }

  if (!certifiedFreshReader.includes(currentUser.email)) {
    return <AccessDenied />;
  }

  const handleEditRetailer = (retailer) => {
    console.log('Edit button clicked');
    setRetailerBeingEdited(retailer);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setRetailerBeingEdited(null);
    setEditorOpen(false);
  };

  const onDelete = (retailerToDelete) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${retailerToDelete.retailerName}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteRetailer(retailerToDelete.id)
        .then(() => {
          setCertifiedFresh(certifiedFresh.filter((retailer) => (
            Number(retailer.id) !== Number(retailerToDelete.id)
          )));
        })
        .catch(),
    });
  };

  return (
    <div>
      <CustomTable
        id="certified-fresh-retailers"
        pluralLabel="retailers"
        searchableColumns={['retailerName', 'region', 'address', 'repUser.name']}
        dataSource={certifiedFresh?.map((retailer) => (
          {
            ...retailer,
            key: retailer.id,
          }
        ))}
        controls={isAuthorisedUser ? (
          <RetailerEditor
            visible={editorOpen}
            onClose={handleEditorClose}
            existingRetailer={retailerBeingEdited}
            onNewRetailer={fetchRetailers}
            disableButton={retailerSaving}
            salesReps={salesReps}
          />
        ) : null}
      >
        <Column
          title="Retailer name"
          dataIndex="retailerName"
          key="retailerName"
          sortType="string"
          sortDirections={['ascend', 'descend', 'ascend']}
        />
        <Column
          title="Region"
          dataIndex="region"
          key="region"
          sortType="string"
          sortDirections={['ascend', 'descend', 'ascend']}
          render={(text) => (
            text
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          )}
        />
        <Column
          title="Address"
          dataIndex="address"
          key="address"
        />
        <Column
          title="Contact"
          dataIndex="attn"
          key="attn"
        />
        <Column
          title="Contact Email"
          dataIndex="email"
          key="email"
        />
        <Column
          title="Latitude"
          dataIndex="latitude"
          key="latitude"
          sortType="number"
          sortDirections={['ascend', 'descend', 'ascend']}
        />
        <Column
          title="Longitude"
          dataIndex="longitude"
          key="longitude"
          sortType="number"
          sortDirections={['ascend', 'descend', 'ascend']}
        />
        <Column
          title="Rep"
          dataIndex={['repUser', 'name']}
          key="rep"
        />
        {isAuthorisedUser ? (
          <Column
            title=""
            key="actions"
            render={(retailer) => (
              <Space size="middle">
                <Button
                  type="link"
                  onClick={() => handleEditRetailer(retailer)}
                >
                  Edit
                </Button>
                <Button
                  type="link"
                  danger
                  onClick={() => onDelete(retailer)}
                >
                  Delete
                </Button>
              </Space>
            )}
          />
        ) : <></>}
      </CustomTable>
    </div>
  );
};

export default CertifiedFresh;
