import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Modal, InputNumber,
} from 'antd';
import { CustomModal, HoverHelp } from 'components';
import * as api from 'util/api.js';
import { PlusOutlined } from '@ant-design/icons';
import { paramCase } from 'change-case';
import QRCodeURLSelector from '../QRCodeURLSelector/QRCodeURLSelector';

const { TextArea } = Input;

const { useForm } = Form;

const QRCodeEditor = ({
  existingItem, onClose, disableButton, existingIds,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [urlType, setUrlType] = useState(existingItem?.urlType);
  const [idLockedIn, setIdLockedIn] = useState(!!existingItem);
  const [uploading, setUploading] = useState(false);
  const [otherIds, setOtherIds] = useState(null);

  useEffect(() => {
    // If an item is being edited, remove it from the list of existing IDs
    setOtherIds(existingItem ? (
      existingIds.filter((id) => existingItem.id !== id)
    ) : existingIds);
  }, [existingIds, existingItem]);

  const [form] = useForm();
  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  const reset = () => {
    form.resetFields();
    form.setFieldsValue(existingItem ? { ...existingItem } : {});
    setUrlType(null);
    setUploading(false);
    setIdLockedIn(false);
  };

  // If editing an existing item, fill the fields with the existing data.
  useEffect(() => {
    if (formInitialised) {
      reset();
    } else {
      setFormInitialised(true);
    }
    if (existingItem) {
      setUrlType(existingItem.urlType);
      setModalOpen(true);
      setIdLockedIn(true);
    } else {
      setIdLockedIn(false);
    }
  }, [existingItem]);

  const onAddClicked = () => {
    setModalOpen(true);
  };

  const cancel = () => {
    reset();
    setModalOpen(false);
    onClose();
  };

  const closeAndUpdate = (updatedQRCode) => {
    reset();
    setModalOpen(false);
    onClose(updatedQRCode);
  };

  const onSubmit = ({ url, ...values }) => {
    setSaving(true);

    const itemData = {
      destinationUrl: url,
      ...values,
    };

    if (existingItem) {
      const { id, qrCodeImageUrl } = existingItem;
      api.updateQRCode(id, itemData).then(({ dateLastModified }) => {
        setSaving(false);
        closeAndUpdate({ ...itemData, qrCodeImageUrl, dateLastModified });
      }).catch(() => {
        setSaving(false);
      });
    } else {
      api.createQRCode(itemData).then(({ qrCodeImageUrl, dateLastModified }) => {
        setSaving(false);
        closeAndUpdate({ ...itemData, qrCodeImageUrl, dateLastModified });
      }).catch(() => {
        setSaving(false);
      });
    }
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields.length === 1) {
      const changedField = changedFields[0];
      const fieldName = changedField.name[0];
      if (fieldName === 'urlType') {
        setUrlType(changedField.value);
      } else if (fieldName === 'name' && !idLockedIn) {
        const newId = paramCase(changedField.value);
        form.setFields([{
          name: 'id',
          value: newId,
          errors: otherIds.includes(newId) ? ['This ID already is already taken'] : [],
        }]);
      }
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        disabled={disableButton || uploading}
        onClick={onAddClicked}
      >
        Create
      </Button>
      <CustomModal
        width={600}
        title={existingItem ? 'Edit QR code' : 'Create QR code'}
        centered
        visible={modalOpen}
        onCancel={cancel}
        footer={[
          <Button
            key="cancel"
            type="secondary"
            onClick={cancel}
            disabled={saving || uploading}
          >
            Cancel
          </Button>,
          <Button
            form="qr-code-editor-form"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={uploading}
            loading={saving}
          >
            {existingItem ? 'Save' : 'Create'}
          </Button>,
        ]}
      >
        <div>
          <Form
            id="qr-code-editor-form"
            name="qr-code-editor-form"
            form={form}
            onFinish={onSubmit}
            onFieldsChange={onFieldsChange}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'A name for the QR code is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="id"
              extra="Note: Once the QR code has been created, or a file has been uploaded, the ID cannot be changed"
              label={(
                <>
                  ID
                  <HoverHelp message="This is what is used to ensure the QR code is unique and will appear at the end of the QR code URL" />
                </>
              )}
              disabled
              rules={[{
                required: true,
                message: 'An ID is required',
              },
              {
                validator: (_, value) => {
                  if (value && otherIds.includes(value)) {
                    return Promise.reject(new Error('This ID is already taken'));
                  }
                  return Promise.resolve();
                },
              }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
            >
              <TextArea
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
            <QRCodeURLSelector
              form={form}
              urlType={urlType}
              onImageUploaded={() => setIdLockedIn(true)}
              onUploadingStateChange={setUploading}
              otherIds={otherIds}
            />
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

QRCodeEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  existingItem: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

QRCodeEditor.defaultProps = {
  existingItem: null,
  disableButton: false,
};

export default QRCodeEditor;
