import React, { useContext } from 'react';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from 'assets/logo.svg';
import { GlobalFooter } from 'components';
import { AuthContext } from 'util/Auth';
import Login from 'pages/landing/Login/Login';

import './LandingLayout.less';

const LandingLayout = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)', // TODO: Change to md breakpoint
  });

  // Redirect to the dashboard if the user is already logged in.
  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return (<Redirect to="/dashboard/home" />);
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <div id="landing-layout-container">
        <div id="landing-layout-content">
          <div id="landing-layout-header">
            <img src={logo} alt="logo" />
          </div>
          <div id="form-container">
            <Login />
          </div>
          <GlobalFooter />
        </div>
        {!isMobile && <div id="landing-layout-background" />}
      </div>
    </Layout>
  );
};

export default LandingLayout;
