import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Modal, InputNumber, Select, notification,
} from 'antd';
import { CustomModal } from 'components';
import * as api from 'util/api.js';
import { PlusOutlined } from '@ant-design/icons';

const { useForm } = Form;

const FoodstuffsProductLinker = ({
  products, region, existingItem, onClose, disableButton, articleNumberColumnId,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = useForm();

  const [selectedProduct, setSelectedProduct] = useState(null);

  // Used to avoid references to form instance on first render.
  const [formInitialised, setFormInitialised] = useState(false);

  useEffect(() => {
    if (!formInitialised) {
      setFormInitialised(true);
    }
  }, [existingItem]);

  // Construct the options for the product select field
  const productOptions = useMemo(() => products?.map((product) => ({
    key: product.id,
    label: product.name,
    value: product.id,
  }) ?? []), [products]);

  // If editing an existing item, fill the fields with the existing data.
  useEffect(() => {
    if (existingItem) {
      setModalOpen(true);
    } else {
      form.resetFields();
      setSelectedProduct(null);
    }
  }, [existingItem]);

  const close = (args) => {
    setModalOpen(false);
    setSaving(false);
    form.resetFields();
    onClose(args);
  };

  const onSubmit = ({ productId }) => {
    setSaving(true);

    const productName = selectedProduct.name;

    const productData = {
      [articleNumberColumnId]: existingItem.articleNumber,
    };

    api.updateProduct(productId, productData).then(() => {
      notification.success({ message: 'Foodstuffs article number has set on the product!' });
      api.updateFoodstuffsItem(region, existingItem.id, {
        deleted: true,
        productId,
      }).then(() => {
        notification.success({ message: 'Old Foodstuffs article number item has been soft-deleted!' });
        setSaving(false);
        close({
          migratedItemId: existingItem.id,
          productId,
          productName,
          articleNumber: productData[articleNumberColumnId],
        });
      }).catch(() => {
        notification.error({ message: 'There was an error soft-deleting the old Foodstuffs article number item.' });
        setSaving(false);
      });
    }).catch(() => {
      notification.error({ message: 'There was an error setting the Foodstuffs article number on the product.' });
      setSaving(false);
    });
  };

  const onSelectProduct = useCallback((productId) => {
    form.setFieldValue('productId', productId);
    setSelectedProduct(products.find(({ id }) => id === productId));
  }, [form, products]);

  return (
    <CustomModal
      width={400}
      title="Migrate to product"
      centered
      visible={modalOpen}
      onCancel={close}
      footer={[
        <Button
          key="cancel"
          type="secondary"
          onClick={close}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          form="foodstuffs-product-linker-form"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saving}
        >
          Migrate
        </Button>,
      ]}
    >
      <div>
        <Form
          id="foodstuffs-product-linker-form"
          name="foodstuffs-product-linker-form"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            name="productId"
            label="Product"
            rules={[{ required: true, message: 'Please select the product to migrate the article number to' }]}
          >
            <Select
              showSearch
              loading={products === null}
              placeholder="Select a product..."
              onChange={onSelectProduct}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={productOptions}
            />
          </Form.Item>
        </Form>
      </div>
    </CustomModal>
  );
};

FoodstuffsProductLinker.propTypes = {
  region: PropTypes.oneOf(Object.values(api.FOODSTUFFS_REGIONS)).isRequired,
  existingItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    articleNumber: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

FoodstuffsProductLinker.defaultProps = {
  existingItem: null,
  disableButton: false,
};

export default FoodstuffsProductLinker;
