import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LoadingImage.less';
import { Image, Skeleton } from 'antd';

const LoadingImage = ({
  shape, width, height, whiteOverlay, ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={`loading-image-container ${shape}`}
      style={{
        width,
        height,
      }}
    >
      <Image
        {...props}
        onLoad={() => setLoaded(true)}
      />
      {!loaded && (
        <div className={`image-loading-overlay ${whiteOverlay ? 'white' : ''}`}>
          <Skeleton.Avatar active size={width} shape={shape} />
        </div>
      )}
    </div>
  );
};

LoadingImage.propTypes = {
  shape: PropTypes.oneOf(['circle', 'square']),
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  whiteOverlay: PropTypes.bool,
};

LoadingImage.defaultProps = {
  shape: 'square',
  width: null,
  whiteOverlay: false,
};

export default LoadingImage;
