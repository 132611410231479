import React, { useState } from 'react';
import './WebstoreProductList.less';
import PropTypes from 'prop-types';
import {
  List, Button, Typography, Divider,
} from 'antd';

const { Text } = Typography;

const renderProductQuantityLabel = (title, quantity, textProps) => (
  <Text
    type="secondary"
    {...textProps}
    className="variant-quantity-label"
  >
    {`${quantity} x ${title}`}
  </Text>
);

const calculateTotalProductPrice = (variants) => variants.reduce((total, variant) => (
  total + (parseFloat(variant.price) * variant.quantity)
), 0);

const calculateTotalCartPrice = (products) => products.reduce((total, product) => (
  total + calculateTotalProductPrice(product.variants)
), 0);

const WebstoreProductList = ({
  products, emptyText, allowRemove, onRemove, bordered, compact, expandable, expandThreshold, showRRP, storeIsNZ,
}) => {
  const [expanded, setExpanded] = useState(
    !expandable || products.length <= expandThreshold,
  );

  const currency = storeIsNZ ? 'NZD' : 'AUD';

  return (
    <div className="webstore-product-list">
      <List
        bordered={bordered}
        itemLayout="horizontal"
        dataSource={expanded ? products : products.slice(0, expandThreshold)}
        locale={{ emptyText }}
        renderItem={({
          id: productId, title: productTitle, imageUrl, variants,
        }, index) => {
          const hasMultipleVariants = variants.length > 0 && variants[0].title !== 'Default Title';
          return (
            <>
              <List.Item className={compact ? 'compact-list-item' : null}>
                <div className="product-image">
                  <img
                    src={imageUrl}
                    alt={productTitle}
                  />
                </div>
                <div className="product-details">
                  {hasMultipleVariants ? (
                    <>
                      <Text strong>{productTitle}</Text>
                      <ul>
                        {variants.map(({
                          id: variantId, title: variantTitle, quantity, price,
                        }) => (
                          <li key={variantId}>
                            {renderProductQuantityLabel(
                              variantTitle,
                              quantity,
                              { type: 'secondary' },
                              price,
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    renderProductQuantityLabel(
                      productTitle,
                      variants[0].quantity,
                      {
                        strong: true,
                        type: 'primary',
                      },
                      variants[0].price,
                    )
                  )}
                  {showRRP && (
                    <Text type="secondary" className="product-total-rrp">
                      {`= RRP $${calculateTotalProductPrice(variants).toFixed(2)} ${currency}`}
                    </Text>
                  )}
                </div>
                {allowRemove && (
                <Button
                  danger
                  className="remove-button"
                  onClick={() => onRemove(productId)}
                >
                  Remove
                </Button>
                )}
              </List.Item>
              {/* Add show remaining button below the last item */}
              {!expanded && index === expandThreshold - 1 && (
                <Button
                  type="link"
                  className="show-hidden-products-button"
                  onClick={() => setExpanded(true)}
                >
                  {`Show ${products.length - expandThreshold} hidden products`}
                </Button>
              )}
            </>
          );
        }}
      />
      {products?.length > 0 && showRRP && (
        <>
          <Divider className="total-rrp-divider" />
          <Text className="cart-total-rrp">{`Total RRP = $${calculateTotalCartPrice(products).toFixed(2)} ${currency}`}</Text>
        </>
      )}
    </div>
  ); };

WebstoreProductList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array.isRequired,
  emptyText: PropTypes.string,
  allowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  bordered: PropTypes.bool,
  compact: PropTypes.bool,
  expandable: PropTypes.bool,
  expandThreshold: PropTypes.number,
  showRRP: PropTypes.bool,
  storeIsNZ: PropTypes.bool.isRequired,
};

WebstoreProductList.defaultProps = {
  emptyText: null,
  allowRemove: false,
  onRemove: null,
  bordered: false,
  compact: false,
  expandable: false,
  expandThreshold: 3,
  showRRP: false,
};

export default WebstoreProductList;
