import React from 'react';
import './Snippet.less';
import UNIT_DETAILS from 'config/unitDetails';

const IntroSnippet = () => (
  <div
    className="email-snippet"
    style={{
      padding: '20px 0px',
      width: '600px',
      margin: '0 auto',
      marginBottom: '10px',
      backgroundColor: 'white',
      // borderTop: '1.3px dashed #d8d8d8',
    }}
  >
    {/* <div
                id="mailout"
                styles={{
                  backgroundColor: "#fcfaff"
                }}
              > */}
    <table style={{ maxwidth: 600, align: 'center' }}>
      <tbody>
        <tr>
          <td
            width="600"
            style={{
              padding: '5px',
              backgroundColor: 'white',
            }}
          >
            <img
              alt="intro-banner"
              src="https://gallery.mailchimp.com/9860c5added2c8b23e6745329/images/c6fd2ed6-93cb-4524-a315-63e195d3f576.png"
              width="600"
              style={{
                float: 'left',
              }}
            />
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '5px',
              backgroundColor: 'white',
            }}
          >
            <h1
              contentEditable=""
              id="stocktitle"
            >
              Stock Update
            </h1>
            <p
              contentEditable=""
              style={{
                color: '#222222',
              }}
            >
              Hey Team,
              {' '}
              <br />
              <br />
              <br />
              Edit email content here...
              <br />
              <br />
              <br />
              The Garage Project team
            </p>
            <a
              href="https://GarageProjectOnLicense.dearportal.com"
              style={{
                cursor: 'pointer',
              }}
            >
              ORDER ON THE PORTAL NOW
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default IntroSnippet;
