import React from 'react';
import './Snippet.less';
import UNIT_DETAILS from 'config/unitDetails';
import { Divider } from 'antd';
import { capitalCase } from 'change-case';

const ProductSnippet = ({ pricingType, product }) => {
  const {
    name, oneLiner, abv, abvMin, abvMax, blurb, webRenderUrl, tapBadgeUrl,
  } = product;
  let packSuffix;
  let kegSuffix;
  switch (pricingType) {
    case 'onPremise':
      packSuffix = 'OnNz';
      kegSuffix = 'Nz';
      break;
    case 'offPremise':
      packSuffix = 'OffNz';
      kegSuffix = 'Nz';
      break;
    case 'progressive':
      packSuffix = 'ProgressiveNz';
      kegSuffix = 'ProgressiveNz';
      break;
    case 'australia':
      packSuffix = 'Au';
      kegSuffix = 'Au';
      break;
    case 'vicSa':
      packSuffix = 'VicSa';
      kegSuffix = 'VicSa';
      break;
    case 'qldWa':
      packSuffix = 'QldWa';
      kegSuffix = 'QldWa';
      break;
    default:
      packSuffix = null;
      kegSuffix = 'null';
  }

  const prices = {
    slab: product[`pack${packSuffix}`] || null,
    // case: product[`pack${suffix}`] || null,
    keg: product[`keg${kegSuffix}`] || null,
  };

  const unitQuantities = {};

  Object.entries(prices).forEach(([unitType, value]) => {
    // Replace prices entered as '-' with null.
    if (value === '-') {
      prices[unitType] = null;
    }

    const barcodeKeyPrefix = `barcode${capitalCase(unitType)}.`;

    // Find which barcode has a value entered to determine the size of the unit
    // (if more than one barcode is entered, the largest (or last) takes precedence)
    const found = Object.keys(product).reverse().find(
      (barcodeKey) => barcodeKey.startsWith(barcodeKeyPrefix) && !!product[barcodeKey],
    );

    if (found) {
      const details = found.replace(barcodeKeyPrefix, '');

      const {
        label: baseUnitLabel,
        quantity: baseUnitQuantity,
      } = UNIT_DETAILS[unitType][details];
      unitQuantities[unitType] = `${baseUnitQuantity > 1 ? `${baseUnitQuantity} x ` : ''}${baseUnitLabel}`;
    }
  });

  const filteredName = name?.replace(/â€˜|â€™/g, "'");
  const filteredBlurb = blurb?.replace(/â€˜|â€™/g, "'");
  const filteredOneLiner = oneLiner?.replace(/â€˜|â€™/g, "'");

  return (
    <div
      className="email-snippet"
      style={{
        padding: '20px 0px',
        width: '600px',
        margin: '0 auto',
        borderTop: '1px solid #E5E5E5',
        borderBottom: '1px solid #E5E5E5',
        backgroundColor: 'white',
        // marginBottom: '10px',
        // borderTop: '1.3px dashed #d8d8d8',
      }}
    >
      <table align="center" style={{ maxWidth: '600px' }}>
        <tbody>
          <tr>
            <td
              colSpan=""
              width="180"
              style={{
                padding: '5px',
                backgroundColor: 'white',
                textAlign: 'center',
              }}
            >
              <img
                alt={`${filteredName}`}
                src={webRenderUrl || tapBadgeUrl}
                style={webRenderUrl ? { maxWidth: '180px', maxHeight: '180px', width: 'auto' } : { maxWidth: '140px' }}
              />
            </td>
            <td
              style={{
                backgroundColor: 'white',
                padding: '15px !important',
              }}
            >
              <h2>{name}</h2>
              <h3 id="beertitle">
                {`${(() => {
                  const abvIsRange = abvMin !== null || abvMax !== null;

                  if (abv === null && !abvIsRange) {
                    return <div className="empty-cell">N/A</div>;
                  }

                  return (abv !== null ? (
                    `${abv}%`
                  ) : (
                    `${abvMin === null ? '?' : `${abvMin}%`} - ${abvMax === null ? '?' : `${abvMax}%`}`
                  ));
                })()}`}
                {' '}
                -
                {' '}
                {filteredOneLiner}
              </h3>
              <p contentEditable="">{filteredBlurb}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="center" style={{ maxWidth: '600px' }}>
        <tbody>
          <tr>
            {prices.slab && (
            <td
              style={{ padding: '5px', backgroundColor: 'white', textAlign: 'center' }}
            >
              <h4
                className="contents"
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  margin: '0px',
                }}
              >
                SLAB
              </h4>
              {unitQuantities.slab && <h6 style={{ fontSize: '13px' }} contentEditable="">{unitQuantities.slab}</h6>}
              <h5
                contentEditable=""
                style={{
                  backgroundColor: '#F7F7F7',
                  padding: '7px',
                  margin: '2px',
                  fontSize: '18px',
                  fontWeight: '300',
                  textAlign: 'center',
                }}
              >
                {prices.slab}

              </h5>
            </td>
            )}
            {prices.case && (
            <td style={{ padding: '5px', backgroundColor: 'white', textAlign: 'center' }}>
              <h4
                className="contents"
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  margin: '0px',
                }}
              >
                CASE
              </h4>
              {unitQuantities.case && <h6 style={{ fontSize: '13px' }} contentEditable="">{unitQuantities.case}</h6>}
              <h5
                contentEditable=""
                style={{
                  backgroundColor: '#F7F7F7',
                  padding: '7px',
                  margin: '2px',
                  fontSize: '18px',
                  fontWeight: '300',
                  textAlign: 'center',
                }}
              >
                {prices.case}
              </h5>
            </td>
            )}

            {prices.keg && (
            <td style={{ padding: '5px', backgroundColor: 'white', textAlign: 'center' }}>
              <h4
                className="contents"
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  margin: '0px',
                }}
              >
                KEG
              </h4>
              {unitQuantities.keg && <h6 style={{ fontSize: '13px' }} contentEditable="">{unitQuantities.keg}</h6>}
              <h5
                contentEditable=""
                style={{
                  backgroundColor: '#F7F7F7',
                  padding: '7px',
                  margin: '2px',
                  fontSize: '18px',
                  fontWeight: '300',
                  textAlign: 'center',
                }}
              >
                {prices.keg}
              </h5>
            </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductSnippet;
