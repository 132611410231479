import React, { useState, useEffect, useContext } from 'react';
import './ProductTable.less';
import * as api from 'util/api.js';
import { AuthContext } from 'util/Auth';
import { Link } from 'react-router-dom';
import {
  Space, Button, Table as AntTable, Modal, Tag, Avatar, Badge, Tooltip, Typography,
} from 'antd';
import { CustomTable } from 'components';
import {
  PlusOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import { PRODUCT_TYPE_TAGS } from 'config/tags';
import { productProperties } from 'config/language';
import dayjs from 'dayjs';
import { MONDAY_PRODUCT_DATABASE_BOARD_ID } from '../MondayIntegration/constants';

const { Text } = Typography;

const { Column } = AntTable;

const ProductTable = () => {
  const [products, setProducts] = useState(null);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    api.getProducts().then(setProducts);
  }, []);

  const onDelete = (productToDelete) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${productToDelete.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be reversed.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk: () => api.deleteProduct(productToDelete.id)
        .then(() => {
          setProducts(products.filter((product) => (
            Number(product.id) !== Number(productToDelete.id)
          )));
        })
        .catch(),
    });
  };

  return (
    <CustomTable
      id="product-database"
      pluralLabel="products"
      searchableColumns={['name', 'type', 'oneLiner']}
      dataSource={products?.map((product) => (
        {
          ...product,
          key: product.id, // Each row must have a unique key
        }
      ))}
      controls={currentUser.admin ? (
        <Link to="/dashboard/product-database/products/add">
          <Button
            type="primary"
            icon={<PlusOutlined />}
          >
            Add product
          </Button>
        </Link>
      ) : null}
    >
      <Column
        title={null}
        dataIndex="tapBadgeUrl"
        key="tapBadge"
        width={64}
        className="avatar-column"
        render={(tapBadgeUrl) => (
          <Avatar src={tapBadgeUrl} />
        )}
      />
      <Column
        title={productProperties.name.labels.field}
        key="name"
        sortType="string"
        sortDirections={['ascend', 'descend', 'ascend']}
        filters={[{ text: 'Complete', value: true }, { text: 'Incomplete', value: false }]}
        onFilter={(value, { complete }) => value === complete}
        render={({
          id, name, complete,
        }) => (
          <Space size="middle">
            <Link
              to={`/dashboard/product-database/products/${id}${currentUser.admin ? '/edit' : ''}`}
              className="product-name-link"
            >
              <Tooltip title={`This product has ${complete ? '' : 'not yet '}been marked as complete`}>
                <Badge status={complete ? 'success' : 'error'} />
              </Tooltip>
              <Button type="link" className="inline-link-button">
                {name}
              </Button>
            </Link>
          </Space>
        )}
      />
      <Column
        title={productProperties.productType.labels.field}
        dataIndex="type"
        key="type"
        sortType="string"
        render={(type) => (
          <Tag color={PRODUCT_TYPE_TAGS[type]}>
            {type}
          </Tag>
        )}
        filters={Object.keys(PRODUCT_TYPE_TAGS).map((type) => ({ text: type, value: type }))}
        onFilter={(value, record) => value === record.type}
      />
      <Column
        title={productProperties.abv.labels.field}
        key="abv"
        render={({ abv, abvMin, abvMax }) => {
          const abvIsRange = abvMin !== null || abvMax !== null;

          if (abv === null && !abvIsRange) {
            return <div className="empty-cell">N/A</div>;
          }

          return (abv !== null ? (
            `${abv}%`
          ) : (
            `${abvMin === null || abvMin === undefined || abvMin === '' ? '?' : `${abvMin}%`} - ${abvMax === null || abvMax === undefined || abvMax === '' ? '?' : `${abvMax}%`}`
          ));
        }}
      />
      <Column
        title={productProperties.oneLiner.labels.field}
        dataIndex="oneLiner"
        key="oneLiner"
      />

      <Column
        title={productProperties.dateReleased.labels.field}
        key="dateReleased"
        dataIndex="dateReleased"
        render={(dateReleased, { releaseMonthUnknown }) => {
          let date;
          if (!dayjs.isDayjs(dateReleased)) {
            date = dateReleased;
          } else {
            date = dateReleased.format(releaseMonthUnknown ? 'YYYY' : 'MMMM YYYY');
          }
          return (
            <span className="no-line-break">
              {date}
            </span>
          );
        }}
      />
      <Column
        title="Requirements ready"
        key="Status"
        render={({ creativeReady, salesReady, productionReady }) => (
          <ul className="product-ready-status-list">
            <li className={creativeReady ? 'ready' : 'not-ready'}>
              <Text>Creative</Text>
              {creativeReady ? <CheckOutlined /> : <CloseOutlined /> }
            </li>
            <li className={salesReady ? 'ready' : 'not-ready'}>
              <Text>Sales</Text>
              {salesReady ? <CheckOutlined /> : <CloseOutlined /> }
            </li>
            <li className={productionReady ? 'ready' : 'not-ready'}>
              <Text>Production</Text>
              {productionReady ? <CheckOutlined /> : <CloseOutlined /> }
            </li>
          </ul>
        )}
      />
      <Column
        title={productProperties.dateAdded.labels.field}
        dataIndex="dateAdded"
        key="dateAdded"
        sortType="date"
        defaultSortOrder="descend"
        render={(value) => (dayjs.isDayjs(value) ? value.format('DD/MM/YYYY') : value)}
      />
      <Column
        style={{ whiteSpace: 'nowrap' }}
        title={productProperties.dateEdited.labels.field}
        dataIndex="dateLastModified"
        key="dateEdited"
        sortType="date"
        render={(value) => (dayjs.isDayjs(value) ? value.format('DD/MM/YYYY') : value)}
      />

      <Column
        title=""
        key="actions"
        render={(product) => (
          <Space size="middle">
            <Tooltip title={product.mondayId === null ? 'This product hasn\'t been linked to an item in Monday yet.' : undefined}>
              <Button
                type="link"
                disabled={product.mondayId === null}
                onClick={() => window.open(`https://garageproject.monday.com/boards/${MONDAY_PRODUCT_DATABASE_BOARD_ID}/pulses/${product.mondayId}`, '_blank')}
              >
                View in Monday
              </Button>
            </Tooltip>
            {currentUser.admin ? (
              <>
                <Link to={`/dashboard/product-database/products/${product.id}/edit`}>
                  <Button type="link">
                    Edit
                  </Button>
                </Link>
                <Button
                  type="link"
                  danger
                  onClick={() => onDelete(product)}
                >
                  Delete
                </Button>
              </>
            ) : null}
          </Space>
        )}
      />
    </CustomTable>
  );
};

export default ProductTable;
