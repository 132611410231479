import React, { useState, useEffect, useContext } from 'react';
import './BirthdayAnnouncement.less';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { GiftOutlined } from '@ant-design/icons';
import { MiscDataContext } from 'util/MiscData';
import AnimatedAlert from 'components/AnimatedAlert/AnimatedAlert';
import arrayToSentence from 'array-to-sentence';
import { AuthContext } from 'util/Auth';

const BirthdayAnnouncement = ({ forceClose }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [confettiSource, setConfettiSource] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const miscData = useContext(MiscDataContext);

  useEffect(() => {
    if (miscData) {
      const { birthdays } = miscData;
      if (birthdays.length > 0 && !forceClose) {
        // List the names in natural language
        const names = arrayToSentence(birthdays.map(({ name }) => name));
        setMessage(birthdays.length > 1 ? (
          `${names} have birthdays today!`
        ) : (
          `It's ${names}${names.charAt(names.length - 1) === 's' ? '\'' : '\'s'} birthday today!`
        ));
        setVisible(true);
        setTimeout(() => {
          const bounding = document.getElementById('birthday-icon')?.getBoundingClientRect();
          if (bounding) {
            setConfettiSource({
              x: bounding.x + bounding.width / 2,
              y: bounding.y + bounding.height / 2,
            });
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 800);
          }
        }, 800);
      }
    }
  }, [miscData]);

  // Close if the alert is visible, but alert is forced to close
  useEffect(() => {
    if (forceClose && visible) {
      setVisible(false);
    }
  }, [forceClose]);

  // TODO: Remove this once ready to release to everyone
  const { currentUser } = useContext(AuthContext);
  return currentUser.email !== 'leyton@garageproject.co.nz' ? null : (
    <>
      <AnimatedAlert
        id="birthday-alert"
        message={message}
        visible={visible}
        closable
        onClose={() => setVisible(false)}
        banner
        showIcon
        icon={<GiftOutlined id="birthday-icon" />}
      />
      { confettiSource && (
      <div id="confetti-container">
        <Confetti
          width={windowWidth}
          height={windowHeight}
          confettiSource={confettiSource}
          numberOfPieces={showConfetti ? 100 : 0}
        />
      </div>
      )}
    </>
  );
};

export default BirthdayAnnouncement;
