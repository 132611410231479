import React from 'react';
import PropTypes from 'prop-types';
import './HoverHelp.less';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const HoverHelp = ({ message, href }) => (
  <Tooltip title={href ? `${message} Click to learn more.` : message}>
    <div className="hover-help-container">
      {href ? (
        <a href={href} target="_blank" rel="noreferrer">
          <QuestionCircleOutlined className="hover-help-icon" />
        </a>
      ) : <QuestionCircleOutlined className="hover-help-icon" />}
    </div>
  </Tooltip>
);

HoverHelp.propTypes = {
  message: PropTypes.string.isRequired,
  href: PropTypes.string,
};

HoverHelp.defaultProps = {
  href: null,
};

export default HoverHelp;
