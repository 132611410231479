import React from 'react';
import './Foodstuffs.less';
import * as api from 'util/api.js';
import { Switch, Route, Redirect } from 'react-router-dom';
import FoodstuffsTable from './FoodstuffsTable/FoodstuffsTable';

const Foodstuffs = () => (
  <Switch>
    {Object.values(api.FOODSTUFFS_REGIONS).map((region) => (
      <Route
        key={region}
        exact
        path={`/dashboard/foodstuffs/${region}`}
        render={() => <FoodstuffsTable region={region} />}
      />
    ))}
    <Redirect to="/dashboard/foodstuffs" />
  </Switch>
);

export default Foodstuffs;
