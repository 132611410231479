import React from 'react';
import { Form, Input } from 'antd';
import { productProperties } from 'config/language';

const Foodstuffs = () => (
  <div className="columns-container">
    <div className="column">
      <Form.Item
        name="foodstuffsArticleNumberNorthIsland"
        label={productProperties.foodstuffsArticleNumberNorthIsland.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="foodstuffsArticleNumberSouthIsland"
        label={productProperties.foodstuffsArticleNumberSouthIsland.labels.field}
        labelCol={{ span: 24 }}
      >
        <Input type="number" />
      </Form.Item>
    </div>
  </div>
);

export default Foodstuffs;
