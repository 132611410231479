export const PRODUCT_TYPE_TAGS = {
  Beer: 'gold',
  Wine: 'cyan',
  Wild: 'purple',
  Seltzer: 'green',
  Other: 'blue',
};

export const WEBSTORE_REQUEST_TAGS = {
  pending: 'blue',
  approved: 'green',
  rejected: 'red',
  failed: 'red',
};
