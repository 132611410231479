import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './DTCProductTable.less';
import * as api from 'util/api.js';
import {
  Table as AntTable, Tooltip, Checkbox, Avatar, Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CustomTable, DTCProductModal } from 'components';
import { TableConfigContext } from 'util/TableConfig';

const { Column } = AntTable;

const DTCProductTable = ({
  prices,
  type,
  selectionType,
  pluralLabel,
  GeneratorFormContent,
  onGenerate,
  generatorStatus,
  generatorOutput,
  onGeneratorReset,
  StoreButton,
  store,
}) => {
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [generatorModalOpen, setGeneratorModalOpen] = useState(false);

  useEffect(() => {
    api.getProducts().then(setProducts);
  }, []);

  const tableId = `generator-product-table-${type}-${pluralLabel}`;

  const onGenerateClicked = () => {
    setGeneratorModalOpen(true);
    console.log(selectedProducts);
  };

  const handleClose = () => {
    setGeneratorModalOpen(false);

    // Avoid flickering back to form view
    setTimeout(() => onGeneratorReset(), 300);
  };

  return (
    <>
      <CustomTable
        id={tableId}
        pluralLabel="products"
        searchableColumns={['name', 'abv', 'oneLiner']}
        enableSelection
        selectionType={selectionType}
        onSelectionChange={setSelectedProducts}
        dataSource={products?.map((product) => (
          {
            ...product,
            key: product.id, // Each row must have a unique key
          }
        ))}
        controls={(
          <Tooltip title={!selectedProducts.length ? `Select products to generate ${pluralLabel} for first` : null}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              // Disable when no products are selected or loading
              disabled={!selectedProducts.length || !products}
              onClick={onGenerateClicked}
            >
              {`Generate ${pluralLabel}`}
            </Button>
          </Tooltip>
      )}
        secondaryControl={(<StoreButton />)}
      >
        <Column
          title={null}
          dataIndex="tapBadgeUrl"
          key="tapBadge"
          width={64}
          render={(tapBadgeUrl) => (
            <Avatar src={tapBadgeUrl} />
          )}
        />
        <Column
          title="Name"
          dataIndex="name"
        />
        {store === 'GPNZ'
          ? (
            <Column
              title="NZ RRP"
              dataIndex="rrp"
            />
          )
          : (
            <Column
              title="AUD RRP"
              dataIndex="rrpAud"
            />
          )}
        <Column
          title="ABV"
          dataIndex="abv"
          key="abv"
          render={(abv) => `${abv}%`}
        />
        <Column title="One Liner" dataIndex="oneLiner" key="oneLiner" />
      </CustomTable>
      <DTCProductModal
        store={store}
        prices={prices}
        type={type}
        selectionType={selectionType}
        pluralLabel={pluralLabel}
        products={selectedProducts}
        open={generatorModalOpen}
        onClose={handleClose}
        onGenerate={onGenerate}
        generatorStatus={generatorStatus}
        generatorOutput={generatorOutput}
        FormContent={GeneratorFormContent}
      />
    </>
  );
};

DTCProductTable.propTypes = {
  type: PropTypes.oneOf(['shopify', 'kounta']).isRequired,
  selectionType: PropTypes.oneOf(['single', 'multiple']).isRequired,
  pluralLabel: PropTypes.string.isRequired,
  GeneratorFormContent: PropTypes.elementType.isRequired,
  onGenerate: PropTypes.func.isRequired,
  generatorStatus: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    success: PropTypes.bool,
  }),
  generatorOutput: PropTypes.shape({
    type: PropTypes.oneOf(['pdf']),
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any,
  }),
  onGeneratorReset: PropTypes.func,
};

DTCProductTable.defaultProps = {
  generatorStatus: {
    active: false,
    error: null,
    success: false,
  },
  generatorOutput: null,
  onGeneratorReset: () => {},
};

export default DTCProductTable;
