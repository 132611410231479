import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomerContactSelect.less';
import { Select, Spin } from 'antd';
import * as api from 'util/api';

const EmptyContent = ({ loading, error, filteredCustomers }) => {
  if (loading) return <Spin size="small" />;
  if (error) return <div>Error loading customer data</div>;
  if (filteredCustomers === null) return <div>Start typing to search...</div>;
  return <div>No existing customers match your search</div>;
};

const CustomerContactSelect = ({
  onChange,
  value,
  store,
  storeIsNZ,
}) => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const fetchCustomerData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.getAllCustomerData(store);
      setAllCustomers(response);
      setError(false);
    } catch (err) {
      console.error('Error fetching customer data:', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [store]);

  useEffect(() => {
    fetchCustomerData();
  }, [fetchCustomerData]);

  const onSearch = (term) => {
    setSearchTerm(term);
    if (!term.trim()) {
      setFilteredCustomers(null);
    } else {
      const searchWords = term.toLowerCase().split(/\s+/);
      const filtered = allCustomers.filter((customer) => searchWords.every((word) => (customer.firstName?.toLowerCase() ?? '').includes(word)
        || (customer.lastName?.toLowerCase() ?? '').includes(word)
        || (customer.email?.toLowerCase() ?? '').includes(word)
        || `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(word)));
      setFilteredCustomers(filtered.map((customer) => ({
        label: `${customer.firstName} ${customer.lastName} - ${customer.email}`,
        value: customer.id,
        customer,
      })));
    }
  };

  const onSelectCustomer = (selection) => {
    const customer = filteredCustomers.find((c) => c.value === selection);
    setSelectedCustomer(customer.customer);
    if (customer.customer.subscriptions.length === 1) {
      setSelectedAddressId(customer.customer.subscriptions[0].addressId);
      onChange({
        ...customer.customer,
        addressId: customer.customer.subscriptions[0].addressId,
      });
    } else {
      setSelectedAddressId(null);
    }
  };

  const onSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
    onChange({
      ...selectedCustomer,
      addressId,
    });
  };

  return (
    <div className="customer-contact-select">
      <Select
        showSearch
        value={selectedCustomer?.id}
        placeholder="Select a customer"
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelectCustomer}
        notFoundContent={(
          <EmptyContent
            loading={loading}
            error={error}
            filteredCustomers={filteredCustomers}
          />
        )}
        loading={loading}
        options={filteredCustomers}
      />
      {selectedCustomer && selectedCustomer.subscriptions.length > 1 && (
        <Select
          style={{ width: '100%', marginTop: '10px' }}
          placeholder="Select an address"
          value={selectedAddressId}
          onChange={onSelectAddress}
        >
          {selectedCustomer.subscriptions.map((subscription) => (
            <Select.Option key={subscription.addressId} value={subscription.addressId}>
              {`Address ID: ${subscription.addressId}`}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

CustomerContactSelect.propTypes = {
  storeIsNZ: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    contactName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    value: PropTypes.string,
    email: PropTypes.shape({
      value: PropTypes.string,
      custom: PropTypes.bool,
    }),
    addressId: PropTypes.string,
  }),
  store: PropTypes.string.isRequired,
};

CustomerContactSelect.defaultProps = {
  onChange: () => {},
  value: null,
};

export default CustomerContactSelect;
